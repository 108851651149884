import { Pipe, PipeTransform } from '@angular/core';
import {
    IModelType,
    IMSTArray,
    ModelInstanceType,
    ModelProperties
} from 'mobx-state-tree';
import { STNValue } from 'mobx-state-tree/dist/internal';

@Pipe({
    name: 'mstArray'
})
export class MSTArrayPipe implements PipeTransform {
    transform<T extends ModelProperties, O, FC, FS>(
        values: IMSTArray<IModelType<T, O, FC, FS>>
    ): Array<STNValue<ModelInstanceType<T, O>, IModelType<T, O, FC, FS>>> {
        return values;
    }
}
