import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toClassName'
})
export class ToClassNamePipe implements PipeTransform {
    transform(value: string, args?: any): string {
        return value ? value.replace(' ', '').toLocaleLowerCase() : '';
    }
}
