import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { SelectItem } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-goldeye-select-button',
    templateUrl: './select-button.component.html',
    styleUrls: ['./select-button.component.scss']
})
export class SelectButtonComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() options: Array<ISelectItem | SelectItem | string>;
    @Input() disabled = false;
    @Input() required = false;
    @Input() multiple = false;

    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() containerClass?: string;

    @Input() model: number | string | Array<number> | Array<string>;
    @Output() modelChange = new EventEmitter<any>();

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        this.modelChange.emit(event);
    }
}
