import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService } from '@app/core/services';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public auth: AuthenticationService, public router: Router) {}

    /* DANGER: The testAuthenticated boolean variable and if block is an auth0 bypass.  For dev purposes only. */
    // testAuthenticated = true;

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.isAuthenticated()
            ? true
            : this.router.parseUrl(`login?returnUrl=${state.url}`);
    }
}
