import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IPropertyAccessModel } from '@app/modules/property-team/models/property-access/property-access.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-user-mini-list',
    templateUrl: './property-access-user-mini-list.component.html',
    styleUrls: ['./property-access-user-mini-list.component.scss']
})
export class PropertyAccessUserMiniListComponent {
    @Input() propertyAccess: IPropertyAccessModel[];
}
