import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import { ICommentFormModel } from '@app/shared/models/comment/comment-form.model';
import { ICommentGridModel } from '@app/shared/models/comment/comment-grid.model';
import { Column } from '@app/core/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-action-item-comments',
    templateUrl: './action-item-comments.component.html',
    styleUrls: ['./action-item-comments.component.scss']
})
export class ActionItemCommentsComponent implements OnInit {
    @Input() comments: ICommentGridModel;
    @Input() commentForm: ICommentFormModel;
    @Output() addComment = new EventEmitter<ICommentFormModel>();

    commentsColumns: Column[];

    ngOnInit() {
        this.commentsColumns = [
            {
                field: 'createdDateAsDate',
                header: 'Date',
                type: 'dateOnly',
                sortColumn: 'createdDate'
            },
            { field: 'createdBy', header: 'Employee', sortColumn: 'createdBy' },
            {
                field: 'contents',
                header: 'Comment',
                type: 'html',
                sortColumn: 'contents'
            }
        ];
    }

    onSaveBtnClicked() {
        this.addComment.emit(this.commentForm);
    }
}
