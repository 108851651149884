<div class="p-grid p-align-center control-padding" *ngIf="!fieldAccess?.isHidden">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-inputMask
            [mask]="mask"
            [appHighlightOnValidationError]="name"
            name="{{ name }}"
            [appFocusIf]="focus"
            [ngModel]="model"
            [placeholder]="placeholder"
            (change)="onChange($event)"
            [disabled]="fieldAccess?.isLocked"
        >
        </p-inputMask>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
