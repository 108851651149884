<div *mobxAutorun>
    <p-dialog
        [(visible)]="showLinkFormDialog"
        (onHide)="showLinkFormDialog = false"
        [modal]="true"
        class="medium-modal claim-step-dialog"
    >
        <p-header>
            <header class="claim-step-dialog-header">
                <i class="fas fa-external-link-alt header-icon"></i>
                <h2>{{ dialogHeader }}</h2>
            </header>
        </p-header>
        <app-link-form [form]="form" (savingDone)="showLinkFormDialog = false">
        </app-link-form>
    </p-dialog>

    <div class="p-grid">
        <div class="p-col-12 p-md-10">
            <app-link-list-grid
                [columns]="columns"
                [grid]="grid"
                (editClicked)="onEdit($event)"
            >
            </app-link-list-grid>
        </div>

        <div class="p-col-12 p-md-2">
            <button
                pButton
                icon="fa fa-plus"
                class="secondary-action-btn"
                label="Add New"
                style="width: 100%;"
                (click)="onAddNewLinkBtnClick()"
            ></button>
        </div>
    </div>
</div>
