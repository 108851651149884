export const countries = [
    { label: 'All', value: null },
    { label: 'Canada', value: 'Canada' },
    { label: 'USA', value: 'USA' }
];

export const provinces = {
    'Canada': [
        { label: 'All', value: null },
        { label: 'Alberta', value: 'Alberta' },
        { label: 'British Columbia', value: 'British Columbia' },
        { label: 'Manitoba', value: 'MB' },
        { label: 'New Brunswick', value: 'New Brunswick' },
        { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
        { label: 'Nova Scotia', value: 'Nova Scotia' },
        { label: 'Ontario', value: 'Ontario' },
        { label: 'Prince Edward Island', value: 'Prince Edward Island' },
        { label: 'Quebec', value: 'Quebec' },
        { label: 'Saskatchewan', value: 'Saskatchewan' },
        { label: 'Northwest Territories', value: 'Northwest Territories' },
        { label: 'Nunavut', value: 'Nunavut' },
        { label: 'Yukon', value: 'Yukon' }
    ],
    'USA': []
};


export const cities = {
    'Alberta': [
        { label: 'All', value: null },
        { label: 'Airdrie', value: 'Airdrie' },
        { label: 'Brooks', value: 'Brooks' },
        { label: 'Calgary', value: 'Calgary' },
        { label: 'Camrose', value: 'Camrose' },
        { label: 'Chestermere', value: 'Chestermere' },
        { label: 'Cold Lake', value: 'Cold Lake' },
        { label: 'Edmonton', value: 'Edmonton' },
        { label: 'Fort Saskatchewan', value: 'Fort Saskatchewan' },
        { label: 'Grande Prairie', value: 'Grande Prairie' },
        { label: 'Lacombe', value: 'Lacombe' },
        { label: 'Leduc', value: 'Leduc' },
        { label: 'Lethbridge', value: 'Lethbridge' },
        { label: 'Lloydminster', value: 'Lloydminster' },
        { label: 'Medicine Hat', value: 'Medicine Hat' },
        { label: 'Red Deer', value: 'Red Deer' },
        { label: 'Spruce Grove', value: 'Spruce Grove' },
        { label: 'St. Albert', value: 'St. Albert' },
        { label: 'Wetaskiwin', value: 'Wetaskiwin' }
    ],

    'British Columbia': [
        { label: 'All', value: null },
        { label: 'Abbotsford', value: 'Abbotsford' },
        { label: 'Armstrong', value: 'Armstrong' },
        { label: 'Burnaby', value: 'Burnaby' },
        { label: 'Campbell River', value: 'Campbell River' },
        { label: 'Castlegar', value: 'Castlegar' },
        { label: 'Chilliwack', value: 'Chilliwack' },
        { label: 'Colwood', value: 'Colwood' },
        { label: 'Coquitlam', value: 'Coquitlam' },
        { label: 'Courtenay', value: 'Courtenay' },
        { label: 'Cranbrook', value: 'Cranbrook' },
        { label: 'Dawson Creek', value: 'Dawson Creek' },
        { label: 'Delta', value: 'Delta' },
        { label: 'Duncan', value: 'Duncan' },
        { label: 'Enderby', value: 'Enderby' },
        { label: 'Fernie', value: 'Fernie' },
        { label: 'Fort St. John', value: 'Fort St. John' },
        { label: 'Grand Forks', value: 'Grand Forks' },
        { label: 'Greenwood', value: 'Greenwood' },
        { label: 'Kamloops', value: 'Kamloops' },
        { label: 'Kelowna', value: 'Kelowna' },
        { label: 'Kimberley', value: 'Kimberley' },
        { label: 'Langford', value: 'Langford' },
        { label: 'Langley', value: 'Langley' },
        { label: 'Maple Ridge', value: 'Maple Ridge' },
        { label: 'Merritt', value: 'Merritt' },
        { label: 'Nanaimo', value: 'Nanaimo' },
        { label: 'Nelson', value: 'Nelson' },
        { label: 'New Westminster', value: 'New Westminster' },
        { label: 'North Vancouver', value: 'North Vancouver' },
        { label: 'Parksville', value: 'Parksville' },
        { label: 'Penticton', value: 'Penticton' },
        { label: 'Pitt Meadows', value: 'Pitt Meadows' },
        { label: 'Port Alberni', value: 'Port Alberni' },
        { label: 'Port Coquitlam', value: 'Port Coquitlam' },
        { label: 'Port Moody', value: 'Port Moody' },
        { label: 'Powell River', value: 'Powell River' },
        { label: 'Prince George', value: 'Prince George' },
        { label: 'Prince Rupert', value: 'Prince Rupert' },
        { label: 'Quesnel', value: 'Quesnel' },
        { label: 'Revelstoke', value: 'Revelstoke' },
        { label: 'Richmond', value: 'Richmond' },
        { label: 'Rossland', value: 'Rossland' },
        { label: 'Salmon Arm', value: 'Salmon Arm' },
        { label: 'Surrey', value: 'Surrey' },
        { label: 'Terrace', value: 'Terrace' },
        { label: 'Trail', value: 'Trail' },
        { label: 'Vancouver', value: 'Vancouver' },
        { label: 'Vernon', value: 'Vernon' },
        { label: 'Victoria', value: 'Victoria' },
        { label: 'West Kelowna', value: 'West Kelowna' },
        { label: 'White Rock', value: 'White Rock' },
        { label: 'Williams Lake', value: 'Williams Lake' }
    ],
    'MB': [
        { label: 'All', value: null },
        { label: 'Brandon', value: 'Brandon' },
        { label: 'Dauphin', value: 'Dauphin' },
        { label: 'Flin Flon', value: 'Flin Flon' },
        { label: 'Morden', value: 'Morden' },
        { label: 'Portage la Prairie', value: 'Portage la Prairie' },
        { label: 'Selkirk', value: 'Selkirk' },
        { label: 'Steinbach', value: 'Steinbach' },
        { label: 'Thompson', value: 'Thompson' },
        { label: 'Winkler', value: 'Winkler' },
        { label: 'Winnipeg', value: 'Winnipeg' }
    ],

    'New Brunswick': [
        { label: 'All', value: null },
        { label: 'Bathurst', value: 'Bathurst' },
        { label: 'Campbellton', value: 'Campbellton' },
        { label: 'Dieppe', value: 'Dieppe' },
        { label: 'Edmundston', value: 'Edmundston' },
        { label: 'Fredericton', value: 'Fredericton' },
        { label: 'Miramichi', value: 'Miramichi' },
        { label: 'Moncton', value: 'Moncton' },
        { label: 'Saint John', value: 'Saint John' }
    ],

    'Newfoundland and Labrador': [
        { label: 'All', value: null },
        { label: 'Corner Brook', value: 'Corner Brook' },
        { label: 'Mount Pearl', value: 'Mount Pearl' },
        { label: 'St. John\'s', value: 'St. John\'s' }
    ],

    'Nova Scotia': [
        { label: 'All', value: null },
    ],

    'Ontario' : [
        { label: 'All', value: null },
        { label: 'Barrie', value: 'Barrie' },
        { label: 'Belleville', value: 'Belleville' },
        { label: 'Brampton', value: 'Brampton' },
        { label: 'Brant', value: 'Brant' },
        { label: 'Brantford', value: 'Brantford' },
        { label: 'Brockville', value: 'Brockville' },
        { label: 'Burlington', value: 'Burlington' },
        { label: 'Cambridge', value: 'Cambridge' },
        { label: 'Caledon', value: 'Caledon' },
        { label: 'Clarence-Rockland', value: 'Clarence-Rockland' },
        { label: 'Cornwall', value: 'Cornwall' },
        { label: 'Dryden', value: 'Dryden' },
        { label: 'Elliot Lake', value: 'Elliot Lake' },
        { label: 'Greater Sudbury', value: 'Greater Sudbury' },
        { label: 'Guelph', value: 'Guelph' },
        { label: 'Haldimand County', value: 'Haldimand County' },
        { label: 'Hamilton', value: 'Hamilton' },
        { label: 'Kawartha Lakes', value: 'Kawartha Lakes' },
        { label: 'Kenora', value: 'Kenora' },
        { label: 'Kingston', value: 'Kingston' },
        { label: 'Kitchener', value: 'Kitchener' },
        { label: 'London', value: 'London' },
        { label: 'Markham', value: 'Markham' },
        { label: 'Mississauga', value: 'Mississauga' },
        { label: 'Niagara Falls', value: 'Niagara Falls' },
        { label: 'Norfolk County', value: 'Norfolk County' },
        { label: 'North Bay', value: 'North Bay' },
        { label: 'Orillia', value: 'Orillia' },
        { label: 'Oshawa', value: 'Oshawa' },
        { label: 'Ottawa', value: 'Ottawa' },
        { label: 'Owen Sound', value: 'Owen Sound' },
        { label: 'Pembroke', value: 'Pembroke' },
        { label: 'Peterborough', value: 'Peterborough' },
        { label: 'Pickering', value: 'Pickering' },
        { label: 'Port Colborne', value: 'Port Colborne' },
        { label: 'Prince Edward County', value: 'Prince Edward County' },
        { label: 'Quinte West', value: 'Quinte West' },
        { label: 'Sarnia', value: 'Sarnia' },
        { label: 'Sault Ste. Marie', value: 'Sault Ste. Marie' },
        { label: 'St. Catharines', value: 'St. Catharines' },
        { label: 'St. Thomas', value: 'St. Thomas' },
        { label: 'Stratford', value: 'Stratford' },
        { label: 'Temiskaming Shores', value: 'Temiskaming Shores' },
        { label: 'Thorold', value: 'Thorold' },
        { label: 'Thunder Bay', value: 'Thunder Bay' },
        { label: 'Timmins', value: 'Timmins' },
        { label: 'Toronto', value: 'Toronto' },
        { label: 'Vaughan', value: 'Vaughan' },
        { label: 'Waterloo', value: 'Waterloo' },
        { label: 'Welland', value: 'Welland' },
        { label: 'Windsor', value: 'Windsor' },
        { label: 'Woodstock', value: 'Woodstock' }
    ],

    'Prince Edward Island': [
        { label: 'All', value: null },
        { label: 'Charlottetown', value: 'Charlottetown' },
        { label: 'Summerside', value: 'Summerside' }
    ],

    'Quebec': [
        { label: 'All', value: null },
        { label: 'Acton Vale', value: 'Acton Vale' },
        { label: 'Alma', value: 'Alma' },
        { label: 'Amos', value: 'Amos' },
        { label: 'Amqui', value: 'Amqui' },
        { label: 'Asbestos', value: 'Asbestos' },
        { label: 'Baie-Comeau', value: 'Baie-Comeau' },
        { label: 'Baie-D\'Urfé', value: 'Baie-D\'Urfé' },
        { label: 'Baie-Saint-Paul', value: 'Baie-Saint-Paul' },
        { label: 'Barkmere', value: 'Barkmere' },
        { label: 'Beaconsfield', value: 'Beaconsfield' },
        { label: 'Beauceville', value: 'Beauceville' },
        { label: 'Beauharnois', value: 'Beauharnois' },
        { label: 'Beaupré', value: 'Beaupré' },
        { label: 'Bécancour', value: 'Bécancour' },
        { label: 'Bedford', value: 'Bedford' },
        { label: 'Belleterre', value: 'Belleterre' },
        { label: 'Beloeil', value: 'Beloeil' },
        { label: 'Berthierville', value: 'Berthierville' },
        { label: 'Blainville', value: 'Blainville' },
        { label: 'Boisbriand', value: 'Boisbriand' },
        { label: 'Bois-des-Filion', value: 'Bois-des-Filion' },
        { label: 'Bonaventure', value: 'Bonaventure' },
        { label: 'Boucherville', value: 'Boucherville' },
        { label: 'Brome Lake', value: 'Brome Lake' },
        { label: 'Bromont', value: 'Bromont' },
        { label: 'Brossard', value: 'Brossard' },
        { label: 'Brownsburg-Chatham', value: 'Brownsburg-Chatham' },
        { label: 'Candiac', value: 'Candiac' },
        { label: 'Cap-Chat', value: 'Cap-Chat' },
        { label: 'Cap-Santé', value: 'Cap-Santé' },
        { label: 'Carignan', value: 'Carignan' },
        { label: 'Carleton-sur-Mer', value: 'Carleton-sur-Mer' },
        { label: 'Causapscal', value: 'Causapscal' },
        { label: 'Chambly', value: 'Chambly' },
        { label: 'Chandler', value: 'Chandler' },
        { label: 'Chapais', value: 'Chapais' },
        { label: 'Charlemagne', value: 'Charlemagne' },
        { label: 'Châteauguay', value: 'Châteauguay' },
        { label: 'Château-Richer', value: 'Château-Richer' },
        { label: 'Chibougamau', value: 'Chibougamau' },
        { label: 'Clermont', value: 'Clermont' },
        { label: 'Coaticook', value: 'Coaticook' },
        { label: 'Contrecoeur', value: 'Contrecoeur' },
        { label: 'Cookshire-Eaton', value: 'Cookshire-Eaton' },
        { label: 'Côte Saint-Luc', value: 'Côte Saint-Luc' },
        { label: 'Coteau-du-Lac', value: 'Coteau-du-Lac' },
        { label: 'Cowansville', value: 'Cowansville' },
        { label: 'Danville', value: 'Danville' },
        { label: 'Daveluyville', value: 'Daveluyville' },
        { label: 'Dégelis', value: 'Dégelis' },
        { label: 'Delson', value: 'Delson' },
        { label: 'Desbiens', value: 'Desbiens' },
        { label: 'Deux-Montagnes', value: 'Deux-Montagnes' },
        { label: 'Disraeli', value: 'Disraeli' },
        { label: 'Dolbeau-Mistassini', value: 'Dolbeau-Mistassini' },
        { label: 'Dollard-des-Ormeaux', value: 'Dollard-des-Ormeaux' },
        { label: 'Donnacona	Portneuf', value: 'Donnacona	Portneuf' },
        { label: 'Dorval', value: 'Dorval' },
        { label: 'Drummondville', value: 'Drummondville' },
        { label: 'Dunham', value: 'Dunham' },
        { label: 'Duparquet', value: 'Duparquet' },
        { label: 'East Angus', value: 'East Angus' },
        { label: 'Estérel', value: 'Estérel' },
        { label: 'Farnham', value: 'Farnham' },
        { label: 'Fermont', value: 'Fermont' },
        { label: 'Forestville', value: 'Forestville' },
        { label: 'Fossambault-sur-le-Lac', value: 'Fossambault-sur-le-Lac' },
        { label: 'Gaspé', value: 'Gaspé' },
        { label: 'Gatineau', value: 'Gatineau' },
        { label: 'Gracefield', value: 'Gracefield' },
        { label: 'Granby', value: 'Granby' },
        { label: 'Grande-Rivière', value: 'Grande-Rivière' },
        { label: 'Hampstead', value: 'Hampstead' },
        { label: 'Hudson', value: 'Hudson' },
        { label: 'Huntingdon', value: 'Huntingdon' },
        { label: 'Joliette', value: 'Joliette' },
        { label: 'Kingsey Falls', value: 'Kingsey Falls' },
        { label: 'Kirkland', value: 'Kirkland' },
        { label: 'La Malbaie', value: 'La Malbaie' },
        { label: 'La Pocatière', value: 'La Pocatière' },
        { label: 'La Prairie', value: 'La Prairie' },
        { label: 'La Sarre', value: 'La Sarre' },
        { label: 'La Tuque', value: 'La Tuque' },
        { label: 'Lac-Delage', value: 'Lac-Delage' },
        { label: 'Lachute', value: 'Lachute' },
        { label: 'Lac-Mégantic', value: 'Lac-Mégantic' },
        { label: 'Lac-Saint-Joseph', value: 'Lac-Saint-Joseph' },
        { label: 'Lac-Sergent	Portneuf', value: 'Lac-Sergent	Portneuf' },
        { label: 'L\'Ancienne-Lorette', value: 'L\'Ancienne-Lorette' },
        { label: 'L\'Assomption', value: 'L\'Assomption' },
        { label: 'Laval', value: 'Laval' },
        { label: 'Lavaltrie', value: 'Lavaltrie' },
        { label: 'Lebel-sur-Quévillon', value: 'Lebel-sur-Quévillon' },
        { label: 'L\'Épiphanie', value: 'L\'Épiphanie' },
        { label: 'Léry', value: 'Léry' },
        { label: 'Lévis', value: 'Lévis' },
        { label: 'L\'Île-Cadieux', value: 'L\'Île-Cadieux' },
        { label: 'L\'Île-Dorval', value: 'L\'Île-Dorval' },
        { label: 'L\'Île-Perrot', value: 'L\'Île-Perrot' },
        { label: 'Longueuil', value: 'Longueuil' },
        { label: 'Lorraine', value: 'Lorraine' },
        { label: 'Louiseville', value: 'Louiseville' },
        { label: 'Macamic', value: 'Macamic' },
        { label: 'Magog', value: 'Magog' },
        { label: 'Malartic', value: 'Malartic' },
        { label: 'Maniwaki', value: 'Maniwaki' },
        { label: 'Marieville', value: 'Marieville' },
        { label: 'Mascouche', value: 'Mascouche' },
        { label: 'Matagami', value: 'Matagami' },
        { label: 'Matane', value: 'Matane' },
        { label: 'Mercier', value: 'Mercier' },
        { label: 'Métabetchouan–Lac-à-la-Croix', value: 'Métabetchouan–Lac-à-la-Croix' },
        { label: 'Métis-sur-Mer', value: 'Métis-sur-Mer' },
        { label: 'Mirabel', value: 'Mirabel' },
        { label: 'Mont-Joli', value: 'Mont-Joli' },
        { label: 'Mont-Laurier', value: 'Mont-Laurier' },
        { label: 'Montmagny', value: 'Montmagny' },
        { label: 'Montreal', value: 'Montreal' },
        { label: 'Montreal West', value: 'Montreal West' },
        { label: 'Montréal-Est', value: 'Montréal-Est' },
        { label: 'Mont-Saint-Hilaire', value: 'Mont-Saint-Hilaire' },
        { label: 'Mont-Tremblant', value: 'Mont-Tremblant' },
        { label: 'Mount Royal', value: 'Mount Royal' },
        { label: 'Murdochville', value: 'Murdochville' },
        { label: 'Neuville', value: 'Neuville' },
        { label: 'New Richmond', value: 'New Richmond' },
        { label: 'Nicolet', value: 'Nicolet' },
        { label: 'Normandin', value: 'Normandin' },
        { label: 'Notre-Dame-de-l\'Île-Perrot', value: 'Notre-Dame-de-l\'Île-Perrot' },
        { label: 'Notre-Dame-des-Prairies	Joliette', value: 'Notre-Dame-des-Prairies	Joliette' },
        { label: 'Otterburn Park', value: 'Otterburn Park' },
        { label: 'Paspébiac', value: 'Paspébiac' },
        { label: 'Percé', value: 'Percé' },
        { label: 'Pincourt', value: 'Pincourt' },
        { label: 'Plessisville', value: 'Plessisville' },
        { label: 'Pohénégamook', value: 'Pohénégamook' },
        { label: 'Pointe-Claire', value: 'Pointe-Claire' },
        { label: 'Pont-Rouge', value: 'Pont-Rouge' },
        { label: 'Port-Cartier', value: 'Port-Cartier' },
        { label: 'Portneuf', value: 'Portneuf' },
        { label: 'Prévost', value: 'Prévost' },
        { label: 'Princeville', value: 'Princeville' },
        { label: 'Québec', value: 'Québec' },
        { label: 'Repentigny', value: 'Repentigny' },
        { label: 'Richelieu', value: 'Richelieu' },
        { label: 'Richmond', value: 'Richmond' },
        { label: 'Rimouski', value: 'Rimouski' },
        { label: 'Rivière-du-Loup', value: 'Rivière-du-Loup' },
        { label: 'Rivière-Rouge', value: 'Rivière-Rouge' },
        { label: 'Roberval', value: 'Roberval' },
        { label: 'Rosemère', value: 'Rosemère' },
        { label: 'Rouyn-Noranda', value: 'Rouyn-Noranda' },
        { label: 'Saguenay', value: 'Saguenay' },
        { label: 'Saint-Augustin-de-Desmaures', value: 'Saint-Augustin-de-Desmaures' },
        { label: 'Saint-Basile', value: 'Saint-Basile' },
        { label: 'Saint-Basile-le-Grand', value: 'Saint-Basile-le-Grand' },
        { label: 'Saint-Bruno-de-Montarville', value: 'Saint-Bruno-de-Montarville' },
        { label: 'Saint-Césaire', value: 'Saint-Césaire' },
        { label: 'Saint-Colomban', value: 'Saint-Colomban' },
        { label: 'Saint-Constant', value: 'Saint-Constant' },
        { label: 'Sainte-Adèle', value: 'Sainte-Adèle' },
        { label: 'Sainte-Agathe-des-Monts', value: 'Sainte-Agathe-des-Monts' },
        { label: 'Sainte-Anne-de-Beaupré', value: 'Sainte-Anne-de-Beaupré' },
        { label: 'Sainte-Anne-de-Bellevue', value: 'Sainte-Anne-de-Bellevue' },
        { label: 'Sainte-Anne-des-Monts', value: 'Sainte-Anne-des-Monts' },
        { label: 'Sainte-Anne-des-Plaines', value: 'Sainte-Anne-des-Plaines' },
        { label: 'Sainte-Catherine', value: 'Sainte-Catherine' },
        { label: 'Sainte-Catherine-de-la-Jacques-Cartier', value: 'Sainte-Catherine-de-la-Jacques-Cartier' },
        { label: 'Sainte-Julie', value: 'Sainte-Julie' },
        { label: 'Sainte-Marguerite-du-Lac-Masson', value: 'Sainte-Marguerite-du-Lac-Masson' },
        { label: 'Sainte-Marie', value: 'Sainte-Marie' },
        { label: 'Sainte-Marthe-sur-le-Lac', value: 'Sainte-Marthe-sur-le-Lac' },
        { label: 'Sainte-Thérèse', value: 'Sainte-Thérèse' },
        { label: 'Saint-Eustache', value: 'Saint-Eustache' },
        { label: 'Saint-Félicien', value: 'Saint-Félicien' },
        { label: 'Saint-Gabriel', value: 'Saint-Gabriel' },
        { label: 'Saint-Georges', value: 'Saint-Georges' },
        { label: 'Saint-Hyacinthe', value: 'Saint-Hyacinthe' },
        { label: 'Saint-Jean-sur-Richelieu', value: 'Saint-Jean-sur-Richelieu' },
        { label: 'Saint-Jérôme', value: 'Saint-Jérôme' },
        { label: 'Saint-Joseph-de-Beauce', value: 'Saint-Joseph-de-Beauce' },
        { label: 'Saint-Joseph-de-Sorel', value: 'Saint-Joseph-de-Sorel' },
        { label: 'Saint-Lambert', value: 'Saint-Lambert' },
        { label: 'Saint-Lazare', value: 'Saint-Lazare' },
        { label: 'Saint-Lin-Laurentides', value: 'Saint-Lin-Laurentides' },
        { label: 'Saint-Marc-des-Carrières', value: 'Saint-Marc-des-Carrières' },
        { label: 'Saint-Ours', value: 'Saint-Ours' },
        { label: 'Saint-Pamphile', value: 'Saint-Pamphile' },
        { label: 'Saint-Pascal', value: 'Saint-Pascal' },
        { label: 'Saint-Pie', value: 'Saint-Pie' },
        { label: 'Saint-Raymond', value: 'Saint-Raymond' },
        { label: 'Saint-Rémi', value: 'Saint-Rémi' },
        { label: 'Saint-Sauveur', value: 'Saint-Sauveur' },
        { label: 'Saint-Tite', value: 'Saint-Tite' },
        { label: 'Salaberry-de-Valleyfield', value: 'Salaberry-de-Valleyfield' },
        { label: 'Schefferville', value: 'Schefferville' },
        { label: 'Scotstown', value: 'Scotstown' },
        { label: 'Senneterre', value: 'Senneterre' },
        { label: 'Sept-Îles', value: 'Sept-Îles' },
        { label: 'Shawinigan', value: 'Shawinigan' },
        { label: 'Sherbrooke', value: 'Sherbrooke' },
        { label: 'Sorel-Tracy', value: 'Sorel-Tracy' },
        { label: 'Stanstead', value: 'Stanstead' },
        { label: 'Sutton', value: 'Sutton' },
        { label: 'Témiscaming', value: 'Témiscaming' },
        { label: 'Témiscouata-sur-le-Lac', value: 'Témiscouata-sur-le-Lac' },
        { label: 'Terrebonne', value: 'Terrebonne' },
        { label: 'Thetford Mines', value: 'Thetford Mines' },
        { label: 'Thurso	Papineau', value: 'Thurso	Papineau' },
        { label: 'Trois-Pistoles', value: 'Trois-Pistoles' },
        { label: 'Trois-Rivières', value: 'Trois-Rivières' },
        { label: 'Valcourt', value: 'Valcourt' },
        { label: 'Val-d\'Or', value: 'Val-d\'Or' },
        { label: 'Varennes', value: 'Varennes' },
        { label: 'Vaudreuil-Dorion', value: 'Vaudreuil-Dorion' },
        { label: 'Victoriaville', value: 'Victoriaville' },
        { label: 'Ville-Marie', value: 'Ville-Marie' },
        { label: 'Warwick', value: 'Warwick' },
        { label: 'Waterloo', value: 'Waterloo' },
        { label: 'Waterville', value: 'Waterville' },
        { label: 'Westmount', value: 'Westmount' },
        { label: 'Windsor', value: 'Windsor' }
    ],

    'Saskatchewan': [
        { label: 'All', value: null },
        { label: 'Estevan', value: 'Estevan' },
        { label: 'Flin Flon', value: 'Flin Flon' },
        { label: 'Humboldt', value: 'Humboldt' },
        { label: 'Lloydminster', value: 'Lloydminster' },
        { label: 'Martensville', value: 'Martensville' },
        { label: 'Meadow Lake', value: 'Meadow Lake' },
        { label: 'Melfort', value: 'Melfort' },
        { label: 'Melville', value: 'Melville' },
        { label: 'Moose Jaw', value: 'Moose Jaw' },
        { label: 'North Battleford', value: 'North Battleford' },
        { label: 'Prince Albert', value: 'Prince Albert' },
        { label: 'Regina', value: 'Regina' },
        { label: 'Saskatoon', value: 'Saskatoon' },
        { label: 'Swift Current', value: 'Swift Current' },
        { label: 'Warman', value: 'Warman' },
        { label: 'Weyburn', value: 'Weyburn' },
        { label: 'Yorkton', value: 'Yorkton' }
    ],

    'Yukon': [
        { label: 'All', value: null },
        { label: 'Whitehorse', value: 'Whitehorse '}
    ],

    'Northwest Territories': [
        { label: 'All', value: null },
    ],

    'Nunavut': [
        { label: 'All', value: null },
    ]
};
