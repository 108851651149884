import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '@env/environment';
import { distinctUntilKeyChanged, take, tap } from 'rxjs/operators';
import { LogEntry } from '@app/core/models/logging/log-entry';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    logsApiEndpoint = environment.appConfig.baseUrl + 'log/';

    errorStream$ = new Subject<{ message: string; details: string }>();

    constructor(private http: HttpClient, private auth: AuthenticationService) {
        const self = this;
        this.errorStream$
            .pipe(
                // tap((val) => console.log('Error received and tapped', val)),
                distinctUntilKeyChanged('message')
            )
            .subscribe({
                next: function (val) {
                    self.logError(val.message, val.details);
                }
            });
    }

    /**
     * logUsage
     */
    public logUsage(message: string, details: any) {
        const logEntry = this.getTsLoggingInfo(message, details);
        this.logIt(logEntry, 'Usage');
    }

    public logDiagnostic(message: string, details: any) {
        const logEntry = this.getTsLoggingInfo(message, details);
        this.logIt(logEntry, 'Diagnostic');
    }

    public logError(message: string, details: any) {
        const logEntry = this.getTsLoggingInfo(message, details);

        if (details.error) {
            logEntry.CorrelationId = details.error.ErrorId;
        }
        this.logIt(logEntry, 'logError');
    }

    /**
     * handleApiError
     */
    public handleApiError(message: string, error: any): any {
        this.errorStream$.next({ message, details: error }); //(message, error);
        throw new Error(error._body ? error._body : message);
    }

    public startPerfTracker(message: string, details: any) {
        const sessionKey = btoa(message);
        sessionStorage[sessionKey] = JSON.stringify(
            this.getTsLoggingInfo(message, details)
        );
    }

    public stopPerfTracker(message: string) {
        const sessionKey = btoa(message);
        const now = new Date();

        const contents = sessionStorage[sessionKey];
        if (!contents) {
            return;
        }

        const logEntry = JSON.parse(contents);
        if (logEntry) {
            logEntry.ElapsedMilliseconds =
                now.getTime() - Date.parse(logEntry.Timestamp);
            this.logIt(logEntry, 'Performance');
        }
    }

    private getTsLoggingInfo(message: string, additionalInfo: any) {
        const logInfo = new LogEntry();
        const toSkip = new Set([
            'rejection',
            'promise',
            'zone',
            'task',
            'ngDebugContext'
        ]);
        logInfo.Subject =
            'Goldeye Frontend Error | ' + environment.environmentName;
        logInfo.Timestamp = new Date();
        logInfo.Location = window.location.toString();
        logInfo.Product = 'Goldeye';
        logInfo.Layer = 'AngularClient';
        logInfo.Message = message;

        logInfo.AdditionalInfo = {};
        if (additionalInfo) {
            for (const prop in additionalInfo) {
                if (!toSkip.has(prop) && additionalInfo.hasOwnProperty(prop)) {
                    logInfo.AdditionalInfo[prop] = additionalInfo[prop];
                }
            }
        }
        logInfo.AdditionalInfo['user-agent'] = window.navigator.userAgent;

        return logInfo;
    }

    private logIt(logEntry: LogEntry, endpoint: string) {
        if (!this.auth.isAuthenticated()) {
            this.postLogEntry(logEntry, endpoint);

            return;
        }

        // get the user's email address
        // from the local storage
        const profile = this.auth.getUserInfo() as any;
        logEntry.Username = profile.nickname;

        for (const prop in profile) {
            if (profile.hasOwnProperty(prop)) {
                logEntry.AdditionalInfo[prop] = profile[prop];
            }
        }

        // then post the log entry as well
        this.postLogEntry(logEntry, endpoint);
    }

    /**
     *Logs an error directly to the Goldeye Admin API
     * @param logEntry The error record to be logged
     * @param endpoint The log method we want to use: e.g. Usage, Diagnostic, Performance
     */
    postLogEntry(logEntry: LogEntry, endpoint: string) {
        if (logEntry) {
            // set the environment
            logEntry.EnvironmentString = environment.environmentName;

            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/json');
            this.http
                .post(environment.adminApiUrl, logEntry, {
                    headers: headers
                })
                .pipe(take(1))
                .subscribe();
        }
    }
}
