import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Column } from '@app/core/models';
import { IPropertyAccessByUserGridModel } from '@app/modules/property-team/models/property-access/property-access-by-user-grid.model';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-list-by-user',
    templateUrl: './property-access-list-by-user.component.html',
    styleUrls: ['./property-access-list-by-user.component.scss']
})
export class PropertyAccessListByUserComponent implements OnInit {
    @Input() grid: IPropertyAccessByUserGridModel;
    @Input() placeholder = 'Search by Resident Manager';
    @Input() columns: Column[] = [
        { field: 'userHandle', header: 'Role Account', type: 'user' },
        { field: 'userFullName', header: 'Name' },
        {
            field: 'properties',
            header: 'Properties',
            type: 'propertyAccessList'
        }
    ];

    matchAllTextChanged = new Subject<string>();
    matchAllTextChanged$: Subscription;

    ngOnInit() {
        this.matchAllTextChanged$ = this.matchAllTextChanged
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .pipe(tap(this.grid.search.terms.setUser))
            .subscribe((_) => {});
    }

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    onMatchAllKeyUp(event) {
        this.matchAllTextChanged.next(event.target.value);
    }
}
