<div *mobxAutorun>
    <p-card
        *ngFor="let pa of propertyAccess"
        styleClass="p-card-shadow property-card"
    >
        <h2>{{ pa.propertyAddress }}</h2>
        <hr />
        <p>{{ pa.propertyName }}</p>
        <p>{{ pa.propertyNumberOfUnits }} units</p>
    </p-card>
</div>
