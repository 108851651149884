import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import {
    DropdownModel,
    ISelectItem
} from '@app/shared/models/goldeye-select-item';

export const WorkOrderDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            propertyScope: types.array(types.number),
            workOrderPriorityDropdown: DropdownModel,
            workOrderStatusDropdown: DropdownModel,
            propertyDropdown: DropdownModel,
            unitDropdown: DropdownModel,
            tenantDropdown: DropdownModel,
            assetDropdown: DropdownModel,
            commonAreaDropdown: DropdownModel,
            buildingDropdown: DropdownModel,
            floorDropdown: DropdownModel,
            roomDropdown: DropdownModel,

            expenseTypeDropdown: DropdownModel,
            workOrderCategoryDropdown: DropdownModel,
            workOrderSubCategoryDropdown: DropdownModel,
            deadlineTypeDropdown: DropdownModel,

            vendorDropdown: DropdownModel,
            jobDropdown: DropdownModel,

            noEmptyMaintenancePropertyTeamMemberDropdown: DropdownModel,

            templateWorkOrderAttachmentTypeDropdown: DropdownModel
        })
        .views((self) => ({
            get filteredProperties(): ISelectItem[] {
                const scope = new Set(self.propertyScope);
                if (scope.size) {
                    return self.propertyDropdown.filter(
                        (p) => p.value == null || scope.has(Number(p.value))
                    );
                }
                return self.propertyDropdown;
            },
            vendorNameById(id: number) {
                if (self.vendorDropdown) {
                    return self.vendorDropdown.find((x) => x.value === id)
                        .label;
                }
            },
            filteredUnits: (propertyId: number): ISelectItem[] =>
                self.unitDropdown.filter(
                    (u) =>
                        !propertyId ||
                        u.value === null ||
                        u.parentId === propertyId
                ),
            filteredTenants: (unitId: number): ISelectItem[] =>
                self.tenantDropdown.filter(
                    (u) => !unitId || u.value === null || u.parentId === unitId
                ),
            filteredSubcategories: (categoryId: number): ISelectItem[] =>
                self.workOrderSubCategoryDropdown.filter(
                    (u) =>
                        !categoryId ||
                        u.value === null ||
                        u.parentId === categoryId
                ),
            filteredPropertyTeamMembersDropdown: (
                propertyId: number
            ): ISelectItem[] => {
                const seen = new Set();
                return self.noEmptyMaintenancePropertyTeamMemberDropdown.filter(
                    (p) =>
                        (!propertyId ||
                            p.value === null ||
                            p.parentId === propertyId) &&
                        (seen.has(p.value) ? false : seen.add(p.value))
                );
            }
        }))
);
type WorkOrderDropdownsModelType = Instance<typeof WorkOrderDropdownModel>;
export interface IWorkOrderDropdownsModel extends WorkOrderDropdownsModelType {}
