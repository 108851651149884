export class RoleGrantStatusLookup implements IRoleGrantStatusLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
}

export class IRoleGrantStatusLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
}

