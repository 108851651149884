/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import * as moment from 'moment';
import GoldeyeCalendar from '../goldeye-calendar';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: [
        'value',
        'name',
        'range',
        'labelClass',
        'inputClass',
        'label',
        'controls',
        'disabled',
        'fieldAccess',
        'dateFormat'
    ],
    outputs: ['selection'],
    selector: 'app-goldeye-scheduling-calendar',
    templateUrl: './scheduling-calendar.component.html',
    styleUrls: ['./scheduling-calendar.component.scss']
})
export class SchedulingCalendarComponent
    extends GoldeyeCalendar
    implements OnInit {
    ngOnInit() {
        this.setClassNames();
    }

    setScheduledForToday(calendar) {
        // If the date is valid, stop
        if (this.value) {
            return;
        }

        this.value = moment().startOf('day').toDate();
        this.selection.emit(this.value);

        setTimeout(() => {
            calendar.overlayVisible = true;
        }, 50);
    }
}
