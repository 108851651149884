import { types, flow, Instance } from 'mobx-state-tree';
import { withItems, withCount, withPaginator } from '@app/shared/utils/mobx-mixins';
import { SortOrder } from '@app/core/models';
import { CommentModel } from './comment.model';
import { CommentQuery } from '../../queries/comment.query';

export const CommentGridModel = types
    .compose(
        withItems((myself) => `${myself.discriminator}/${myself.relatedToId}/comments`, CommentModel),
        withCount((myself) => `${myself.discriminator}/${myself.relatedToId}/comments/count`),
        withPaginator('createdDate', SortOrder.Descending, 5),
        types.model({
            loading: true,
            relatedToId: types.number,
            discriminator: types.enumeration(['policy', 'claim', 'unit', 'actionitem', 'workordertechnote', 'residentactivity'])
        })
    )
    .views((self) => ({
        get query(): CommentQuery {
            return {
                ...self.paginator.query
            };
        }
    }))
    .actions((self) => ({
        setRelatedToId: (id: number) => (self.relatedToId = id),
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([self.fetchItems(self.query), self.fetchCount(self.query)]);
            } finally {
                self.loading = false;
            }
        })
    }));
type modelType = Instance<typeof CommentGridModel>;
export interface ICommentGridModel extends modelType {}
