import { types, Instance } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';

export const UserModel = resource(
    'users',
    types
        .model({
            id: types.identifierNumber,

            firstName: types.maybeNull(types.string),
            lastName: types.maybeNull(types.string),
            email: types.maybeNull(types.string),
            mobileNumber: types.maybeNull(types.string),

            jobTitle: types.maybeNull(types.string),
            skillIcon: types.maybeNull(types.string),

            dateRegistered: types.maybeNull(
                types.union(types.string, types.Date)
            ),

            emailChannelOn: types.maybeNull(types.boolean),
            smsChannelOn: types.maybeNull(types.boolean),
            browserChannelOn: types.maybeNull(types.boolean)
        })
        .views((self) => ({
            channelStatus(channelIsOn: boolean) {
                let status = 'off';

                if (channelIsOn) {
                    status = 'on';
                }

                return status;
            },
            get fullname() {
                return `${self.firstName} ${self.lastName}`;
            }
        }))
        .views((self) => ({
            get emailChannelStatus() {
                return self.channelStatus(self.emailChannelOn);
            },
            get smsChannelStatus() {
                return self.channelStatus(self.smsChannelOn);
            },
            get browserChannelStatus() {
                return self.channelStatus(self.browserChannelOn);
            }
        }))
        .actions((self) => ({}))
);

type UserModelType = Instance<typeof UserModel>;
export interface IUserModel extends UserModelType {}
