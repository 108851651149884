import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IAttachmentModel } from '@app/core/models/storage/attachment.model';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-attachments-viewer',
    templateUrl: './attachments-viewer.component.html',
    styleUrls: ['./attachments-viewer.component.scss']
})
export class AttachmentsViewerComponent implements OnInit, OnChanges {
    @Input() container?: any;
    @Input() title: string;
    @Input() fieldsetTitle = 'General Files';
    @Input() uploadModalTitle = 'Select Files';
    @Input() attachments: IAttachmentModel[];
    @Input() showPhotoThumbnail = false;
    @Input() collapsed = true;
    hasUploaded = new BehaviorSubject<boolean>(false);

    displayFileUploader = false;

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {}

    onFinishUploading() {
        this.displayFileUploader = false;
    }
}
