import { Injectable, NgZone } from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    UIError,
    FkConstraintViolationError,
    DbUpdateConflictError,
    ValidationError,
    isConsistencyViolation
} from '@app/core/errors';
import { ValidationMessagesService } from '../services/validation/validation-messages.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UIErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private validationMessagesSesrvice: ValidationMessagesService,
        private messageService: MessageService,
        private ngZone: NgZone
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err, _) => {
                switch (err.status) {
                    case 400:
                        this.showError(new ValidationError(err));
                        break;
                    case 405:
                        this.showError(new FkConstraintViolationError(err));
                        break;
                    case 409:
                        this.showError(new DbUpdateConflictError(err));
                        break;
                }
                return throwError(err);
            })
        );
    }

    /**
     * There are two types of ui errors that can raise due
     * to user input: errors created due to validation rules
     * (ValidationError) and errors due to a DB consistency violation.
     * Each type of error is shown in different ways, for business rules
     * validations we usually want to shown them inline in the form the user
     * is using, for DB consistency errors, we want to show them using a toast.
     * @param uiError Ui error to be shown
     */
    private showError(uiError: UIError) {
        this.ngZone.run(() => {
            if (isConsistencyViolation(uiError)) {
                this.messageService.addAll(uiError.errors());
            } else {
                this.validationMessagesSesrvice.setValidationMessages(
                    uiError.errors()
                );
            }
        });
    }
}
