export function toDateRange(from: Date, to: Date): Date[] {
    return (from && to) ? [from || undefined, to || undefined] : undefined;
}

export function createDateRange(from: Date, to: Date, date: Date): Date[] {
    if (!from || date < from || (from && to)) {
        return [date, undefined];
    }
    return [from, date];
}
