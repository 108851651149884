<div class="pages-body accessdenied-page p-d-flex p-flex-column">
    <div class="exception-type">
        <img src="assets/layout/images/401.svg" />
    </div>

    <div class="p-as-center p-mt-auto p-mb-auto">
        <div class="pages-panel card p-d-flex p-flex-column">
            <i class="fad fa-shield"></i>
            <h1>Permission Denied!</h1>
            <div class="pages-detail p-pb-6">
                You do not have permission to access that screen. Please contact
                support for help.
            </div>
            <p-button
                label="Go Back"
                (click)="go_back()"
                #backButton
                class="blue-btn"
            >
            </p-button>
        </div>
    </div>
</div>
