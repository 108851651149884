import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ITimelineModel } from '@app/shared/models/timeline/timeline.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
    @Input() timeline: ITimelineModel;
}
