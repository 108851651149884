export interface ISignalRMessage {
    id: number;
    date: Date;
    subject: string;
    body: string;
    type: SignalRMessageTypes;
    status: SignalRMessageStatus;
    groupName?: string;
    userEmail?: string;
    itemUrl?: string;
    frontEndIconClass?: string;
}

export enum SignalRMessageTypes {
    ALERT = 1,
    WATCH = 2,
    REMINDER = 3,
    BROADCAST = 4,
    EMERGENCY = 5
}

export enum SignalRMessageStatus {
    UNREAD = 1,
    READ = 2
}
