<div class="no-margin" *mobxAutorun>
    <app-action-item-dialog
        *ngIf="selectedActionItemForm"
        appendTo="body"
        class="no-margin"
        [store]="selectedActionItemForm"
        [properties]="properties"
        [dialogVisible]="showFormDialog"
        (closeAction)="onCloseFormDialog()"
    ></app-action-item-dialog>
    <div class="p-col-12 p-grid p-justify-between" *ngIf="!grid.listLoading; else loading">
        <div class="p-col-12 p-grid p-justify-around" [ngClass]="showAdd ? 'p-md-10 p-xl-11' : 'p-md-12'">
            <app-action-item-board-lane
                class="p-col-12 p-md-4"
                [items]="grid.newBoardActionItems"
                type="New"
                (dragStarting)="dragStart($event)"
                (dragEnded)="dragEnd()"
                (drop)="dropped($event)"
                (clickItem)="onEditNewBtnClick($event)"
            ></app-action-item-board-lane>
            <app-action-item-board-lane
                class="p-col-12 p-md-4"
                [items]="grid.inProgressBoardActionItems"
                type="InProgress"
                (dragStarting)="dragStart($event)"
                (dragEnded)="dragEnd()"
                (drop)="dropped($event)"
                (clickItem)="onEditNewBtnClick($event)"
            ></app-action-item-board-lane>
            <app-action-item-board-lane
                class="p-col-12 p-md-4"
                [items]="grid.completedBoardActionItems"
                type="Completed"
                (dragStarting)="dragStart($event)"
                (dragEnded)="dragEnd()"
                (drop)="dropped($event)"
                (clickItem)="onEditNewBtnClick($event)"
            ></app-action-item-board-lane>
        </div>
        <div *ngIf="showAdd" class="p-col-12 p-md-2 p-xl-1 p-col-nogutter">
            <app-goldeye-action-button
                class="trouble-report-button-container"
                label="New Incident Report"
                icon="fas fa-plus"
                btnClass="p-col-12 p-col-nogutter"
                (btnClick)="onAddNewBtnClick(2)"
            >
            </app-goldeye-action-button>
            <app-goldeye-action-button
                class="action-item-button-container"
                label="New Action Item"
                icon="fas fa-plus"
                btnClass="p-col-12 p-col-nogutter"
                (btnClick)="onAddNewBtnClick(1)"
            >
            </app-goldeye-action-button>
        </div>
        <div class="p-col-12 p-md-6">
            <app-goldeye-action-button label="Show More..." icon="fa fa-bars" [disabled]="grid.isBusy" (btnClick)="increaseDateOffset(3)">
            </app-goldeye-action-button>
        </div>
    </div>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
