import { Component } from '@angular/core';

@Component({
    template: `
        <span style="white-space: pre-line;">
            {{ contents | smartDate }}
        </span>
    `
})
export class SmartDateGridColumnComponent {
    contents: any;
    props?: any;
}
