import { WorkRegion } from '@app/core/models';

export interface IUserRegistration {
    userId: number;
    mobileNumber: string;
    directNumber: string;
}

export class UserRegistration implements IUserRegistration {
    userId: number;
    mobileNumber: string;
    directNumber: string;
}
