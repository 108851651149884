import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import {
    isNumber,
    roundToNDecimalPlaces
} from '@app/shared/utils/number-helper';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';

@Component({
    selector: 'app-goldeye-currency-input',
    templateUrl: './currency-input.component.html',
    styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() name: string;
    @Input() placeholder?: string;
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() focus?: boolean;
    @Input() model: number | string;
    @Input() isSummationValue = false;
    @Input() readonly = false;
    @Input() boldSummationValue = false;

    @Output() change = new EventEmitter<number>();

    @Input() fieldAccess?: IFieldAccessModel;

    ngOnInit() {
        this.setModelToValidNumber();
        this.placeholder = this.placeholder || '$0';
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        event.stopPropagation();
        const inputValue: string = event.target.value;

        const formattedVal =
            inputValue && inputValue.includes('$')
                ? inputValue.replace('$', '')
                : inputValue;

        const returnVal = Number(formattedVal);
        this.change.emit(returnVal);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setModelToValidNumber();
    }

    setModelToValidNumber() {
        this.model =
            this.model && isNumber(this.model)
                ? roundToNDecimalPlaces(+this.model, 2)
                : '';
    }
}
