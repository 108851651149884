import { Component } from '@angular/core';

@Component({
    template: `
        <span>
            <i [ngClass]="accessClasses(props)" class="far fa-building"></i>
            <span>{{ contents }}</span>
        </span>
    `,
    selector: 'app-user-grid-column',
    styles: ['span span { margin-left: 10px }', 'i { font-size: 24px; }']
})
export class PropertyAccessPropertyGridColumnComponent {
    contents: any;
    props?: any;

    accessClasses(props) {
        if (props) {
            if (props.isNotActive) {
                return ['p-state-disabled'];
            } else if (props.isTemp) {
                return ['access-temporary-color'];
            } else {
                return ['access-permanent-color'];
            }
        }
        return [];
    }
}
