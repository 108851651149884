import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownModelType, ISelectItem } from '@app/shared/models/goldeye-select-item';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';
import { SelectItem } from 'primeng/api';
import { SecuredLayoutComponent } from '@app/modules/secured-layout/components/secured-layout/secured-layout.component';

@Component({
    selector: 'app-goldeye-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() options: Array<ISelectItem | SelectItem>;
    @Input() disabled = false;
    @Input() filter = false;
    @Input() required = false;

    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() containerClass?: string;
    @Input() appendTo?: string;
    @Input() optionLabel?: string;

    @Input() fieldAccess?: IFieldAccessModel;

    @Input() model: number | string | ISelectItem | SelectItem;
    @Output() change = new EventEmitter<any>();

    constructor(public layout: SecuredLayoutComponent) {}

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        let value;
        if (event.type === 'change') {
            event.stopPropagation();
        } else {
            value = event.value;
        }

        if (typeof value !== 'undefined') {
            this.change.emit(value);
        }
    }
}
