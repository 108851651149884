<div
    class="p-grid p-align-center control-padding"
    *ngIf="!fieldAccess?.isHidden"
>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
        <button
            pButton
            *ngIf="showToggleReadonlyButton"
            [icon]="readonly ? 'fa fa-lock-open-alt' : 'fa fa-lock-alt'"
            (click)="readonly = !readonly"
            class="toggle-readonly-button"
        ></button>

        <button
            pButton
            type="button"
            *ngIf="labelIconClass"
            [icon]="labelIconClass"
            class="labelIcon"
            iconPos="left"
            (click)="labelIconClicked()"
        ></button>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-editor
            #textEditor
            name="{{ name }}"
            [style]="{ height: height }"
            [appHighlightOnValidationError]
            [ngModel]="model"
            (onTextChange)="onTextChange($event)"
            [placeholder]="placeholder"
            [readonly]="fieldAccess?.isLocked || readonly"
        >
            <p-header>
                <span
                    class="ql-formats"
                    [ngClass]="{ hidden: readonly }"
                    *ngIf="!useCustomControls"
                >
                    <select class="ql-header">
                        <option value="1">Heading</option>
                        <option value="2">Subheading</option>
                        <option selected>Normal</option>
                    </select>
                </span>
                <span class="ql-formats" [ngClass]="{ hidden: readonly }">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button
                        class="ql-underline"
                        aria-label="Underline"
                    ></button>
                </span>
                <span
                    class="ql-formats"
                    [ngClass]="{ hidden: readonly }"
                    *ngIf="!useCustomControls"
                >
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                </span>
                <span
                    class="ql-formats"
                    [ngClass]="{ hidden: readonly }"
                    *ngIf="!useCustomControls"
                >
                    <button
                        class="ql-list"
                        value="ordered"
                        aria-label="Ordered List"
                    ></button>
                    <button
                        class="ql-list"
                        value="bullet"
                        aria-label="Unordered List"
                    ></button>
                    <select class="ql-align">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                </span>
                <span
                    class="ql-formats"
                    [ngClass]="{ hidden: readonly }"
                    *ngIf="!useCustomControls"
                >
                    <button
                        class="ql-clean"
                        aria-label="Remove Styles"
                    ></button>
                </span>
            </p-header>
        </p-editor>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
