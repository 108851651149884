import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Column } from '@app/core/models';
import { IActionItemGridModel } from '@app/modules/residential/models/action-item/action-item-grid.model';
import {
    ActionItemFormStore,
    IActionItemFormStore
} from '@app/modules/residential/stores/action-items/action-item-form.store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IActionItemModel } from '@app/modules/residential/models/action-item/action-item.model';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { getSnapshot, applySnapshot } from 'mobx-state-tree';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { RoutesNames } from '@app/modules/residential/residential-routes.names';
import * as RMRoutesNames from '@app/modules/rm/rm-routes.names';
import { Router } from '@angular/router';
import { ActionItemTypes } from '@app/modules/property-team/enums/action-item-types';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-action-item-list',
    templateUrl: './action-item-list.component.html',
    styleUrls: ['./action-item-list.component.scss']
})
export class ActionItemListComponent implements OnInit {
    @Input() grid: IActionItemGridModel;
    @Input() propertyId = -1;
    @Input() actionItemType: ActionItemTypes = ActionItemTypes.General;
    @Input() propertyName?: string;
    @Input() properties?: Array<ISelectItem>;
    @Input() columns: Column[] = [
        {
            field: 'action',
            header: this.actionItemType === ActionItemTypes.IncidentReport ? 'Incident' : 'Action',
            type: 'text',
            width: '25%'
        },
        {
            field: 'typeDisplayValue',
            header: 'Item Type',
            type: 'text',
            sortColumn: 'Type.DisplayValue'
        },
        {
            field: 'assignedToUserNames',
            header: 'Assigned To',
            type: 'unorderedList',
            disableSorting: true
        },
        {
            field: 'statusDescription',
            header: 'Status',
            type: 'text',
            sortColumn: 'Status',
            width: '8%'
        },
        {
            field: 'createdOnDate',
            header: 'Date Created',
            sortColumn: 'createdDate',
            type: 'date'
        }
    ];
    @Input() showButtons = ['view', 'edit', 'delete', 'add', 'filter'];
    @Input() buttonColumnWidth = 10;
    @Input() propertyTeamId?: number;

    showFormDialog = false;
    selectedActionItemForm: IActionItemFormStore;
    openEdit = false;

    constructor(
        private actionItemStore: ActionItemFormStore,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private router: Router
    ) {}

    ngOnInit() {}

    get gridButtons(): Array<string> {
        return this.showButtons.filter((button) => button !== 'add' && button !== 'filter');
    }

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    onAddNewBtnClick() {
        const storeInstance = this.actionItemStore.create(
            0,
            this.propertyId >= 0 ? this.propertyId : null,
            this.propertyName,
            null,
            this.propertyTeamId
        );
        this.showFormDialog = true;
        this.selectedActionItemForm = storeInstance;
    }

    onEditBtnClick(id: number, propertyId = this.propertyId) {
        this.openEdit = true;
        const storeInstance = this.actionItemStore.create(
            id,
            propertyId,
            null,
            null,
            this.propertyTeamId
        );
        this.showFormDialog = true;
        this.selectedActionItemForm = storeInstance;
    }

    onViewBtnClick(id: number, propertyId = this.propertyId) {
        this.openEdit = false;
        const storeInstance = this.actionItemStore.create(id, propertyId);
        this.showFormDialog = true;
        this.selectedActionItemForm = storeInstance;
    }

    onGoToBtnClick(id: number, propertyId = this.propertyId) {
        this.router.navigate([
            RoutesNames.BASE,
            ...RoutesNames.PROPERTY_BOARD_OPEN_ACTION_ITEM.replace(':id', propertyId + '')
                .replace(':actionItemId', id + '')
                .split('/')
        ]);
    }

    onPrintBtnClick(id: number) {
        this.router.navigate([
            RMRoutesNames.RoutesNames.BASE,
            ...RMRoutesNames.RoutesNames.INCIDENT_REPORTS_DETAIL.replace(':id', id + '').split('/')
        ]);
    }

    onDeleteBtnClick(actionItem: IActionItemModel) {
        this.confirmationService.confirm({
            message: `Are you sure you want to delete the ${actionItem.action} action item?`,
            accept: async () => {
                await actionItem.delete();
                this.messageService.add({
                    severity: 'success',
                    summary: 'Delete successful',
                    detail: `Action Item ${actionItem.action} deleted`
                });
                this.grid.removeItem(actionItem);
            }
        });
    }

    onCloseFormDialog() {
        this.showFormDialog = false;
        const item = this.grid.items.find(
            (x) => x.id === this.selectedActionItemForm.form.model.id
        );
        if (item) {
            applySnapshot(item, getSnapshot(this.selectedActionItemForm.form.model));
        }
        this.selectedActionItemForm = undefined;
        this.grid.loadItemsAndCount();
    }
}
