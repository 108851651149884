import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import { DropdownModel } from '@app/shared/models/goldeye-select-item';

export const ActionItemDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            noEmptyPropertyDropdown: DropdownModel,
            actionItemTypeDropdown: DropdownModel,
            noEmptyUserDropdown: DropdownModel,
            noEmptyPropertyBoardItemStatusDropdown: DropdownModel,
            jobDropdown: DropdownModel
        })
        .views((self) => ({
            get noEmptyActionItemTypeDropdown() {
                return self.actionItemTypeDropdown.filter((at) => at.value !== null);
            },
            filteredUserDropdown: (userIds: number[]) => [
                {
                    label: '--Select Other Employee--',
                    value: null,
                    parentId: null
                },
                ...self.noEmptyUserDropdown.filter((u) => !userIds.some((userId) => u.value === userId))
            ],
            filteredJobs: (propertyId?: number, jobIds: number[] = []) =>
                self.jobDropdown.filter(
                    (job) => (job.value === null || job.parentId === propertyId) && !jobIds.some((jobId) => job.value === jobId)
                ),
            propertyName: (propertyId: number) => self.noEmptyPropertyDropdown.find((p) => p.value === propertyId)?.label
        }))
);
type ActionItemDropdownsModelType = Instance<typeof ActionItemDropdownModel>;
export interface IActionItemDropdownsModel extends ActionItemDropdownsModelType {}
