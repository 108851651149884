import { types } from 'mobx-state-tree';
import { AttachmentModel, IAttachmentModel } from './attachment.model';
import * as moment from 'moment';
import mimeTypeMap from '@app/core/services/storage/file-ext-to-mime-type';
import { setMobxFieldDatetime } from '@app/shared/utils/date-helper';

/**
 * Mixin to be used with models that have file attachments, it assumes
 * that the mixed model has an id property and a modifiedBy property.
 * @param prefixGen Function (type) => string to be used for
 * generating the attachment prefix.
 * @param discriminator Value used for setting the discriminator value
 */
export function withAttachments(prefixGen, discriminator) {
    return types
        .model({ attachments: types.array(AttachmentModel) })
        .views((self) => ({
            get attachmentTypeDescriptions() {
                return new Set(
                    self.attachments
                        .filter((a) => a.uploaded)
                        .sort((a) => a.typeDisplayOrder)
                        .map((a) => a.typeDescription)
                );
            },
            /** Returns a grouping of attachments based on their type (which might or not be set),
             */
            get attachmentsGrouped() {
                return self.attachments
                    .filter((a) => a.uploaded)
                    .reduce((prev, curr) => {
                        if (curr.typeDescription) {
                            prev[curr.typeDescription] =
                                prev[curr.typeDescription] || [];
                            prev[curr.typeDescription].push(curr);
                        }
                        return prev;
                    }, {});
            },
            /** When the user presses the Clear or the Close button
             * we want to clear any attachments that have not been uploaded
             */
            get attachmentsToBeCleared() {
                return self.attachments.filter(
                    (attachment) =>
                        attachment.notUploaded ||
                        attachment.cancelled ||
                        attachment.withErrors
                );
            },

            /** When the user presses the upload button
             * we want to upload only attachments that have not been uploaded
             */
            get pendingAttachments() {
                return self.attachments.filter(
                    (attachment) =>
                        attachment.notUploaded ||
                        attachment.uploading ||
                        attachment.withErrors
                );
            },

            get allTypesSelected() {
                return (
                    self.attachments.filter(
                        (attachment) => attachment.typeId === null
                    ).length === 0
                );
            },

            get uploadingAttachments() {
                return (
                    self.attachments.filter(
                        (attachment) => attachment.uploading
                    ).length !== 0
                );
            },

            get uploadedAttachments() {
                return self.attachments.filter(
                    (attachment) => attachment.uploaded
                );
            },
            get allUploaded() {
                return self.attachments.every(
                    (attachment) => attachment.uploaded
                );
            },

            /** Returns image attachments based on MIME type
             */
            get imageAttachments() {
                return self.attachments.filter(
                    (attachment) => attachment.isImage && attachment.uploaded
                );
            },

            /** Returns file attachments based on MIME type
             */
            get fileAttachments() {
                return self.attachments.filter(
                    (attachment) => !attachment.isImage && attachment.uploaded
                );
            },
            /** Returns File attachments as galleria photos */
            get asGalleriaPhotos() {
                return self.attachments.map((img) => ({
                    source: img.tokenizedUrl,
                    alt: '',
                    title: img.title
                }));
            },
            /** Returns File attachments as grouped galleria photos dictionary */
            get asGroupedGalleriaPhotos() {
                const groupedGalleriaPhotos = {};

                self.attachments.forEach((img) => {
                    if (!groupedGalleriaPhotos[img.typeDescription]) {
                        groupedGalleriaPhotos[img.typeDescription] = [];
                    }

                    groupedGalleriaPhotos[img.typeDescription].push({
                        source: img.tokenizedUrl,
                        alt: '',
                        title: img.title
                    });
                });

                return groupedGalleriaPhotos;
            },
            get loggedInUsername() {
                let modifiedBy = localStorage.getItem('profile_nickname');

                const indexOfAtSymbol = modifiedBy.indexOf('@');

                if (indexOfAtSymbol > 0) {
                    modifiedBy = modifiedBy.slice(0, indexOfAtSymbol);
                }

                return modifiedBy;
            }
        }))
        .actions((self: any) => ({
            /** Maps a file and a token to an attachemnt model */
            addAttachment(file, objectUrl) {
                const { name, size } = file;
                let type = file.type;
                const { id } = self;

                const fileParts = name.split('.');
                const fileName = `${name}_${moment().unix()}`;
                const url = `${prefixGen(self)}_${
                    fileParts[0]
                }_${moment().unix()}.${fileParts[1]}`;
                const thumbnailUrl = `${prefixGen(self)}_${
                    fileParts[0]
                }-thumbnail_${moment().unix()}.${fileParts[1]}`;
                type = type || mimeTypeMap[`.${fileParts[1]}`];

                const modifiedBy = self.loggedInUsername;
                const today = setMobxFieldDatetime(new Date());

                const newAttachment = AttachmentModel.create({
                    fileName,
                    title: name,
                    attachedToId: id,
                    fileSize: size,
                    discriminator,
                    mimeType: type,
                    url,
                    thumbnailUrl,
                    createdBy: modifiedBy,
                    modifiedBy: modifiedBy,
                    createdDate: today,
                    storageStatus: 'notUploaded'
                });

                newAttachment.setObjectUrl(objectUrl);
                newAttachment.setFile(file);
                self.attachments.forEach((attachment) => {
                    attachment.setJustUploaded(false);
                });
                newAttachment.setJustUploaded(true);

                self.attachments.push(newAttachment);
            },

            /** Used by children to delegate the removal of attachments*/
            removeAttachment(attachment) {
                self.attachments.remove(attachment);
            },

            /** When the user presses the Clear or the Close button
             * we want to cancel any pending attachments and
             * remove them fron the collection
             */
            clearAttachments() {
                self.attachmentsToBeCleared.forEach((attachment) => {
                    attachment.cancel();
                    attachment.remove();
                });
            }
        }));
}
