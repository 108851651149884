<div *mobxAutorun>
    <form
        #formControl="ngForm"
        *ngIf="form.initialized; else loading"
        novalidate
    >
        <div class="p-grid">
            <div [ngClass]="inputClass">
                <app-text-editor
                    name="contents"
                    [model]="form.comment.contents"
                    (change)="form.comment.setContents($event)"
                    [useCustomControls]="true"
                    height="100px"
                    labelClass="hidden"
                    [debounceTime]="200"
                    (keydown.control.enter)="onSaveBtnClick()"
                >
                </app-text-editor>
            </div>
            <div [ngClass]="btnClass">
                <app-goldeye-action-button
                    icon="fa fa-save"
                    [label]="saveLabel"
                    (btnClick)="onSaveBtnClick()"
                    btnClass="p-col-12"
                    class="main-action-container"
                    pTooltip="Ctrl+Enter (keyboard shortcut)"
                    tooltipPosition="top"
                >
                </app-goldeye-action-button>
            </div>
        </div>
    </form>

    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
