<div class="no-margin no-padding-top" *mobxAutorun>
    <p-dialog
        *ngIf="store"
        [(visible)]="dialogVisible"
        [modal]="true"
        (onHide)="onCancelBtnClicked()"
        class="large-modal claim-step-dialog"
    >
        <p-header>
            <header class="claim-step-dialog-header">
                <i class="fas fa-pencil-alt header-icon"></i>
                <h3>
                    {{ store?.form.model.isNew ? 'New' : 'Edit' }}
                    {{
                        store?.form.model.typeId === 1
                            ? 'Action Item'
                            : store?.form.model.typeDisplayValue || formatType(store?.form.model.typeId)
                    }}
                </h3>
                <app-validation-messages></app-validation-messages>
            </header>
        </p-header>

        <div class="card no-margin no-padding-top">
            <p-tabView *ngIf="store.form.initialized; else loading">
                <p-tabPanel header="SUMMARY" *ngIf="!store.form.model.isNew">
                    <app-action-item-summary class="no-margin" [form]="store.form" [comments]="store.comments"></app-action-item-summary>
                </p-tabPanel>
                <p-tabPanel header="DETAILS" [selected]="editTabSelected">
                    <app-action-item-form
                        class="no-margin"
                        [form]="store.form"
                        [commentForm]="store.commentForm"
                        [properties]="properties"
                        (saveAction)="onSaveBtnClicked($event)"
                    ></app-action-item-form>
                </p-tabPanel>
                <p-tabPanel header="COMMENTS" *ngIf="!store.form.model.isNew">
                    <app-action-item-comments
                        [commentForm]="store.commentForm"
                        [comments]="store.comments"
                        (addComment)="saveComment($event)"
                    ></app-action-item-comments>
                </p-tabPanel>
                <p-tabPanel
                    [header]="'FILES' + (store.form.model.attachments.length > 0 ? ' (' + store.form.model.attachments.length + ')' : '')"
                    *ngIf="!store.form.model.isNew"
                >
                    <app-attachments-viewer
                        [title]="'Attachments'"
                        [attachments]="store.form.model.attachments"
                        [container]="store.form.model"
                    ></app-attachments-viewer>
                </p-tabPanel>
            </p-tabView>
        </div>
        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    </p-dialog>
</div>
