import {
    Component,
    Input,
    EventEmitter,
    Output,
    ChangeDetectionStrategy
} from '@angular/core';
import { Column } from '@app/core/models';
import { IPropertyAccessGridModel } from '@app/modules/property-team/models/property-access/property-access-grid.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-edit-grid',
    templateUrl: './property-access-edit-grid.component.html',
    styleUrls: ['./property-access-edit-grid.component.scss']
})
export class PropertyAccessEditGridComponent {
    @Input() grid: IPropertyAccessGridModel;
    @Input() columns: Column[];
    @Output() editBtnClick = new EventEmitter<number>();
}
