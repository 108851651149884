import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    OnInit
} from '@angular/core';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { ITreeNode, Column } from '@app/core/models';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';
import { ICommonAreaInvolvedModel } from '@app/shared/models/common-area-involved.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-common-areas',
    templateUrl: './common-areas.component.html',
    styleUrls: ['./common-areas.component.scss']
})
export class CommonAreasComponent implements OnInit {
    @Input() properties: ISelectItem[];
    @Input() commonAreas: ISelectItem[];

    @Input() selectedProperty?: number;
    @Input() selectedCommonAreas: number[];

    @Input() commonAreaDictionary: {};
    @Input() propertyDictionary: {};

    @Output() selectedPropertyChanged = new EventEmitter<number>();
    @Output() selectedCommonAreasChanged = new EventEmitter<
        ICommonAreaInvolvedModel[]
    >();

    selectedLocationsTreenodes: ITreeNode[] = [];
    @Input() locationTreenodes: ITreeNode[];
    @Input() readonly = false;
    showEditPropertyLocationDialog = false;

    treeNodeCols: Column[];

    @Input() fieldAccess?: IFieldAccessModel;

    ngOnInit() {
        this.treeNodeCols = [{ field: 'label', header: 'locations' }];
    }

    onPropertyLocationSelection() {
        this.showEditPropertyLocationDialog = false;
        const selectedPropertyLocations =
            this.convertLocationsStringToCommonAreasInvolvedModel(
                this.selectedCommonAreas
            );
        this.selectedCommonAreasChanged.emit(selectedPropertyLocations);
    }

    editPropertyLocations() {
        this.selectedLocationsTreenodes = this.areasInvolvedAsTreenodes(
            this.selectedCommonAreas
        );
        this.showEditPropertyLocationDialog = true;
    }

    regenerateTreenodes() {
        this.selectedLocationsTreenodes = this.areasInvolvedAsTreenodes(
            this.selectedCommonAreas
        );
    }

    onDialogHide() {
        this.showEditPropertyLocationDialog = false;
    }

    areasInvolvedAsTreenodes(selectedCommonAreas): ITreeNode[] {
        const locations =
            this.convertLocationsStringToCommonAreasInvolvedModel(
                selectedCommonAreas
            );

        const result = {};
        const resultTreeNodeArray: ITreeNode[] = [];

        if (locations && locations.length > 0) {
            locations.forEach((l) => {
                if (l.propertyId) {
                    if (result[l.propertyCode] == null) {
                        result[l.propertyCode] = [];
                    }
                }

                if (l.commonAreaId) {
                    result[l.commonAreaPropertyCode] =
                        result[l.commonAreaPropertyCode] == null
                            ? [l.commonAreaCode]
                            : [
                                ...result[l.commonAreaPropertyCode],
                                l.commonAreaCode
                            ];
                }
            });

            Object.keys(result).forEach((propertyCode, index, arr) => {
                const commonAreaCodes = result[propertyCode];

                const commonAreaToTreeNode = (commonAreaCode): ITreeNode => ({
                    label: commonAreaCode,
                    data: { label: commonAreaCode },
                    expandedIcon: 'far fa-folder-open',
                    collapsedIcon: 'fa fa-folder',
                    children: null
                });

                const treeNode: ITreeNode = {
                    label: propertyCode,
                    data: { label: propertyCode },
                    expandedIcon: 'far fa-folder-open',
                    collapsedIcon: 'fa fa-folder',
                    children: commonAreaCodes
                        ? commonAreaCodes.map(commonAreaToTreeNode)
                        : null,
                    expanded: true
                };

                resultTreeNodeArray.push(treeNode);
            });
        }

        return resultTreeNodeArray;
    }

    convertLocationsStringToCommonAreasInvolvedModel(locations) {
        const result = locations.map((selectedCommonAreaId) => {
            const commonArea = this.commonAreaDictionary[selectedCommonAreaId];
            const property = this.propertyDictionary[commonArea.parentId];

            return {
                propertyId: +property.value,
                propertyCode: property.label,
                commonAreaId: +commonArea.value,
                commonAreaCode: commonArea.label,
                commonAreaPropertyCode: property.label
            };
        });

        return result;
    }
}
