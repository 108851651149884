<div class="pages-body login-page p-d-flex p-flex-column">
    <div class="p-as-center p-mb-auto">
        <div class="pages-panel card p-d-flex p-flex-column">
            <h1>GOLDEYE</h1>
            <ng-container *ngIf="currentlyLoggedIn; else notLoggedIn">
                <div class="pages-detail p-mb-6 p-px-6">
                    <span>You are currently logged in as: </span>
                    <div class="login-nickname">{{ nickname }}</div>
                </div>
                <p-button
                    class="login-button p-mb-6 p-px-3"
                    type="button"
                    (click)="continueSession()"
                    icon="fa fa-user"
                    label="Continue"
                ></p-button>
            </ng-container>
            <ng-template #notLoggedIn>
                <div class="pages-detail p-mb-6 p-px-6">
                    <span class="login-panel-subheader">
                        Sign-in to the Goldeye Platform
                    </span>
                </div>
                <p-button
                    class="login-button p-mb-6 p-px-3"
                    type="button"
                    (click)="initiateLogin()"
                    icon="fa fa-user"
                    label="Login"
                ></p-button>
            </ng-template>
            <div class="p-mb-12 login-image">
                <img src="assets/layout/images/login/logo_bricks_only.png" />
            </div>
        </div>
    </div>
</div>
