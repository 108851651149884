import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AppLocalStorageService, AuthenticationService } from '../services';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    constructor(
        private localStorage: AppLocalStorageService,
        private auth: AuthenticationService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const tokenKey = 'access_token';
        const token = this.localStorage.selectSync(tokenKey);
        if (token && this.auth.isAuthenticated()) {
            if (!this.isAzureRequest(request)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            return next.handle(request);
        } else {
            this.auth.logout();
            return EMPTY;
        }
    }

    isAzureRequest(request) {
        const blobContentType = request.headers.get('x-ms-blob-content-type');
        const blobType = request.headers.get('x-ms-blob-type');
        return Boolean(blobContentType) || Boolean(blobType);
    }
}
