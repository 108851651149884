<div *mobxAutorun class="attachment-uploader">
    <div class="p-fileupload p-component">
        <div
            class="p-grid p-justify-center p-fileupload-buttonbar p-component-header"
        >
            <p-button
                class="p-fileupload-choose p-col-12 p-md-6 main-action-container"
                [disabled]="container.uploadingAttachments"
                [ngClass]="{
                    'main-action-container':
                        container.attachments.length == 0 ||
                        !container.allTypesSelected
                }"
                label="Upload"
                icon="fa fa-plus-circle"
            >
                <input
                    type="file"
                    name="files[]"
                    [multiple]="true"
                    [accept]="accept"
                    onclick="this.value = null"
                    (change)="onImageSelect($event)"
                />
            </p-button>
        </div>

        <div class="p-fileupload-content p-component p-corner-bottom">
            <div class="p-grid">
                <div class="p-col-12 p-fileupload-files">
                    <app-attachment-selection
                        *ngFor="let attachment of container.pendingAttachments"
                        [attachment]="attachment"
                        [types]="types"
                    >
                    </app-attachment-selection>
                </div>
            </div>
        </div>
    </div>
</div>
