import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import { DropdownModel } from '@app/shared/models/goldeye-select-item';

export const PropertyAccessDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            propertyDropdown: DropdownModel,
            residentManagerDropdown: DropdownModel,
            maintenanceStaffDropdown: DropdownModel,
            propertyAccessTypeDropdown: DropdownModel,
            propertyAccessStatusDropdown: DropdownModel
        })
        .views((self) => ({}))
);
type PropertyAccessDropdownsModelType = Instance<typeof PropertyAccessDropdownModel>;
export interface IPropertyAccessDropdownsModel extends PropertyAccessDropdownsModelType {}
