<div>
    <app-validation-messages></app-validation-messages>
    <form *ngIf="!lookup.isBusy; else loading" novalidate>
        <div class="p-grid">
            <div class="p-col-12">
                <app-goldeye-text-input
                    label="Display Value"
                    name="displayValue"
                    [model]="lookup.displayValue"
                    (change)="lookup.setDisplayValue($event)"
                >
                </app-goldeye-text-input>

                <app-goldeye-masked-input
                    label="Display Order"
                    name="displayOrder"
                    [model]="lookup.displayOrder"
                    (change)="lookup.setDisplayOrder($event)"
                    mask="9?99"
                >
                </app-goldeye-masked-input>

                <app-goldeye-text-input
                    label="Tooltip Description"
                    name="tooltipDescription"
                    [model]="lookup.tooltipDescription"
                    (change)="lookup.setTooltipDescription($event)"
                >
                </app-goldeye-text-input>

                <app-goldeye-text-area
                    label="Description"
                    name="description"
                    placeholder="Enter Description"
                    [model]="lookup.fullDescription"
                    (change)="lookup.setFullDescription($event)"
                >
                </app-goldeye-text-area>
            </div>
        </div>

        <app-goldeye-form-controls
            [disabled]="lookup.isBusy"
            (save)="onSaveBtnClicked()"
            (cancel)="onCancelBtnClicked()"
            btnClass="p-col-12 p-lg-4 p-md-6"
        >
        </app-goldeye-form-controls>
    </form>

    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
