<div class="no-margin" *mobxAutorun>
    <div class="no-margin p-grid p-align-start">
        <div class="p-col-12 p-md-7">
            <div class="p-grid p-justify-start grouped-titles">
                <h3 class="p-col-12">
                    {{ form.model.action }}
                </h3>

                <h4 class="p-col-12 bold">
                    {{ form.model.propertyName }}
                </h4>

                <!-- <h4 *ngIf="form.model.typeId === 2" class="p-col-12">
                    {{ form.model.projectNames }}
                </h4> -->
            </div>
            <div class="p-grid grouped-titles">
                <h4 class="p-col-12">{{ form.model.typeId === 2 ? 'Reported' : 'Created' }} by {{ form.model.createdBy }}</h4>
                <h4 *ngIf="form.model.typeId === 1" class="p-col-12">Assigned: {{ form.model.assignedToUserNamesAndDates }}</h4>
                <h4 *ngIf="form.model.typeId === 2" class="p-col-12">Notify {{ form.model.assignedToUserNames }}</h4>
                <!-- <h4 *ngIf="form.model.typeId === 2" class="p-col-12">Work Order: {{ form.model.workOrderNumbers }}</h4> -->
                <!-- <h4 *ngIf="form.model.typeId === 2" class="p-col-12">Vendor: {{ form.model.vendorNames }}</h4> -->
            </div>
        </div>
        <div class="p-col-12 p-md-5 p-grid">
            <app-comment-list [list]="comments"></app-comment-list>
        </div>
    </div>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
