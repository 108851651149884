<div class="p-grid p-align-start control-padding" *mobxAutorun>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <ng-content select=".above-the-listbox"></ng-content>
        <p-listbox
            *ngIf="options.length > 0; else emptyListBox"
            [appHighlightOnValidationError]="name"
            [style]="{ width: '100%' }"
            [options]="options"
            [multiple]="true"
            [checkbox]="true"
            [filter]="filter"
            [disabled]="disabled"
            [ngModel]="model"
            (onChange)="onChange($event)"
        >
        </p-listbox>
        <!-- WorkAround since primeng seems to have broken listbox change detection -->
        <ng-template #emptyListBox>
            <p-listbox
                [style]="{ width: '100%' }"
                [multiple]="true"
                [checkbox]="true"
                [filter]="filter"
                [disabled]="disabled"
            >
            </p-listbox>
        </ng-template>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
