<div *mobxAutorun>
    <div class="p-grid">
        <div class="p-col-11 p-offset-1"><b>{{ pointOfContact.fullName }}</b></div>

        <div class="p-col-3 p-offset-1">Email</div>
        <div class="p-col-8">{{ pointOfContact.email }}</div>

        <div class="p-col-3 p-offset-1">Company</div>
        <div class="p-col-8">{{ companyName }}</div>

        <div class="p-col-3 p-offset-1">Mobile</div>
        <div class="p-col-8">{{ pointOfContact.mobile }}</div>

        <div class="p-col-3 p-offset-1">Office</div>
        <div class="p-col-8">{{ pointOfContact.office }}</div>
    </div>

    <div class="p-grid p-justify-center">
        <div class="p-col-2">
            <button pButton label="Close" style="width: 100%" (click)="closeBtnClick.emit()"></button>
        </div>
    </div>
</div>
