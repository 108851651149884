import { types, Instance } from 'mobx-state-tree';

export const SelectItemModel = types.model({
    label: types.string,
    value: types.maybeNull(types.union(types.number, types.string)),
    parentId: types.maybeNull(types.number)
});

export const DropdownModel = types.array(SelectItemModel);
type DropdownModelType = Instance<typeof DropdownModel>;
export interface IDropdownModelType extends DropdownModelType {}

type SelectItemType = Instance<typeof SelectItemModel>;
export interface ISelectItem extends SelectItemType {}
