import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import { IPropertyAccessFormModel } from '@app/modules/property-team/models/property-access/property-access-form.model';
import { IDropdownModelType } from '@app/shared/models/goldeye-select-item';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-form',
    templateUrl: './property-access-form.component.html',
    styleUrls: ['./property-access-form.component.scss']
})
export class PropertyAccessFormComponent {
    @Input() form: IPropertyAccessFormModel;
    @Input() userDd: IDropdownModelType;
    @Input() disabled?: string[];
    @Output() saveClicked = new EventEmitter<void>();
    @Output() cancelClicked = new EventEmitter<void>();
}
