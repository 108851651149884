export class LogEntry {
    Timestamp: Date;
    Subject: string;
    Product: string;
    Layer: string;
    Location: string;
    Username: string;
    Message: string;
    CorrelationId: string;
    ElapsedMilliseconds: number;
    AdditionalInfo: any;
    CustomerId = 0;
    EnvironmentString: string;
    SendEmail = true;
    LogToDb = true;
}
