import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    constructor() {}

    ping(): string {
        return 'ping';
    }

    StandardText(): any[] {
        return [Validators.required, Validators.minLength(2)];
    }

    StandardNumeric(): any[] {
        return [Validators.required, Validators.pattern('^[0-9]*$')];
    }

    CustomNumberRangeValidator(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (
                c.value !== undefined &&
                (isNaN(c.value) || c.value < min || c.value > max)
            ) {
                return { range: true };
            }
            return null;
        };
    }

    CustomListBoxHasAtLeast(min: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (
                c &&
                c.value &&
                c.value.length !== undefined &&
                (isNaN(c.value.length) || c.value.length < min)
            ) {
                return { required: true };
            }
            return null;
        };
    }
}
