<div class="p-grid p-align-start control-padding" *mobxAutorun>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <div *ngFor="let option of options" class="{{ radioButtonClass }}">
            <p-radioButton
                [name]="name"
                [label]="option.label"
                [value]="option.value"
                [ngModel]="model"
                (onClick)="onChange(option)"
                [disabled]="disabled"
            ></p-radioButton>
        </div>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
