<div
    [ngClass]="{ 'p-steps p-component': true, 'p-readonly': readonly }"
    [ngStyle]="style"
    [class]="styleClass"
>
    <ul role="tablist">
        <li
            *ngFor="let item of model; let i = index"
            class="p-steps-item"
            #menuitem
            [ngStyle]="item.style"
            [class]="item.styleClass"
            role="tab"
            [attr.aria-selected]="i === activeIndex"
            [attr.aria-expanded]="i === activeIndex"
            [ngClass]="{
                'p-highlight p-steps-current': isActive(item, i),
                'p-disabled': item.disabled || (readonly && !isActive(item, i))
            }"
            [pTooltip]="item.title"
            [tooltipPosition]="tooltipPosition"
            [tooltipStyleClass]="item.styleClass ? item.styleClass : ''"
        >
            <a
                *ngIf="isClickableRouterLink(item); else elseBlock"
                [routerLink]="item.routerLink"
                [queryParams]="item.queryParams"
                role="presentation"
                [routerLinkActive]="'p-menuitem-link-active'"
                [routerLinkActiveOptions]="
                    item.routerLinkActiveOptions || { exact: false }
                "
                class="p-menuitem-link"
                (click)="itemClick($event, item, i)"
                (keydown.enter)="itemClick($event, item, i)"
                [attr.target]="item.target"
                [attr.id]="item.id"
                [attr.tabindex]="
                    item.disabled || readonly
                        ? null
                        : item.tabindex
                        ? item.tabindex
                        : '0'
                "
                [fragment]="item.fragment"
                [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment"
                [skipLocationChange]="item.skipLocationChange"
                [replaceUrl]="item.replaceUrl"
                [state]="item.state"
            >
                <span class="p-steps-number">{{ i + 1 }}</span>
                <span
                    class="p-steps-title"
                    *ngIf="item.escape !== false; else htmlLabel"
                    >{{ item.label }}</span
                >
                <ng-template #htmlLabel
                    ><span class="p-steps-title" [innerHTML]="item.label"></span
                ></ng-template>
            </a>
            <ng-template #elseBlock>
                <a
                    [attr.href]="item.url"
                    class="p-menuitem-link"
                    role="presentation"
                    (click)="itemClick($event, item, i)"
                    (keydown.enter)="itemClick($event, item, i)"
                    [attr.target]="item.target"
                    [attr.id]="item.id"
                    [attr.tabindex]="
                        item.disabled || (i !== activeIndex && readonly)
                            ? null
                            : item.tabindex
                            ? item.tabindex
                            : '0'
                    "
                >
                    <span class="p-steps-number">{{ i + 1 }}</span>
                    <span
                        class="p-steps-title"
                        *ngIf="item.escape !== false; else htmlRouteLabel"
                        >{{ item.label }}</span
                    >
                    <ng-template #htmlRouteLabel
                        ><span
                            class="p-steps-title"
                            [innerHTML]="item.label"
                        ></span
                    ></ng-template>
                </a>
            </ng-template>
        </li>
    </ul>
</div>
