<app-work-order-search-dialog
    [show]="showDialog"
    [dropdowns]="search.dropdowns"
    [terms]="termsCopy"
    [hiddenFilters]="hiddenFilters"
    (searchBtnClicked)="onSearchBtnClick($event)"
    (cancelBtnClicked)="onCancelBtnClick()"
    (dialogHidden)="onDialogHidden()"
>
</app-work-order-search-dialog>
<div
    *mobxAutorun
    class="work-order-search p-grid"
    [ngClass]="{ 'search-in-effect': search.inEffect }"
>
    <div class="p-inputgroup p-col-12">
        <input
            type="text"
            pInputText
            [ngModel]="search.terms.workOrderNumber.value"
            (keyup)="onWorkOrderKeyUp($event)"
            style="background-color: initial"
            placeholder="Find a work order"
        />
        <button
            pButton
            icon="fas fa-external-link-square-alt"
            id="work-order-search-more-btn"
            type="button"
            label="Advanced"
            (click)="onMoreBtnClick()"
            [disabled]="!search.initialized"
        ></button>

        <button
            pButton
            type="button"
            label="Clear"
            [disabled]="!search.canBeCleared"
            (click)="onClearBtnClick()"
            icon="fa fa-times"
        ></button>
    </div>
</div>
