import { types, Instance } from 'mobx-state-tree';
import { stringToDateTime } from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';
import { WorkOrderStatus } from '@app/modules/operations/enums/work-order-status.enum';
import { convertPascalCaseToASentence } from '@app/shared/utils/app-string-helper';

export const WorkOrderHistoryModel = resource(
    'work-order-histories',
    types
        .model({
            id: types.maybeNull(types.number),

            newValue: types.maybeNull(types.string),
            newValueDescription: types.maybeNull(types.string),
            oldValue: types.maybeNull(types.string),
            oldValueDescription: types.maybeNull(types.string),
            columnName: types.maybeNull(types.string),
            createdDate: types.maybeNull(types.string),
            createdBy: types.maybeNull(types.string)
        })
        .views((self) => ({
            get createdDateAsDate() {
                return stringToDateTime(self.createdDate);
            },
            get newValueAsWorkOrderStatus() {
                return convertPascalCaseToASentence(
                    WorkOrderStatus[self.newValue]
                );
            },
            get oldValueAsWorkOrderStatus() {
                return convertPascalCaseToASentence(
                    WorkOrderStatus[self.oldValue]
                );
            },
            get icon() {
                const icons = {
                    [WorkOrderStatus.ReportedStatus]: 'fa fa-hourglass-start',
                    [WorkOrderStatus.CancelledStatus]: 'fa fa-hand-paper',
                    [WorkOrderStatus.Completed]: 'fa fa-',
                    [WorkOrderStatus.HoldStatus]: 'fa fa-',
                    [WorkOrderStatus.InProgress]: 'fa fa-',
                    [WorkOrderStatus.Scheduled]: 'fa fa-'
                };

                return icons[self.newValue];
            }
        }))
);

type WorkOrderHistoryModelType = Instance<typeof WorkOrderHistoryModel>;
export interface IWorkOrderHistoryModel extends WorkOrderHistoryModelType {}
