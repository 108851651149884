<div *mobxAutorun>
    <div pDroppable="actionitem" (onDrop)="dropped($event)" class="action-item-list p-col-12">
        <h2>{{ title() }}</h2>
        <span class="count">{{ items.length }}</span>
        <hr />
        <div class="p-col-12">
            <div
                class="p-col-12 p-md-12 action-item"
                *ngFor="let actionItem of items"
                pDraggable="actionitem"
                (onDragStart)="dragStart($event, actionItem)"
                (onDragEnd)="dragEnd($event)"
                (click)="onClick(actionItem)"
                [ngStyle]="{ 'border-color': actionItem.typeColor }"
            >
                <div class="p-col-12 no-padding">
                    <h5 class="no-padding">
                        <i [ngClass]="actionItem.typeIcon" [ngStyle]="{ color: actionItem.typeColor }"></i>
                        {{ actionItem.action }}
                    </h5>
                </div>
                <div class="p-col-12 p-grid no-padding card-info">
                    <div
                        class="p-col-12 no-padding associated-data"
                        *ngIf="
                            (actionItem.projects && actionItem.projects.length > 0) ||
                            (actionItem.workOrders && actionItem.workOrders.length > 0)
                        "
                    >
                        {{ actionItem.projects && actionItem.projects.length > 0 ? actionItem.projectNames : actionItem.workOrderNames }}
                    </div>
                    <div class="p-md-2 p-col-12 property-code no-padding">
                        {{ actionItem.propertyCode }}
                    </div>
                    <div class="p-grid p-col-12 p-md-10 p-justify-end no-padding no-margin">
                        <div class="p-md-4 no-padding user-name" *ngFor="let assignedUser of actionItem.assignedToUsers">
                            {{ assignedUser.userName.split(' ')[0] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
