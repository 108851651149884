<div *mobxAutorun>
    <div class="p-grid p-col-12" *ngIf="preview">
        <div [class]="imageClass">
            <img [src]="preview.image" style="width: 230px; height: 130px;" />
        </div>
        <div [class]="textClass">
            <h3>{{ preview.title }}</h3>
            <p>{{ preview.description }}</p>
        </div>
    </div>
</div>
