<div *mobxAutorun>
    <p-table
        [sortField]="grid.paginator.sortField"
        [responsive]="true"
        [sortOrder]="grid.paginator.sortOrder"
        [value]="grid.items"
        [columns]="columns"
        [loading]="grid.listLoading"
        [rowHover]="true"
        [paginator]="grid.paginator.showPages"
        [rows]="10"
        [lazy]="true"
        [totalRecords]="grid.totalRecords"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        (onLazyLoad)="onGridLazyLoad($event)"
        selectionMode="single"
    >
        <ng-template pTemplate="caption">
            <div class="p-inputgroup" style="padding: 10px">
                <input
                    type="text"
                    pInputText
                    class="search-input"
                    [placeholder]="placeholder"
                    (input)="onMatchAllKeyUp($event)"
                    style="width: 50%"
                />
                <button pButton type="button" icon="fa fa-search"></button>
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">No records found</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th
                    *ngFor="let col of columns"
                    [ngStyle]="{ width: col.width ? col.width : 'auto' }"
                    [pSortableColumn]="col.sortColumn ? col.sortColumn : col.field"
                    [pSortableColumnDisabled]="col.disableSorting"
                >
                    {{ col.header }}
                    <p-sortIcon *ngIf="!col.disableSorting" [field]="col.sortColumn ? col.sortColumn : col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                    <span class="p-column-title">{{ col.header }}</span>
                    <app-grid-column [column]="col" [row]="rowData"></app-grid-column>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
