import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';

@Component({
    selector: 'app-goldeye-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
    @Input() label: string;
    @Input() btnClass?: string;
    @Input() icon: string;
    @Input() iconPos: 'left' | 'right' | 'top' | 'bottom' = 'left';
    @Input() disabled = false;

    @Input() fieldAccess?: IFieldAccessModel;
    @Output() btnClick = new EventEmitter<void>();

    ngOnInit() {
        const btnClass = this.btnClass || 'p-col-12 p-md-4';
        this.btnClass = btnClass + ' action-button';
    }

    onClick() {
        this.btnClick.emit();
    }
}
