<div class="p-grid p-align-center control-padding">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}" [ngClass]="{ required: required }">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <input
            pInputText
            [appHighlightOnValidationError]="name"
            type="number"
            name="{{ name }}"
            [appFocusIf]="focus"
            [required]="required"
            [placeholder]="placeholder"
            [ngModel]="model"
            (change)="onChange($event)"
        />
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
