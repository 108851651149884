export interface IUserChannel {
    userId: number;
    channelId: number;

    channelName: string;
    dateActivated: Date;
    dateDeactivated: Date;
    isOn: boolean;
    modifiedBy: string;

    dateActivatedString: string;
    dateDeactivatedString: string;
}

export class UserChannel implements IUserChannel {
    userId: number;
    channelId: number;

    channelName: string;
    dateActivated: Date;
    dateDeactivated: Date;
    isOn: boolean;
    modifiedBy: string;

    dateActivatedString: string;
    dateDeactivatedString: string;
}
