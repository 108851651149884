import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesNames } from '@app/modules/property-team/property-team-routes.names';
import { UserRoles } from '@app/core/models/enums/user-role-enum';
import { IPropertyAccessModel } from '@app/modules/property-team/models/property-access/property-access.model';

@Component({
    template: `
        <ul>
            <li *ngFor="let pa of contents">
                <button
                    pButton
                    type="button"
                    icon="far fa-building"
                    iconPos="left"
                    label="{{ pa.propertyCode }}"
                    class="p-button-raised"
                    [ngClass]="accessClasses(pa)"
                    (click)="navigateToUser(pa)"
                ></button>
            </li>
        </ul>
    `,
    selector: 'app-user-grid-column',
    styles: ['ul { list-style: none; margin: 0; padding: 0; }', 'li { display: inline-block; margin-right: 10px;  }']
})
export class PropertyAccessListGridColumnComponent {
    contents: any;
    props?: any;

    constructor(private router: Router) {}

    accessClasses(propertyAccess: IPropertyAccessModel) {
        if (propertyAccess.isNotActive) {
            return ['access-disabled-bg'];
        } else if (propertyAccess.isTemp) {
            return ['access-temporary-bg'];
        } else {
            return ['access-permanent-bg'];
        }
    }

    navigateToUser(propertyAccess: IPropertyAccessModel) {
        const editRouteName =
            propertyAccess.primaryUserRoleId === UserRoles.MaintenanceStaffRoleId
                ? RoutesNames.MAINTENANCE_EDIT_PROPERTY_ACCESS_BY_USER
                : RoutesNames.EDIT_PROPERTY_ACCESS_BY_USER;
        this.router.navigate([RoutesNames.BASE, ...editRouteName.replace(':id', propertyAccess.userId + '').split('/')]);
    }
}
