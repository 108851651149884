import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Column } from '@app/core/models';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { ILinkGridModel } from '@app/shared/models/links/link-grid.model';
import { ILinkModel } from '@app/shared/models/links/link.model';
import { ConfirmationService } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-link-list-grid',
    templateUrl: './link-list-grid.component.html',
    styleUrls: ['./link-list-grid.component.scss']
})
export class LinkListGridComponent {
    @Input() grid: ILinkGridModel;
    @Input() columns: Column[];

    @Output() editClicked = new EventEmitter<ILinkModel>();

    hoveredLink: ILinkModel;

    constructor(private confirmationService: ConfirmationService) {
    }

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    onUrlPreviewBtnEnter(link, evt, popup) {
        this.hoveredLink = link;
        popup.show(evt);
    }

    onUrlPreviewBtnExit(evt, popup) {
        popup.hide(evt);
        this.hoveredLink = null;
    }

    onDeleteBtnClick(link: ILinkModel) {
        this.confirmationService.confirm({
            key: 'delete-link-confirmation',
            message: 'Remove selected Link?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await link.delete();
                this.grid.removeItem(link);
            }
        });
    }

    onEditBtnClick(link: ILinkModel) {
        this.editClicked.emit(link);
    }
}
