import { types, Instance, getSnapshot } from 'mobx-state-tree';
import { WorkOrderSearchTermsModel } from './work-order-search-terms.model';
import { WorkOrderDropdownModel } from './work-order-dropdown.model';
import { dropdownDescriptor as ddDesc } from '@app/shared/utils/dropdown-helper';
import { dateDescription as dDesc } from '@app/shared/utils/date-helper';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';
import { searchTermsUtils } from '@app/shared/utils/mobx-utils';

export const WorkOrderSearchModel = types
    .model({
        dropdowns: types.optional(
            WorkOrderDropdownModel,
            {} as Instance<typeof WorkOrderDropdownModel>
        ),
        terms: types.optional(
            WorkOrderSearchTermsModel,
            {} as Instance<typeof WorkOrderSearchTermsModel>
        )
    })
    .views((self) => ({
        get description(): Array<[ISearchTerm, string, string]> {
            const { dropdowns, terms } = self;
            const descriptions: Array<[ISearchTerm, string, string]> = [
                [
                    terms.unitCode,
                    terms.unitCode.value
                        ? 'Unit Code: ' + terms.unitCode.value
                        : '',
                    'unitCode'
                ],
                [
                    terms.createdOnOrAfterYear,
                    `Created On or After Year ${terms.createdOnOrAfterYear.value}`,
                    'createdOnOrAfterYear'
                ],
                [
                    terms.priorityId,
                    ddDesc(
                        dropdowns.workOrderPriorityDropdown,
                        terms.priorityId.value,
                        'Priority: '
                    ),
                    'priorityId'
                ],
                [
                    terms.statusId,
                    ddDesc(
                        dropdowns.workOrderStatusDropdown,
                        terms.statusId.value,
                        'Status: '
                    ),
                    'statusId'
                ],
                [
                    terms.propertyId,
                    ddDesc(
                        dropdowns.propertyDropdown,
                        terms.propertyId.value,
                        'Property: '
                    ),
                    'propertyId'
                ],
                [
                    terms.unitId,
                    ddDesc(
                        dropdowns.unitDropdown,
                        terms.unitId.value,
                        'Unit: '
                    ),
                    'unitId'
                ],
                [
                    terms.categoryId,
                    ddDesc(
                        dropdowns.workOrderCategoryDropdown,
                        terms.categoryId.value,
                        'Category: '
                    ),
                    'categoryId'
                ],
                [
                    terms.subcategoryId,
                    ddDesc(
                        dropdowns.workOrderSubCategoryDropdown,
                        terms.subcategoryId.value,
                        'Subcategory: '
                    ),
                    'subcategoryId'
                ],
                [
                    terms.createdFrom,
                    dDesc('Created From: ', terms.createdFrom.value),
                    'createdFrom'
                ],
                [
                    terms.createdTo,
                    dDesc('Created To: ', terms.createdTo.value),
                    'createdTo'
                ],
                [
                    terms.isUnassigned,
                    terms.isUnassigned.value ? 'Is Unassigned' : 'Is Assigned',
                    'isUnassigned'
                ]
            ];
            return descriptions.filter((term) =>
                searchTermsUtils.hasValue(term[0])
            );
        },
        get inEffect() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(searchTermsUtils.hasValue).length
            );
        },
        inEffectExceptTerm(...exceptions: Array<string>) {
            const terms = getSnapshot(self.terms);

            const filtered = Object.keys(terms)
                .filter((key) => !exceptions.includes(key))
                .reduce((obj, key) => ({ ...obj, [key]: terms[key] }), {});
            return Boolean(
                Object.values(filtered).filter(searchTermsUtils.hasValue).length
            );
        },
        get initialized() {
            return Boolean(self.dropdowns.dropdownsReady);
        }
    }))
    .views((self) => ({
        get canBeCleared() {
            return (
                self.initialized &&
                self.inEffect &&
                searchTermsUtils.anyEnabledTerms(self.terms)
            );
        },
        descriptionExcept(
            ...exceptions: Array<string>
        ): Array<[ISearchTerm, string, string]> {
            return self.description.filter(
                (term) => !exceptions.includes(term[2])
            );
        }
    }))
    .actions((self) => ({
        async initialize() {
            await self.dropdowns.fetchDropdowns();
        },
        setPropertyScope(scope) {
            self.dropdowns.propertyScope.replace(scope);
        }
    }));
type modelType = Instance<typeof WorkOrderSearchModel>;

export interface IWorkOrderSearchModel extends modelType {}
