import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import { types, getSnapshot, Instance } from 'mobx-state-tree';
import { createDateRange, toDateRange } from '@app/shared/utils/date-range-helper';
import { WorkOrdersQuery } from '../../queries/work-orders/work-order.query';

export const WorkOrderSearchTermsModel = types
    .model({
        statusId: searchTermOf(types.number),
        priorityId: searchTermOf(types.number),
        ids: searchTermOf(types.array(types.number)),
        statusIdList: searchTermOf(types.array(types.number)),

        categoryId: searchTermOf(types.number),
        subcategoryId: searchTermOf(types.number),
        propertyId: searchTermOf(types.number),
        propertyTeamId: searchTermOf(types.number),
        propertyGroupId: searchTermOf(types.number),
        propertyGroupIds: searchTermOf(types.array(types.number)),
        portfolioViewId: searchTermOf(types.number),

        unitId: searchTermOf(types.number),
        unitCode: searchTermOf(types.string),
        workOrderNumber: searchTermOf(types.string),

        jobId: searchTermOf(types.number),

        vendorId: searchTermOf(types.number),

        createdFrom: searchTermOf(types.Date),
        createdTo: searchTermOf(types.Date),

        createdOnOrAfterYear: searchTermOf(types.number),
        employeeId: searchTermOf(types.number),
        isUnassigned: searchTermOf(types.boolean, true),
        isRecurring: searchTermOf(types.boolean),

        isOpenOrCompletedRecently: searchTermOf(types.boolean),
        jobIds: searchTermOf(types.array(types.number)),

        daysSinceAssigned: searchTermOf(types.number)
    })
    .actions((self) => ({
        setIds: (id: number) => self.ids.value.push(id),
        setStatusIdList: (val: number[]) => (self.statusIdList.value = val),
        setPriorityId: (val: number) => (self.priorityId.value = val),
        setPropertyGroupId: (val: number) => (self.propertyGroupId.value = val),
        setPropertyGroupIds: (val: number[]) => (self.propertyGroupIds.value = val),
        setStatusId: (val: number) => (self.statusId.value = val),
        setIsUnassigned: (val: boolean) => (self.isUnassigned.value = val),
        setCategoryId: (val: number) => (self.categoryId.value = val),
        setSubcategoryId: (val: number) => (self.subcategoryId.value = val),
        setPropertyId: (propertyId: number) => (self.propertyId.value = propertyId),
        setPropertyTeamId: (propertyTeamId: number) => (self.propertyTeamId.value = propertyTeamId),
        setPortfolioViewId: (portfolioViewId: number) => (self.portfolioViewId.value = portfolioViewId),
        setUnitId: (unitId: number) => (self.unitId.value = unitId),
        setCreatedOnOrAfterYear: (val: number) => (self.createdOnOrAfterYear.value = val),
        setCreatedBetween: (date: Date) => {
            const [from, to] = createDateRange(self.createdFrom.value, self.createdTo.value, date);
            self.createdFrom.value = from;
            self.createdTo.value = to;
        },
        setUnitCode: (unitCode: string) => (self.unitCode.value = unitCode),
        setWorkOrderNumber: (workOrderNumber: string) => (self.workOrderNumber.value = workOrderNumber),
        setJobId: (jobId: number) => (self.jobId.value = jobId),
        setVendorId: (vendorId: number) => (self.vendorId.value = vendorId),
        setisOpenOrCompletedRecently: (isOpenOrCompletedRecently: boolean) =>
            (self.isOpenOrCompletedRecently.value = isOpenOrCompletedRecently),
        setjobIds: (jobIds: Array<number>) => (self.jobIds.value = jobIds),
        setDaysSinceAssigned: (val: number) => (self.daysSinceAssigned.value = val),
    }))
    .views((self) => ({
        get query(): WorkOrdersQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        },
        get createdBetween(): Date[] {
            return toDateRange(self.createdFrom.value, self.createdTo.value);
        }
    }));
type WorkOrderSearchTermsModelType = Instance<typeof WorkOrderSearchTermsModel>;
export interface IWorkOrderSearchTermsModel extends WorkOrderSearchTermsModelType {}
