import {
    AfterContentInit,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import { Column } from '@app/core/models';
import { GridColumn } from './grid-column';
import { DateGridColumnComponent } from './date-grid-column.component';
import { DateOnlyGridColumnComponent } from './date-only-grid-column.component';
import { SmartDateGridColumnComponent } from './smart-date-grid-column.component';
import { TextGridColumnComponent } from './text-grid-column.component';
import { TruncatedGridColumnComponent } from './truncated-grid-column.component';
import { HyperlinkGridColumnComponent } from './hyperlink-grid-column.component';
import { ColorGridColumnComponent } from './color-grid-column.component';
import { UnOrderedListGridColumnComponent } from './unordered-list-column.component';
import { IconGridColumnComponent } from './icon-grid-column.component';
import { PropertyAccessPropertyGridColumnComponent } from './property-access-property-grid-column.component';
import { PropertyAccessListGridColumnComponent } from './property-access-list-grid-column.component';
import { BooleanGridColumnComponent } from './boolean-grid-column.component';
import { UserGridColumnComponent } from './user-grid-column.component';
import { FileSizeColumnComponent } from './filesize-grid-column.component';
import { HtmlGridColumnComponent } from './html-grid-column.component';
import { ThumbnailGridColumnComponent } from './thumbnail-grid-column.component';
import { AssignedUsersListColumnComponent } from './assigned-users-list.column.component';
import { RatingGridColumnComponent } from './rating-grid-column.component';

@Component({
    selector: 'app-grid-column',
    template: ' <ng-template #column></ng-template> '
})
export class GridColumnComponent implements AfterContentInit {
    @ViewChild('column', { read: ViewContainerRef, static: true })
    wrapper: ViewContainerRef;

    @Input() column: Column;
    @Input() row: any;

    @Input() defaultValue?: string;

    @Output() doubleClick?: EventEmitter<Column> = new EventEmitter<Column>();
    @Output() click?: EventEmitter<Column> = new EventEmitter<Column>();

    private typeToComponentMap = {
        date: DateGridColumnComponent,
        dateOnly: DateOnlyGridColumnComponent,
        smartDate: SmartDateGridColumnComponent,
        text: TextGridColumnComponent,
        truncated: TruncatedGridColumnComponent,
        html: HtmlGridColumnComponent,
        fileSize: FileSizeColumnComponent,
        user: UserGridColumnComponent,
        boolean: BooleanGridColumnComponent,
        propertyAccessList: PropertyAccessListGridColumnComponent,
        propertyAccessProperty: PropertyAccessPropertyGridColumnComponent,
        unorderedList: UnOrderedListGridColumnComponent,
        icon: IconGridColumnComponent,
        color: ColorGridColumnComponent,
        hyperlink: HyperlinkGridColumnComponent,
        thumbnail: ThumbnailGridColumnComponent,
        assignedUsersList: AssignedUsersListColumnComponent,
        rating: RatingGridColumnComponent,
        default: TextGridColumnComponent
    };

    constructor(private resolver: ComponentFactoryResolver) {}

    ngAfterContentInit() {
        const targetComp = this.getComponentFrom(this.column);
        const factory = this.resolver.resolveComponentFactory(targetComp);
        const component = this.wrapper.createComponent(factory);
        const instance = component.instance as GridColumn;
        instance.contents = this.column.defaultValue
            ? this.row[this.column.field] || this.column.defaultValue
            : this.row[this.column.field];
        if (this.column.props) {
            instance.props = this.column.props.reduce((a, c) => {
                a[c] = this.row[c];
                return a;
            }, {});
        }
    }

    onColumnDblClick() {}

    onColumnClick() {}

    getComponentFrom(column: Column) {
        if (column.type) {
            const matchingColumnType = this.typeToComponentMap[column.type];
            return matchingColumnType
                ? matchingColumnType
                : this.typeToComponentMap.default;
        }
        return this.typeToComponentMap.default;
    }
}
