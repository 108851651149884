import { Component, OnInit } from '@angular/core';
import {
    AuthenticationService,
    AuthorizationService,
    LoginService,
    UserService
} from '../../../../core/services';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { IUserEntity } from '@app/core/models';

(window as any).global = window;

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();

    currentUser: IUserEntity;
    user$: Observable<IUserEntity>;
    userSub$: Subscription;

    constructor(
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private authorizationService: AuthorizationService,
        private loginService: LoginService
    ) {}

    ngOnInit(): void {
        this.authService.resumeAuth(
            window.location.hash,
            (_) => {
                this.router.navigate(['error-occurred']);
            },
            (profile, redirectTo) => {
                // Call the Authorization service, retrieve
                // the list of allowed features for the user,
                // and save the allowed feature list to the local storage
                this.authorizationService.saveUsersAllowedFeatures(
                    profile.nickname
                );

                // record the login details for the user
                const newLoginInfo = this.loginService.initializeLoginSuccess();
                newLoginInfo.createdBy = profile.nickname;
                this.loginService.saveLoginInfo(newLoginInfo).subscribe();

                this.checkUserExists(redirectTo);
            }
        );
    }

    checkUserExists(redirectTo): void {
        const email = localStorage.getItem('profile_nickname');

        this.user$ = this.userService.getUserEntityByEmail(email).pipe(
            takeUntil(this.destroy$),
            map((data) => (this.currentUser = data))
        );

        this.userSub$ = this.user$.subscribe(() => {
            if (this.currentUser.id && this.currentUser.id > 0) {
                localStorage.setItem(
                    'profile_currentuserid',
                    this.currentUser.id.toString()
                );
                if (!redirectTo.trim().substring(1) && this.currentUser.loginStartPage) {
                    this.router.navigateByUrl(this.currentUser.loginStartPage);
                } else {
                    this.router.navigateByUrl(redirectTo);
                }
            } else {
                this.router.navigate(['register']);
            }
        });
    }
}
