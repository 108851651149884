import { types, Instance } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';

export const PropertyGroupModel = resource(
    'property-groups',
    types
        .model({
            id: types.number,
            name: types.maybeNull(types.string),
            notes: types.maybeNull(types.string),
            propertiesAssigned: types.array(types.number),
            propertiesDescriptions: types.maybe(types.string)
        })
        .views(self => ({
            get totalProperties() {
                return self.propertiesAssigned.length;
            }
        }))
        .actions(self => ({
            setName(value: string) {
                self.name = value;
            },
            setNotes(value: string) {
                self.notes = value;
            },
            assignProperty(value: number[]) {
                self.propertiesAssigned.replace(value);
            }
        }))
);
type PropertyGroupModelType = Instance<typeof PropertyGroupModel>;
export interface IPropertyGroupModel extends PropertyGroupModelType {}
