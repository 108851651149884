<div class="p-grid p-align-center {{ containerClass }} control-padding" *ngIf="!isHidden || !fieldAccess?.isHidden">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}" [ngClass]="{ required: required }">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <input
            pInputText
            [appHighlightOnValidationError]="name"
            type="text"
            name="{{ name }}"
            [required]="required"
            [placeholder]="placeholder"
            [appFocusIf]="focus"
            [ngModel]="model"
            (change)="onChange($event)"
            [disabled]="disabled || fieldAccess?.isLocked"
            (blur)="onBlur($event)"
        />
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
