import { Component } from '@angular/core';

@Component({
    template: `
        <div [style.background]="contents">
        </div>
    `,
    selector: 'app-color-grid-column',
    styles: ['div { width: 24px; height: 24px; margin: 0; position: relative; left: calc(55% - 24px); }']
})
export class ColorGridColumnComponent {
    contents: any;
}
