import { dropdownDescriptor as ddDesc } from '@app/shared/utils/dropdown-helper';
import { types, Instance, getSnapshot } from 'mobx-state-tree';
import { VendorDropdownModel } from './vendor-dropdown.model';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';
import { searchTermsUtils } from '@app/shared/utils/mobx-utils';
import { VendorSearchTermsModel } from './vendor-search-term.model';

export const VendorSearchModel = types
    .model({
        dropdowns: types.optional(VendorDropdownModel, {} as Instance<typeof VendorDropdownModel>),
        terms: types.optional(VendorSearchTermsModel, {} as Instance<typeof VendorSearchTermsModel>)
    })
    .views((self) => ({
        get description(): Array<[ISearchTerm, string]> {
            const { terms, dropdowns } = self;
            const descriptions: Array<[ISearchTerm, string]> = [
                [terms.contactName, `Contact Name: ${terms.contactName.value}`],
                [terms.name, `Name: ${terms.name.value}`],
                [terms.code, `Code: ${terms.code.value}`],

                [
                    { ...terms.vendorStatusId },
                    ddDesc(
                        dropdowns.vendorStatusDropdown,
                        terms.vendorStatusId.value,
                        'Vendor Status: '
                    )
                ],

                [
                    { ...terms.vendorTypeId },
                    ddDesc(dropdowns.vendorTypeDropdown, terms.vendorTypeId.value, 'Vendor Type: ')
                ]
            ];
            return descriptions.filter(
                (term) => searchTermsUtils.hasValue(term[0]) && !term[0].disabled
            );
        },
        get inEffect() {
            const terms = getSnapshot(self.terms);
            return Boolean(Object.values(terms).filter(searchTermsUtils.hasValue).length);
        },
        get initialized() {
            return Boolean(self.dropdowns.dropdownsReady);
        },
        get enabledTerms() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(
                    (term) => searchTermsUtils.snapshotHasValue(term) && !term.disabled
                ).length
            );
        },
        inEffectExceptTerm(...exceptions: Array<string>) {
            const terms = getSnapshot(self.terms);
            const filtered = Object.keys(terms)
                .filter((key) => !exceptions.includes(key))
                .reduce((obj, key) => ({ ...obj, [key]: terms[key] }), {});
            return Boolean(Object.values(filtered).filter(searchTermsUtils.hasValue).length);
        }
    }))
    .views((self) => ({
        get canBeCleared() {
            return (
                self.initialized && self.inEffect && searchTermsUtils.anyEnabledTerms(self.terms)
            );
        }
    }))
    .actions((self) => ({
        async initialize() {
            await self.dropdowns.fetchDropdowns();
        }
    }));
type modelType = Instance<typeof VendorSearchModel>;
export interface IVendorSearchModel extends modelType {}
