import { FeatureGroups } from '../notifications/models/featureGroups/feature-group-enum.model';

export const RoutesNames = {
    BASE: '/operations',

    OPS_DASHBOARD_THIS_WEEK: 'dashboards/thisweek',

    OPS_CONTACT_GROUPS: 'notifications/contactgroups/' + 'operations',
    OPS_NEW_CONTACT_GROUP: 'notifications/contactgroups/new/' + 'operations',
    OPS_EDIT_CONTACT_GROUP: 'notifications/contactgroups/edit/:id/' + 'operations',
    OPS_NOTIFICATIONS: 'notifications',

    OPS_NOTIFICATION_TYPES: 'notifications/types/' + 'operations',

    NEW_WORK_ORDERS: 'workorders/newbyform',
    NEW_WORK_ORDERS_BY_TEMPLATE: 'workorders/newbytemplate/:id',
    WORK_ORDERS: 'workorders/list',
    SHOW_WORK_ORDERS: 'workorders/list/:id',
    EDIT_WORK_ORDERS: 'workorders/list/edit/:id',
    WORK_ORDERS_CALENDAR: 'workorders/calendar',
    WORK_ORDERS_QUEUE: 'workorders/queue',
    WORK_ORDERS_TODAY: 'workorders/today',

    SETTINGS: 'settings/hrandpayroll',

    NEW_WORK_ORDER_TEMPLATE: 'templates/add',
    WORK_ORDER_TEMPLATES: 'templates/view',
    EDIT_WORK_ORDER_TEMPLATE: 'templates/edit/:id',
    SHOW_WORK_ORDER_TEMPLATE: 'templates/:id',

    NEW_RECURRING_WORK_ORDER: 'recurring/add',
    RECURRING_WORK_ORDERS: 'recurring/list',
    EDIT_RECURRING_WORK_ORDER: 'recurring/list/edit/:id',
    SHOW_RECURRING_WORK_ORDER: 'recurring/list/:id',

    NEW_VENDOR: 'vendors/add',
    VENDORS: 'vendors/list',
    EDIT_VENDOR: 'vendors/edit/:id',
    DETAILS_VENDOR: 'vendors/details/:id',
    DELETE_VENDOR: 'vendors/delete/:id',

    ICON_SETTINGS: 'settings/icons',
    WORK_ORDER_SETTINGS: 'settings/work-order-settings',
    NEW_WORK_ORDER_SETTING: 'settings/work-order-settings/new',
    EDIT_WORK_ORDER_SETTING: 'settings/work-order-settings/edit/:id',

    TIME_ISSUES: 'time/issues',
    EDIT_TIME_ISSUES: 'time/issues/edit/:id',
    TIME_ADJUSTMENTS: 'time/adjustments',
    NEW_TIME_ENTRY_ADJUSTMENTS: 'time/adjustments/new',
    EDIT_TIME_ADJUSTMENT: 'time/adjustments/edit/:id',
    EDIT_TIME_ENTRY_CREATE_TIME_ADJUSTMENT: 'time/adjustment-entries/new/:id',
    CREATE_TIME_ENTRY: 'time/entries/new',
    TIME_ENTRIES: 'time/entries',
    SHOW_TIME_ENTRY: 'time/entries/:id',

    UNASSIGNED_WORK_ORDERS: 'dispatch/unassigned',
    ASSIGNED_WORK_ORDERS: 'dispatch/assigned',
    DISPATCH_RECURRING_WORK_ORDERS: 'dispatch/recurring',

    QUICKSTART: 'quickstart'
};
