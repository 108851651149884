import { getPropertyMembers } from 'mobx-state-tree';
import { ISelectItem } from '../models/goldeye-select-item';

export function dropdownProperties(state: any): string[] {
    return Object.keys(getPropertyMembers(state).properties)
        .filter(key => key.endsWith('Dropdown'))
        .map(key => key);
}

export function dropdownDescriptor(
    dropdownValues: ISelectItem[],
    selectedValue: number,
    prefix: string = ''
): string {
    if (dropdownValues && selectedValue !== null) {
        const match = dropdownValues.find(x => x.value === selectedValue);
        return match ? `${prefix}${match.label}` : '';
    }
    return '';
}
