import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, zip } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppLocalStorageService {
    protected subjects: { [key: string]: BehaviorSubject<any> } = {};

    selectSync(key: string, defaultValue: string = null): string {
        const value = localStorage.getItem(key);
        return value ? value : defaultValue;
    }

    selectMany(keys: string[]): Observable<any> {
        const obs = keys.map(key => this.select(key));
        return zip(...obs);
    }

    select(key: string, defaultValue: any = null): Observable<any> {
        if (this.subjects.hasOwnProperty(key)) {
            return this.subjects[key];
        }

        if (!localStorage.getItem(key) && defaultValue) {
            localStorage.setItem(key, JSON.stringify(defaultValue));
        }

        const value = localStorage.getItem(key); // p? JSON.parse(localStorage.getItem(key))       : defaultValue;

        return (this.subjects[key] = new BehaviorSubject(value));
    }

    set(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));

        if (this.subjects.hasOwnProperty(key)) {
            this.subjects[key].next(value);
        }
    }

    remove(key: string): void {
        localStorage.removeItem(key);

        if (this.subjects.hasOwnProperty(key)) {
            this.subjects[key].next(null);
        }
    }
}

export interface IAppStorageService {
    select(key: string, defaultValue: any): Observable<any>;
    set(key: string, value: any): void;
    remove(key: string): void;
}
