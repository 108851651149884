export enum UserRoles {
    PropertyManagerRoleId = 2,
    ResidentManagerRoleId = 3,
    CommercialPropertyManagerRoleId = 4,
    SeniorMaintenanceManagerRoleId = 5,
    MaintenanceStaffRoleId = 6,
    PurchasingRoleId = 7,
    AccountsReceivableRoleId = 8,
    AccountsPayablesRoleId = 9,
    FinanceRoleId = 10,
    PayrollAdminRoleId = 15,
    ExecutiveRoleId = 12
}
