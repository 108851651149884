export const RoutesNames = {
    BASE: '/rm',
    DASHBOARD: 'dashboard',
    INCIDENT_REPORTS: 'incidentreports',
    INCIDENT_REPORTS_DETAIL: 'incidentreports/:id',
    INSPECTIONS: 'inspections',
    INSPECTIONS_DETAIL: 'inspections/:id',

    NEW_TROUBLE_REPORT: 'property/:propertyId/trouble-report/new'
};
