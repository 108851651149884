import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService } from '@app/core/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    nickname: string;
    currentlyLoggedIn: boolean;

    constructor(
        private renderer: Renderer2,
        private authService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.renderer.addClass(document.body, 'login-body');
    }

    ngOnInit() {
        this.nickname = localStorage.getItem('profile_nickname');
        this.currentlyLoggedIn = this.authService.renewAccessTokenIfAuthenticated();
    }

    initiateLogin() {
        const {
            snapshot: { queryParams }
        } = this.activatedRoute;
        const returnUrl = queryParams.returnUrl || '';
        this.authService.login(returnUrl);
    }

    continueSession() {
        this.router.navigate(['welcome']);
    }

    initiateLogout() {
        this.authService.logout();
    }

    isLoggedIn(): boolean {
        return this.currentlyLoggedIn;
    }
}
