<div
    class="p-grid p-align-center control-padding"
    *ngIf="!fieldAccess?.isHidden"
>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-calendar
            #cal
            [appendTo]="appendTo"
            [dateFormat]="dateFormat"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            name="{{ this.name }}"
            [showTime]="true"
            [timeOnly]="true"
            [hourFormat]="hourFormat"
            [disabled]="fieldAccess?.isLocked || disabled"
            [appHighlightOnValidationError]="this.name"
            [showIcon]="false"
            [ngModel]="value"
            (onSelect)="dateSelected($event)"
            (ngModelChange)="dateSelected($event)"
            [touchUI]="layout.windowIsMobile$ | async"
            [defaultDate]="defaultDate"
        >
        </p-calendar>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
