import { types, getSnapshot } from 'mobx-state-tree';
import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import { PropertyAccessQuery } from '../../../residential/queries/property-access.query';

export const PropertyAccessSearchTermsModel = types
    .model({
        userId: searchTermOf(types.number),
        userRoleId: searchTermOf(types.number),
        propertyId: searchTermOf(types.number),
        accessStatus: searchTermOf(types.number),
        property: searchTermOf(types.string),
        user: searchTermOf(types.string)
    })
    .views((self) => ({
        get query(): PropertyAccessQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        }
    }))
    .actions((self) => ({
        setPropertyId: (propertyId: number) => (self.propertyId.value = propertyId),
        setProperty: (property: string) => (self.property.value = property),
        setUser: (user: string) => (self.user.value = user)
    }));

type PropertyAccessSearchTermsModelType = typeof PropertyAccessSearchTermsModel.Type;
export interface IPropertyAccessSearchTermsModel extends PropertyAccessSearchTermsModelType {}
