export interface IUserFeature {
    userId: number;
    featureId: number;
    statusId: number;

    createdBy: string;
    createdDate: Date;
    dateGranted: Date;
    dateRevoked: Date;
    displayOrder: number;
    featureName: string;
    description: string;
    isArchived: boolean;
    modifiedBy: string;
    modifiedDate: Date;
    status: string;

    dateGrantedString: string;
    dateRevokedString: string;
}

export class UserFeature implements IUserFeature {
    userId: number;
    featureId: number;
    statusId: number;

    createdBy: string;
    createdDate: Date;
    dateGranted: Date;
    dateRevoked: Date;
    displayOrder: number;
    featureName: string;
    description: string;
    isArchived: boolean;
    modifiedBy: string;
    modifiedDate: Date;
    status: string;

    dateGrantedString: string;
    dateRevokedString: string;
}
