import { types, flow, Instance } from 'mobx-state-tree';
import {
    withItems,
    withCount,
    withPaginator
} from '@app/shared/utils/mobx-mixins';
import { JobModel } from './job.model';
import { SortOrder } from '@app/core/models';
import { JobSearchModel } from './job-search.model';
import { JobsQuery } from '../../queries/jobs/job.query';

export const JobGridModel = types
    .compose(
        withItems('jobs', JobModel),
        withCount('jobs/count'),
        withPaginator('id', SortOrder.Descending, 50),
        types.model({
            loading: true,
            search: JobSearchModel
        })
    )
    .views((self) => ({
        get query(): JobsQuery {
            return {
                ...self.paginator.query,
                ...self.search.terms.query
            };
        },
        get listLoading() {
            return Boolean(!self.search.initialize || self.loading);
        },
        get itemName() {
            if (self.totalRecords > 1) {
                return 'Jobs';
            }
            return 'Job';
        }
    }))
    .actions((self) => ({
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([
                    self.fetchItems(self.query),
                    self.fetchCount(self.query)
                ]);
            } finally {
                self.loading = false;
            }
        })
    }));
type JobGridModelType = Instance<typeof JobGridModel>;
export interface IJobGridModel extends JobGridModelType {}
