import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ILinkFormModel } from '@app/shared/models/links/link-form.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-link-form',
    templateUrl: './link-form.component.html',
    styleUrls: ['./link-form.component.scss']
})
export class LinkFormComponent {
    @Input() form: ILinkFormModel;
    @Output() savingDone = new EventEmitter<ILinkFormModel>();

    constructor(private messageService: MessageService) {}

    async onSaveBtnClick() {
        if (this.form.link.isNew) {
            await this.form.link.save();
            if (this.form.link.noErrors) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Link created',
                    detail: 'New link was created successfully'
                });
                this.savingDone.emit(this.form);
            }
        } else {
            await this.form.link.update();
            this.savingDone.emit(this.form);
        }
    }

    onCancel() {
        this.savingDone.emit(null);
    }
}
