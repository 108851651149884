export interface IFeatureOverrideLookup {
    label: string;
     value: {
        id: number;
        name: string;
        code: string;
    };
}

export class FeatureOverrideLookup implements IFeatureOverrideLookup {
    label: string;
     value: {
        id: number;
        name: string;
        code: string;
    };
}
