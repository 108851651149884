import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';

import { MessageService } from 'primeng/api';
import { UIErrorInterceptor } from '@app/core/interceptors/ui-error-interceptor';

import { AppComponent } from './app.component';
import { LoginModule } from '@app/modules/login/login.module';
import { PermissionDeniedComponent } from './core/components/permission-denied/permission-denied.component';
import { GlobalErrorHandler } from '@app/core/errors';
import { AccessTokenInterceptor } from './core/interceptors/access-token-interceptor';
import { ErrorOccurredComponent } from './core/components/error-occurred/error-occurred.component';
import { TzInterceptor } from './core/interceptors/tz-interceptor';
import { CanDeactivateGuard } from './core/guards/can-deactivate/can-deactivate.guard';
import { AppRoutingModule } from '@app/app-routing.module';
import { MenuService } from './app.menu.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        SharedModule,
        CoreModule,
        AppRoutingModule,

        LoginModule,
    ],
    declarations: [
        AppComponent,
        PermissionDeniedComponent,
        ErrorOccurredComponent
    ],
    providers: [
        MessageService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UIErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AccessTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TzInterceptor,
            multi: true
        },
        CanDeactivateGuard,
        MenuService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
