import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import {
    DropdownModel,
    ISelectItem
} from '@app/shared/models/goldeye-select-item';

export const JobDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            propertyScope: types.array(types.number),
            propertyDropdown: DropdownModel,
            propertyTeamDropdown: DropdownModel,
            unitDropdown: DropdownModel,
            jobTypeDropdown: DropdownModel,
            jobStatusDropdown: DropdownModel
        })
        .views((self) => ({
            get filteredProperties(): ISelectItem[] {
                const scope = new Set(self.propertyScope);
                if (scope.size) {
                    return self.propertyDropdown.filter(
                        (p) => p.value == null || scope.has(Number(p.value))
                    );
                }
                return self.propertyDropdown;
            }
        }))
);
type JobDropdownsModelType = Instance<typeof JobDropdownModel>;
export interface IJobDropdownsModel extends JobDropdownsModelType {}
