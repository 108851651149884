import { types, flow, Instance } from 'mobx-state-tree';
import {
    withItems,
    withCount,
    withPaginator
} from '@app/shared/utils/mobx-mixins';
import { VendorModel } from './vendor.model';
import { SortOrder } from '@app/core/models';
import { VendorQuery } from '../../queries/vendors/vendor.query';
import { VendorSearchModel } from './vendor-search.model';

export const VendorGridModel = types
    .compose(
        withItems('vendors', VendorModel),
        withCount('vendors/count'),
        withPaginator('id', SortOrder.Descending),
        types.model({
            loading: true,
            search: VendorSearchModel
        })
    )
    .views((self) => ({
        get query(): VendorQuery {
            return {
                ...self.paginator.query,
                ...self.search.terms.query
            };
        },
        get listLoading() {
            return Boolean(!self.search.initialize || self.loading);
        },
        get itemName() {
            if (self.totalRecords > 1) {
                return 'Vendors';
            }
            return 'Vendor';
        }
    }))
    .actions((self) => ({
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([
                    self.fetchItems(self.query),
                    self.fetchCount(self.query)
                ]);
            } finally {
                self.loading = false;
            }
        })
    }));
type VendorGridModelType = Instance<typeof VendorGridModel>;
export interface IVendorGridModel extends VendorGridModelType {}
