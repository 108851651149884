<div *mobxAutorun>
    <p-confirmDialog
        appendTo="body"
        header="Confirmation"
        key="delete-link-confirmation"
        icon="pi pi-exclamation-triangle"
        [style]="{ width: '425px' }"
    >
    </p-confirmDialog>

    <p-overlayPanel [styleClass]="'p-col-6'" #urlPreviewPopup [appendTo]="dt">
        <app-link-preview [preview]="hoveredLink?.urlPreviewAsJSON">
        </app-link-preview>
    </p-overlayPanel>

    <p-table
        #dt
        [sortField]="grid.paginator.sortField"
        [responsive]="true"
        [sortOrder]="grid.paginator.sortOrder"
        [value]="grid.items"
        [columns]="columns"
        [loading]="grid.loading"
        [rowHover]="true"
        [paginator]="grid.paginator.showPages"
        [rows]="10"
        [lazy]="true"
        [totalRecords]="grid.totalRecords"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        (onLazyLoad)="onGridLazyLoad($event)"
        selectionMode="single"
    >
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length + 1">
                    No records found
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th
                    *ngFor="let col of columns"
                    [ngStyle]="{ width: col.width ? col.width : 'auto' }"
                    [pSortableColumn]="
                        col.sortColumn ? col.sortColumn : col.field
                    "
                    [pSortableColumnDisabled]="col.disableSorting"
                >
                    {{ col.header }}
                    <p-sortIcon
                        *ngIf="!col.disableSorting"
                        [field]="col.sortColumn ? col.sortColumn : col.field"
                    ></p-sortIcon>
                </th>
                <th colspan="3" style="width: 15%;"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td
                    *ngFor="let col of columns"
                >
                    <span class="p-column-title">{{ col.header }}</span>
                    <app-grid-column
                        class="format-link-column"
                        *ngIf="!col.type || col.type !== 'html'"
                        [column]="col"
                        [row]="rowData"
                    ></app-grid-column>
                    <span
                        *ngIf="col.type && col.type === 'html'"
                        [innerHTML]="rowData[col.field] | truncate: 100"
                    >
                    </span>
                </td>
                <td>
                    <button
                        pButton
                        type="button"
                        icon="fa fa-search"
                        (mouseenter)="
                            onUrlPreviewBtnEnter(
                                rowData,
                                $event,
                                urlPreviewPopup
                            )
                        "
                        (mouseleave)="
                            onUrlPreviewBtnExit($event, urlPreviewPopup)
                        "
                    ></button>
                </td>
                <td>
                    <button
                        pButton
                        type="button"
                        icon="fa fa-edit"
                        (click)="onEditBtnClick(rowData)"
                    ></button>
                </td>
                <td>
                    <button
                        pButton
                        type="button"
                        icon="fas fa-trash-alt"
                        (click)="onDeleteBtnClick(rowData)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
