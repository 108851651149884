<div *mobxAutorun>
    <div *ngIf="!list.loading; else loading" class="comment-container">
        <div *ngIf="list.items.length; else empty">
            <div *ngFor="let comment of list.items | mstArray" class="comment">
                <h3>
                    <b><i class="fa fa-comment" [style.color]="bubbleColor"></i> {{ comment.createdBy }}</b>
                    on {{ comment.createdDateAsRelativeDate }}
                </h3>
                <p [innerHTML]="comment.contents"></p>
            </div>
        </div>

        <ng-template #empty>
            <p>No comments</p>
        </ng-template>
    </div>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
