import { dateDescription as dDesc } from '@app/shared/utils/date-helper';
import { dropdownDescriptor as ddDesc } from '@app/shared/utils/dropdown-helper';
import { types, Instance, getSnapshot } from 'mobx-state-tree';
import { PropertyTeamMemberSearchTermsModel } from './property-team-member-search-terms.model';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';
import { searchTermsUtils } from '@app/shared/utils/mobx-utils';
// eslint-disable-next-line max-len
import { PropertyCallsheetDropdownModel } from '@app/modules/residential/models/property/property-callsheet-dropdown.model';

export const PropertyTeamMemberSearchModel = types
    .model({
        dropdowns: types.optional(
            PropertyCallsheetDropdownModel,
            {} as Instance<typeof PropertyCallsheetDropdownModel>
        ),
        terms: types.optional(
            PropertyTeamMemberSearchTermsModel,
            {} as Instance<typeof PropertyTeamMemberSearchTermsModel>
        )
    })
    .views((self) => ({
        get description(): Array<[ISearchTerm, string]> {
            const { terms, dropdowns } = self;
            const descriptions: Array<[ISearchTerm, string]> = [
                [
                    terms.propertyId,
                    ddDesc(
                        dropdowns.propertyDropdown,
                        terms.propertyId.value,
                        'PropertyId: '
                    )
                ]

                // [
                //     { ...terms.certificateType, disabled: true },
                //     ddDesc(
                //         dropdowns.certificateTypeDropdown,
                //         terms.certificateType.value,
                //         'Certificate Type: '
                //     )
                // ]
            ];
            return descriptions.filter((term) =>
                searchTermsUtils.hasValue(term[0])
            );
        },
        get inEffect() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(searchTermsUtils.hasValue).length
            );
        },
        get initialized() {
            return Boolean(self.dropdowns.dropdownsReady);
        },
        get enabledTerms() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(
                    (term) =>
                        searchTermsUtils.snapshotHasValue(term) &&
                        !term.disabled
                ).length
            );
        }
    }))
    .views((self) => ({
        get canBeCleared() {
            return (
                self.initialized &&
                self.inEffect &&
                searchTermsUtils.anyEnabledTerms(self.terms)
            );
        }
    }))
    .actions((self) => ({
        async initialize() {
            await self.dropdowns.fetchDropdowns();
        }
    }));
type modelType = Instance<typeof PropertyTeamMemberSearchModel>;
export interface IPropertyTeamMemberSearchModel extends modelType {}
