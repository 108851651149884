<div
    *mobxAutorun
    class="p-grid p-col-12"
    [ngClass]="{ 'search-in-effect': search.inEffect }"
>
    <div class="p-inputgroup p-col-12">
        <input
            type="text"
            pInputText
            [ngModel]="search.matchAll.value"
            (keyup)="onMatchAllKeyUp($event)"
            placeholder="{{ placeholder }}"
        />
        <button
            pButton
            icon="fas fa-external-link-square-alt"
            type="button"
            label="Advanced"
            [disabled]="true"
        ></button>
        <button
            pButton
            type="button"
            label="Clear"
            icon="fa fa-times"
            [disabled]="!search.inEffect"
            (click)="onClearBtnClick()"
        ></button>
    </div>
</div>
