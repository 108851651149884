import { FkConstraintViolationError } from './fk-constraint-violation-error';
import { DbUpdateConflictError } from './db-update-conflict-error';

export interface ErrorMessage {
    severity?: string;
    summary: string;
    detail: string;
}

export interface UIError {
    errors(): ErrorMessage[];
}

export function isConsistencyViolation(err: UIError) {
    return (
        err instanceof FkConstraintViolationError ||
        err instanceof DbUpdateConflictError
    );
}
