import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import { IActionItemFormModel } from '@app/modules/residential/models/action-item/action-item-form.model';
import { IActionItemUserModel } from '@app/modules/residential/models/action-item/action-item-user.model';
import { ICommentFormModel } from '@app/shared/models/comment/comment-form.model';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { castToSnapshot, clone, getSnapshot } from 'mobx-state-tree';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-action-item-form',
    templateUrl: './action-item-form.component.html',
    styleUrls: ['./action-item-form.component.scss']
})
export class ActionItemFormComponent implements OnInit {
    @Input() form: IActionItemFormModel;
    @Input() properties?: ISelectItem[];
    @Input() commentForm: ICommentFormModel;
    @Output() saveAction = new EventEmitter<{
        form: IActionItemFormModel;
        commentForm: ICommentFormModel;
    }>();

    selectedUser: ISelectItem;
    selectedProject: ISelectItem;
    selectedWorkOrder: ISelectItem;
    selectedVendor: ISelectItem;

    ngOnInit() {}

    async onSaveBtnClicked() {
        this.saveAction.emit({
            form: this.form,
            commentForm: this.commentForm
        });
    }

    assignOrRemoveTeamMember(evt) {
        const item = this.form.model.assignedToUsers.find(
            (x: IActionItemUserModel) => x.userId === evt.option.value
        );
        if (item) {
            this.form.model.removeAssignedUser(item);
        } else {
            this.form.model.addAssignedUser(castToSnapshot(evt.option));
        }
    }

    addSelectedUser() {
        this.form.model.addAssignedUser(clone(this.selectedUser));
        this.selectedUser = null;
    }

    selectedUserFirstName() {
        return this.selectedUser && this.selectedUser.value
            ? this.selectedUser.label.substr(
                0,
                this.selectedUser.label.indexOf(' ')
            )
            : '';
    }

    assignOrRemoveProject(evt) {
        const item = this.form.model.projects.find((x: ISelectItem) => x.value === evt.option.value);
        if (item) {
            this.form.model.removeProject(item);
        } else {
            this.form.model.addProject(castToSnapshot(evt.option));
        }
    }

    addSelectedProject() {
        this.form.model.addProject(getSnapshot(this.selectedProject));
        this.selectedProject = null;
    }

    addSelectedWorkOrder() {
        this.form.model.addWorkOrder(this.selectedWorkOrder);
        this.selectedWorkOrder = null;
    }

    addSelectedVendor() {
        this.form.model.addVendor(this.selectedVendor);
        this.selectedVendor = null;
    }
}
