<div class="p-grid p-align-center control-padding">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ previewClass }}">
        <p-colorPicker
            name="{{ name }}"
            [ngModel]="model"
            (onChange)="onChange($event)"
        >
        </p-colorPicker>
    </div>

    <div class="{{ inputClass }}">
        <input
            pInputText
            type="text"
            [ngModel]="model"
            (change)="onInputChange($event)"
        />
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
