import { types, flow, Instance } from 'mobx-state-tree';
import { withCount, withItems, withPaginator } from '@app/shared/utils/mobx-mixins';
import { IPropertyAccessModel, PropertyAccessModel } from './property-access.model';
import { SortOrder } from '@app/core/models';
import { PropertyAccessSearchModel } from './property-access-search.model';
import { PropertyAccessQuery } from '../../../residential/queries/property-access.query';

export const PropertyAccessGridModel = types
    .compose(
        withItems('property-access', PropertyAccessModel),
        withCount('property-access/count'),
        withPaginator('id', SortOrder.Descending, 50),
        types.model({
            loading: true,
            search: PropertyAccessSearchModel
        })
    )
    .views((self) => ({
        get query(): PropertyAccessQuery {
            return {
                ...self.paginator.query,
                ...self.search.terms.query
            };
        },
        get listLoading() {
            return Boolean(!self.search.initialize || self.loading);
        },
        get itemName() {
            if (self.totalRecords > 1) {
                return 'PropertyAccess';
            }
            return 'PropertyAccess';
        },
        get byProperty(): Array<IPropertyAccessModel> {
            const primaryUserRoleId = self.search.terms.userRoleId.value;
            const result = self.items.reduce((groups, item) => {
                const { propertyId } = item;
                groups[propertyId] = groups[propertyId] || {
                    propertyId,
                    propertyCode: item.propertyCode,
                    propertyAddress: item.propertyAddress,
                    propertyName: item.propertyName,
                    propertyNumberOfUnits: item.propertyNumberOfUnits,
                    isTemp: item.isTemp,
                    isNotActive: item.isNotActive,
                    primaryUserRoleId
                };
                return groups;
            }, {});
            return Object.values(result);
        },
        get propertyIds(): Array<number> {
            return self.items.map((x) => x.propertyId);
        }
    }))
    .actions((self) => ({
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([self.fetchItems(self.query), self.fetchCount(self.query)]);
            } finally {
                self.loading = false;
            }
        })
    }));
type PropertyAccessGridModelType = Instance<typeof PropertyAccessGridModel>;
export interface IPropertyAccessGridModel extends PropertyAccessGridModelType {}
