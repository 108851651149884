import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

    transform(value: string, numChars: number): string {
        return value && value.length > numChars ? value.substring(0, numChars) + '...' : value;
    }
}
