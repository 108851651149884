/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component, OnInit } from '@angular/core';
import GoldeyeCalendar from '../goldeye-calendar';

@Component({
    selector: 'app-two-months-no-time-calendar',
    inputs: [
        'value',
        'name',
        'range',
        'labelClass',
        'inputClass',
        'label',
        'controls',
        'disabled',
        'fieldAccess',
        'dateFormat'
    ],
    outputs: ['selection'],
    templateUrl: './two-months-no-time-calendar.component.html',
    styleUrls: ['./two-months-no-time-calendar.component.scss']
})
export class TwoMonthsNoTimeCalendarComponent extends GoldeyeCalendar
    implements OnInit {
    ngOnInit() {
        this.setClassNames();
        this.setDefaultControls();
    }
}
