<div class="p-grid p-align-center control-padding {{ containerClass }}" *ngIf="!fieldAccess?.isHidden">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}" [ngClass]="{ required: required }">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-dropdown
            *ngIf="layout.windowIsDesktop$ | async"
            [autofocus]="true"
            name="{{ name }}"
            [appHighlightOnValidationError]="name"
            [filter]="filter"
            [required]="required"
            [options]="options"
            [ngModel]="model"
            [disabled]="fieldAccess?.isLocked || disabled || options.length === 0"
            (onChange)="onChange($event)"
            (change)="onChange($event)"
            [appendTo]="appendTo"
            [optionLabel]="optionLabel"
        >
            <ng-template let-entry pTemplate="selectedItem"> <i class="{{ entry.value + '' }}"></i> {{ entry.label }} </ng-template>
            <ng-template let-entry pTemplate="item"> <i class="{{ entry.value + '' }}"></i> {{ entry.label }} </ng-template>
        </p-dropdown>
        <p-listbox
            *ngIf="layout.windowIsMobile$ | async"
            name="{{ name }}"
            [appHighlightOnValidationError]="name"
            [filter]="filter"
            [required]="required"
            [options]="options"
            [ngModel]="model"
            [disabled]="fieldAccess?.isLocked || disabled || options.length === 0"
            (onChange)="onChange($event)"
            (change)="onChange($event)"
            [optionLabel]="optionLabel"
        >
            <ng-template let-entry pTemplate="item"> <i class="{{ entry.value + '' }}"></i> {{ entry.label }} </ng-template>
        </p-listbox>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
