import { GrantStatus } from '@app/core/models';

export interface IUserRole {
    userId: number;
    roleId: number;
    statusId: GrantStatus;

    createdBy: string;
    createdDate: Date;
    dateGranted: Date;
    dateRevoked: Date;
    isPrimaryRole: boolean;
    description: string;
    displayOrder: number;
    isArchived: boolean;
    modifiedBy: string;
    modifiedDate: Date;
    roleName: string;
    displayStatus: string;

    statusChangeDescription: string;
    wasStatusChanged: boolean;

    userFullName: string;

    dateGrantedString: string;
    dateRevokedString: string;
}

export class UserRole implements IUserRole {
    userId: number;
    roleId: number;
    statusId: number;

    createdBy: string;
    createdDate: Date;
    dateGranted: Date;
    dateRevoked: Date;
    isPrimaryRole: boolean;
    description: string;
    displayOrder: number;
    isArchived: boolean;
    modifiedBy: string;
    modifiedDate: Date;
    roleName: string;
    displayStatus: string;

    statusChangeDescription: string;
    wasStatusChanged: boolean;

    userFullName: string;

    dateGrantedString: string;
    dateRevokedString: string;
}
