<div
    class="p-grid"
    [ngClass]="containerClass"
    *ngIf="!reverseButtons; else reverseButtonsContainer"
>
    <ng-content select=".before-form-field"></ng-content>

    <div [class]="btnClass" *ngIf="!readonly">
        <button
            pButton
            [ngClass]="{ 'main-action-btn': useActionBtnColor ,'secondary-action-btn':useSecondaryActionBtnColor}"
            [label]="saveLabel"
            [icon]="saveIcon"
            [iconPos]="saveIconPos"
            (click)="onSave()"
            [disabled]="disabled"
        ></button>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div [class]="btnClass" *ngIf="!writeonly">
        <button
            pButton
            [label]="cancelLabel"
            [icon]="cancelIcon"
            (click)="onCancel()"
            [disabled]="disabled"
        ></button>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>

<ng-template #reverseButtonsContainer>
    <div class="p-grid" [ngClass]="containerClass">
        <ng-content select=".before-form-field"></ng-content>

        <div [class]="btnClass" *ngIf="!writeonly">
            <button
                pButton
                [label]="cancelLabel"
                [icon]="cancelIcon"
                (click)="onCancel()"
                [disabled]="disabled"
            ></button>
        </div>

        <ng-content select=".between-form-field"></ng-content>

        <div [class]="btnClass" *ngIf="!readonly">
            <button
                pButton
                [ngClass]="{ 'main-action-btn': useActionBtnColor ,'secondary-action-btn':useSecondaryActionBtnColor}"
                [label]="saveLabel"
                [iconPos]="saveIconPos"
                [icon]="saveIcon"
                (click)="onSave()"
                [disabled]="disabled"
            ></button>
        </div>

        <ng-content select=".after-form-field"></ng-content>
    </div>
</ng-template>
