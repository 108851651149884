/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component, OnInit, Input } from '@angular/core';
import GoldeyeCalendar from '../goldeye-calendar';

@Component({
    selector: 'app-three-step-calendar-date-time',
    inputs: [
        'value',
        'name',
        'range',
        'labelClass',
        'inputClass',
        'label',
        'controls',
        'disabled',
        'fieldAccess',
        'dateFormat'
    ],
    outputs: ['selection'],
    templateUrl: './three-step-calendar-date-time.component.html',
    styleUrls: ['./three-step-calendar-date-time.component.scss']
})
export class ThreeStepCalendarDateTimeComponent extends GoldeyeCalendar
    implements OnInit {
    @Input() stepMinute = 1;

    step = 1;

    resultDate = new Date();

    ngOnInit() {
        this.setClassNames();
    }

    daySelected(date: Date, cal: any) {
        this.resultDate = date;
        this.incrementStep();
        this.openCalendar(cal);
    }

    finalDateSelected(date: Date) {
        this.dateSelected(date);
    }

    openCalendar(cal: any) {
        cal.showOverlay(cal.inputfieldViewChild.nativeElement);
    }

    resetStep() {
        this.step = 1;
    }

    incrementStep() {
        this.step++;
    }
}
