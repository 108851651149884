import { Component } from '@angular/core';

@Component({
    selector: 'app-progress-spinner',
    template: `
        <div class="ui-g">
            <div class="ui-g-12">
                <p-progressSpinner [style]="{ width: '25px', height: '25px' }">
                </p-progressSpinner>
                Loading
            </div>
        </div>
    `
})
export class ProgressSpinnerComponent {}
