import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { IPropertiesDashboardFormModel } from '@app/shared/models/dashboards/properties-dashboard-form.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
    @Input() title: string;
    @Input() form: IPropertiesDashboardFormModel;
    @Output() clickBoard = new EventEmitter<void>();

    header = '';

    headerTitles = [
        'Dashboards Home',
        'Timeline Dashboards',
        'Action Items Dashboards',
        'Work Orders Dashboards',
        'Inspections Dashboards',
        'Claims Dashboards',
        'Jobs Dashboards'
    ];

    ngOnInit(): void {
        this.header = this.headerTitles[0];
    }

    onBoardIconClick() {
        this.clickBoard.emit();
    }

    onTabChange(index) {
        this.header = this.headerTitles[index];
    }
}
