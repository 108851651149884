import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { PermissionDeniedComponent } from './core/components/permission-denied/permission-denied.component';
import { ErrorOccurredComponent } from './core/components/error-occurred/error-occurred.component';
import {
    CallbackComponent,
    LoginComponent
} from '@app/modules/login/components';

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'callback', component: CallbackComponent, pathMatch: 'full' },
    { path: 'permission-denied', component: PermissionDeniedComponent },
    { path: 'error-occurred', component: ErrorOccurredComponent },
    {
        path: 'blank',
        redirectTo: '/'
    },
    {
        path: '',
        loadChildren: () =>
            import('./modules/secured-layout/secured-layout.module').then(
                (m) => m.SecuredLayoutModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
