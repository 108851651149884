import { types, Instance } from 'mobx-state-tree';
import { withModifiedWatch } from '@app/shared/utils/mobx-mixins';
import { CommentModel } from './comment.model';

export const CommentFormModel = types.compose(
    withModifiedWatch(model => model.initialized, [
        { pattern: new RegExp('apiStatus$') }
    ]),
    types
        .model({
            comment: CommentModel
        })
        .views(self => ({
            get initialized() {
                return self.comment.apiStatus !== 'loading';
            }
        }))
        .actions(self => ({
            async initialize() {
                await self.comment.load();
            },
            clear() {
                self.comment.setContents('');
            }
        }))
);

type modelType = Instance<typeof CommentFormModel>;
export interface ICommentFormModel extends modelType {}
