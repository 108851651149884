import { types, Instance, onSnapshot, onPatch } from 'mobx-state-tree';
import { withModifiedWatch } from '@app/shared/utils/mobx-mixins';
import { ActionItemModel } from './action-item.model';
import { ActionItemDropdownModel } from './action-item-dropdown.model';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
// eslint-disable-next-line max-len
import { PropertyTeamMemberGridModel } from '@app/modules/property-team/models/property-team-member/property-team-member-grid.model';
import { JobGridModel } from '@app/modules/capital-projects/models/jobs/job-grid.model';
import { VendorGridModel } from '@app/modules/operations/models/vendors/vendor-grid.model';
import { WorkOrderGridModel } from '@app/modules/operations/models/work-orders/work-order-grid.model';
import { SessionFilterModel } from '@app/shared/models/session-filters/session-filter.model';
import { PropertyStaffGridModel } from '../property-staff/property-staff-grid.model';
import { PropertyAccessGridModel } from '../../../property-team/models/property-access/property-access-grid.model';
import { BuildingManagerGridModel } from '../building-manager/building-manager-grid.model';

export const ActionItemFormModel = types.compose(
    withModifiedWatch((model) => model.initialized, [{ pattern: new RegExp('apiStatus$') }]),
    types
        .model({
            model: ActionItemModel,
            dd: ActionItemDropdownModel,
            teamMembers: PropertyTeamMemberGridModel,
            workOrders: WorkOrderGridModel,
            projects: JobGridModel,
            vendors: VendorGridModel,
            sessionFilter: SessionFilterModel,
            staff: PropertyStaffGridModel,
            propertyAccess: PropertyAccessGridModel,
            buildingManagers: BuildingManagerGridModel
        })
        .views((self) => ({
            get initialized() {
                return self.dd.dropdownsReady && self.model.apiStatus !== 'loading';
            },
            get teamMembersAsSelectItem(): Array<ISelectItem> {
                return self.teamMembers.items
                    .filter(
                        (currentValue, currentIndex, array) => array.findIndex((t) => t.userId === currentValue.userId) === currentIndex
                    )
                    .map((t) => ({
                        value: t.userId,
                        label: t.userName,
                        parentId: null
                    }))
                    .sort((a, b) => (a.label >= b.label ? 1 : -1));
            },
            get staffUsersAsSelectItem(): Array<ISelectItem> {
                return [
                    ...self.buildingManagers.items.map((b) => ({ value: b.userId, label: b.userName, parentId: null })),
                    ...self.propertyAccess.items.map((b) => ({ value: b.userId, label: b.userFullName, parentId: null })),
                    ...self.staff.items.map((b) => ({ value: b.userId, label: b.userName, parentId: null }))
                ].sort((a, b) => (a.label >= b.label ? 1 : -1));
            },
            get projectsAsSelectItem(): Array<ISelectItem> {
                return self.projects.items
                    .map((t) => ({
                        value: t.id,
                        label: t.jobCode + ' - ' + t.briefDescription,
                        parentId: null
                    }))
                    .sort((a, b) => (a.label >= b.label ? 1 : -1));
            },
            get vendorsAsSelectItem(): Array<ISelectItem> {
                return [
                    {
                        label: '--Select Vendor--',
                        value: null,
                        parentId: null
                    },
                    ...self.vendors.items
                        .map((t) => ({
                            value: t.id,
                            label: t.name,
                            parentId: null
                        }))
                        .sort((a, b) => (a.label >= b.label ? 1 : -1))
                ];
            },
            get workOrdersAsSelectItems(): Array<ISelectItem> {
                return [
                    {
                        label: '--Select Work Order--',
                        value: null,
                        parentId: null
                    },
                    ...self.workOrders.items
                        .map((wo) => ({
                            value: wo.id,
                            label: (wo.workOrderNumber || '') + ' - ' + (wo.shortDescription || ''),
                            parentId: null
                        }))
                        .sort((a, b) => (a.label >= b.label ? 1 : -1))
                ];
            },
            get allWorkOrderItemsSelected(): boolean {
                return self.workOrders.items.length > 0 && self.workOrders.items.every((w) => self.model.workOrderIds.includes(w.id));
            },
            get allVendorItemsSelected(): boolean {
                return self.vendors.items.length > 0 && self.vendors.items.every((v) => self.model.vendorIds.includes(v.id));
            }
        }))
        .views((self) => ({
            filteredVendors(vendorIds: number[]): Array<ISelectItem> {
                return self.vendorsAsSelectItem.filter((v) => !vendorIds.some((vendorId) => v.value === vendorId));
            },
            filteredWorkOrders(workOrderIds: number[]): Array<ISelectItem> {
                return self.workOrdersAsSelectItems.filter((wo) => !workOrderIds.some((workOrderId) => wo.value === workOrderId));
            }
        }))
        .actions((self) => ({
            async initialize() {
                await self.model.load();
                await self.dd.fetchDropdowns();
                await self.teamMembers.loadItems();
                await self.vendors.loadItems();

                if (self.model.propertyId) {
                    await self.projects.loadItems();
                    await self.workOrders.loadItems();
                }
                if (self.staff.search.terms.propertyId.value) {
                    await self.staff.loadItems();
                }
                if (self.buildingManagers.search.terms.propertyId.value) {
                    await self.buildingManagers.loadItems();
                }
                if (self.propertyAccess.search.terms.propertyId.value) {
                    await self.propertyAccess.loadItems();
                }
            }
        }))
);
type modelType = Instance<typeof ActionItemFormModel>;
export interface IActionItemFormModel extends modelType {}
