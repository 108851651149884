import { environment } from '@env/environment';

export const Auth0Settings = {
    options: {
        auth: {
            audience: 'https://goldeye.software/api',
            redirectUrl: environment.authConfig.redirectUrl,
            responseType: environment.authConfig.responseType,
            params: {
                scope: environment.authConfig.scope,
                state: ''
            }
        },
        configurationBaseUrl: environment.authConfig.clientConfigurationBaseUrl,
        autoclose: true,
        rememberLastLogin: false
    },

    clientId: environment.authConfig.clientID,
    domain: environment.authConfig.domain
};

