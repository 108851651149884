<div class="{{ containerClass }} control-padding" *ngIf="!isHidden">
    <p-rating
        [stars]="starCount"
        [pTooltip]="tooltipText"
        [tooltipPosition]="tooltipPosition"
        [ngModel]="model"
        [readonly]="readOnly"
        [disabled]="disabled"
        [cancel]="canCancel"
        [iconOnClass]="onClass"
        [iconOffClass]="offClass"
        [iconCancelClass]="cancelClass"
        (onRate)="onRate($event.originalEvent, $event.value)"
        (onCancel)="onCancel($event)"
    ></p-rating>
    <ng-content select=".after-form-field"></ng-content>
</div>
