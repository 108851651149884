import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { arrayToggleValue } from '@app/shared/utils/array-helper';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-day-of-week',
    templateUrl: './day-of-week.component.html',
    styleUrls: ['./day-of-week.component.scss']
})
export class DayOfWeekComponent implements OnInit {
    @Input() options: Array<SelectItem | ISelectItem>;
    @Input() disabled: boolean;
    @Input() required: boolean;

    @Input() models: number[];
    @Output() change = new EventEmitter<Array<number>>();

    ngOnInit() {}

    toggleFromModels(val: number) {
        let newArray = [...this.models];

        newArray = arrayToggleValue(newArray, val);

        this.change.emit(newArray);
    }
}
