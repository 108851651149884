<div class="p-grid p-align-start control-padding {{containerClass}}" *mobxAutorun>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <ng-content select=".above-the-buttons"></ng-content>
        <p-selectButton
            *ngIf="options?.length > 0"
            [appHighlightOnValidationError]="name"
            [options]="options"
            [disabled]="disabled"
            [ngModel]="model"
            [name]="name"
            [required]="required"
            [multiple]="multiple"
            (onOptionClick)="onChange($event)"
        >
        </p-selectButton>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
