import { types, flow, getSnapshot, getEnv } from 'mobx-state-tree';
import { toUrl } from '@app/shared/utils/url-helper';
import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { SelectItemModel } from '@app/shared/models/goldeye-select-item';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { PropertyGroupModel } from '../property-group/property-group.model';

export const SessionFilterModel = types
    .compose(
        withDropdowns(),
        types
            .model({
                userId: types.identifierNumber,
                loading: true,
                propertyGroup: types.maybeNull(PropertyGroupModel),
                propertyGroupDropdown: types.array(SelectItemModel)
            })
            .views(self => ({
                get inEffect() {
                    return Boolean(self.propertyGroup);
                },
                get query() {
                    const propertyGroupId = self.propertyGroup
                        ? self.propertyGroup.id
                        : null;
                    return { propertyGroupId };
                },
                get description() {
                    return self.propertyGroup
                        ? self.propertyGroup.name
                        : 'None';
                },
                get propertyGroupId() {
                    return self.propertyGroup ? self.propertyGroup.id : null;
                },
                get propertyGroupName() {
                    return self.propertyGroup ? self.propertyGroup.name : null;
                },
                get propertyGroupNotes() {
                    return self.propertyGroup ? self.propertyGroup.notes : null;
                },
                get propertiesDescriptions() {
                    return self.propertyGroup
                        ? self.propertyGroup.propertiesDescriptions
                        : null;
                },
                get propertyScope() {
                    return self.propertyGroup
                        ? self.propertyGroup.propertiesAssigned
                        : [];
                }
            }))
            .actions(self => ({
                removeSessionFilter: flow(function*() {
                    try {
                        self.loading = true;
                        const url = toUrl('session-filters');
                        const { http } = getEnv(self);
                        yield http.delete(`${url}/${self.userId}`).toPromise();
                        self.propertyGroup = null;
                    } finally {
                        self.loading = false;
                    }
                }),
                saveSessionFilter: flow(function*(id: number) {
                    try {
                        self.loading = true;
                        const url = toUrl('session-filters');
                        const { http } = getEnv(self);

                        const paylaod = {
                            ...getSnapshot(self),
                            ...{ propertyGroup: { id } }
                        };

                        const response = yield http
                            .post(url, paylaod)
                            .toPromise();
                        applyPartialSnapshot(self, response);
                    } finally {
                        self.loading = false;
                    }
                }),
                fetchSessionFilter: flow(function*() {
                    try {
                        self.loading = true;
                        const url = toUrl(`session-filters/${self.userId}`);
                        const { http } = getEnv(self);
                        const response = yield http.get(url).toPromise();
                        applyPartialSnapshot(self, response);
                    } catch (e) {
                        if (e.status !== 404) {
                            throw e;
                        }
                    } finally {
                        self.loading = false;
                    }
                })
            }))
            .actions(self => ({
                setPropertyGroup(propertyGroupId) {
                    self.saveSessionFilter(propertyGroupId);
                }
            }))
    )
    .views(self => ({
        get initialized() {
            return !self.loading && self.dropdownsReady;
        }
    }))
    .actions(self => ({
        async initialize() {
            await self.fetchDropdowns();
            await self.fetchSessionFilter();
        }
    }));
type SessionFilterModelType = typeof SessionFilterModel.Type;
export interface ISessionFilterModel extends SessionFilterModelType {}
