export interface ITreeNode {
    key?: string;
    label?: string;
    data?: any;
    icon?: any;
    isMenu?: boolean;
    description?: string;
    expandedIcon?: any;
    collapsedIcon?: any;
    expanded?: boolean;
    children?: TreeNode[];
    type?: string;
    partialSelected?: boolean;
    parent?: any;
}

export class TreeNode implements ITreeNode {
    key?: string;
    label?: string;
    data?: any;
    icon?: any;
    isMenu?: boolean;
    description?: string;
    expandedIcon?: any;
    collapsedIcon?: any;
    expanded?: boolean;
    children?: TreeNode[];
    type?: string;
    partialSelected?: boolean;
    parent?: any;
}
