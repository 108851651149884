import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Auth0Lock from 'auth0-lock';
import { AuthorizationService } from '../authorization/authorization.service';
import { Auth0Settings } from './auth0-settings';
import { JwtHelperService } from '@auth0/angular-jwt';

(window as any).global = window;

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    authLock = new Auth0Lock(
        Auth0Settings.clientId,
        Auth0Settings.domain,
        Auth0Settings.options
    );
    jwtHelper = new JwtHelperService();

    constructor(
        private router: Router,
        private authorizationService: AuthorizationService
    ) {}

    private setSession(authResult, error, success): void {
        localStorage.setItem('access_token', authResult.accessToken);
        try {
            const profile = this.jwtHelper.decodeToken(authResult.idToken);
            localStorage.setItem('profile_name', profile.name);
            localStorage.setItem('profile_family_name', profile.family_name);
            localStorage.setItem('profile_given_name', profile.given_name);
            localStorage.setItem('profile_nickname', profile.nickname);
            localStorage.setItem('profile_picture', profile.picture);
            success(profile, authResult.state || '');
        } catch {
            error();
        }
    }

    public resumeAuth(hash: string, error, success) {
        this.authLock.resumeAuth(hash, (err, authResult) => {
            if (err) {
                console.error(err);
                error(err);
            } else {
                this.setSession(authResult, error, success);
            }
        });
    }

    public getUserInfo() {
        const profile = {};
        profile['name'] = localStorage.getItem('profile_name');
        profile['family_name'] = localStorage.getItem('profile_family_name');
        profile['given_name'] = localStorage.getItem('profile_given_name');
        profile['nickname'] = localStorage.getItem('profile_nickname');
        profile['picture'] = localStorage.getItem('profile_picture');
        return profile;
    }

    public login(returnUrl: string = '') {
        const opts = Object.assign({}, Auth0Settings.options);
        opts.auth.params.state = returnUrl;
        this.authLock.show(opts);
    }

    public logout() {
        localStorage.removeItem('profile_currentuserid');
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile_name');
        localStorage.removeItem('profile_family_name');
        localStorage.removeItem('profile_given_name');
        localStorage.removeItem('profile_nickname');
        localStorage.removeItem('profile_picture');
        localStorage.removeItem('token');
        localStorage.removeItem('expires');
        localStorage.removeItem('starts');

        // reset the user's authorized features
        this.authorizationService.resetAuthorization();
        this.authLock.logout({ returnTo: `${window.location.origin}` });
    }

    public isAuthenticated() {
        const accessToken = localStorage.getItem('access_token');
        const sessionIsActive = !this.jwtHelper.isTokenExpired(accessToken);

        return sessionIsActive;
    }

    public renewAccessTokenIfAuthenticated() {
        const sessionIsActive = this.isAuthenticated();

        if (sessionIsActive) {
            // checkSession renews the auth token
            // See: https://auth0.com/docs/tokens/concepts/refresh-tokens#single-page-apps
            this.authLock.checkSession({}, (err, authResult) => {
                if (err) {
                    this.logout();
                } else {
                    localStorage.setItem(
                        'access_token',
                        authResult.accessToken
                    );
                }
            });
        }

        return sessionIsActive;
    }
}
