import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit
} from '@angular/core';
import { ILinkGridModel } from '@app/shared/models/links/link-grid.model';
import { ILinkFormModel } from '@app/shared/models/links/link-form.model';
import { Column } from '@app/core/models';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import { ILinkModel } from '@app/shared/models/links/link.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent implements OnInit {
    @Input() grid: ILinkGridModel;
    @Input() form: ILinkFormModel;
    @Input() relatedToId: number;

    columns: Column[];
    showLinkFormDialog: boolean;

    dialogHeader = 'Add Link';

    ngOnInit(): void {
        this.columns = [
            { field: 'name', header: 'Name' },
            { field: 'url', header: 'Hyperlink', type: 'hyperlink' },
            { field: 'notes', header: 'Notes', type: 'text' }
        ];
    }

    onAddNewLinkBtnClick() {
        applySnapshot(this.form.link, {
            relatedToId: this.relatedToId,
            discriminator: 'claim',
            id: 0
        });
        this.dialogHeader = 'Add Link';
        this.showLinkFormDialog = true;
    }

    onEdit(link: ILinkModel) {
        applyPartialSnapshot(this.form.link, getSnapshot(link));
        this.dialogHeader = 'Edit Link';
        this.showLinkFormDialog = true;
    }
}
