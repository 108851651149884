<div *mobxAutorun>
    <div *ngIf="!fieldAccess?.isHidden">
        <p-dialog
            *ngIf="showEditPropertyLocationDialog"
            [(visible)]="showEditPropertyLocationDialog"
            [modal]="true"
            (onHide)="onDialogHide()"
            class="large-modal claim-step-dialog"
        >
            <p-header>
                <header class="claim-step-dialog-header">
                    <i class="fa fa-building header-icon"></i>
                    <h2>Common Areas For Claim</h2>
                </header>
            </p-header>
            <div class="p-grid p-col-12 no-padding p-justify-center">
                <div class="p-md-5">
                    <h3>
                        Choose Common Areas
                    </h3>
                    <app-goldeye-dropdown
                        label="Choose Property"
                        name="property"
                        [filter]="true"
                        [options]="properties"
                        [model]="selectedProperty"
                        (change)="selectedPropertyChanged.emit($event)"
                    >
                    </app-goldeye-dropdown>
                    <p-listbox
                        [options]="commonAreas"
                        [(ngModel)]="selectedCommonAreas"
                        [filter]="true"
                        [checkbox]="true"
                        [multiple]="true"
                        (onChange)="regenerateTreenodes()"
                    >
                        <ng-template let-item pTemplate="item">
                            <span>
                                {{ item.label }}
                            </span>
                        </ng-template>
                    </p-listbox>
                </div>

                <div class="p-md-5 p-md-offset-1">
                    <h3>
                        Common Areas Selected
                    </h3>
                    <p-treeTable
                        [value]="selectedLocationsTreenodes"
                        [columns]="treeNodeCols"
                        [paginator]="false"
                        [lazy]="true"
                        selectionMode="none"
                    >
                        <ng-template
                            pTemplate="body"
                            let-rowNode
                            let-rowData="rowData"
                            let-columns="columns"
                        >
                            <tr>
                                <td *ngFor="let col of columns; let i = index">
                                    <p-treeTableToggler
                                        [rowNode]="rowNode"
                                        *ngIf="i == 0"
                                    ></p-treeTableToggler>
                                    <span style="vertical-align: middle;">{{
                                        rowData[col.field]
                                    }}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                </div>
            </div>

            <app-goldeye-form-controls
                class="form-controls"
                (save)="onPropertyLocationSelection()"
                saveLabel="Set Locations"
                saveIcon="fa fa-edit"
                (cancel)="onDialogHide()"
                [btnClass]="'p-col-12 p-md-4'"
            >
            </app-goldeye-form-controls>
        </p-dialog>

        <div class="p-grid p-align-top control-padding">
            <div class="p-col-12 p-md-5">
                <label>
                    Common Areas
                </label>
            </div>

            <div class="p-col-12 p-md-7 location-container">
                <div class="p-col-10 no-padding">
                    <p-treeTable
                        [value]="locationTreenodes"
                        [columns]="treeNodeCols"
                        [paginator]="false"
                        [lazy]="true"
                        selectionMode="none"
                    >
                        <ng-template
                            pTemplate="body"
                            let-rowNode
                            let-rowData="rowData"
                            let-columns="columns"
                        >
                            <tr>
                                <td *ngFor="let col of columns; let i = index">
                                    <p-treeTableToggler
                                        [rowNode]="rowNode"
                                        *ngIf="i == 0"
                                    ></p-treeTableToggler>
                                    <span style="vertical-align: middle;">{{
                                        rowData[col.field]
                                    }}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                </div>
                <div
                    class="p-col-2 no-padding icon-container"
                    *ngIf="!readonly"
                >
                    <button
                        pButton
                        type="button"
                        [icon]="
                            !locationTreenodes || locationTreenodes.length == 0
                                ? 'fa fa-plus'
                                : 'fas fa-pencil-alt'
                        "
                        (click)="editPropertyLocations()"
                        [disabled]="fieldAccess?.isLocked"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</div>
