import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Column } from '@app/core/models';
import { IPropertyAccessGridModel } from '@app/modules/property-team/models/property-access/property-access-grid.model';
import { Router } from '@angular/router';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-list-by-property',
    templateUrl: './property-access-list-by-property.component.html',
    styleUrls: ['./property-access-list-by-property.component.scss']
})
export class PropertyAccessListByPropertyComponent implements OnInit {
    @Input() grid: IPropertyAccessGridModel;
    @Input() columns: Column[] = [
        {
            field: 'propertyCode',
            header: 'Property',
            type: 'propertyAccessProperty',
            props: ['isTemp', 'isNotActive']
        },
        { field: 'userHandle', header: 'Role Account' },
        { field: 'activeDateParsed', header: 'Date Active', type: 'date', sortColumn: 'activeDate' },
        { field: 'expireDateParsed', header: 'Date Expired', type: 'date', sortColumn: 'expireDate' }
    ];
    @Output() editBtnClicked = new EventEmitter<number>();

    matchAllTextChanged = new Subject<string>();
    matchAllTextChanged$: Subscription;

    constructor(private router: Router) {}

    ngOnInit() {
        this.matchAllTextChanged$ = this.matchAllTextChanged
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .pipe(tap(this.grid.search.terms.setProperty))
            .subscribe((_) => {});
    }

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    onEditBtnClick(id) {
        this.editBtnClicked.emit(id);
    }

    onMatchAllKeyUp(event) {
        this.matchAllTextChanged.next(event.target.value);
    }
}
