export function computeProgress(total: number, uploaded: number): number {
    return Math.round((uploaded / total) * 1000) / 10;
}

export function roundToNDecimalPlaces(
    numberToRound: number = 0,
    numberOfDecimalPlaces: number = 2
): string {
    const result = (Math.round(numberToRound * 100) / 100).toFixed(
        numberOfDecimalPlaces
    );

    return result;
}

export function isNumber(numberToTest): boolean {
    const isANumber = !isNaN(Number(numberToTest));

    return isANumber;
}
