import { types, Instance } from 'mobx-state-tree';
import {
    anyUtcDateFormatToString,
    stringToDateTime
} from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';

export const VendorRatingModel = resource(
    'vendor-ratings',
    types
        .model({
            id: types.identifierNumber,

            rating: types.maybeNull(types.number),
            comment: types.maybeNull(types.string),

            userName: types.maybeNull(types.string),

            userId: types.maybeNull(types.number),
            vendorId: types.maybeNull(types.number),

            createdDate: types.maybeNull(types.union(types.string, types.Date)),
            createdBy: types.maybeNull(types.string),
            modifiedBy: types.maybeNull(types.string)
        })
        .views((self) => ({
            get createdOnDate() {
                if (self.createdDate) {
                    return stringToDateTime(self.createdDate);
                }
            },
            get createdDateString() {
                if (self.createdDate) {
                    return anyUtcDateFormatToString(
                        self.createdDate,
                        'MM-DD-YYYY'
                    );
                }
            }
        }))
        .actions((self) => ({
            setRating: (value: number) => (self.rating = value),
            setComment: (value: string) => (self.comment = value),
            setVendorId: (value: number) => (self.vendorId = value),
            setUserId: (value: number) => (self.userId = value)
        }))
);

type VendorRatingModelType = Instance<typeof VendorRatingModel>;
export interface IVendorRatingModel extends VendorRatingModelType {}
