import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
    selector: 'app-goldeye-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;

    @Input() labelClass?: string;
    @Input() previewClass?: string;
    @Input() inputClass?: string;

    @Input() model: string;
    @Output() colorChanged = new EventEmitter<string>();

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.previewClass = this.previewClass || 'p-col-2 p-md-1';
        this.inputClass = this.inputClass || 'p-col-10 p-md-6';
    }

    onChange(event) {
        this.colorChanged.emit(event.value);
    }

    onInputChange(event) {
        event.stopPropagation();
        this.colorChanged.emit(event.target.value);
    }
}
