<div class="p-grid p-align-start control-padding" *ngIf="!fieldAccess?.isHidden">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <textarea
            pInputTextarea
            [appHighlightOnValidationError]="name"
            [ngModel]="model"
            name="{{ name }}"
            [autoResize]="true"
            [rows]="rows"
            (change)="onChange($event)"
            [disabled]="fieldAccess?.isLocked || disabled"
            [appFocusIf]="focus"
            [ngStyle]="{ 'min-height': rows * 17 + 21 + 'px' }"
            [placeholder]="placeholder"
        >
        </textarea>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
