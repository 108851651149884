import { types, Instance } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';
import { stringToDateTime, anyUtcDateFormatToCalendarString } from '@app/shared/utils/date-helper';
import { formatDate } from '@angular/common';
import { UserModel } from '@app/modules/notifications/models/users/operations-user.model';

export const CommentModel = resource(
    (myself) => `${myself.discriminator}/${myself.relatedToId}/comments`,
    types
        .model({
            id: types.identifierNumber,
            relatedToId: types.number,
            discriminator: types.enumeration([
                'policy',
                'claim',
                'unit',
                'actionitem',
                'workordertechnote',
                'residentactivity'
            ]),
            contents: types.maybeNull(types.string),
            createdBy: types.maybeNull(types.string),
            modifiedBy: types.maybeNull(types.string),
            createdDate: types.maybeNull(types.string),
            modifiedDate: types.maybeNull(types.string),
            createdByUser: types.maybeNull(UserModel)
        })
        .actions((self) => ({
            setContents: (value: string) => {
                self.contents = value;
            },
            setRelatedToId: (value: number) => {
                self.relatedToId = value;
            }
        }))
        .views((self) => ({
            get createdDateAsDate() {
                return stringToDateTime(self.createdDate);
            }
        }))
        .views((self) => ({
            get header() {
                const createdDate: any = self.createdDate;
                return `${self.createdBy} on ${formatDate(
                    stringToDateTime(createdDate),
                    'short',
                    'en'
                )}`;
            },
            get isValid() {
                return self.contents && self.contents.length > 3;
            },
            get createdDateAsRelativeDate() {
                return anyUtcDateFormatToCalendarString(self.createdDateAsDate, {
                    sameDay: '[Today at] HH:mm:ss',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday at] HH:mm:ss',
                    lastWeek: '[Last] dddd',
                    sameElse: 'MMM Do, YYYY'
                });
            }
        }))
);

type modelType = Instance<typeof CommentModel>;
export interface ICommentModel extends modelType {}
