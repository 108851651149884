import { Component } from '@angular/core';

@Component({
    template: `
        <span
            pTooltip="{{ contents | date: 'fullDate' }}"
            tooltipPosition="top"
            style="white-space: pre-line;"
        >
            {{ contents | date: 'short' }}
        </span>
    `
})
export class DateGridColumnComponent {
    contents: any;
    props?: any;
}
