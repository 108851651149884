import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { MobxAngularModule } from 'mobx-angular';
import { MomentModule } from 'ngx-moment';

import { PrimeNgModule } from './primeng.module';

import {
    SelectOnFocusDirective,
    HighlightOnValidationErrorDirective,
    FocusIfDirective
} from './directives';

import {
    TruncatePipe,
    YesNoPipe,
    ToClassNamePipe,
    FileSizePipe,
    SmartDatePipe,
    SmartDatetimePipe,
    SafeUrl,
    StripHtmlPipe,
    MSTArrayPipe
} from './pipes';

import {
    ValidationMessagesComponent,
    KanbanBoardComponent,
    SchedulingCalendarComponent,
    GridColumnComponent,
    DataBarComponent,
    ProgressSpinnerComponent,
    ScoreBoardComponent,
    DateGridColumnComponent,
    HtmlGridColumnComponent,
    TextGridColumnComponent,
    TruncatedGridColumnComponent,
    AttachmentUploaderComponent,
    AttachmentSelectionComponent,
    AttachmentsViewerComponent,
    FileSizeColumnComponent,
    UserGridColumnComponent,
    PropertyAccessListGridColumnComponent,
    PropertyAccessPropertyGridColumnComponent,
    SmartDateGridColumnComponent,
    UnOrderedListGridColumnComponent,
    BooleanGridColumnComponent,
    IconGridColumnComponent,
    TextInputComponent,
    TextAreaComponent,
    DropdownComponent,
    TextEditorComponent,
    ActionButtonComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    TriStateCheckboxComponent,
    FormControlsComponent,
    ReadonlyFieldComponent,
    PageHeaderComponent,
    OneMonthNoTimeCalendarComponent,
    OneMonthWithTimeCalendarComponent,
    TwoMonthsNoTimeCalendarComponent,
    TwoMonthsWithTimeCalendarComponent,
    MaskedInputComponent,
    ListboxComponent,
    RadioButtonListComponent,
    ReadonlyTextareaComponent,
    GridSearchBarComponent,
    PropertyLocationsComponent,
    UserCardComponent,
    TimeOnlyCalendarComponent,
    ColorGridColumnComponent,
    ColorPickerComponent,
    ThreeStepCalendarDateTimeComponent,
    SessionFilterComponent,
    CommonAreasComponent,
    DateOnlyGridColumnComponent,
    LookupFormComponent,
    LookupListComponent,
    AttachmentsMiniGridComponent,
    DayOfWeekComponent,
    NumberInputComponent,
    OneMonthNoWeekendsOrTimeCalendarComponent,
    CurrencyInputComponent,
    CommentFormComponent,
    CommentListComponent,
    CommentListGridComponent,
    TimelineComponent,
    EntityLogEntryListGridComponent,
    LinkListGridComponent,
    LinkListComponent,
    LinkFormComponent,
    LinkPreviewComponent,
    HyperlinkGridColumnComponent,
    StepsExtendedComponent,
    PointOfContactInfoComponent,
    UnderConstructionComponent,
    ThumbnailGridColumnComponent,
    PropertyAccessEditGridComponent,
    PropertyAccessFormComponent,
    PropertyAccessLegendComponent,
    PropertyAccessListByPropertyComponent,
    PropertyAccessListByUserComponent,
    PropertyAccessPropertyCardsComponent,
    PropertyAccessPropertyMiniListComponent,
    PropertyAccessUserCardsComponent,
    PropertyAccessUserMiniListComponent,
    MultiplePropertyAccessFormComponent,
    RecentCommentListComponent,
    AssignedUsersListColumnComponent,
    ActionItemBoardComponent,
    ActionItemBoardLaneComponent,
    ActionItemCommentsComponent,
    ActionItemDialogComponent,
    ActionItemFormComponent,
    ActionItemListComponent,
    ActionItemSummaryComponent,
    ListboxWithFeedbackModalComponent,
    DashboardLayoutComponent,
    GridLegendComponent,
    WorkOrderListGridComponent,
    WorkOrderSearchComponent,
    WorkOrderSearchDialogComponent,
    RatingGridColumnComponent,
    RatingComponent,
    SelectButtonComponent
} from './components';

// TODO: Hack to be removed when bug is fixed in PrimeNG.
import { ConfirmDialog } from 'primeng/confirmdialog';
import { LegendItemComponent } from './components/form/legend-item/legend-item.component';

ConfirmDialog.prototype.appendContainer = function (): void {
    if (this.appendTo) {
        if (this.appendTo === 'body') {
            document.body.appendChild(this.el.nativeElement);
        } else {
            this.domHandler.appendChild(this.container, this.appendTo);
        }
    }
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        PrimeNgModule,
        MobxAngularModule,
        MomentModule
    ],
    declarations: [
        HighlightOnValidationErrorDirective,
        SelectOnFocusDirective,
        FocusIfDirective,

        TruncatePipe,
        YesNoPipe,
        ToClassNamePipe,
        FileSizePipe,
        SmartDatePipe,
        SmartDatetimePipe,
        SafeUrl,
        StripHtmlPipe,
        MSTArrayPipe,

        DataBarComponent,
        SchedulingCalendarComponent,
        ValidationMessagesComponent,
        KanbanBoardComponent,
        GridColumnComponent,
        ThumbnailGridColumnComponent,
        DateGridColumnComponent,
        HtmlGridColumnComponent,
        TextGridColumnComponent,
        TruncatedGridColumnComponent,
        FileSizeColumnComponent,
        UserGridColumnComponent,
        PropertyAccessListGridColumnComponent,
        PropertyAccessPropertyGridColumnComponent,
        AssignedUsersListColumnComponent,
        DateOnlyGridColumnComponent,
        SmartDateGridColumnComponent,
        UnOrderedListGridColumnComponent,
        BooleanGridColumnComponent,
        IconGridColumnComponent,
        ColorGridColumnComponent,
        HyperlinkGridColumnComponent,
        RatingGridColumnComponent,

        ProgressSpinnerComponent,
        ScoreBoardComponent,

        AttachmentUploaderComponent,
        AttachmentSelectionComponent,
        AttachmentsViewerComponent,
        AttachmentsMiniGridComponent,

        TextInputComponent,
        MaskedInputComponent,
        TextAreaComponent,
        DropdownComponent,
        TextEditorComponent,
        ActionButtonComponent,
        FormControlsComponent,
        ToggleSwitchComponent,
        CheckboxComponent,
        TriStateCheckboxComponent,
        ReadonlyFieldComponent,
        PageHeaderComponent,
        OneMonthNoTimeCalendarComponent,
        OneMonthWithTimeCalendarComponent,
        TwoMonthsNoTimeCalendarComponent,
        TwoMonthsWithTimeCalendarComponent,
        ListboxComponent,
        RadioButtonListComponent,
        GridSearchBarComponent,
        ReadonlyTextareaComponent,
        PropertyLocationsComponent,
        CommonAreasComponent,
        UserCardComponent,
        TimeOnlyCalendarComponent,
        ColorPickerComponent,
        RatingComponent,
        SelectButtonComponent,

        LookupFormComponent,
        LookupListComponent,
        SessionFilterComponent,
        ThreeStepCalendarDateTimeComponent,
        DayOfWeekComponent,
        NumberInputComponent,
        OneMonthNoWeekendsOrTimeCalendarComponent,
        CurrencyInputComponent,

        CommentFormComponent,
        CommentListComponent,
        RecentCommentListComponent,
        CommentListGridComponent,
        TimelineComponent,
        EntityLogEntryListGridComponent,
        LinkListGridComponent,
        LinkListComponent,
        LinkFormComponent,
        LinkPreviewComponent,
        StepsExtendedComponent,

        PointOfContactInfoComponent,

        UnderConstructionComponent,

        PropertyAccessEditGridComponent,
        PropertyAccessFormComponent,
        PropertyAccessLegendComponent,
        PropertyAccessListByPropertyComponent,
        PropertyAccessListByUserComponent,
        PropertyAccessPropertyCardsComponent,
        PropertyAccessPropertyMiniListComponent,
        PropertyAccessUserCardsComponent,
        PropertyAccessUserMiniListComponent,
        MultiplePropertyAccessFormComponent,

        ActionItemBoardComponent,
        ActionItemBoardLaneComponent,
        ActionItemCommentsComponent,
        ActionItemDialogComponent,
        ActionItemFormComponent,
        ActionItemListComponent,
        ActionItemSummaryComponent,
        ListboxWithFeedbackModalComponent,

        DashboardLayoutComponent,

        GridLegendComponent,

        LegendItemComponent,

        WorkOrderListGridComponent,
        WorkOrderSearchComponent,
        WorkOrderSearchDialogComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        PrimeNgModule,
        MomentModule,

        HighlightOnValidationErrorDirective,
        FocusIfDirective,
        SelectOnFocusDirective,
        TruncatePipe,

        DataBarComponent,
        ValidationMessagesComponent,
        KanbanBoardComponent,
        SchedulingCalendarComponent,
        GridColumnComponent,
        ThumbnailGridColumnComponent,
        ProgressSpinnerComponent,
        ScoreBoardComponent,

        AttachmentUploaderComponent,
        AttachmentSelectionComponent,
        AttachmentsViewerComponent,
        AttachmentsMiniGridComponent,

        YesNoPipe,
        ToClassNamePipe,
        FileSizePipe,
        SmartDatePipe,
        SmartDatetimePipe,
        SafeUrl,
        MSTArrayPipe,

        TextInputComponent,
        MaskedInputComponent,
        TextAreaComponent,
        DropdownComponent,
        TextEditorComponent,
        ActionButtonComponent,
        FormControlsComponent,
        ToggleSwitchComponent,
        CheckboxComponent,
        TriStateCheckboxComponent,
        ReadonlyFieldComponent,
        PageHeaderComponent,
        OneMonthNoTimeCalendarComponent,
        OneMonthNoWeekendsOrTimeCalendarComponent,
        OneMonthWithTimeCalendarComponent,
        TwoMonthsNoTimeCalendarComponent,
        TwoMonthsWithTimeCalendarComponent,
        ColorPickerComponent,
        ListboxComponent,
        RadioButtonListComponent,
        GridSearchBarComponent,
        ReadonlyTextareaComponent,
        PropertyLocationsComponent,
        CommonAreasComponent,
        UserCardComponent,
        RatingComponent,
        SelectButtonComponent,

        TimeOnlyCalendarComponent,

        LookupFormComponent,
        LookupListComponent,
        SessionFilterComponent,
        ThreeStepCalendarDateTimeComponent,
        DayOfWeekComponent,
        NumberInputComponent,
        CurrencyInputComponent,

        CommentFormComponent,
        CommentListComponent,
        RecentCommentListComponent,
        CommentListGridComponent,
        TimelineComponent,
        EntityLogEntryListGridComponent,
        LinkListGridComponent,
        LinkListComponent,
        LinkFormComponent,
        LinkPreviewComponent,
        StepsExtendedComponent,

        PointOfContactInfoComponent,

        UnderConstructionComponent,

        PropertyAccessEditGridComponent,
        PropertyAccessFormComponent,
        PropertyAccessLegendComponent,
        PropertyAccessListByPropertyComponent,
        PropertyAccessListByUserComponent,
        PropertyAccessPropertyCardsComponent,
        PropertyAccessPropertyMiniListComponent,
        PropertyAccessUserCardsComponent,
        PropertyAccessUserMiniListComponent,
        MultiplePropertyAccessFormComponent,

        ActionItemBoardComponent,
        ActionItemBoardLaneComponent,
        ActionItemCommentsComponent,
        ActionItemDialogComponent,
        ActionItemFormComponent,
        ActionItemListComponent,
        ActionItemSummaryComponent,
        ListboxWithFeedbackModalComponent,

        DashboardLayoutComponent,
        GridLegendComponent,

        WorkOrderListGridComponent,
        WorkOrderSearchComponent
    ],
    providers: [YesNoPipe]
})
export class SharedModule {}
