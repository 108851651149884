<div class="p-grid p-align-center control-padding" *ngIf="!fieldAccess?.isHidden">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-calendar
            #cal
            [dateFormat]="dateFormat"
            [selectionMode]="range ? 'range' : 'single'"
            [style]="{ width: '100%' }"
            [selectionMode]="range ? 'range' : 'single'"
            [inputStyle]="{ width: 'calc(100% - 2em)' }"
            name="{{ this.name }}"
            [appHighlightOnValidationError]="name"
            [showIcon]="true"
            [disabledDays]="[6, 0]"
            [disabled]="fieldAccess?.isLocked || disabled"
            [appendTo]="appendTo"
            [numberOfMonths]="1"
            [ngModel]="intermediateValue"
            (onSelect)="dateSelected($event)"
            [touchUI]="layout.windowIsMobile$ | async"
        >
            <p-footer>
                <button
                    pButton
                    style="margin-top: 0.5em"
                    label="Close"
                    class="p-button-secondary"
                    (click)="cal.hideOverlay()"
                    *ngIf="controls.includes('close')"
                ></button>
            </p-footer>
        </p-calendar>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
