import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
    @Input() headerIcon: string;
    @Input() headerText: string;
    @Input() subHeaderText: string;
    @Input() buttonIcon: string;
    @Input() buttonText: string;

    @Output() btnClick = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {
        this.headerIcon =
            'card-header-icon ' + (this.headerIcon || ' fa fa-user');
        this.buttonIcon = this.buttonIcon || 'fas fa-trash-alt';
        this.buttonText = this.buttonText || 'Remove';
    }

    buttonClicked() {
        this.btnClick.emit(true);
    }
}
