export class WindowFeatures {
    left?: number;
    top?: number;
    width?: number = 300;
    height?: number = 200;
    menubar?: boolean;
    toolbar?: boolean;
    location?: boolean;
    status?: boolean;
    resizable?: boolean;
    scrollbars?: boolean;
}
