import { types, Instance } from 'mobx-state-tree';
import { stringToDateTime } from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';
import { PointOfContact } from '@app/shared/models/point-of-contact.model';

export const BuildingManagerModel = resource(
    'building-managers',
    types.compose(
        PointOfContact,
        types
            .model({
                id: types.identifierNumber,

                propertyId: types.maybeNull(types.number),
                userId: types.maybeNull(types.number),
                userName: types.maybeNull(types.string),
                userRoleDescription: types.maybeNull(types.string),
                userPhotoAsBase64String: types.maybeNull(types.string),
                type: types.maybeNull(types.number),
                isDefault: types.optional(types.boolean, false),

                createdDate: types.maybeNull(
                    types.union(types.string, types.Date)
                ),
                createdBy: types.maybeNull(types.string),
                modifiedBy: types.maybeNull(types.string)
            })
            .views((self) => ({
                get createdOnDate() {
                    if (self.createdDate) {
                        return stringToDateTime(self.createdDate);
                    }
                }
            }))
            .actions((self) => ({
                setBuildingManagerType: (value: number) => (self.type = value),
                setIsDefault: (value: boolean) => (self.isDefault = value)
            }))
    )
);

type BuildingManagerModelType = Instance<typeof BuildingManagerModel>;
export interface IBuildingManagerModel extends BuildingManagerModelType {}
