import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Instance, types, onPatch, onSnapshot } from 'mobx-state-tree';
import { CommentFormModel } from '@app/shared/models/comment/comment-form.model';
import { CommentGridModel } from '@app/shared/models/comment/comment-grid.model';
import { ActionItemFormModel } from '../../models/action-item/action-item-form.model';
import { SortOrder } from '@app/core/models';
import { IWorkOrderModel } from '@app/modules/operations/models/work-orders/work-order.model';
import { IVendorModel } from '@app/modules/operations/models/vendors/vendor.model';

const store = types
    .model({
        form: ActionItemFormModel,
        commentForm: CommentFormModel,
        comments: CommentGridModel
    })
    .actions((self) => ({
        async afterCreate() {
            const { comments, commentForm } = self;

            onPatch(self.form.model, (changes) => {
                if (changes.path === '/propertyId') {
                    if (!self.form.teamMembers.search.terms.propertyTeamId.value) {
                        self.form.teamMembers.search.terms.setPropertyId(changes.value);
                    }

                    self.form.workOrders.search.terms.setPropertyId(changes.value);
                    self.form.staff.search.terms.setPropertyId(changes.value);
                    self.form.propertyAccess.search.terms.setPropertyId(changes.value);
                    self.form.buildingManagers.search.terms.setPropertyId(changes.value);
                    self.form.projects.search.terms.setPropertyId(changes.value);
                }
            });

            onSnapshot(self.form.teamMembers.search, self.form.teamMembers.loadItems);
            onSnapshot(self.form.staff.search, self.form.staff.loadItems);
            onSnapshot(self.form.propertyAccess.search.terms, self.form.propertyAccess.loadItems);
            onSnapshot(self.form.buildingManagers.search, self.form.buildingManagers.loadItems);

            onSnapshot(self.form.workOrders.search, self.form.workOrders.loadItems);
            onSnapshot(self.form.projects.search, self.form.projects.loadItems);
            onSnapshot(self.form.vendors.search, self.form.vendors.loadItems);

            onSnapshot(self.form.model.projects, (value) => {
                self.form.workOrders.search.terms.setjobIds(self.form.model.projectIds);
            });

            onSnapshot(self.form.model.workOrders, (value) => {
                self.form.vendors.search.terms.setWorkOrderIds(self.form.model.workOrderIds);
            });

            onSnapshot(self.form.workOrders.items, (value) => {
                if (value.length === 1 && !self.form.model.workOrders.some((w) => w.value === value[0].id)) {
                    const wo = value[0];
                    self.form.model.addWorkOrder({ label: wo.workOrderNumber + ' - ' + wo.shortDescription, value: wo.id, parentId: null });
                }
            });

            onSnapshot(self.form.vendors.items, (value) => {
                if (value.length === 1 && !self.form.model.vendors.some((v) => v.value === value[0].id)) {
                    const v = value[0];
                    self.form.model.addVendor({ label: v.name, value: v.id, parentId: null });
                }
            });

            if (!self.form.model.isNew) {
                onPatch(commentForm, (changes) => {
                    if (changes.path === '/comment/apiStatus' && changes.value === 'saved') {
                        commentForm.clear();
                        self.comments.loadItemsAndCount();
                    }
                });

                onSnapshot(comments.paginator, comments.loadItems);
                comments.loadItemsAndCount();
            }
            self.form.sessionFilter.loading = false;
            await self.form.initialize();
        },
        async reinitializeStore() {
            const { comments, commentForm } = self;
            commentForm.clear();
            comments.loadItemsAndCount();
            await self.form.initialize();
        }
    }));
type storeType = Instance<typeof store>;
export interface IActionItemFormStore extends storeType {}

@Injectable({
    providedIn: 'root'
})
export class ActionItemFormStore {
    constructor(private http: HttpClient) {}
    public create(id: number, propertyId?: number, propertyName?: string, action?: string, propertyTeamId?: number, typeId?: number) {
        const initialState: any = {
            form: {
                model: {
                    id: id,
                    propertyId: propertyId,
                    propertyName: propertyName,
                    action: action,
                    typeId: typeId
                },
                dd: {},
                sessionFilter: { userId: 0 },
                teamMembers: {
                    search: {
                        terms: {
                            propertyId: { value: propertyId },
                            propertyTeamId: { value: propertyTeamId }
                        }
                    },
                    paginator: {
                        sortField: 'auditId',
                        sortOrder: SortOrder.Descending,
                        showPages: false,
                        rows: 100,
                        first: 0
                    }
                },
                projects: {
                    search: {
                        terms: {
                            propertyId: { value: propertyId },
                            jobStatusId: { value: 2 }
                        }
                    },
                    paginator: {
                        sortField: 'id',
                        sortOrder: SortOrder.Descending,
                        showPages: false,
                        rows: 100,
                        first: 0
                    }
                },
                vendors: {
                    search: {
                        terms: {}
                    },
                    paginator: {
                        sortField: 'id',
                        sortOrder: SortOrder.Descending,
                        showPages: false,
                        rows: 100,
                        first: 0
                    }
                },
                workOrders: {
                    sessionFilter: 0,
                    search: {
                        terms: {
                            propertyId: { value: propertyId },
                            isOpenOrCompletedRecently: { value: true }
                        }
                    },
                    paginator: {
                        sortField: 'createdDate',
                        sortOrder: SortOrder.Descending,
                        showPages: false,
                        rows: 100,
                        first: 0
                    }
                },
                staff: {
                    search: { terms: { propertyId: { value: propertyId } } },
                    paginator: {
                        sortField: 'auditId',
                        sortOrder: SortOrder.Descending,
                        showPages: false,
                        rows: 100,
                        first: 0
                    }
                },
                propertyAccess: {
                    search: { terms: { propertyId: { value: propertyId } } }
                },
                buildingManagers: {
                    search: { terms: { propertyId: { value: propertyId } } },
                    paginator: {
                        sortField: 'auditId',
                        sortOrder: SortOrder.Descending,
                        showPages: false,
                        rows: 100,
                        first: 0
                    }
                }
            },
            commentForm: {
                comment: {
                    relatedToId: id,
                    discriminator: 'actionitem',
                    id: 0
                }
            },
            comments: {
                relatedToId: id,
                discriminator: 'actionitem'
            }
        };
        return store.create(initialState, { http: this.http });
    }
}
