import { types, getSnapshot, Instance } from 'mobx-state-tree';
import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import {
    createDateRange,
    toDateRange
} from '@app/shared/utils/date-range-helper';
import { PropertyStaffsQuery } from '../../queries/property-staff-query';

export const PropertyStaffSearchTermsModel = types
    .model({
        statusId: searchTermOf(types.number),
        priorityId: searchTermOf(types.number),
        ids: searchTermOf(types.array(types.number)),

        categoryId: searchTermOf(types.number),
        subcategoryId: searchTermOf(types.number),
        propertyId: searchTermOf(types.number),

        unitId: searchTermOf(types.number),
        unitCode: searchTermOf(types.string),
        shortDescription: searchTermOf(types.string),

        createdFrom: searchTermOf(types.Date),
        createdTo: searchTermOf(types.Date),

        createdAtYear: searchTermOf(types.number)
    })
    .actions((self) => ({
        setIds: (id: number) => self.ids.value.push(id),
        setPriorityId: (val: number) => (self.priorityId.value = val),
        setStatusId: (val: number) => (self.statusId.value = val),
        setCategoryId: (val: number) => (self.categoryId.value = val),
        setSubcategoryId: (val: number) => (self.subcategoryId.value = val),
        setPropertyId: (propertyId: number) =>
            (self.propertyId.value = propertyId),
        setUnitId: (unitId: number) => (self.unitId.value = unitId),
        setCreatedBetween: (date: Date) => {
            const [from, to] = createDateRange(
                self.createdFrom.value,
                self.createdTo.value,
                date
            );
            self.createdFrom.value = from;
            self.createdTo.value = to;
        },
        setUnitCode: (unitCode: string) => (self.unitCode.value = unitCode),
        setShortDescription: (shortDescription: string) =>
            (self.shortDescription.value = shortDescription)
    }))
    .views((self) => ({
        get query(): PropertyStaffsQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        },
        get createdBetween(): Date[] {
            return toDateRange(self.createdFrom.value, self.createdTo.value);
        }
    }));
type PropertyStaffSearchTermsModelType = Instance<
    typeof PropertyStaffSearchTermsModel
>;
export interface IPropertyStaffSearchTermsModel
    extends PropertyStaffSearchTermsModelType {}
