import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-link-preview',
    templateUrl: './link-preview.component.html',
    styleUrls: ['./link-preview.component.scss']
})
export class LinkPreviewComponent {
    @Input() preview: any;
    @Input() imageClass = 'p-col-12 p-lg-4';
    @Input() textClass = 'p-col-12 p-lg-8';
}
