import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-goldeye-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() placeholder?: string;
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() focus?: boolean;
    @Input() model: number | string;
    @Input() required = false;

    @Output() change = new EventEmitter<number>();

    ngOnInit() {
        this.placeholder = this.placeholder || '';
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        event.stopPropagation();
        this.change.emit(+event.target.value);
    }
}
