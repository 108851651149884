<div *mobxAutorun>
    <ng-container *ngIf="sessionFilter.initialized; else loading">
        <div class="session-filters p-grid p-align-center search-in-effect" *ngIf="sessionFilter.inEffect">
            <div class="p-col-12 p-md-7 p-lg-8 filter-text-container" style="position: relative">
                <p class="session-filter-centered">
                    <i class="fal fa-info-circle" pTooltip="{{ help }}" tooltipPosition="top" style="margin-right: 5px"></i>

                    <span [ngClass]="{ bold: isBold }">Property Group Filter: </span>
                    <span pTooltip="{{ sessionFilter.propertiesDescriptions }}" tooltipPosition="top">
                        {{ sessionFilter.propertyGroupName }}
                    </span>
                </p>
            </div>
            <button
                pButton
                type="button"
                label="Disable"
                style="height: 36px"
                class="p-button-danger"
                (click)="sessionFilter.removeSessionFilter()"
                [disabled]="sessionFilter.loading"
            ></button>
        </div>

        <form novalidate *ngIf="!sessionFilter.inEffect">
            <div class="p-grid p-align-center p-grid-responsive p-grid-pad p-fluid">
                <div class="p-col-12 p-md-12 p-lg-12 no-padding">
                    <div class="p-grid p-align-center">
                        <div [class]="labelClass" style="position: relative">
                            <label for="property-group" class="session-filter-centered">
                                <i class="fal fa-info-circle" pTooltip="{{ help }}" tooltipPosition="top" style="margin-right: 5px"></i>
                                <span [ngClass]="{ bold: isBold }">{{ label }}: </span>
                            </label>
                        </div>

                        <div [class]="inputClass">
                            <p-dropdown
                                name="property-group"
                                [options]="sessionFilter.propertyGroupDropdown"
                                [ngModel]="sessionFilter.propertyGroupId"
                                [filter]="true"
                                (onChange)="sessionFilter.setPropertyGroup($event.value)"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>

    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
