<div *mobxAutorun>
    <form
        #formControl="ngForm"
        *ngIf="form.initialized; else loading"
        novalidate
    >
        <div class="p-grid">
            <div class="p-col-12 p-md-12">
                <app-goldeye-text-input
                    label="Name"
                    name="name"
                    [model]="form.link.name"
                    (change)="form.link.setName($event)"
                >
                </app-goldeye-text-input>
            </div>

            <div class="p-col-12 p-md-12">
                <app-goldeye-text-area
                    label="Hyperlink"
                    name="url"
                    [rows]="2"
                    [model]="form.link.url"
                    (change)="form.link.setUrl($event)"
                >
                </app-goldeye-text-area>
            </div>
            <div class="p-col-12 p-md-12" *ngIf="form.link.urlPreviewAsJSON">
                <div class="p-md-offset-5">
                    <app-link-preview
                        [preview]="form.link.urlPreviewAsJSON"
                        [textClass]="'p-col-12'"
                        [imageClass]="'p-col-12'"
                    ></app-link-preview>
                </div>
            </div>

            <div class="p-col-12 p-md-12">
                <app-goldeye-text-area
                    label="Notes"
                    name="description"
                    [model]="form.link.notes"
                    (change)="form.link.setNotes($event)"
                >
                </app-goldeye-text-area>
            </div>
        </div>

        <app-goldeye-form-controls
            [disabled]="form.isBusy"
            (save)="onSaveBtnClick()"
            (cancel)="onCancel()"
        >
        </app-goldeye-form-controls>
    </form>

    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
