import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { leftPadWithZeros } from '@app/shared/utils/app-string-helper';
import { computeProgress } from '@app/shared/utils/number-helper';
import { IAttachmentModel } from '@app/core/models/storage/attachment.model';
import { ISASTokenModel, SASTokenModel } from '@app/core/models/authorization/sas-token.model';
import { getSnapshot } from 'mobx-state-tree';
import * as pica from 'pica';
import { iif, forkJoin, Subject, throwError } from 'rxjs';
import { map, concatMap, retry, catchError } from 'rxjs/operators';

const THUMBNAIL_WIDTH = 60;
const TARGET_WIDTH = 1024;
const DEFAULT_BLOCK_SIZE = 64 * 1024;
const BLOCK_BLOB_HEADER = new HttpHeaders({
    'x-ms-blob-type': 'BlockBlob',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/octet-stream'
});
const BLOCK_BLOB_OPTIONS: any = {
    headers: BLOCK_BLOB_HEADER,
    responseType: 'text'
};

@Injectable({ providedIn: 'root' })
export class BlobStorageService {
    resizer: any;
    uploaded = new Subject<boolean>();
    uploadedAll: Subject<void>;
    private callQueue$ = new Subject();

    constructor(private http: HttpClient) {
        this.resizer = pica({
            js: true,
            cib: true, // Create image image bitmap
            ww: true // Use web workers
        });

        this.callQueue$
            .pipe(
                concatMap((state: any) => this.commitFile(state)),
                catchError((err, caught) => throwError(caught)),
                concatMap((attachment: IAttachmentModel) => attachment.uploadingCompleted()),
                retry(1)
            )
            .subscribe(
                () => this.uploaded.next(true),
                (attachment: IAttachmentModel) => {
                    if (attachment.uploadingError) {
                        attachment.uploadingError();
                    }
                }
            );
    }

    private readNextBlock(reader, state) {
        if (!state.attachment.cancelled) {
            if (state.totalBytesRemaining > 0) {
                const block = state.attachment.file.slice(state.currentFilePointer, state.currentFilePointer + state.blockSize);
                const blockId = state.blockIdPrefix + leftPadWithZeros(state.blockIds.length, 6);
                state.blockIds.push(btoa(blockId));

                reader.readAsArrayBuffer(block);

                state.currentFilePointer += state.blockSize;
                state.totalBytesRemaining -= state.blockSize;

                if (state.totalBytesRemaining < state.blockSize) {
                    state.blockSize = state.totalBytesRemaining;
                }
            } else {
                this.callQueue$.next(state);
            }
        }
    }

    private commitFile(state) {
        const url = `${state.fileUrl}&comp=blocklist`;
        const headers = new HttpHeaders({
            'x-ms-blob-content-type': state.attachment.mimeType
        });
        const payload = [
            '<?xml version=\'1.0\' encoding=\'utf-8\'?><BlockList>',
            ...state.blockIds.map((blockId) => `<Latest>${blockId}</Latest>`),
            '</BlockList>'
        ].join('');
        const thumbnailHeaders = new HttpHeaders({
            'x-ms-blob-content-type': state.attachment.mimeType,
            'x-ms-blob-type': 'BlockBlob'
        });

        const fileUploader = () =>
            this.http.put(url, payload, {
                headers,
                responseType: 'text'
            });
        const thumbnailUploader = () =>
            this.http.put(state.thumbnailUrl, state.attachment.thumbnail, {
                headers: thumbnailHeaders,
                responseType: 'text'
            });

        const hasThumbnail = state.attachment.isImage;
        const uploadSequence$ = iif(
            () => hasThumbnail,
            forkJoin([fileUploader(), thumbnailUploader()]).pipe(map(() => state.attachment)),
            fileUploader().pipe(map(() => state.attachment))
        );
        return uploadSequence$;
    }

    private stateFrom(attachment: IAttachmentModel, sasToken: ISASTokenModel) {
        const { file } = attachment;
        const { token } = sasToken;

        const blockSize = Math.min(DEFAULT_BLOCK_SIZE, file.size);
        const numberOfBlocks = Math.ceil(file.size / blockSize);

        return {
            fileUrl: `${sasToken.url}/${attachment.url}${token}`,
            thumbnailUrl: `${sasToken.url}/${attachment.thumbnailUrl}${token}`,
            attachment,
            blockSize,
            numberOfBlocks,
            totalBytesRemaining: file.size,
            currentFilePointer: 0,
            blockIds: new Array(),
            blockIdPrefix: 'block-',
            bytesUploaded: 0
        };
    }

    private continueReading(evt, state) {
        return evt.target.readyState === 2 && !state.attachment.cancelled;
    }

    private blockBlobUrl(state) {
        return state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1];
    }

    private getReadResult(evt) {
        const readResult = evt.target.result;
        const bytesRead = new Uint8Array(evt.target.result).length;
        return [readResult, bytesRead];
    }

    private async loadToken() {
        const storedToken = localStorage.getItem('sas_token') || '{}';
        const sasToken = SASTokenModel.create(JSON.parse(storedToken), {
            http: this.http
        });
        if (sasToken.expired) {
            await sasToken.load();
            const payload = JSON.stringify(getSnapshot(sasToken));
            localStorage.setItem('sas_token', payload);
        }
        return sasToken;
    }

    private uploadByParts(attachment: IAttachmentModel, token: ISASTokenModel) {
        const state = this.stateFrom(attachment, token);
        const reader = new FileReader();

        reader.onloadend = (evt: any) => {
            if (this.continueReading(evt, state)) {
                const result = this.getReadResult(evt);
                this.http.put(this.blockBlobUrl(state), result[0], BLOCK_BLOB_OPTIONS).subscribe(
                    (_) => {
                        state.bytesUploaded += result[1];
                        state.attachment.setProgress(computeProgress(state.attachment.fileSize, state.bytesUploaded));
                        this.readNextBlock(reader, state);
                    },
                    (err) => {
                        if (state.attachment && state.attachment.uploadingError) {
                            state.attachment.uploadingError();
                        }
                    }
                );
            }
        };
        this.readNextBlock(reader, state);

        attachment.uploadingStarted();
    }

    /**
     * Returns an array with 4 dimensions based on provided img:
     * 0 : The width we want the resized image to be
     * 1 : The height we want the resized image to be
     * 2 : The width of the thumbnail
     * 3 : The height of the thumbnail
     * @param img Image used for computing new dimensions
     */
    private resizeDims(img) {
        const imgRatio = img.width <= TARGET_WIDTH ? 1 : TARGET_WIDTH / img.width;
        const thumbnailRatio = img.width <= THUMBNAIL_WIDTH ? 1 : THUMBNAIL_WIDTH / img.width;

        return [img.width * imgRatio, img.height * imgRatio, img.width * thumbnailRatio, img.height * thumbnailRatio];
    }

    private resize(attachment: IAttachmentModel, image: HTMLImageElement, width: number, height: number) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        return this.resizer
            .resize(image, canvas)
            .then((r) => this.resizer.toBlob(r, attachment.mimeType, 0.9))
            .then((b) => new File([b], attachment.file.name));
    }

    async upload(attachments: IAttachmentModel[]) {
        await this.uploadAllAttachments(attachments);

        await this.retokenizeAllAttachments(attachments);
    }

    async retokenizeAllAttachments(attachments: IAttachmentModel[]) {
        attachments.forEach((a) => this.tokenize(a));
    }

    async uploadAllAttachments(attachments: IAttachmentModel[]) {
        const token = await this.loadToken();

        attachments.forEach((attachment) => {
            if (attachment.isImage) {
                const img = new Image();
                const reader = new FileReader();
                reader.onload = (evt: any) => {
                    img.onload = () => {
                        // Resize and compute thumbnail
                        const d = this.resizeDims(img);
                        Promise.all([this.resize(attachment, img, d[0], d[1]), this.resize(attachment, img, d[2], d[3])]).then((result) => {
                            attachment.setFile(result[0]);
                            attachment.setThumbnail(result[1]);

                            this.uploadByParts(attachment, token);
                        });
                    };
                    img.src = evt.target.result as string;
                };
                reader.readAsDataURL(attachment.file);
            } else {
                this.uploadByParts(attachment, token);
            }
        });
    }

    async tokenize(attachment: IAttachmentModel) {
        const { url, token } = await this.loadToken();
        attachment.setTokenizedUrls(url, token);
    }

    public initPromise() {
        this.uploadedAll = new Subject();
    }
}
