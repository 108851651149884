<div class="user-mini-list" *mobxAutorun>
    <div class="p-grid" *ngFor="let pa of propertyAccess">
        <div
            [ngClass]="{
                'access-permanent-bg': !pa.isTemp,
                'access-temporary-bg': pa.isTemp,
                'p-disabled': pa.isNotActive
            }"
            class="p-col-3 user-mini-list__icon"
        >
            <i class="far fa-user"></i>
        </div>
        <div class="p-col-9 user-mini-list__description">
            <p>
                <b>{{ pa.userFullName }}</b>
            </p>
            <ul>
                <li *ngFor="let role of pa.userRoles">
                    {{ role }}
                </li>
            </ul>
        </div>
    </div>
</div>
