import {
    Input,
    Output,
    EventEmitter,
    Directive,
    OnChanges,
    SimpleChanges,
    OnInit
} from '@angular/core';
import * as moment from 'moment';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';
import { SecuredLayoutComponent } from '@app/modules/secured-layout/components/secured-layout/secured-layout.component';

@Directive()
export default class GoldeyeCalendar implements OnChanges, OnInit {
    @Input() value: any;
    @Input() name: string;
    @Input() range = false;
    @Input() disabled = false;

    // Go to https://www.primefaces.org/primeng/showcase/#/calendar
    // Find the DateFormat section for ideas on what is allowed
    @Input() dateFormat = 'M d, yy';
    @Input() hourFormat = '24';
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() label: string;
    @Input() controls?: string[];
    @Input() appendTo?: string;
    @Input() defaultDate?: any;

    @Output() selection = new EventEmitter<Date>();

    @Input() fieldAccess?: IFieldAccessModel;

    intermediateValue: any;

    constructor(public layout: SecuredLayoutComponent) {}

    dateSelected(value) {
        this.selection.emit(value);
    }

    addToDate(amount, units) {
        const date = this.value || moment.now();
        const nextDate = moment(date).add(amount, units).toDate();
        this.selection.emit(nextDate);
    }

    setClassNames() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    setDefaultControls() {
        this.controls = this.controls || ['nextWeek', 'in2Weeks', 'in3Weeks', 'close'];
    }

    ngOnInit(): void {
        this.intermediateValue = this.value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !this.areEqual(this.intermediateValue, changes.value.currentValue)) {
            this.intermediateValue = changes.value.currentValue;
        }
    }

    private areEqual(originalValue: Date | Date[], newValue: Date | Date[]) {
        if (originalValue === newValue) {
            return true;
        }
        if (!originalValue || !newValue) {
            return false;
        }
        if (originalValue instanceof Date || newValue instanceof Date) {
            return originalValue === newValue;
        }
        if (originalValue.length !== newValue.length) {
            return false;
        }

        for (let i = 0; i < originalValue.length; ++i) {
            if (originalValue[i] !== newValue[i]) {
                return false;
            }
        }
        return true;
    }
}
