import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { IPointOfContact } from '@app/shared/models/point-of-contact.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-point-of-contact-info',
    templateUrl: './point-of-contact-info.component.html',
    styleUrls: ['./point-of-contact-info.component.scss']
})
export class PointOfContactInfoComponent {
    @Input() pointOfContact: IPointOfContact;
    @Input() companyName: string;

    @Output() closeBtnClick = new EventEmitter<void>();
}
