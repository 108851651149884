<div class="no-margin" *mobxAutorun>
    <form *ngIf="!form.model.isBusy; else loading">
        <div class="p-grid no-margin">
            <div class="p-col-12 p-grid">
                <app-goldeye-text-input
                    class="p-col-12 p-md-6"
                    labelClass="p-col-12 p-lg-12 p-xl-2 control-padding"
                    inputClass="p-col-12 p-lg-12 p-xl-10 control-padding"
                    [focus]="true"
                    [label]="form.model.typeId == 2 ? 'Incident' : 'Action'"
                    name="action"
                    [model]="form.model.action"
                    (change)="form.model.setAction($event)"
                >
                </app-goldeye-text-input>
                <app-goldeye-text-area
                    name="comment"
                    class="p-col-12 p-md-6"
                    [model]="commentForm.comment.contents"
                    (change)="commentForm.comment.setContents($event)"
                    [rows]="5"
                    label="Comment"
                    labelClass="p-col-12 p-lg-12 p-xl-2 p-col-align-start"
                    inputClass="p-col-12 p-lg-12 p-xl-10"
                >
                </app-goldeye-text-area>
            </div>
            <div class="p-col-12 p-grid p-align-start">
                <ng-container *ngIf="properties?.length > 0; else readOnlyProperty">
                    <div class="p-col-12 p-md-1 control-padding">
                        <label appHighlightOnValidationError="property" for="property"> Property </label>
                    </div>
                    <p-selectButton
                        class="p-col-12 p-md-10 control-padding"
                        [options]="properties"
                        [ngModel]="form.model.propertyId"
                        (onChange)="form.model.setPropertyId($event.value)"
                        name="property"
                    ></p-selectButton>
                </ng-container>
                <ng-template #readOnlyProperty>
                    <h3 class="p-col-12 p-grid p-align-start">
                        <div class="p-col-12 p-md-2 control-padding">
                            <label appHighlightOnValidationError="property" for="property"> Property </label>
                        </div>
                        <div class="p-col-12 p-md-10 control-padding">
                            {{ form.model.propertyName }}
                        </div>
                    </h3>
                </ng-template>
            </div>
            <hr class="p-col-12 full-line" />
            <ng-container *ngIf="form.model.typeId === 2">
                <!-- Projects -->
                <!-- <app-goldeye-select-button
                    class="p-col-12"
                    labelClass="p-col-12 p-md-2"
                    inputClass="p-col-12 p-md-10"
                    [options]="form.projectsAsSelectItem"
                    [model]="form.model.projectIds"
                    (modelChange)="assignOrRemoveProject($event)"
                    name="projects"
                    label="Project"
                    [multiple]="true"
                >
                    <div *ngIf="!form.model.propertyId" class="above-the-buttons">Select a Property first</div>
                    <app-goldeye-dropdown
                        class="after-form-field p-col-12"
                        labelClass="p-col-12 p-md-2"
                        inputClass="p-col-12 p-md-8"
                        containerClass="no-margin"
                        label=""
                        name="projects"
                        optionLabel="label"
                        [filter]="true"
                        [options]="form.dd.filteredJobs(form.model.propertyId, form.model.projectIds)"
                        [model]="selectedProject"
                        (change)="selectedProject = $event"
                    >
                        <app-goldeye-action-button
                            class="after-form-field secondary-action-container p-col-12 p-md-2 p-grid"
                            btnClass="p-col-12"
                            type="button"
                            icon="fa fa-plus"
                            pTooltip="Associate"
                            tooltipPosition="bottom"
                            label="Associate"
                            [disabled]="!selectedProject || !selectedProject.value"
                            (btnClick)="addSelectedProject()"
                        >
                        </app-goldeye-action-button>
                    </app-goldeye-dropdown>
                </app-goldeye-select-button>
                <div class="p-col-12 p-md-10 p-md-offset-2 p-grid p-justify-start">
                    <div class="p-col-12 p-grid" *ngFor="let project of form.model.projects | mstArray">
                        <div class="p-col-12">
                            <h3 class="no-margin">
                                {{ project.label }}
                                <i class="fa fa-times" (click)="form.model.removeProject(project)"></i>
                            </h3>
                        </div>
                    </div>
                </div>
                <hr class="p-col-12 full-line" /> -->
                <!-- WorkOrders -->
                <!-- <app-goldeye-dropdown
                    class="p-col-12"
                    labelClass="p-col-12 p-md-2"
                    [inputClass]="form.allWorkOrderItemsSelected ? 'hidden' : 'p-col-12 p-md-8'"
                    label="Work Orders"
                    name="workOrders"
                    optionLabel="label"
                    [filter]="true"
                    [options]="form.filteredWorkOrders(this.form.model.workOrderIds)"
                    [model]="selectedWorkOrder"
                    (change)="selectedWorkOrder = $event"
                >
                    <app-goldeye-action-button
                        *ngIf="!form.allWorkOrderItemsSelected"
                        class="after-form-field secondary-action-container p-col-12 p-md-2 p-grid"
                        btnClass="p-col-12"
                        type="button"
                        icon="fa fa-plus"
                        pTooltip="Associate"
                        tooltipPosition="bottom"
                        label="Associate"
                        [disabled]="!selectedWorkOrder || !selectedWorkOrder.value"
                        (btnClick)="addSelectedWorkOrder()"
                    >
                    </app-goldeye-action-button>
                    <div class="p-col-12 after-form-field p-grid" [ngClass]="form.allWorkOrderItemsSelected ? 'p-md-10' : ''">
                        <div class="p-col-12 p-grid p-justify-start" [ngClass]="form.allWorkOrderItemsSelected ? '' : 'p-md-offset-2'">
                            <div class="p-col-12 p-grid" *ngFor="let workOrder of form.model.workOrders | mstArray">
                                <div class="p-col-12" [ngClass]="form.allWorkOrderItemsSelected ? '' : 'no-padding-left'">
                                    <h3 class="no-margin">
                                        {{ workOrder.label }}
                                        <i class="fa fa-times" (click)="form.model.removeWorkOrder(workOrder)"></i>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-goldeye-dropdown>

                <hr class="p-col-12 full-line" /> -->
                <!-- Vendors -->
                <!-- <app-goldeye-dropdown
                    class="p-col-12"
                    labelClass="p-col-12 p-md-2"
                    [inputClass]="form.allVendorItemsSelected ? 'hidden' : 'p-col-12 p-md-8'"
                    label="Vendors"
                    name="vendors"
                    optionLabel="label"
                    [filter]="true"
                    [options]="form.filteredVendors(form.model.vendorIds)"
                    [model]="selectedVendor"
                    (change)="selectedVendor = $event"
                >
                    <app-goldeye-action-button
                        *ngIf="!form.allVendorItemsSelected"
                        class="after-form-field secondary-action-container p-col-12 p-md-2 p-grid"
                        btnClass="p-col-12"
                        type="button"
                        icon="fa fa-plus"
                        pTooltip="Associate"
                        tooltipPosition="bottom"
                        label="Associate"
                        [disabled]="!selectedVendor || !selectedVendor.value"
                        (btnClick)="addSelectedVendor()"
                    >
                    </app-goldeye-action-button>
                    <div class="p-col-12 after-form-field p-grid" [ngClass]="form.allVendorItemsSelected ? 'p-md-10' : ''">
                        <div class="p-col-12 p-grid p-justify-start" [ngClass]="form.allVendorItemsSelected ? '' : 'p-md-offset-2'">
                            <div class="p-col-12 p-grid" *ngFor="let vendor of form.model.vendors | mstArray">
                                <div class="p-col-12" [ngClass]="form.allVendorItemsSelected ? '' : 'no-padding-left'">
                                    <h3 class="no-margin">
                                        {{ vendor.label }}
                                        <i class="fa fa-times" (click)="form.model.removeVendor(vendor)"></i>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-goldeye-dropdown>

                <hr class="p-col-12 full-line" /> -->
            </ng-container>

            <div class="p-col-12 no-margin no-padding p-grid p-align-start">
                <div class="p-col-12 p-md-2 control-padding">
                    <label appHighlightOnValidationError="assignedToUsers" for="assignedToUsers">
                        {{ form.model.typeId === 2 ? 'Notify' : 'Assigned' }}
                    </label>
                </div>
                <p-selectButton
                    *ngIf="!form.teamMembers.loading && form.teamMembers.items.length > 0"
                    class="p-col-12 p-md-6 control-padding"
                    [options]="form.model.typeId === 2 ? form.staffUsersAsSelectItem : form.teamMembersAsSelectItem"
                    [ngModel]="form.model.assignedToUserIds"
                    (onOptionClick)="assignOrRemoveTeamMember($event)"
                    [multiple]="true"
                    name="assignedToUsers"
                ></p-selectButton>
                <div class="p-col-12 p-md-4 no-padding no-margin-left p-grid">
                    <app-goldeye-dropdown
                        labelClass="hidden"
                        inputClass="p-col-12 p-col-align-start"
                        name="assignedToUsers"
                        class="p-col-12"
                        [required]="true"
                        [filter]="true"
                        optionLabel="label"
                        [options]="form.dd.filteredUserDropdown(this.form.model.assignedToUserIds)"
                        [model]="selectedUser"
                        (change)="selectedUser = $event"
                        appendTo="body"
                    >
                    </app-goldeye-dropdown>
                    <div class="no-padding p-col-12 p-grid p-justify-end">
                        <button
                            pButton
                            class="secondary-action-btn"
                            type="button"
                            icon="fa fa-plus"
                            pTooltip="Add User"
                            tooltipPosition="bottom"
                            (click)="addSelectedUser()"
                            [label]="'Assign ' + selectedUserFirstName() + ' now'"
                            [disabled]="!selectedUser || !selectedUser.value"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-10 p-md-offset-2 p-grid p-justify-start">
                <div class="p-col-12 p-md-6 p-grid" *ngFor="let assignedToUser of form.model.assignedToUsers | mstArray">
                    <div class="p-col-12 no-padding-left">
                        <h3 class="no-margin">
                            {{ assignedToUser.userName }}
                            <i class="fa fa-times" (click)="form.model.removeAssignedUser(assignedToUser)"></i>
                        </h3>
                    </div>
                </div>
            </div>
            <hr class="p-col-12 full-line" />
            <app-goldeye-action-button
                label="Save"
                class="p-col-12 main-action-container p-grid p-justify-center"
                icon="fa fa-save"
                [disabled]="form.model.isBusy"
                (btnClick)="onSaveBtnClicked()"
            >
            </app-goldeye-action-button>
        </div>
    </form>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
