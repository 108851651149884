import { types, Instance, applySnapshot, getSnapshot } from 'mobx-state-tree';
import {
    anyDateFormatToCalendarString,
    dateDescription,
    setMobxFieldDatetime,
    stringToDate,
    stringToDateTime
} from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';
import { IWorkOrderAssignedUserModel, WorkOrderAssignedUserModel } from './work-order-assigned-user.model';
import { convertPascalCaseToASentence, prefixOrEmpty } from '@app/shared/utils/app-string-helper';
import { WorkOrderStatus } from '../../enums/work-order-status.enum';
import { WorkOrderHistoryModel, IWorkOrderHistoryModel } from './history/work-order-history.model';
import { IWorkOrderTemplateModel } from '../work-order-templates/work-order-template.model';
import { withAttachments } from '@app/core/models/storage/with-attachment.model';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { IUserModel } from '@app/modules/notifications/models/users/operations-user.model';

export const WorkOrderModel = types.compose(
    withAttachments((insp) => `${insp.createdBy ? insp.createdBy : insp.modifiedBy}_work_order_${insp.id}`, 'work-order'),
    resource(
        'workorders',
        types
            .model({
                id: types.identifierNumber,

                workOrderNumber: types.maybeNull(types.string),
                shortDescription: types.maybeNull(types.string),
                accessInstructions: types.maybeNull(types.string),
                notesByTechnician: types.maybeNull(types.string),
                hotNotes: types.maybeNull(types.string),
                longDescription: types.maybeNull(types.string),
                workOrderPriorityId: types.maybeNull(types.number),
                workOrderPriorityDescription: types.maybeNull(types.string),
                workOrderPriorityColor: types.maybeNull(types.string),
                workOrderStatusId: types.maybeNull(types.number),
                propertyId: types.maybeNull(types.number),
                propertyDescription: types.maybeNull(types.string),
                propertyCode: types.maybeNull(types.string),
                location: types.maybeNull(types.string),
                unitId: types.maybeNull(types.number),
                unitCode: types.maybeNull(types.string),
                tenantId: types.maybeNull(types.number),
                assetId: types.maybeNull(types.number),
                isCommercialWorkOrder: types.optional(types.boolean, false),
                commonAreaId: types.maybeNull(types.number),
                buildingId: types.maybeNull(types.number),
                floorId: types.maybeNull(types.number),
                roomId: types.maybeNull(types.number),
                expenseTypeId: types.maybeNull(types.number),
                workOrderCategoryId: types.maybeNull(types.number),
                workOrderCategoryDescription: types.maybeNull(types.string),
                workOrderSubcategoryId: types.maybeNull(types.number),
                workOrderSubcategoryDescription: types.maybeNull(types.string),
                deadlineTypeId: types.maybeNull(types.number),

                legacyWorkOrderNumber: types.maybeNull(types.string),
                dateCalledIn: types.maybeNull(types.string),
                dateWorkCompleted: types.maybeNull(types.string),
                dateDue: types.maybeNull(types.string),
                dateReassigned: types.maybeNull(types.string),

                templateWorkOrderId: types.maybeNull(types.number),
                workOrderTemplateDescription: types.maybeNull(types.string),
                recurringWorkOrderId: types.maybeNull(types.number),

                vendorId: types.maybeNull(types.number),
                jobId: types.maybeNull(types.number),
                jobCode: types.maybeNull(types.string),
                reportedByTenantId: types.maybeNull(types.number),
                wasTenantResponsible: types.optional(types.boolean, false),
                isOkToAccess: types.optional(types.boolean, false),
                isNeedsFollowup: types.optional(types.boolean, false),
                followupNote: types.maybeNull(types.string),
                followUpDate: types.maybeNull(types.string),
                reasonReassigned: types.maybeNull(types.string),
                reportedByExternalEmail: types.maybeNull(types.string),
                reportedByExternalName: types.maybeNull(types.string),
                reportedByExternalPhone: types.maybeNull(types.string),
                reportedByUserId: types.maybeNull(types.number),

                assignedUsersIdList: types.array(WorkOrderAssignedUserModel),
                workOrderHistory: types.array(WorkOrderHistoryModel),

                userDefined1: types.maybeNull(types.string),
                userDefined2: types.maybeNull(types.string),
                userDefined3: types.maybeNull(types.string),
                userDefined4: types.maybeNull(types.string),
                userDefined5: types.maybeNull(types.string),
                userDefined6: types.maybeNull(types.string),
                userDefined7: types.maybeNull(types.string),
                userDefinedDate1: types.maybeNull(types.string),
                userDefinedDate2: types.maybeNull(types.string),
                userDefinedDate3: types.maybeNull(types.string),
                userDefinedDate4: types.maybeNull(types.string),
                userDefinedDate5: types.maybeNull(types.string),
                userDefinedDate6: types.maybeNull(types.string),
                userDefinedDate7: types.maybeNull(types.string),
                isBeingWatched: types.maybeNull(types.boolean),

                createdDate: types.maybeNull(types.string),

                icon: types.maybeNull(types.string),
                color: types.maybeNull(types.string)
            })
            .views((self) => ({
                get asCalendarEvent() {
                    return {
                        title: self.shortDescription,
                        start: self.createdDate,
                        id: self.id,
                        classNames: ''
                    };
                },

                get workOrderStatusDescription() {
                    return convertPascalCaseToASentence(WorkOrderStatus[self.workOrderStatusId]);
                },

                get dateCalledInDate() {
                    return stringToDateTime(self.dateCalledIn);
                },
                get dateWorkCompletedDate() {
                    return stringToDateTime(self.dateWorkCompleted);
                },
                get dateDueDate() {
                    return stringToDateTime(self.dateDue);
                },
                get dateReassignedDate() {
                    return stringToDateTime(self.dateReassigned);
                },
                get createdDateAsDate() {
                    return stringToDateTime(self.createdDate);
                },
                get userDefinedDate1AsDate() {
                    return stringToDateTime(self.userDefinedDate1);
                },
                get userDefinedDate2AsDate() {
                    return stringToDateTime(self.userDefinedDate2);
                },
                get userDefinedDate3AsDate() {
                    return stringToDateTime(self.userDefinedDate3);
                },
                get userDefinedDate4AsDate() {
                    return stringToDateTime(self.userDefinedDate4);
                },
                get userDefinedDate5AsDate() {
                    return stringToDateTime(self.userDefinedDate5);
                },
                get userDefinedDate6AsDate() {
                    return stringToDateTime(self.userDefinedDate6);
                },
                get userDefinedDate7AsDate() {
                    return stringToDateTime(self.userDefinedDate7);
                },
                get followUpDateAsDate() {
                    return stringToDateTime(self.followUpDate);
                },
                get locationIsValid() {
                    return self.propertyId && self.unitId;
                },
                get selectedUserIds(): number[] {
                    return self.assignedUsersIdList ? self.assignedUsersIdList.map((user) => user.assignedUserId) : [];
                },
                get selectedUsers() {
                    return self.assignedUsersIdList;
                },
                get assignedToUsers(): string {
                    return self.assignedUsersIdList.reduce(
                        (accumulator, currentValue) =>
                            accumulator !== '' ? `${accumulator}, ${currentValue.assignedUsername}` : currentValue.assignedUsername,
                        ''
                    );
                },
                get haveAssignedUsers(): boolean {
                    return self.assignedUsersIdList && self.assignedUsersIdList.length > 0 ? true : false;
                },
                get assignedToUsersWhen(): string {
                    return self.assignedUsersIdList.reduce(
                        (accumulator, currentValue) =>
                            accumulator !== ''
                                ? `${accumulator}, ${currentValue.assignedUsername} ` +
                                  `on ${anyDateFormatToCalendarString(currentValue.dateAssigned)}`
                                : `${currentValue.assignedUsername} on ${anyDateFormatToCalendarString(currentValue.dateAssigned)}`,
                        ''
                    );
                },
                assignedUserByUserId(userId: number) {
                    return self.assignedUsersIdList.find((au) => au.assignedUserId === userId);
                },
                get colorByPriority(): string {
                    return self.dateWorkCompleted ? '#666666' : self.workOrderPriorityColor;
                },
                get isFinished(): boolean {
                    return (
                        self.workOrderStatusId === WorkOrderStatus.Completed || self.workOrderStatusId === WorkOrderStatus.CancelledStatus
                    );
                },
                get workOrderEditPageTitle(): string {
                    return self.unitCode ? `${self.unitCode} - ${self.propertyDescription}` : self.propertyDescription;
                },
                get isOpen(): boolean {
                    return (
                        self.workOrderStatusId === WorkOrderStatus.ReportedStatus ||
                        self.workOrderStatusId === WorkOrderStatus.Scheduled ||
                        self.workOrderStatusId === WorkOrderStatus.InProgress
                    );
                }
            }))
            .views((self) => ({
                get asKanbanCard() {
                    const fields = [
                        self.workOrderPriorityDescription,
                        prefixOrEmpty('Property: ', self.propertyDescription),
                        prefixOrEmpty('Unit Code: ', self.unitCode),

                        dateDescription('Created: ', self.createdDateAsDate),
                        dateDescription('Due Date: ', self.dateDueDate)
                    ];
                    return fields.filter((f) => f !== '').join(', ');
                },
                get cardTitle() {
                    return self.shortDescription;
                },
                get summary(): string {
                    return (
                        `${self.workOrderNumber} | <b>${self.workOrderPriorityDescription}</b> | ` +
                        `${self.workOrderStatusDescription} | ${self.location}</br>` +
                        `${self.workOrderCategoryDescription}, ${self.workOrderSubcategoryDescription} | ${self.assignedToUsersWhen}`
                    );
                }
            }))
            .actions((self) => ({
                addToHistory(newHistory: any) {
                    if (newHistory) {
                        self.workOrderHistory.push(newHistory);
                    }
                },
                setAssignedUsersList(newList: any) {
                    self.assignedUsersIdList = newList;
                },
                setIsOkToAccess(val: boolean) {
                    self.isOkToAccess = val;
                },
                setIsNeedsFollowup(val: boolean) {
                    self.isNeedsFollowup = val;
                },
                setFollowupNote(val: string) {
                    const newNote = self.followupNote ? `${self.followupNote}\n\n\n` : '';
                    self.followupNote = newNote + val;
                },
                setReasonReassigned(val: string) {
                    self.reasonReassigned = val;
                },
                setAddToLongDescription(newComment: string) {
                    self.longDescription += newComment;
                },
                setWorkOrderNumber(newNumber: string) {
                    self.workOrderNumber = newNumber;
                },
                setShortDescription(newVal: string) {
                    self.shortDescription = newVal;
                },
                setAccessInstructions(newVal: string) {
                    self.accessInstructions = newVal;
                },
                setTechnicianNotes(newVal: string) {
                    self.notesByTechnician = newVal;
                },
                setHotNotes(newVal: string) {
                    self.hotNotes = newVal;
                },
                setLongDescription(newVal: string) {
                    self.longDescription = newVal;
                },
                setJobId(newVal: number) {
                    self.jobId = newVal;
                },
                setWorkOrderPriorityId(newVal: number) {
                    self.workOrderPriorityId = newVal;
                },
                setWorkOrderStatusId(newVal: number) {
                    self.workOrderStatusId = newVal;
                },
                setWorkOrderStatus(newStatus: WorkOrderStatus) {
                    self.workOrderStatusId = newStatus;
                },
                setPropertyId(newVal: number) {
                    self.propertyId = newVal;
                },

                setUnitId(newVal: number) {
                    self.unitId = newVal;
                },

                setTenantId(newVal: number) {
                    self.tenantId = newVal;
                },

                setCommonAreaId(newVal: number) {
                    self.commonAreaId = newVal;
                },

                setBuildingId(newVal: number) {
                    self.buildingId = newVal;
                },

                setFloorId(newVal: number) {
                    self.floorId = newVal;
                },

                setRoomId(newVal: number) {
                    self.roomId = newVal;
                },

                setAssetId(newVal: number) {
                    self.assetId = newVal;
                },
                setIsCommercialWorkOrder(newVal: boolean) {
                    self.isCommercialWorkOrder = newVal;
                },
                setDateCalledIn(newVal) {
                    self.dateCalledIn = setMobxFieldDatetime(newVal);
                },
                setDateWorkCompleted(newVal) {
                    self.dateWorkCompleted = setMobxFieldDatetime(newVal);
                },
                setDateDue(newVal) {
                    self.dateDue = setMobxFieldDatetime(newVal);
                },
                setDateReassigned(newVal) {
                    self.dateReassigned = setMobxFieldDatetime(newVal);
                },
                setFollowUpDate(newVal) {
                    self.followUpDate = setMobxFieldDatetime(newVal);
                },
                setExpenseTypeId(newVal: number) {
                    self.expenseTypeId = newVal;
                },
                setWorkOrderCategoryId(newVal: number) {
                    self.workOrderCategoryId = newVal;
                },
                setWorkOrderSubcategoryId(newVal: number) {
                    self.workOrderSubcategoryId = newVal;
                },
                setDeadlineTypeId(newVal: number) {
                    self.deadlineTypeId = newVal;
                },
                setLegacyWorkOrderNumber(newVal: string) {
                    self.legacyWorkOrderNumber = newVal;
                },
                setWorkOrderTemplate(template: IWorkOrderTemplateModel) {
                    if (template) {
                        for (const field in self) {
                            // IF:
                            // - its not the id identifier field, and
                            // - (CHANGE: overwrite all work order fields) (OLD: the work order field has not been set yet, and)
                            // - the template field has a valid value, THEN
                            // Set the work order field to the template field
                            if (field !== 'id' && field !== 'attachments' && template[field] !== undefined) {
                                self[field] = template[field];
                            }
                        }
                        if (self.assignedUsersIdList.length === 0) {
                            const userEmail = localStorage.getItem('profile_nickname');
                            applySnapshot(
                                self.assignedUsersIdList,
                                template.assignedUsers.map((user: IWorkOrderAssignedUserModel) => ({
                                    id: 0,
                                    assignedUserId: user.assignedUserId,
                                    assignedUsername: user.assignedUsername,
                                    assignedUserPhone: user.assignedUserPhone,
                                    assignedByUserId: 0,
                                    skillId: null,
                                    skillIcon: user.skillIcon,
                                    skillDisplayValue: null,
                                    assignedByUserEmail: userEmail,
                                    dateAssigned: new Date().toUTCString()
                                }))
                            );
                        }
                        self.templateWorkOrderId = template.id;
                        self.workOrderTemplateDescription = template.shortDescription;
                    }
                },
                setWorkOrderTemplateId(newId: number) {
                    self.templateWorkOrderId = newId;
                },
                setWorkOrderTemplateDescription(newVal: string) {
                    self.workOrderTemplateDescription = newVal;
                },

                setVendorId(newVal: number) {
                    self.vendorId = newVal;
                },
                setReportedByTenantId(newVal: number) {
                    self.reportedByTenantId = newVal;
                },
                setWasTenantResponsible(newVal: boolean) {
                    self.wasTenantResponsible = newVal;
                },
                setReportedByExternalEmail(newVal: string) {
                    self.reportedByExternalEmail = newVal;
                },
                setReportedByExternalName(newVal: string) {
                    self.reportedByExternalName = newVal;
                },
                setReportedByExternalPhone(newVal: string) {
                    self.reportedByExternalPhone = newVal;
                },
                setReportedByUserId(newVal: number) {
                    self.reportedByUserId = newVal;
                },
                setRecurringWorkOrderId(newVal: number) {
                    self.recurringWorkOrderId = newVal;
                },
                setUserDefined1(val: string) {
                    self.userDefined1 = val;
                },
                setUserDefined2(val: string) {
                    self.userDefined2 = val;
                },
                setUserDefined3(val: string) {
                    self.userDefined3 = val;
                },
                setUserDefined4(val: string) {
                    self.userDefined4 = val;
                },
                setUserDefined5(val: string) {
                    self.userDefined5 = val;
                },
                setUserDefined6(val: string) {
                    self.userDefined6 = val;
                },
                setUserDefined7(val: string) {
                    self.userDefined7 = val;
                },
                setUserDefinedDate1(val: string | Date) {
                    self.userDefinedDate1 = setMobxFieldDatetime(val);
                },
                setUserDefinedDate2(val: string | Date) {
                    self.userDefinedDate2 = setMobxFieldDatetime(val);
                },
                setUserDefinedDate3(val: string | Date) {
                    self.userDefinedDate3 = setMobxFieldDatetime(val);
                },
                setUserDefinedDate4(val: string | Date) {
                    self.userDefinedDate4 = setMobxFieldDatetime(val);
                },
                setUserDefinedDate5(val: string | Date) {
                    self.userDefinedDate5 = setMobxFieldDatetime(val);
                },
                setUserDefinedDate6(val: string | Date) {
                    self.userDefinedDate6 = setMobxFieldDatetime(val);
                },
                setUserDefinedDate7(val: string | Date) {
                    self.userDefinedDate7 = setMobxFieldDatetime(val);
                },
                setWorkOrderHistory(newHistory) {
                    self.workOrderHistory = newHistory;
                },
                setSelectedArrayOfUsers(selectedUsers) {
                    if (selectedUsers) {
                        const userEmail = localStorage.getItem('profile_nickname');
                        self.assignedUsersIdList = selectedUsers.map((userId) => {
                            const result = {
                                id: 0,
                                assignedByUserId: 0,
                                assignedUserId: userId,
                                assignedByUserEmail: userEmail,
                                dateAssigned: new Date().toUTCString()
                            };

                            return result;
                        });
                    }
                },
                addAssignedUserFromSelectItem(value: ISelectItem, skillIcon?: string) {
                    const userEmail = localStorage.getItem('profile_nickname');
                    const result = {
                        id: 0,
                        assignedByUserId: 0,
                        assignedUserId: Number(value.value),
                        assignedByUserEmail: userEmail,
                        dateAssigned: new Date().toUTCString(),
                        assignedUsername: value.label,
                        assignedUserPhone: null,
                        skillId: null,
                        skillDisplayValue: null,
                        skillIcon: skillIcon,
                        dateClockedInClockedOut: null
                    };

                    self.assignedUsersIdList.push(result);
                },
                addAssignedUser(user: IUserModel) {
                    const userEmail = localStorage.getItem('profile_nickname');
                    const result = {
                        id: 0,
                        assignedByUserId: 0,
                        assignedUserId: Number(user.id),
                        assignedByUserEmail: userEmail,
                        dateAssigned: new Date().toUTCString(),
                        assignedUsername: user.firstName + ' ' + user.lastName,
                        assignedUserPhone: null,
                        skillId: null,
                        skillDisplayValue: null,
                        skillIcon: user.skillIcon,
                        dateClockedInClockedOut: null
                    };

                    self.assignedUsersIdList.push(result);
                },
                removeAssignedUser(value: IWorkOrderAssignedUserModel) {
                    self.assignedUsersIdList.remove(value);
                }
            }))
    )
);

type WorkOrderModelType = Instance<typeof WorkOrderModel>;
export interface IWorkOrderModel extends WorkOrderModelType {}
