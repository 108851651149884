import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Steps } from 'primeng/steps';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-goldeye-steps-extended',
    templateUrl: './steps-extended.component.html',
    styleUrls: ['./steps-extended.component.scss']
})
export class StepsExtendedComponent extends Steps implements OnInit {
    @Input() tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
    width = '25%';

    constructor(router: Router, route: ActivatedRoute, cd: ChangeDetectorRef) {
        super(router, route, cd);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.tooltipPosition = this.tooltipPosition || 'bottom';

        this.width = this.model ? 100 / this.model.length + '%' : this.width;
    }
}
