import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy
} from '@angular/core';
import { IPropertyAccessModel } from '@app/modules/property-team/models/property-access/property-access.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-property-mini-list',
    templateUrl: './property-access-property-mini-list.component.html',
    styleUrls: ['./property-access-property-mini-list.component.scss']
})
export class PropertyAccessPropertyMiniListComponent {
    @Input() propertyAccess: IPropertyAccessModel[];
}
