import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';
import { Editor } from 'primeng/editor';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('textEditor', { read: Editor }) editor: Editor;
    @Input() label: string;
    @Input() name: string;

    @Input() labelIconClass?: string;
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() height?: string;

    @Input() placeholder?: string;
    @Input() focus?: boolean;

    @Input() model: string;
    @Input() useCustomControls = false;
    @Input() debounceTime = 1000;
    @Input() readonly = false;
    @Input() showToggleReadonlyButton = false;
    @Output() change = new EventEmitter<any>();
    @Output() labelIconClick = new EventEmitter<any>();

    @Input() fieldAccess?: IFieldAccessModel;

    textChanged = new Subject<string>();
    textChanged$: Subscription;

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12';
        this.inputClass = this.inputClass || 'p-col-12';
        this.labelIconClass = this.labelIconClass;
        this.height = this.height || '200px';

        this.textChanged$ = this.textChanged
            .pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(),
                tap((value) => this.change.emit(value))
            )
            .subscribe((_) => {});
    }

    ngAfterViewInit(): void {
        if (this.focus) {
            const quill = this.editor.getQuill();
            setTimeout(() => quill.focus(), 200);
        }
    }

    onTextChange($event) {
        this.textChanged.next($event.htmlValue);
    }

    labelIconClicked() {
        this.labelIconClick.emit();
    }

    ngOnDestroy() {
        if (this.textChanged$) {
            this.textChanged$.unsubscribe();
        }
    }
}
