<div class="p-grid attachment-viewer" *mobxAutorun>
    <div class="p-col-12">
        <div [hidden]="attachments.length != 0">
            <app-attachment-uploader [container]="container" (closed)="hasUploaded.next(true)">
            </app-attachment-uploader>
        </div>

        <div *ngIf="attachments.length > 0">
            <p-dialog
                appendTo="body"
                styleClass="large-modal"
                [header]="uploadModalTitle"
                [(visible)]="displayFileUploader"
                [modal]="true"
                [closable]="true"
            >
                <app-attachment-uploader
                    [showCloseButton]="true"
                    [container]="container"
                    (closed)="onFinishUploading()"
                >
                </app-attachment-uploader>
            </p-dialog>

            <div class="p-grid">
                <div class="p-col-12">
                    <p-fieldset
                        [legend]="fieldsetTitle"
                        [toggleable]="true"
                        [collapsed]="!(hasUploaded | async) && collapsed"
                    >
                        <div class="p-grid p-col-12 no-margin no-padding p-justify-end">
                            <div class="main-action-container no-top-control-padding">
                                <div class="p-col-12 action-button control-padding">
                                    <button
                                        pButton
                                        class="secondary-action-btn"
                                        icon="fa fa-upload"
                                        (click)="displayFileUploader = true"
                                    ></button>
                                </div>
                            </div>
                        </div>
                        <app-attachments-mini-grid
                            [loading]="false"
                            [title]="title"
                            [attachments]="attachments"
                            [showPhotoThumbnail]="showPhotoThumbnail"
                        ></app-attachments-mini-grid>
                    </p-fieldset>
                </div>
            </div>
        </div>
    </div>
</div>
