import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { SelectItem } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-listbox-with-feedback-modal',
    templateUrl: './listbox-with-feedback-modal.component.html',
    styleUrls: ['./listbox-with-feedback-modal.component.scss']
})
export class ListboxWithFeedbackModalComponent implements OnInit {
    @Input() controlLabel: string;
    @Input() modalTitle: string;
    @Input() modalIconClass = 'fa fa-building';
    @Input() listboxColumnTitle: string;
    @Input() feedbackColumnTitle: string;

    @Input() options: Array<SelectItem | ISelectItem>;
    @Input() model: number[];
    @Output() change = new EventEmitter<number[]>();

    showDialog = false;
    modelCopy: number[];

    selectedItems: string[];
    mainViewSelectedItems: string[];

    constructor() {}

    ngOnInit() {
        this.generateSelectedItems();

        // Generate main readonly view
        this.generateMainViewSelectedItems();
    }

    onSaveBtnClicked() {
        this.model = this.modelCopy;

        // Generate main readonly view
        this.generateMainViewSelectedItems();

        this.showDialog = false;

        this.change.emit(this.modelCopy);

        this.resetDialogEntities();
    }

    onDialogHide() {
        this.showDialog = false;

        this.resetDialogEntities();
    }

    resetDialogEntities() {
        this.modelCopy = [];
        this.selectedItems = [];
    }

    // Generates the modal's selected items
    generateSelectedItems() {
        if (this.modelCopy && this.options && this.modelCopy.length > 0) {
            this.selectedItems = this.options
                .filter((o) => this.modelCopy.includes(+o.value))
                .map((o) => o.label);
        } else {
            this.selectedItems = [];
        }
    }

    generateMainViewSelectedItems() {
        if (this.model && this.options && this.model.length > 0) {
            this.mainViewSelectedItems = this.options
                .filter((o) => this.model.includes(+o.value))
                .map((o) => o.label);
        } else {
            this.mainViewSelectedItems = [];
        }
    }

    editDialog() {
        this.modelCopy = this.model ? [...this.model] : [];
        this.generateSelectedItems();
        this.showDialog = true;
    }
}
