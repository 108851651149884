import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ICommentFormModel } from '@app/shared/models/comment/comment-form.model';
import { MessageService } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-comment-form',
    templateUrl: './comment-form.component.html',
    styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent {
    @Input() form: ICommentFormModel;
    @Input() saveLabel = 'Add Comment';
    @Input() inputClass = 'p-col-12 p-md-8';
    @Input() btnClass = 'p-col-12 p-sm-6 p-md-4';

    constructor(private messageService: MessageService) {}

    async onSaveBtnClick() {
        await this.form.comment.save();
        if (this.form.comment.noErrors) {
            this.messageService.add({
                severity: 'success',
                summary: 'Comment created',
                detail: 'New comment was created successfully'
            });
        }
    }
}
