<div *mobxAutorun>
    <div>
        <p-dialog
            *ngIf="showDialog"
            [(visible)]="showDialog"
            [modal]="true"
            (onHide)="onDialogHide()"
            class="large-modal claim-step-dialog"
        >
            <p-header>
                <header class="claim-step-dialog-header">
                    <i class="header-icon" [ngClass]="modalIconClass"></i>
                    <h2>{{ modalTitle }}</h2>
                </header>
            </p-header>
            <div class="p-grid p-col-12 no-padding p-justify-center">
                <div class="p-md-5">
                    <h3>
                        {{ listboxColumnTitle }}
                    </h3>

                    <p-listbox
                        [options]="options"
                        [(ngModel)]="modelCopy"
                        [filter]="true"
                        [checkbox]="true"
                        [multiple]="true"
                        class="p-col-12"
                        (onChange)="generateSelectedItems()"
                    >
                        <ng-template let-item pTemplate="item">
                            <span>
                                {{ item.label }}
                            </span>
                        </ng-template>
                    </p-listbox>
                </div>

                <div class="p-md-5 p-md-offset-1">
                    <h3>
                        {{ feedbackColumnTitle }}
                    </h3>
                    <div class="feedback-column">
                        <ul *ngIf="selectedItems">
                            <li *ngFor="let item of selectedItems">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <app-goldeye-form-controls
                class="form-controls"
                (save)="onSaveBtnClicked()"
                saveLabel="Save"
                saveIcon="fa fa-edit"
                (cancel)="onDialogHide()"
                [btnClass]="'p-col-12 p-md-4'"
            >
            </app-goldeye-form-controls>
        </p-dialog>

        <div class="p-grid p-align-top control-padding">
            <div class="p-col-12 p-md-5">
                <label>
                    {{ controlLabel }}
                </label>
            </div>

            <div class="p-col-12 p-md-7 location-container main-view">
                <div class="p-col-10 no-padding">
                    <div class="feedback-column">
                        <ul
                            *ngIf="mainViewSelectedItems"
                            class="no-padding no-margin"
                        >
                            <li *ngFor="let item of mainViewSelectedItems">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-col-2 no-padding icon-container">
                    <button
                        pButton
                        type="button"
                        [icon]="
                            !mainViewSelectedItems ||
                            mainViewSelectedItems.length == 0
                                ? 'fa fa-plus'
                                : 'fas fa-pencil-alt'
                        "
                        (click)="editDialog()"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</div>
