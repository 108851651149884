import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { stringToDate } from '@app/shared/utils/date-helper';
import { ISignalRMessage } from '@app/core/models/realtime-communication/signalr-message';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private baseUrl = environment.appConfig.baseUrl;

    constructor(private http: HttpClient) {}

    public getUserNotificationList(
        userEmail: string
    ): Observable<ISignalRMessage[]> {
        const url = `${this.baseUrl}notification/${userEmail}`;

        return this.http.get<ISignalRMessage[]>(url).pipe(
            tap((messages) => {
                messages.forEach((msg) => {
                    msg.date = stringToDate(msg.date);
                });
            })
        );
    }

    public getNotificationList(): Observable<ISignalRMessage[]> {
        const url = `${this.baseUrl}notification/list`;

        return this.http.get<ISignalRMessage[]>(url).pipe(
            tap((messages) => {
                messages.forEach((msg) => {
                    msg.date = stringToDate(msg.date);
                });
            })
        );
    }

    dismissAllUserNotifications(lastBroadcastMessageList: ISignalRMessage[]) {
        const url = `${this.baseUrl}notification/dismissallforuser`;

        return this.http.post<boolean>(url, lastBroadcastMessageList);
    }

    markNotificationAsRead(singleNotification: ISignalRMessage) {
        const url = `${this.baseUrl}notification/markasread`;

        return this.http.post<boolean>(url, singleNotification);
    }
}
