<div class="data-bar" [ngClass]="{ 'search-in-effect': searchInEffect }">
    <p-accordion>
        <p-accordionTab [selected]="filterIsExpanded">
            <p-header class="p-col-12">
                <div class="p-grid p-align-center p-justify-between">
                    <div class="p-col-12 p-sm-5">
                        {{ searchInEffect ? 'Filtering is on' : 'Filtering' }}
                    </div>
                    <div class="p-col-12 p-sm-5 data-bar-search-chips">
                        <ul>
                            <li
                                *ngFor="let term of searchTerms"
                                class="p-chips-token p-highlight"
                            >
                                <span class="p-chips-token-label">{{
                                    term[1]
                                }}</span>
                                <span
                                    *ngIf="!term[0].disabled"
                                    class="p-chips-token-icon pi pi-fw pi-times"
                                    (click)="removeItem($event, term[0])"
                                ></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </p-header>

            <div class="p-grid p-align-start">
                <div class="p-col-12 p-md-6 p-lg-6">
                    <ng-content select=".left-position"></ng-content>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    <ng-content select=".right-position"></ng-content>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
