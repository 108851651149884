<div class="p-grid" *mobxAutorun>
    <div class="p-col-12 card no-margin">
        <p-card
            class="kanban-column hidden-xs"
            [ngClass]="{
                'p-col-2': columns.length >= 4,
                'p-col-3': columns.length <= 3
            }"
            *ngFor="let column of columns; let i = index"
        >
            <p-header>
                <h3>{{ column.caption }}</h3>
                <p>{{ column.totalRecords }} {{ column.itemName }}</p>
                <hr />
            </p-header>

            <div
                [hidden]="column.listLoading"
                *ngIf="column.items.length; else noCards"
            >
                <ng-container *ngIf="lineItemTemplate">
                    <ng-template ngFor let-lineItem [ngForOf]="column.items">
                        <ng-container
                            *ngTemplateOutlet="
                                lineItemTemplate;
                                context: { $implicit: lineItem }
                            "
                        ></ng-container>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="!lineItemTemplate">
                    <p-card
                        class="kanban-card"
                        (click)="onCardClick(item)"
                        *ngFor="let item of column.items"
                    >
                        <div class="kanban-label">
                            <span
                                [class]="item.cardTitle | toClassName"
                                [title]="item.cardTitle"
                            >
                            </span>
                        </div>
                        {{ item.asKanbanCard }}
                    </p-card>
                </ng-container>
            </div>

            <app-progress-spinner
                [hidden]="!column.listLoading"
            ></app-progress-spinner>

            <ng-template #noCards>
                <i>No cards to show</i>
            </ng-template>

            <!-- <p-footer *ngIf="column.hasAddBtn">
                <button
                    pButton
                    id="kanban-add-btn"
                    type="button"
                    label="Add New"
                    icon="fa fa-edit"
                    (click)="onAddBtnClick(column)"
                ></button>
            </p-footer> -->
        </p-card>

        <p-tabView class="hidden visible-xs">
            <p-tabPanel
                *ngFor="let column of columns; let i = index"
                [header]="column.caption"
                class="kanban-column"
            >
                <p-header>
                    <h3>{{ column.caption }}</h3>
                    <p>{{ column.totalRecords }} inspections</p>
                    <hr />
                </p-header>

                <ul>
                    <p-card
                        class="kanban-card"
                        *ngFor="let item of column.items"
                        (click)="onCardClick(item)"
                    >
                        <div class="kanban-label">
                            <span
                                [class]="item.cardTitle | toClassName"
                                [title]="item.cardTitle"
                            >
                            </span>
                        </div>
                        {{ item.asKanbanCard }}
                    </p-card>
                </ul>

                <!-- <p-footer *ngIf="column.hasAddBtn">
                    <button
                        pButton
                        id="kanban-add-btn"
                        type="button"
                        label="Add New"
                        icon="fa fa-edit"
                        (click)="onAddBtnClick(column)"
                    ></button>
                </p-footer> -->
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
