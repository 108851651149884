import { UIError, ErrorMessage } from './ui-error';

export class ValidationError implements UIError {
    err: any;

    constructor(err: any) {
        this.err = err;
        Object.setPrototypeOf(this, ValidationError.prototype);
    }

    errors(): ErrorMessage[] {
        return Object.keys(this.err.error).map((prop) => ({
            summary: prop,
            detail: this.err.error[prop].join(', ')
        }));
    }
}
