import {
    createDateRange,
    toDateRange
} from '@app/shared/utils/date-range-helper';
import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import { getSnapshot, Instance, types } from 'mobx-state-tree';
import { JobsQuery } from '../../queries/jobs/job.query';

export const JobSearchTermsModel = types
    .model({
        ids: searchTermOf(types.array(types.number)),

        matchAll: searchTermOf(types.string),

        propertyId: searchTermOf(types.number),
        propertyTeamId: searchTermOf(types.number),

        unitId: searchTermOf(types.number),
        unitCode: searchTermOf(types.string),

        jobTypeId: searchTermOf(types.number),
        jobStatusId: searchTermOf(types.number),
        jobCode: searchTermOf(types.string),

        briefDescription: searchTermOf(types.string),

        createdFrom: searchTermOf(types.Date),
        createdTo: searchTermOf(types.Date),

        completionDateFrom: searchTermOf(types.Date),
        completionDateTo: searchTermOf(types.Date),

        startDateFrom: searchTermOf(types.Date),
        startDateTo: searchTermOf(types.Date),

        createdAtYear: searchTermOf(types.number),

        isCloseToCompletion: searchTermOf(types.boolean),
        isRehab: searchTermOf(types.boolean),
        isReno: searchTermOf(types.boolean),
        isOtherType: searchTermOf(types.boolean),

        createdDateOffset: searchTermOf(types.number)
    })
    .actions((self) => ({
        clearSpecialFilters() {
            self.isCloseToCompletion.setValue(undefined);
            self.isRehab.setValue(undefined);
            self.isReno.setValue(undefined);
            self.isOtherType.setValue(undefined);
        }
    }))
    .actions((self) => ({
        setIds: (id: number) => self.ids.value.push(id),
        setMatchAll: (matchAll: string) => (self.matchAll.value = matchAll),
        setPropertyId: (propertyId: number) =>
            (self.propertyId.value = propertyId),
        setPropertyTeamId: (propertyTeamId: number) =>
            (self.propertyTeamId.value = propertyTeamId),
        setUnitId: (unitId: number) => (self.unitId.value = unitId),
        setJobTypeId: (jobTypeId: number) => (self.jobTypeId.value = jobTypeId),
        setJobStatusId: (jobStatusId: number) =>
            (self.jobStatusId.value = jobStatusId),
        setJobCode: (jobCode: string) => (self.jobCode.value = jobCode),
        setCreatedBetween: (date: Date) => {
            const [from, to] = createDateRange(
                self.createdFrom.value,
                self.createdTo.value,
                date
            );
            self.createdFrom.value = from;
            self.createdTo.value = to;
        },
        setUnitCode: (unitCode: string) => (self.unitCode.value = unitCode),
        setShortDescription: (shortDescription: string) =>
            (self.briefDescription.value = shortDescription),

        setCompletionDate: (date) => {
            const [from, to] = createDateRange(
                self.completionDateFrom.value,
                self.completionDateTo.value,
                date
            );
            self.completionDateFrom.value = from;
            self.completionDateTo.value = to;
        },
        setStartDate: (date) => {
            const [from, to] = createDateRange(
                self.startDateFrom.value,
                self.startDateTo.value,
                date
            );
            self.startDateFrom.value = from;
            self.startDateTo.value = to;
        },

        setIsCloseToCompletion: (value: boolean) => {
            self.clearSpecialFilters();
            self.isCloseToCompletion.value = value;
        },
        setIsRehab: (value: boolean) => {
            self.clearSpecialFilters();
            self.isRehab.value = value;
        },
        setIsReno: (value: boolean) => {
            self.clearSpecialFilters();
            self.isReno.value = value;
        },
        setIsOtherType: (value: boolean) => {
            self.clearSpecialFilters();
            self.isOtherType.value = value;
        },
        setCreatedDateOffset: (createdDateOffset: number) =>
            (self.createdDateOffset.value = createdDateOffset),
        increaseCreatedDateOffset: (by: number) =>
            (self.createdDateOffset.value += by)
    }))
    .views((self) => ({
        get query(): JobsQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        },
        get createdBetween(): Date[] {
            return toDateRange(self.createdFrom.value, self.createdTo.value);
        },
        get completedBetween(): Date[] {
            return toDateRange(
                self.completionDateFrom.value,
                self.completionDateTo.value
            );
        },
        get startedBetween(): Date[] {
            return toDateRange(
                self.startDateFrom.value,
                self.startDateTo.value
            );
        }
    }));
type JobSearchTermsModelType = Instance<typeof JobSearchTermsModel>;
export interface IJobSearchTermsModel extends JobSearchTermsModelType {}
