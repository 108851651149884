import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { NavMenu } from '@app/core/models';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NavMenuService {
    private baseUrl = environment.appConfig.baseUrl;

    constructor(private http: HttpClient) {}

    getMenuViewModel(id: number) {
        const url = `${this.baseUrl}menus/${id}`;
        return id === 0 ? of(this.initializeMenuViewModel()) : this.http.get<NavMenu>(url);
    }

    getMenuViewModelsForUser(email: string) {
        const url = this.baseUrl + `menus/${email}/allowedmenus`;
        return this.http.get<NavMenu[]>(url);
    }

    private initializeMenuViewModel(): NavMenu {
        return {
            Label: '',
            Icon: '',
            RouterLink: '',
            Title: '',
            Items: []
        };
    }
}
