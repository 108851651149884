import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import { DropdownModel } from '@app/shared/models/goldeye-select-item';

export const PropertyCallsheetDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            propertyDropdown: DropdownModel,
            noEmptyVendorDropdown: DropdownModel,
            noEmptyUserDropdown: DropdownModel
        })
        .views((self) => ({
            filteredUserDropdown: (selectedUsers: { userId: number }[]) =>
                self.noEmptyUserDropdown.filter(
                    (u) =>
                        !selectedUsers.some(
                            (selectedUser) => u.value === selectedUser.userId
                        )
                )
        }))
);
type PropertyCallsheetDropdownsModelType = Instance<
    typeof PropertyCallsheetDropdownModel
>;
export interface IPropertyCallsheetDropdownsModel
    extends PropertyCallsheetDropdownsModelType {}
