import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-goldeye-readonly-textarea',
    templateUrl: './readonly-textarea.component.html',
    styleUrls: ['./readonly-textarea.component.scss']
})
export class ReadonlyTextareaComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;

    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() rows: number;

    @Input() model: string;

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
        this.rows = this.rows || 4;
    }
}
