import { Instance, types } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';
import { PropertyAccessType } from '../../../property-team/enums/property-access-types';
import { PropertyAccessStatus } from '../../../property-team/enums/property-access-status';
import * as moment from 'moment';
import { UserRoles } from '@app/core/models/enums/user-role-enum';
import { stringToDateTime, setMobxFieldDatetime } from '@app/shared/utils/date-helper';

export const PropertyAccessModel = resource(
    (myself) =>
        myself.primaryUserRoleId === UserRoles.MaintenanceStaffRoleId
            ? 'maintenance-staff-property-access'
            : 'resident-manager-property-access',
    types
        .model({
            id: types.number,

            userId: types.maybeNull(types.number),
            primaryUserRoleId: types.maybeNull(types.number),
            userFullName: types.maybeNull(types.string),
            userHandle: types.maybeNull(types.string),
            userEmail: types.maybeNull(types.string),
            userMobileNumber: types.maybeNull(types.string),
            userDirectNumber: types.maybeNull(types.string),
            userCreatedDate: types.maybeNull(types.string),
            userRoles: types.array(types.string),

            propertyId: types.maybeNull(types.number),
            propertyCode: types.maybeNull(types.string),
            propertyAddress: types.maybeNull(types.string),
            propertyName: types.maybeNull(types.string),
            propertyNumberOfUnits: types.maybeNull(types.number),

            accessStatusDescription: types.maybeNull(types.string),
            accessStatusValue: types.maybeNull(types.number),

            accessTypeDescription: types.maybeNull(types.string),
            accessTypeValue: types.maybeNull(types.number),

            activeDate: types.maybeNull(types.string),
            expireDate: types.maybeNull(types.string)
        })
        .views((self) => ({
            get isTemp() {
                return self.accessTypeValue === PropertyAccessType.Temporary;
            },
            get isNotActive() {
                return self.accessStatusValue === PropertyAccessStatus.NotActive;
            },
            get activeDateParsed() {
                return stringToDateTime(self.activeDate);
            },
            get expireDateParsed() {
                return stringToDateTime(self.expireDate);
            }
        }))
        .actions((self) => ({
            setUserId: (userId) => (self.userId = userId),
            setPropertyId: (propertyId) => (self.propertyId = propertyId),
            setAccessType: (value) => (self.accessTypeValue = value),
            setAccessStatus: (value) => (self.accessStatusValue = value),
            setActiveDate: (value) => {
                self.activeDate = setMobxFieldDatetime(value);
            },
            setExpireDate: (value) => {
                // If the date is in the future,
                // set the status to active,
                // if not set to inactive
                if (value) {
                    // check if the date is in the past
                    const expireDate = moment(value);
                    const today = moment();

                    const dateHasPassed = expireDate.isBefore(today);

                    const accessStatus = dateHasPassed ? PropertyAccessStatus.NotActive : PropertyAccessStatus.Active;

                    self.accessStatusValue = accessStatus;
                }

                self.expireDate = setMobxFieldDatetime(value);
            }
        }))
);

type PropertyAccessModelType = Instance<typeof PropertyAccessModel>;
export interface IPropertyAccessModel extends PropertyAccessModelType {}
