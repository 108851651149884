<div class="property-mini-list" *mobxAutorun>
    <div class="p-grid" *ngFor="let pa of propertyAccess">
        <div
            class="p-col-3 property-mini-list__icon"
            [ngClass]="{
                'access-permanent-bg': !pa.isTemp,
                'access-temporary-bg': pa.isTemp,
                'p-disabled': pa.isNotActive
            }"
        >
            <i class="far fa-building"></i>
        </div>
        <div class="p-col-9 property-mini-list__description">
            <p>
                {{ pa.propertyAddress }}<br />
                {{ pa.propertyName }}
            </p>
        </div>
    </div>
</div>
