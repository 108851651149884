import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'smartDatetime'
})
export class SmartDatetimePipe implements PipeTransform {
    transform(value: Date): string {
        const today = new Date();
        if (Boolean(value)) {
            return value.getFullYear() < today.getFullYear()
                ? moment(value).format('MMM Do YYYY, h:mm a')
                : moment(value).format('ddd MMM Do, h:mm a');
        }
        return '';
    }
}
