<div class="p-grid p-align-center control-padding" *ngIf="!hideEmpty || model">
    <ng-content
        select=".before-form-field"

    ></ng-content>

    <div class="{{ labelClass }}">
        <label for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content
        select=".between-form-field"
    ></ng-content>

    <div class="{{ inputClass }}" >
        <input
            pInputText
            appHighlightOnValidationError
            type="text"
            name="{{ name }}"
            [ngModel]="model"
            readonly="readonly"
        />
    </div>

    <ng-content
        select=".after-form-field"
    ></ng-content>
</div>
