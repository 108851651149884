<div
    class="p-grid p-align-center control-padding"
    *ngIf="!fieldAccess?.isHidden"
>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-calendar
            #cal
            [dateFormat]="dateFormat"
            name="{{ this.name }}"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: 'calc(100% - 2em)' }"
            [appHighlightOnValidationError]="name"
            [showIcon]="true"
            [showTime]="true"
            [hourFormat]="'12'"
            [disabledDays]="[6, 0]"
            [disabled]="fieldAccess?.isLocked || disabled"
            [numberOfMonths]="1"
            [stepMinute]="15"
            [ngModel]="value"
            (onFocus)="setScheduledForToday(cal)"
            (onSelect)="dateSelected($event)"
            [touchUI]="layout.windowIsMobile$ | async"
        >
            <!-- In the p-calendar above,
        The onFocus property is used in place of the defaultDate property
        because only the Year, Month, and Time get set by default.
        The Day does not get selected by default and we need this
        https://github.com/primefaces/primeng/issues/2799 -->

            <p-footer>
                <div class="p-grid p-justify-center">
                    <div class="p-col-3">
                        <p-button
                            label="Close"
                            styleClass="p-button-secondary"
                            (click)="cal.hideOverlay()"
                        >
                        </p-button>
                    </div>
                </div>
            </p-footer>
        </p-calendar>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
