import {
    Directive,
    Input,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { ValidationMessagesService } from '@app/core/services/validation/validation-messages.service';
import { Subscription } from 'rxjs';
import { ErrorMessage } from '@app/core/errors';

/** Applies validation error classes if either the appHighlightOnValidationError or the
 * name of element is contained in the validation errors colection.
 */

@Directive({
    selector: '[appHighlightOnValidationError]'
})
export class HighlightOnValidationErrorDirective implements OnInit, OnDestroy {
    @Input() appHighlightOnValidationError?: string;

    errorMessages$: Subscription;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private validationMessagesService: ValidationMessagesService
    ) {}

    ngOnInit() {
        this.errorMessages$ = this.validationMessagesService
            .getValidationMessages()
            .subscribe((errorMessages: ErrorMessage[]) => {
                let target = '';
                const { attributes } = this.el.nativeElement;
                if (attributes.name && attributes.name.nodeValue) {
                    target = attributes.name.nodeValue;
                } else {
                    target = this.appHighlightOnValidationError;
                }
                if (this.errorsContainProperty(errorMessages, target)) {
                    this.renderer.addClass(this.el.nativeElement, 'invalid');
                } else {
                    this.renderer.removeClass(this.el.nativeElement, 'invalid');
                }
            });
    }

    errorsContainProperty(errors, property) {
        return property && errors
            ? errors.find(
                err => err.summary.toLowerCase() === property.toLowerCase()
            )
            : false;
    }

    ngOnDestroy() {
        if (this.errorMessages$) {
            this.errorMessages$.unsubscribe();
        }
    }
}
