export function prefixOrEmpty(prefix: string, value?: string) {
    return value ? `${prefix} ${value}` : '';
}

export function includes(str1: string, str2: string) {
    return str1.toLowerCase().includes(str2.toLowerCase());
}

export function leftPadWithZeros(number: number, length: number): string {
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}

export function uuid(): string {
    // eslint-disable-next-line no-bitwise
    const s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${s4 + s4}-${s4}-4${s4.substr(
        0,
        3
    )}-${s4}-${s4}${s4}${s4}`.toLowerCase();
}

export function formatStrForICS(str: string): string {
    if (str && str.length) {
        return str.replace(/\\r/g, '\n').replace(/\\n/g, '\n');
    }
    return null;
}

// Also converts camel case to a sentence
// Inputs | Outputs:
// helloThere  |  hello There
// HelloThere  |  Hello There
// ILoveTheUSA |  I Love The USA
// iLoveTheUSA |  i Love The USA
export function convertPascalCaseToASentence(str: string): string {
    if (str && str.length) {
        return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
    }
    return null;
}

export class StringHelper {
    convertUTCDateToLocalDate(date: Date): string {
        if (date === null) {
            return '';
        }

        // typescript needs a new inside-method-scope date object in order to access date functions
        const suppliedDate = new Date(date);

        const convertedDate = new Date(
            suppliedDate.getTime() +
                suppliedDate.getTimezoneOffset() * 60 * 1000
        );

        const offset = suppliedDate.getTimezoneOffset() / 60;
        const hours = suppliedDate.getHours();

        convertedDate.setHours(hours - offset);

        return (
            convertedDate.toLocaleDateString() +
            ' ' +
            convertedDate.toLocaleTimeString()
        );
    }
}
