import { UserRole, UserFeature, UserAttributes, UserChannel } from '@app/core/models';

export interface IUser {
    id: number;
    attributes: UserAttributes;
    userRoles: UserRole[];
    userFeatures: UserFeature[];
    userChannels: UserChannel[];
}

export class User implements IUser {
    id: number;
    attributes: UserAttributes;
    userRoles: UserRole[];
    userFeatures: UserFeature[];
    userChannels: UserChannel[];
}

// other lists will include
// recentLogins: a list of recent logins;
// userStats: a set of aggregated, gamified user stats
