<div *mobxAutorun>
    <app-validation-messages></app-validation-messages>

    <form novalidate *ngIf="form.initialized; else loading">
        <div class="p-grid padded-form-container">
            <div class="p-col-12 p-md-6">
                <app-goldeye-dropdown
                    label="User"
                    name="userId"
                    [filter]="true"
                    [options]="userDd"
                    [model]="form.propertyAccess.userId"
                    [disabled]="disabled?.includes('userId')"
                    (change)="form.propertyAccess.setUserId($event)"
                >
                </app-goldeye-dropdown>
                <app-goldeye-listbox
                    label="Property"
                    name="propertyId"
                    [options]="form.dd.propertyDropdown"
                    [model]="form.propertyAccess.propertyIds"
                    (modelChange)="form.propertyAccess.setPropertyIds($event)"
                >
                </app-goldeye-listbox>
                <!-- <app-goldeye-dropdown
                    label="Property"
                    name="propertyId"
                    [filter]="true"
                    [options]="form.dd.propertyDropdown"
                    [disabled]="disabled?.includes('propertyId')"
                    [model]="form.propertyAccess.propertyId"
                    (change)="form.propertyAccess.setPropertyId($event)"
                >
                </app-goldeye-dropdown> -->

                <app-goldeye-dropdown
                    label="Access Status"
                    name="accessStatusValue"
                    [disabled]="form.propertyAccess.isNew"
                    [options]="form.dd.propertyAccessStatusDropdown"
                    [model]="form.propertyAccess.accessStatusValue"
                    (change)="form.propertyAccess.setAccessStatus($event)"
                >
                </app-goldeye-dropdown>
                <app-goldeye-dropdown
                    label="Access Type"
                    name="accessTypeValue"
                    [options]="form.dd.propertyAccessTypeDropdown"
                    [model]="form.propertyAccess.accessTypeValue"
                    (change)="form.propertyAccess.setAccessType($event)"
                >
                </app-goldeye-dropdown>
                <app-one-month-with-time-calendar
                    label="Active Date"
                    name="activeDate"
                    [value]="form.propertyAccess.activeDateParsed"
                    (selection)="form.propertyAccess.setActiveDate($event)"
                >
                </app-one-month-with-time-calendar>
                <app-one-month-with-time-calendar
                    label="Expire Date"
                    name="expireDate"
                    [value]="form.propertyAccess.expireDateParsed"
                    (selection)="form.propertyAccess.setExpireDate($event)"
                >
                </app-one-month-with-time-calendar>
            </div>
        </div>

        <app-goldeye-form-controls
            [disabled]="form.propertyAccess.isBusy"
            (save)="saveClicked.emit()"
            (cancel)="cancelClicked.emit()"
        >
        </app-goldeye-form-controls>
    </form>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
