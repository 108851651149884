import { FeatureGroups } from '../notifications/models/featureGroups/feature-group-enum.model';

export const RoutesNames = {
    BASE: '/residential',

    EDIT_LEASE: 'leases/:id/edit',
    SHOW_LEASE: 'leases/:id',
    LEASES: 'leases',

    SHOW_PROPERTY: 'properties/:id',
    PROPERTY_BOARD: 'properties/:id/board',
    PROPERTY_BOARD_OPEN_ACTION_ITEM: 'properties/:id/board/action-item/:actionItemId',
    PROPERTY_DASHBOARD: 'properties/:id/dashboard',
    PROPERTIES: 'properties',
    Temp: '/no-layout/test',

    PROPERTY_GROUPS: 'property-groups',
    NEW_PROPERTY_GROUP: 'property-groups/new',

    SHOW_AMENITY: 'amenities/:id',
    AMENITIES: 'amenities',

    RESIDENTIAL_DASHBOARD: 'dashboard',

    SHOW_TENANT: 'tenants/:id',
    TENANT_DASHBOARD: 'tenants/:id/dashboard',
    EDIT_TENANT: 'tenants/:id/edit',
    TENANTS: 'tenants',

    SHOW_UNIT: 'units/list/:id',
    EDIT_UNIT: 'units/list/:id/edit',
    SHOW_UNIT_HISTORY_DETAIL: 'units/:id/history/:historyId/detail',
    EDIT_UNIT_HISTORY: 'units/:id/history/:historyId/edit',
    SHOW_UNIT_HISTORY: 'units/:id/history',
    RECENT_UNIT_HISTORY: 'units/history/recent',
    UNITS: 'units/list',

    VACANCIES_DASHBOARD: 'vacancies/:id/dashboard',
    VACANCIES_PENDING: 'vacancies/moveouts',
    VACANCIES_REHAB: 'vacancies/rehabs',
    VACANCIES: 'vacancies',



    COMMON_AREAS: 'common-areas',
    EDIT_COMMON_AREA: 'common-areas/edit/:id',
    NEW_COMMON_AREA: 'common-areas/new',

    RESIDENTIAL_CONTACT_GROUPS: 'notifications/contactgroups/' + 'residential',
    RESIDENTIAL_NEW_CONTACT_GROUP:
        'notifications/contactgroups/new/' + 'residential',
    RESIDENTIAL_EDIT_CONTACT_GROUP:
        'notifications/contactgroups/edit/:id/' + 'residential',
    RESIDENTIAL_NOTIFICATIONS: 'notifications',

    RESIDENTIAL_NOTIFICATION_TYPES: 'notifications/types/' + 'residential'
};
