import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appFocusIf]'
})
export class FocusIfDirective implements OnChanges {
    @Input() appFocusIf: boolean;

    constructor(private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appFocusIf && changes.appFocusIf.currentValue) {
            this.el.nativeElement.focus();
        }
    }
}
