import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';

@Component({
    selector: 'app-goldeye-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnInit {
    @Input() title: string;
    @Input() icon: string;
    @Input() iconColor = '#71b2c1';

    @Input() titleSize?: string;
    titleClass = '';

    @Input() controlSize?: string;
    controlClass = '';

    ngOnInit() {
        this.titleSize = this.titleSize || 'p-md-6';
        this.titleClass = `p-col-12 ${this.titleSize} page-header__title`;

        this.controlSize = this.controlSize || 'p-md-6';
        this.controlClass = `p-col-12 ${this.controlSize} page-header__controls`;
    }
}
