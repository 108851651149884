
import { GrantStatus } from '@app/core/models';

export class UserRoleLookup implements IUserRoleLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
    displayOrder: number;
    statusId: GrantStatus;
    displayStatus: string;
}

export class IUserRoleLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
    displayOrder: number;
    statusId: GrantStatus;
    displayStatus: string;
}
