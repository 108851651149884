import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ChangeDetectionStrategy
} from '@angular/core';
import { IWorkOrderSearchModel } from '@app/modules/operations/models/work-orders/work-order-search.model';
import { Subject, Subscription } from 'rxjs';
import { applySnapshot, getSnapshot, clone } from 'mobx-state-tree';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { IWorkOrderSearchTermsModel } from '@app/modules/operations/models/work-orders/work-order-search-terms.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-work-order-search',
    templateUrl: './work-order-search.component.html',
    styleUrls: ['./work-order-search.component.scss']
})
export class WorkOrderSearchComponent implements OnInit, OnDestroy {
    @Input() search: IWorkOrderSearchModel;
    @Input() hiddenFilters: Array<string> = [];

    termsCopy: IWorkOrderSearchTermsModel;

    initialTerms: any;
    showDialog = false;
    workOrderNumberTextChanged = new Subject<string>();
    workOrderNumberTextChanged$: Subscription;

    constructor() {}

    ngOnInit() {
        this.initialTerms = getSnapshot(this.search.terms);
        this.workOrderNumberTextChanged$ = this.workOrderNumberTextChanged
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .pipe(tap(this.search.terms.setWorkOrderNumber))
            .subscribe((_) => {});
    }

    onMoreBtnClick() {
        this.termsCopy = clone(this.search.terms);
        this.showDialog = true;
    }

    onSearchBtnClick(newState) {
        applySnapshot(this.search.terms, getSnapshot(newState));
        this.showDialog = false;
    }

    onCancelBtnClick() {
        this.showDialog = false;
    }

    onWorkOrderKeyUp($event) {
        this.workOrderNumberTextChanged.next($event.target.value);
    }

    onClearBtnClick() {
        applySnapshot(this.search.terms, this.initialTerms || {});
    }

    onDialogHidden() {
        this.showDialog = false;
    }

    ngOnDestroy() {
        if (this.workOrderNumberTextChanged$) {
            this.workOrderNumberTextChanged$.unsubscribe();
        }
    }
}
