import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class SignalRService implements OnDestroy {
    private readonly _baseUrl: string =
        environment.appConfig.baseUrl.replace('/api/', '') + '/hub/'; // e.g. broadcast/';

    private hubConnectionList = new BehaviorSubject<{
        [hubName: string]: HubConnection;
    }>(null);
    hubConnections = this.hubConnectionList.asObservable();

    constructor() {}

    // e.g. hubName = broadcast';
    init(hubName: string) {
        if (!this.getHubConnection(hubName)) {
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(this._baseUrl + hubName)
                .configureLogging(signalR.LogLevel.Information)
                .build();

            hubConnection
                .start()
                .then(_ => {
                    if (!this.getHubConnection(hubName)) {
                        this.hubConnectionList.next({
                            [hubName]: hubConnection
                        });
                    } else {
                        this.hubConnectionList.value[hubName] = hubConnection;
                    }
                })
                .catch(err => console.error(err.toString()));
        }
    }

    getHubConnection(hubName: string) {
        if (
            this.hubConnectionList.value &&
            this.hubConnectionList.value[hubName].state ===
                HubConnectionState.Connected
        ) {
            return this.hubConnectionList.value[hubName];
        }

        return null;
    }

    ngOnDestroy() {
        if (this.hubConnectionList) {
            for (const hubName in this.hubConnectionList.value) {
                if (this.hubConnectionList.value.hasOwnProperty(hubName)) {
                    const hubConnection = this.hubConnectionList.value[hubName];

                    hubConnection.stop();
                }
            }
        }
    }
}
