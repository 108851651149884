import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { SelectItem } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-goldeye-listbox',
    templateUrl: './listbox.component.html',
    styleUrls: ['./listbox.component.scss']
})
export class ListboxComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() options: Array<ISelectItem | SelectItem>;
    @Input() disabled = false;
    @Input() filter = true;
    @Input() required = false;

    @Input() labelClass?: string;
    @Input() inputClass?: string;

    @Input() model: Array<number> | Array<string>;
    @Output() modelChange = new EventEmitter<any[]>();

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        this.modelChange.emit(event.value);
    }
}
