import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import { of } from 'rxjs';

import {
    IUser,
    IUserEntity,
    IUserRole,
    WorkRegion,
    IUserAllowedFeature,
    ITreeNode,
    UserRole,
    GrantStatus,
    MyAccount,
    IUserChannel,
    IUserRegistration
} from '@app/core/models';
import { IUserListItem, IFeature } from '@app/modules/admin/models';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginDefaultPage } from '@app/core/models/user/login-default-page';

// handy snippet:
// tap(data => console.log('output some data: ' + JSON.stringify(data)))

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private baseUrl = environment.appConfig.baseUrl;

    constructor(private http: HttpClient, private router: Router) {}

    // Gets the full user view model with Active roles ONLY and features
    // TODO: Verify this id check and initializeUserViewModel is still needed.
    getUserViewModel(id: number) {
        const allUserModel = this.getUserViewModelWithAllRoles(id).pipe(
            map((m) => {
                m.userRoles = this.getActiveUserRolesOnly(m.userRoles);
                return m;
            })
        );

        return allUserModel;
    }

    getActiveUserRolesOnly(userRoles: Array<IUserRole>) {
        if (userRoles) {
            return userRoles.filter((r) => r.statusId === GrantStatus.Granted);
        }
    }

    // Gets the full user view model with ALL roles and features
    getUserViewModelWithAllRoles(id: number) {
        const url = `${this.baseUrl}users/${id}/viewmodel`;
        return id === 0 ? of(this.initializeUserViewModel()) : this.http.get<IUser>(url);
    }

    // TODO: Verify this id check and initializeUserEntity is still needed.
    getUserEntity(id: number) {
        const url = `${this.baseUrl}users/${id}/lookup`;
        return id === 0 ? of(this.initializeUserEntity()) : this.http.get<IUserEntity>(url);
    }

    getMyAccountViewModel(userEmail: string) {
        const url = `${this.baseUrl}users/${userEmail}/myaccountviewmodel`;
        return this.http.get<MyAccount>(url);
    }

    // Used by loading component to see if a user exists
    getUserEntityByEmail(email: string) {
        const url = `${this.baseUrl}users/${email}/email`;
        return this.http.get<IUserEntity>(url);
    }

    // Gets list of user view models for grids
    getUserList() {
        const url = `${this.baseUrl}users/list`;
        return this.http.get<IUserListItem[]>(url);
    }

    getUserAvailableRoles(userId: number) {
        const url = `${this.baseUrl}users/${userId}/availableroles`;
        return this.http.get<IUserRole[]>(url);
    }

    getUserAllowedFeatures(userEmail: string) {
        const url = `${this.baseUrl}userallowedfeatures/${userEmail}/email`;
        return this.http.get<IUserAllowedFeature[]>(url);
    }

    getUsersFeatureOverrides(userId: number) {
        const url = `${this.baseUrl}userallowedfeatures/${userId}/GetUsersFeatureOverrides`;
        return this.http.get<IFeature[]>(url);
    }

    // specialized way to save a user without any roles or features
    registerUser(newUser: IUserEntity) {
        const url = `${this.baseUrl}users/register`;
        return this.http.post<IUserEntity>(url, newUser);
    }

    updateUserInfoOnRegister(user: IUserRegistration) {
        const url = `${this.baseUrl}users/updateuseronregister`;
        return this.http.post<IUserEntity>(url, user);
    }

    saveUserFeatureOverrides(userId: number, selectedFeatures: ITreeNode[], loggedInUserEmail: string) {
        const url = `${this.baseUrl}userallowedfeatures/UserFeatureOverrides`;

        return this.http.put<ITreeNode[]>(url, {
            userId: userId,
            featureOverrides: selectedFeatures,
            loggedInUserEmail: loggedInUserEmail
        });
    }

    saveUserRoles(userId: number, selectedRoles: UserRole[], loggedInUserEmail: string) {
        const url = `${this.baseUrl}users/${userId}/roles`;

        return this.http.put<UserRole[]>(url, {
            userRoles: selectedRoles,
            loggedInUserEmail: loggedInUserEmail
        });
    }

    saveAllUserChanges(user: IUser) {
        const url = `${this.baseUrl}users/${user.id}`;
        return this.http.put<IUser>(url, user);
    }

    saveAllUserProfileChanges(user: MyAccount) {
        const url = `${this.baseUrl}users/${user.id}/profileviewmodel`;
        return this.http.put<MyAccount>(url, user);
    }

    saveUserNotificationSettings(userId: number, model: IUserChannel[]) {
        const url = `${this.baseUrl}users/${userId}/usernotifications`;

        return this.http.put<IUserChannel[]>(url, model);
    }

    saveCurrentRouteAsDefaultLogin(userId: number) {
        return this.saveRouteAsDefaultLogin(userId, this.router.url);
    }

    saveRouteAsDefaultLogin(userId: number, route: string) {
        const url = `${this.baseUrl}users/${userId}/logindefaultpage`;

        return this.http.put<LoginDefaultPage>(url, { route });
    }

    private initializeUserEntity(): IUserEntity {
        return {
            id: 0,
            email: '',
            isActive: false,
            dateRegistered: null,
            isLocked: false,
            dateLocked: null,
            dateDeactivated: null,
            firstName: '',
            lastName: '',
            jobTitle: '',
            mobileNumber: '',
            directNumber: '',
            hrEmployeeIdentifier: '',
            securityNote: '',
            workRegionId: 0,
            workRegion: new WorkRegion(),
            workRegionNote: '',
            createdBy: '',
            createdDate: new Date(),
            modifiedBy: '',
            modifiedDate: new Date(),
            isArchived: false,
            loginStartPage: null
        };
    }

    private initializeUserViewModel(): IUser {
        const userAttributes = {
            userId: 0,
            workRegionId: 0,
            createdBy: '',
            createdDate: new Date(),
            dateDeactivated: new Date(),
            dateLocked: new Date(),
            dateRegistered: new Date(),
            directNumber: '',
            email: '',
            firstName: '',
            hrEmployeeIdentifier: '',
            defaultSkillDescription: '',
            isActive: false,
            isArchived: false,
            isLocked: false,
            jobTitle: '',
            lastName: '',
            mobileNumber: '',
            modifiedBy: '',
            modifiedDate: new Date(),
            securityNote: '',
            workRegion: '',
            workRegionNote: '',
            isAdminUser: false
        };

        return {
            id: 0,
            attributes: userAttributes,
            userRoles: [],
            userFeatures: [],
            userChannels: []
        };
    }
}
