import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-goldeye-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
    @Input() containerClass = '';
    @Input() model: number;
    @Input() starCount = 5;
    @Input() tooltipText?: string;
    @Input() tooltipPosition?: string;
    @Input() readOnly: boolean;
    @Input() disabled: boolean;
    @Input() canCancel = true;
    @Input() isHidden?: boolean;
    @Input() onClass = 'fas fa-star';
    @Input() offClass = 'far fa-star';
    @Input() cancelClass = 'fas fa-ban';

    @Output() modelChange = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {}

    onRate(evt, value: number) {
        evt.stopPropagation();
        this.modelChange.emit(value);
    }

    onCancel(evt) {
        evt.stopPropagation();
        this.modelChange.emit(0);
    }
}
