<p-table
    *mobxAutorun
    [responsive]="true"
    [value]="grid.items"
    [columns]="columns"
    [rowHover]="true"
    [loading]="grid.loading"
>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length + 1">
                No records found
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{ col.header }}
            </th>
            <th colspan="1">Actions</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr
            [pSelectableRow]="rowData"
            (dblclick)="editBtnClick.emit(rowData.id)"
        >
            <td *ngFor="let col of columns">
                <app-grid-column
                    [column]="col"
                    [row]="rowData"
                ></app-grid-column>
            </td>
            <td>
                <button
                    pButton
                    type="button"
                    label="Edit"
                    icon="fa fa-edit"
                    style="width: 100%;"
                    (click)="editBtnClick.emit(rowData.id)"
                ></button>
            </td>
        </tr>
    </ng-template>
</p-table>
