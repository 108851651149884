/* eslint-disable @typescript-eslint/naming-convention */
import {
    flow,
    getEnv,
    getSnapshot,
    IAnyStateTreeNode,
    IModelType,
    Instance,
    ModelProperties,
    onPatch,
    types
} from 'mobx-state-tree';
import { dropdownProperties } from './dropdown-helper';
import { toUrl } from './url-helper';
import { applyPartialSnapshot } from './mobx-utils';
import * as moment from 'moment';
import { PaginatedQuery } from '@app/core/models';

export interface ISearchModel {
    matchAll?: ISearchTerm;
    inEffect: boolean;
    canBeCleared: any;
    description: any;
    query: any;
    setMatchAll: any;
}

/**
 * To be used with forms that have a property locations widget
 * @param locationsGetter Getter used for obtaining the property locations
 * @param propertyDropdown Getter used for obtaining the Property dropdown
 * @param unitDropdown Getter used for obtaining the Unit dropdown
 */
export function withPropertyLocations(locationsGetter, propertyDropdown, unitDropdown) {
    return types
        .model({})
        .actions((self) => ({
            setPropertyLocations(selections) {
                if (selections !== null) {
                    locationsGetter(self).replace(selections);
                }
            }
        }))
        .views((self) => ({
            get unitDictionary() {
                const unitDictionary = {};

                if (unitDropdown(self)) {
                    unitDropdown(self).forEach((u) => {
                        unitDictionary[u.value] = u;
                    });
                }

                return unitDictionary;
            },
            get propertyDictionary() {
                const propertyDictionary = {};

                if (propertyDropdown(self)) {
                    propertyDropdown(self).forEach((u) => {
                        propertyDictionary[u.value] = u;
                    });
                }

                return propertyDictionary;
            }
        }));
}

/**
 * To be used with forms that have a common area locations widget
 * @param locationsGetter Getter used for obtaining the common area locations
 * @param propertyDropdown Getter used for obtaining the common area dropdown
 * @param commonAreaDropdown Getter used for obtaining the Common Areas dropdown
 */
export function withCommonAreasInvolved(locationsGetter, propertyDropdown, commonAreaDropdown) {
    return types
        .model({})
        .actions((self) => ({
            setCommonAreaLocations(selections) {
                if (selections !== null) {
                    locationsGetter(self).replace(selections);
                }
            }
        }))
        .views((self) => ({
            get commonAreaDictionary() {
                const commonAreaDictionary = {};

                if (commonAreaDropdown(self)) {
                    commonAreaDropdown(self).forEach((u) => {
                        commonAreaDictionary[u.value] = u;
                    });
                }

                return commonAreaDictionary;
            },
            get propertyDictionary() {
                const propertyDictionary = {};

                if (propertyDropdown(self)) {
                    propertyDropdown(self).forEach((u) => {
                        propertyDictionary[u.value] = u;
                    });
                }

                return propertyDictionary;
            }
        }));
}

/**
 * Represents a RESTFUL resource
 * @param endPoint API endpoint, could be either a string or a function
 * @param typeDef  Model of item that will get posted to the backend,
 * if none provided getSnapshot will be used
 */
export function resource<T extends ModelProperties, O, FC, FS>(endPoint, typeDef: IModelType<T, O, FC, FS>, alternateGetEndpoint?) {
    return types
        .compose(
            typeDef,
            types.model({
                apiStatus: types.optional(
                    types.enumeration(['loaded', 'loading', 'saving', 'saved', 'deleting', 'deleted', 'error']),
                    'loaded'
                )
            })
        )
        .views((self) => ({
            getResourceEndPoint(id: number) {
                if (typeof endPoint === 'function') {
                    return endPoint(self) + (id ? `/${id}` : '');
                }
                return endPoint + (id ? `/${id}` : '');
            },
            get isNew() {
                return self.id === 0;
            },
            get isBusy() {
                return self.apiStatus === 'saving' || self.apiStatus === 'deleting';
            },
            get isLoading() {
                return self.apiStatus === 'loading';
            },
            get noErrors() {
                return self.apiStatus !== 'error';
            }
        }))
        .views((self) => ({
            getResourceGetEndPoint(id: number) {
                if (alternateGetEndpoint) {
                    if (typeof alternateGetEndpoint === 'function') {
                        return alternateGetEndpoint(self);
                    }
                    return alternateGetEndpoint;
                }
                return self.getResourceEndPoint(id);
            }
        }))
        .actions((self: IAnyStateTreeNode) => ({
            load: flow(function* () {
                if (self.id !== 0) {
                    try {
                        self.apiStatus = 'loading';
                        const url = `${toUrl(self.getResourceGetEndPoint(self.id))}`;
                        const { http } = getEnv(self);
                        const result = yield http.get(url).toPromise();
                        applyPartialSnapshot(self, result);
                    } catch (ex) {
                        self.apiStatus = 'error';
                        throw ex;
                    } finally {
                        if (self.noErrors) {
                            self.apiStatus = 'loaded';
                        }
                    }
                } else {
                    self.apiStatus = 'loaded';
                }
            }),
            save: flow(function* () {
                try {
                    self.apiStatus = 'saving';
                    const url = `${toUrl(self.getResourceEndPoint())}`;
                    const { http } = getEnv(self);
                    return yield http.post(url, getSnapshot(self)).toPromise();
                } catch (ex) {
                    self.apiStatus = 'error';
                    throw ex;
                } finally {
                    if (self.noErrors) {
                        self.apiStatus = 'saved';
                    }
                }
            }),
            update: flow(function* () {
                try {
                    self.apiStatus = 'saving';
                    const url = `${toUrl(self.getResourceEndPoint(self.id))}`;
                    const { http } = getEnv(self);
                    return yield http.put(url, getSnapshot(self)).toPromise();
                } catch (ex) {
                    self.apiStatus = 'error';
                    throw ex;
                } finally {
                    if (self.noErrors) {
                        self.apiStatus = 'saved';
                    }
                }
            }),
            delete: flow(function* () {
                try {
                    self.apiStatus = 'deleting';
                    const url = `${toUrl(self.getResourceEndPoint(self.id))}`;
                    const { http } = getEnv(self);
                    return yield http.delete(url).toPromise();
                } catch (ex) {
                    self.apiStatus = 'error';
                    throw ex;
                } finally {
                    if (self.noErrors) {
                        self.apiStatus = 'deleted';
                    }
                }
            })
        }));
}

export function withBarChart<T>() {
    return types
        .model({})
        .volatile((_) => ({
            xLabeler: (__: T[]): string[] => [],
            grouper: (__: T[]): { [key: string]: T[] } => ({}),
            backgroundColor: (__: string): string => null,
            borderColor: (__: string): string => null,
            dataGenerator: (__: T[]): number[] => null
        }))
        .views((self) => ({
            data(items: T[]) {
                return {
                    labels: self.xLabeler(items),
                    datasets: Object.entries(self.grouper(items)).map((group) => ({
                        label: group[0],
                        backgroundColor: self.backgroundColor(group[0]),
                        borderColor: self.borderColor(group[0]),
                        data: self.dataGenerator(group[1])
                    }))
                };
            }
        }));
}

/**
 * Mixin to be used with any model that requires the total list
 * of items matching a query (like the grids)
 * @param endPoint API endpoint to use for request the count
 */
export function withCount(endPoint) {
    return types
        .model({
            totalRecords: 0,
            apiStatus: types.optional(types.enumeration(['loaded', 'loading']), 'loaded')
        })
        .views((self) => ({
            getCountEndPoint() {
                if (typeof endPoint === 'function') {
                    return endPoint(self);
                }
                return endPoint;
            },
            get isBusy() {
                return self.apiStatus === 'loading';
            }
        }))
        .actions((self) => ({
            fetchCount: flow(function* (query) {
                const url = toUrl(self.getCountEndPoint(), { ...query });
                const { http } = getEnv(self);
                if (http) {
                    self.apiStatus = 'loading';
                    const response = yield http.get(url).toPromise();
                    self.totalRecords = response;
                    self.apiStatus = 'loaded';
                }
            })
        }));
}

/**
 * Mixin to be used with any model that have a list of items
 * (like grids, calendars, etc)
 * @param endPoint API endpoint
 * @param typeDef  Model of items
 */
export function withItems<T extends ModelProperties, O, FC, FS>(endPoint, typeDef: IModelType<T, O, FC, FS>) {
    return types
        .model({
            items: types.array(typeDef),
            apiStatus: types.optional(types.enumeration(['loaded', 'loading']), 'loaded')
        })
        .views((self) => ({
            get isBusy() {
                return self.apiStatus === 'loading';
            },
            getItemsEndPoint() {
                if (typeof endPoint === 'function') {
                    return endPoint(self);
                }
                return endPoint;
            }
        }))
        .actions((self) => ({
            addItem: function (item) {
                if (item) {
                    self.items.push(item);
                }
            },
            removeItem: function (item) {
                if (self.items && item) {
                    self.items.remove(item);
                }
            },
            fetchItems: flow(function* (query = {}) {
                const url = toUrl(self.getItemsEndPoint(), { ...query });
                const { http } = getEnv(self);
                if (http) {
                    self.apiStatus = 'loading';
                    const response = yield http.get(url).toPromise();

                    self.items = response;
                    self.items.forEach((i: IAnyStateTreeNode) => (i.apiStatus = 'loaded'));
                    self.apiStatus = 'loaded';
                }
            })
        }));
}

/**
 * Mixin to be used with any model that have a list of items that we want to update
 * (like grids, calendars, etc)
 */
export function withUpdatableItems<T extends ModelProperties, O, FC, FS>(endPoint, typeDef: IModelType<T, O, FC, FS>) {
    return types.compose(
        withItems(endPoint, typeDef),
        types
            .model({
                apiStatus: types.optional(types.enumeration(['loaded', 'loading', 'saving', 'saved', 'error']), 'loaded')
            })
            .views((self) => ({
                get noErrors() {
                    return self.apiStatus !== 'error';
                }
            }))
            .actions((self: IAnyStateTreeNode) => ({
                update: flow(function* () {
                    try {
                        const { items, getItemsEndPoint } = self;
                        self.apiStatus = 'saving';
                        const url = `${toUrl(getItemsEndPoint())}`;
                        const { http } = getEnv(self);
                        return yield http.put(url, getSnapshot(items)).toPromise();
                    } catch (ex) {
                        self.apiStatus = 'error';
                        throw ex;
                    } finally {
                        if (self.noErrors) {
                            self.apiStatus = 'saved';
                        }
                    }
                })
            }))
    );
}

/**
 * Mixin to be used with any model that have a list of items
 * (like grids, calendars, etc)
 * @param endPoint API endpoint
 * @param typeDef  Model of items
 */
export function withItemsAndFrontEndSorting(endPoint: string, typeDef) {
    return withItems(endPoint, typeDef).extend((self) => ({
        actions: {
            sortByField: ({ field, order }) => {
                let sortResult = 0;
                if (field) {
                    const result = self.items.sort((a, b) => {
                        const value = a[field];
                        const fieldType = typeof value;

                        switch (fieldType) {
                            case 'string':
                                const trySortDate = sortIfDates(a[field], b[field], order);

                                if (trySortDate === null) {
                                    sortResult = sortString(a[field], b[field], order);
                                } else {
                                    sortResult = trySortDate;
                                }
                                break;
                            case 'number':
                                sortResult = sortNumbers(a[field], b[field], order);
                                break;

                            default:
                                sortResult = 0;
                                break;
                        }

                        return sortResult;
                    });

                    self.items = result;
                }
            }
        }
    }));
}

function sortIfDates(str1: string, str2: string, sortOrder: number): number {
    let sortResult = null;
    const date1 = moment(str1);
    const date2 = moment(str2);

    if (!date1.isValid() && !date2.isValid()) {
        return null; // return null if nothing is a date
    } else {
        if (sortOrder === 1) {
            if (date1.isValid() && !date2.isValid()) {
                sortResult = 1;
            } else if (!date1.isValid() && date2.isValid()) {
                sortResult = -1;
            }

            // asc
            sortResult = sortDates(date1.toDate(), date2.toDate());
        } else {
            if (date1.isValid() && !date2.isValid()) {
                sortResult = -1;
            } else if (!date1.isValid() && date2.isValid()) {
                sortResult = 1;
            }

            // desc
            sortResult = sortDates(date2.toDate(), date1.toDate());
        }
    }

    return sortResult;
}

function sortDates(date1: Date, date2: Date) {
    let sortResult = 0;
    if (date1 < date2) {
        sortResult = -1;
    } else if (date1.getTime() === date2.getTime()) {
        sortResult = 0;
    } else {
        sortResult = 1;
    }

    return sortResult;
}

function sortString(str1: string, str2: string, sortOrder: number): number {
    let sortResult = str1.localeCompare(str2);

    if (sortOrder === -1) {
        sortResult = str2.localeCompare(str1);
    }

    return sortResult;
}

function sortNumbers(num1: number, num2: number, sortOrder: number): number {
    let sortResult = num1 - num2;

    if (sortOrder === -1) {
        sortResult = num2 - num1;
    }

    return sortResult;
}

/**
 * Creates a generic paginator type which can be used on any
 * grid.
 * @param defaultProperty Default sorting property
 * @param defaultOrder Default sorting order
 * @param itemsPerPage Items per page, 0 means that all records should be fetched
 */
export function withPaginator(sortField: string, sortOrder: number, rows: number = 10, first: number = 0, showPages: boolean = true) {
    return types.model({
        paginator: types.optional(
            types
                .model({
                    sortField: types.string,
                    sortOrder: types.number,
                    rows: types.number,
                    first: types.number,
                    showPages: types.boolean
                })
                .views((self) => ({
                    get query(): PaginatedQuery {
                        return {
                            itemsPerPage: self.rows,
                            page: self.first === 0 ? 1 : self.first / self.rows + 1,
                            orderByProperty: self.sortField,
                            orderDirection: self.sortOrder
                        };
                    }
                })),
            { sortField, sortOrder, rows, first, showPages }
        )
    });
}

/**
 * Mixin to be used with any model that has dropdowns.
 */
export function withDropdowns() {
    return types.model({ dropdownsReady: false }).actions((self) => ({
        fetchDropdowns: flow(function* () {
            try {
                self.dropdownsReady = false;
                const dropdowns = { dropdowns: dropdownProperties(self) };
                const url = toUrl('dropdown', dropdowns);
                const { http } = getEnv(self);
                const response = yield http.get(url).toPromise();
                applyPartialSnapshot(self, response);
            } finally {
                self.dropdownsReady = true;
            }
        })
    }));
}

/**
 * Mixin to be used with any model that wants to keep track of its changes.
 * @param isInitialized  A predicate indicating when should we start listing for changes.
 * @param ignoreRules  An optional blacklist with the changes we want to ignore, for
 * each blacklist item we can specify if we want to ignore the change just once or
 * multiple times.
 *  [{
 *      pattern: new RegExp('/modified'),
 *      ignoreOnce: true
 *  }, {
 *      pattern: new RegExp('apiStatus$'),
 * }]
 */
export function withModifiedWatch(isInitialized, ignoreRules = []) {
    ignoreRules = ignoreRules.concat({ pattern: new RegExp('/modified') });

    const shouldBeIgnored = (changePath) =>
        ignoreRules.reduce((a, c) => {
            if (c.pattern.test(changePath)) {
                if (c.ignoreOnce) {
                    c.ignoreOnce = false;
                    return a;
                }
                return a || true;
            }
            return a;
        }, false);

    return types
        .model({
            modified: false
        })
        .actions((self) => ({
            setModified() {
                self.modified = true;
            }
        }))
        .actions((self) => ({
            afterCreate() {
                onPatch(self, (patch) => {
                    if (isInitialized(self) && !shouldBeIgnored(patch.path)) {
                        self.setModified();
                    }
                });
            }
        }));
}

/**
 * Wrapper around typeDef used to define search terms that can be disabled.
 * @param typeDef type definition to wrap
 */
export function searchTermOf(typeDef, ignoreFalseValue = false) {
    return types.optional(
        types
            .model({
                value: types.maybeNull(typeDef),
                disabled: false,
                ignoreFalseValue: ignoreFalseValue
            })
            .actions((self) => ({
                setValue(value) {
                    self.value = value;
                }
            })),
        {
            value: undefined,
            disabled: false,
            ignoreFalseValue: ignoreFalseValue
        }
    );
}

const searchTermType = searchTermOf(types.union(types.string, types.number, types.boolean, types.Date, types.integer));
export interface ISearchTerm extends Instance<typeof searchTermType> {}
