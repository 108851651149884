import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ValidationMessagesService } from '@app/core/services/validation/validation-messages.service';
import { MessageService } from 'primeng/api';
import { IActionItemFormStore } from '@app/modules/residential/stores/action-items/action-item-form.store';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { convertPascalCaseToASentence } from '@app/shared/utils/app-string-helper';
import { ActionItemTypes } from '@app/modules/property-team/enums/action-item-types';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-action-item-dialog',
    templateUrl: './action-item-dialog.component.html',
    styleUrls: ['./action-item-dialog.component.scss']
})
export class ActionItemDialogComponent implements OnInit {
    @Input() store: IActionItemFormStore;
    @Input() dialogVisible = false;
    @Input() editTabSelected = false;
    @Input() properties?: ISelectItem[];
    @Output() closeAction = new EventEmitter<void>();

    constructor(private validationMessageService: ValidationMessagesService, private messageService: MessageService) {}

    ngOnInit() {}

    async saveComment(commentForm = this.store.commentForm) {
        if (commentForm && commentForm.comment && commentForm.comment.isValid) {
            await commentForm.comment.save();
            if (!commentForm.comment.noErrors) {
                this.messageService.add({
                    severity: 'Error',
                    summary: 'Comment was not saved',
                    detail: 'The comment was not created successfully. Please try again.'
                });
            }
        }
    }

    async reloadStore() {
        await this.store.reinitializeStore();
    }

    private finishSave() {
        this.validationMessageService.clearValidationMessages();
        this.closeAction.emit();
    }

    async onSaveBtnClicked(parent = { form: this.store.form, commentForm: this.store.commentForm }) {
        const { form, commentForm } = parent;
        const action = form.model.isNew ? form.model.save : form.model.update;
        const savedEntity = await action();
        if (form.model.noErrors) {
            commentForm.comment.setRelatedToId(savedEntity.id);
            await this.saveComment(commentForm);
            this.finishSave();
        }
    }

    onCancelBtnClicked() {
        this.closeAction.emit();
    }

    formatType(type: number) {
        if (type in ActionItemTypes) {
            return convertPascalCaseToASentence(ActionItemTypes[type]);
        }
    }
}
