import { Component, OnInit } from '@angular/core';
import { ValidationMessagesService } from '@app/core/services/validation/validation-messages.service';
import { Observable } from 'rxjs';
import { ErrorMessage } from '@app/core/errors';

@Component({
    selector: 'app-validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {
    validatonMessages$: Observable<ErrorMessage[]>;

    constructor(private validationMessagesService: ValidationMessagesService) {}

    ngOnInit() {
        this.validatonMessages$ = this.validationMessagesService.getValidationMessages();
    }
}
