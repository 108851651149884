export interface INavMenu {
    Label: string;
    Icon: string;
    RouterLink: string;
    Title: string;
    Items: NavMenuItem[];
}

export class NavMenu {
    Label: string;
    Icon: string;
    RouterLink: string;
    Title: string;
    Items: NavMenuItem[];
}

export class NavMenuItem {
    Label: string;
    Icon: string;
    Title: string;
    RouterLink: string;
    items: NavMenuItem[];
}
