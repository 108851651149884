import { FormGroup } from '@angular/forms';
import { Message } from 'primeng/api';
// Generic validator for Reactive forms
// Implemented as a class, not a service, so it can retain state for multiple forms.
export class GenericValidator {
    // Provide the set of valid validation messages
    // Stucture:
    // controlName1: {
    //     validationRuleName1: 'Validation Message.',
    //     validationRuleName2: 'Validation Message.'
    // },
    // controlName2: {
    //     validationRuleName1: 'Validation Message.',
    //     validationRuleName2: 'Validation Message.'
    // }
    constructor(
        private messageLookup: { [key: string]: { [key: string]: Message } }
    ) {}

    // Processes each control within a FormGroup
    // And returns a set of validation messages to display
    // Structure
    // controlName1: 'Validation Message.',
    // controlName2: 'Validation Message.'
    processMessages(container: FormGroup): Message[] {

        const returnMsgs: Message[] = [];
        const messages = {};
        for (const controlKey in container.controls) {
            if (container.controls.hasOwnProperty(controlKey)) {
                const c = container.controls[controlKey];

                // If it is a FormGroup, process its child controls.
                if (c instanceof FormGroup) {
                    const childMessages = this.processMessages(c);
                    Object.assign(messages, childMessages);
                } else {
                    // Only validate if there are validation messages for the control
                    if (this.messageLookup[controlKey]) {
                        messages[controlKey] = '';
                        if ((c.dirty || c.touched) && c.errors) {
                            Object.keys(c.errors).map(messageKey => {
                                if (
                                    this.messageLookup[controlKey][messageKey]
                                ) {
                                    returnMsgs.push({
                                        severity: this.messageLookup[controlKey][messageKey].severity,
                                        summary: this.messageLookup[controlKey][messageKey].summary,
                                        detail: this.messageLookup[controlKey][messageKey].detail
                                    });
                                }
                            });
                        }
                    }
                }
            }
        }
        return returnMsgs;

    }
}
