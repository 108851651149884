import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';

@Component({
    selector: 'app-goldeye-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() placeholder?: string;
    @Input() containerClass = '';
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() focus?: boolean;
    @Input() model: string;
    @Input() disabled: boolean;
    @Input() isHidden: boolean;
    @Input() required = false;

    @Output() change = new EventEmitter<string>();
    @Output() blur = new EventEmitter<boolean>();

    @Input() fieldAccess?: IFieldAccessModel;

    ngOnInit() {
        this.placeholder = this.placeholder || '';
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        event.stopPropagation();
        this.change.emit(event.target.value);
    }

    onBlur(event) {
        event.stopPropagation();
        if (this.blur) {
            this.blur.emit(event.target.value);
        }
    }
}
