export interface IUserAttributes {
    userId: number;
    workRegionId: number;

    createdBy: string;
    createdDate: Date;
    dateDeactivated: Date;
    dateLocked: Date;
    dateRegistered: Date;
    directNumber: string;
    email: string;
    firstName: string;
    hrEmployeeIdentifier: string;
    isActive: boolean;
    isArchived: boolean;
    isLocked: boolean;
    jobTitle: string;
    lastName: string;
    mobileNumber: string;
    modifiedBy: string;
    modifiedDate: Date;
    securityNote: string;
    workRegion: string;
    workRegionNote: string;
}

export class UserAttributes implements IUserAttributes {
    userId: number;
    workRegionId: number;

    createdBy: string;
    createdDate: Date;
    dateDeactivated: Date;
    dateLocked: Date;
    dateRegistered: Date;
    directNumber: string;
    email: string;
    firstName: string;
    hrEmployeeIdentifier: string;
    defaultSkillDescription: string;
    isActive: boolean;
    isArchived: boolean;
    isLocked: boolean;
    jobTitle: string;
    lastName: string;
    mobileNumber: string;
    modifiedBy: string;
    modifiedDate: Date;
    securityNote: string;
    workRegion: string;
    workRegionNote: string;

    isAdminUser: boolean;
}
