<div
    class="p-grid p-align-center control-padding"
    *ngIf="!fieldAccess?.isHidden"
>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label
            [appHighlightOnValidationError]="name"
            for="{{ name }}"
            [ngClass]="{ 'sum-value': isSummationValue }"
        >
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <input
            pInputText
            [appHighlightOnValidationError]="name"
            type="text"
            name="{{ name }}"
            [placeholder]="placeholder"
            [appFocusIf]="focus"
            class="number-value"
            [ngModel]="model ? '$' + model : ''"
            (change)="onChange($event)"
            [readonly]="fieldAccess?.isLocked || readonly"
            *ngIf="!isSummationValue"
        />
        <span
            *ngIf="isSummationValue"
            class="number-value"
            [ngClass]="{ bold: boldSummationValue }"
            >${{ model }}</span
        >
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
