export class RoleGrantResultLookup implements IRoleGrantResultLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
}

export class IRoleGrantResultLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
}
