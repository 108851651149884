import {
    Component,
    Input,
    ChangeDetectionStrategy,
    ContentChild,
    TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Column } from '@app/core/models';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { IWorkOrderGridModel } from '@app/modules/operations/models/work-orders/work-order-grid.model';
import { IWorkOrderModel } from '@app/modules/operations/models/work-orders/work-order.model';
import { RoutesNames } from '@app/modules/operations/operations-routes.names';
import { IWorkOrderDispatchGridModel } from '@app/modules/operations/models/dispatch/work-order-dispatch-grid.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-work-order-list-grid',
    templateUrl: './work-order-list-grid.component.html',
    styleUrls: ['./work-order-list-grid.component.scss']
})
export class WorkOrderListGridComponent {
    selectedItem: IWorkOrderModel;

    @Input() grid: IWorkOrderGridModel| IWorkOrderDispatchGridModel;
    @Input() columns: Column[];
    @Input() isMiniGrid = false;

    @Input() actionButtonCount = 0;
    @Input() actionButtonsColumnTitle = 'Actions';

    @ContentChild('buttonsTemplate')
    buttons: TemplateRef<any>;

    constructor(private router: Router) {}

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    onRowDblClick(id: string) {
        this.router.navigate([
            RoutesNames.BASE,
            ...RoutesNames.EDIT_WORK_ORDERS.replace(':id', id).split('/')
        ]);
    }

    onEditBtnClick(id: string) {
        this.router.navigate([
            RoutesNames.BASE,
            ...RoutesNames.EDIT_WORK_ORDERS.replace(':id', id).split('/')
        ]);
    }
}
