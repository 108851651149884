import {
    Component,
    AfterViewInit,
    Renderer2,
    OnInit,
    OnDestroy,
    HostListener
} from '@angular/core';
import {
    AuthenticationService,
    AppLocalStorageService
} from '@app/core/services';
import {
    trigger,
    state,
    style,
    transition,
    animate
} from '@angular/animations';
import { AppComponent } from '@app/app.component';
import { MenuService } from '@app/app.menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'app-secured-layout',
    templateUrl: './secured-layout.component.html',
    styleUrls: ['./secured-layout.component.scss'],
    animations: [
        trigger('menu', [
            state(
                'hidden',
                style({
                    height: '0px'
                })
            ),
            state(
                'visible',
                style({
                    height: '*'
                })
            ),
            transition(
                'visible => hidden',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            ),
            transition(
                'hidden => visible',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            )
        ])
    ]
})
export class SecuredLayoutComponent
implements OnInit, AfterViewInit, OnDestroy {
    layoutCompact: boolean;
    layoutCompactSubscription: Subscription;
    layoutCompactLocalStorageKey = 'layoutCompact';
    topbarMenuActive: boolean;

    menuActive: boolean;

    staticMenuDesktopInactive: boolean;

    mobileMenuActive: boolean;

    menuClick: boolean;

    mobileTopbarActive: boolean;

    topbarRightClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: string;

    documentClickListener: () => void;

    configActive: boolean;

    configClick: boolean;

    rightMenuActive: boolean;

    menuHoverActive = false;

    searchClick = false;

    search = false;

    currentInlineMenuKey: string;

    inlineMenuActive: any[] = [];

    inlineMenuClick: boolean;

    notificationExists: boolean;

    windowIsMobile$: BehaviorSubject<boolean>;
    windowIsDesktop$: BehaviorSubject<boolean>;

    constructor(
        public authService: AuthenticationService,
        public appStorageService: AppLocalStorageService,
        private renderer: Renderer2,
        public app: AppComponent,
        private menuService: MenuService,
        private primengConfig: PrimeNGConfig
    ) {
        /*
        when calling router.navigate from outside pages
        (eg: public login continue or page not found back button)
        we were still getting the classes on <body> from the previous page
        so we remove them explicitly

        this is harmless when navigating within the secured views
        */

        this.renderer.removeClass(document.body, 'login-body');
        this.renderer.removeClass(document.body, 'exception-body');
    }

    ngOnInit() {
        this.menuActive = this.isStatic() && !this.isMobile();
        const useCompactSize = this.isDesktop() ? false : true;

        this.layoutCompactSubscription = this.appStorageService
            .select(this.layoutCompactLocalStorageKey, useCompactSize)
            .subscribe((val) => {
                if (val === null) {
                    val = useCompactSize;
                }
                this.layoutCompact = val.toString() === 'true';
            });
        this.windowIsMobile$ = new BehaviorSubject(this.isMobile());
        this.windowIsDesktop$ = new BehaviorSubject(this.isDesktop());
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.windowIsMobile$.next(this.isMobile());
        this.windowIsDesktop$.next(this.isDesktop());
    }

    ngAfterViewInit() {
        // hides the horizontal submenus or top menu if outside is clicked
        this.documentClickListener = this.renderer.listen(
            'body',
            'click',
            () => {
                if (!this.topbarItemClick) {
                    this.activeTopbarItem = null;
                    this.topbarMenuActive = false;
                }

                if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
                    this.menuService.reset();
                }

                if (this.configActive && !this.configClick) {
                    this.configActive = false;
                }

                if (!this.menuClick) {
                    if (this.mobileMenuActive) {
                        this.mobileMenuActive = false;
                    }

                    if (this.isOverlay()) {
                        this.menuActive = false;
                    }

                    this.menuHoverActive = false;
                    this.unblockBodyScroll();
                }

                if (!this.searchClick) {
                    this.search = false;
                }

                if (
                    this.inlineMenuActive[this.currentInlineMenuKey] &&
                    !this.inlineMenuClick
                ) {
                    this.inlineMenuActive[this.currentInlineMenuKey] = false;
                }

                this.inlineMenuClick = false;
                this.searchClick = false;
                this.configClick = false;
                this.topbarItemClick = false;
                this.topbarRightClick = false;
                this.menuClick = false;
            }
        );
    }

    onLayoutCompactSwitch() {
        this.appStorageService.set(
            this.layoutCompactLocalStorageKey,
            !this.layoutCompact
        );
    }

    onMenuButtonClick(event) {
        this.menuActive = !this.menuActive;
        this.topbarMenuActive = false;
        this.topbarRightClick = true;
        this.menuClick = true;

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.mobileMenuActive = !this.mobileMenuActive;
            if (this.mobileMenuActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onTopbarMobileButtonClick(event) {
        this.mobileTopbarActive = !this.mobileTopbarActive;
        event.preventDefault();
    }

    onRightMenuButtonClick(event) {
        this.rightMenuActive = !this.rightMenuActive;
        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;

        if (
            this.inlineMenuActive[this.currentInlineMenuKey] &&
            !this.inlineMenuClick
        ) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false;
        }
    }

    onSearchKeydown(event) {
        if (event.keyCode === 27) {
            this.search = false;
        }
    }

    onInlineMenuClick(event, key) {
        if (key !== this.currentInlineMenuKey) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false;
        }

        this.inlineMenuActive[key] = !this.inlineMenuActive[key];
        this.currentInlineMenuKey = key;
        this.inlineMenuClick = true;
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        if (item === 'search') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onLogoutButton() {
        this.authService.logout();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(
                new RegExp(
                    '(^|\\b)' +
                        'blocked-scroll'.split(' ').join('|') +
                        '(\\b|$)',
                    'gi'
                ),
                ' '
            );
        }
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }

        if (this.layoutCompactSubscription) {
            this.layoutCompactSubscription.unsubscribe();
        }
    }
}
