import { UIError, ErrorMessage } from './ui-error';

export class DbUpdateConflictError extends Error implements UIError {
    constructor(err: any) {
        super('');

        Object.setPrototypeOf(this, DbUpdateConflictError.prototype);
    }

    errors(): ErrorMessage[] {
        return [
            {
                severity: 'error',
                summary: 'DB Conflict',
                detail:
                    'Request could not be completed because of a data conflict.'
            }
        ];
    }
}
