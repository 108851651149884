import * as moment from 'moment';
import { formatDate } from '@angular/common';

export function isSameDay(d1: Date, d2: Date) {
    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    );
}

export function dateDescription(prefix: string, date: Date): string {
    return date ? `${prefix}${formatDate(date, 'mediumDate', 'en')}` : '';
}

export function utcDateDescription(prefix: string, date: Date): string {
    return date ? `${prefix}${formatDate(stringToDateTime(date), 'mediumDate', 'en')}` : '';
}

export function dateExists(date: any): boolean {
    return moment(new Date(date)).isValid();
}

export function formatDateForICS(date: Date) {
    const DATE_PATTERN = /-|:|\.\d+/g;
    return date.toISOString().replace(DATE_PATTERN, '');
}

export function computeEndDate(startDate: Date, endDate?: Date, duration?: number): Date {
    if (endDate) {
        return endDate;
    }

    if (!duration) {
        throw new Error('You have to provide either the duration or end');
    }

    return new Date(startDate.getTime() + duration * 60 * 1000);
}

export function fromUtcToLocal(date: Date | string): moment.Moment {
    if (date) {
        return moment.utc(date).local();
    }
}

// Forget about converting to utc
export function setMobxFieldDate(value): string {
    let returnDate = null;

    if (value instanceof Date) {
        returnDate = moment(value).format('YYYY-MM-DD');
    }

    return returnDate;
}
// Forget about converting to utc
export function setMobxFieldTime(value): string {
    let returnDate = null;
    if (value instanceof Date) {
        returnDate = moment(value).format('HH:mm');
    }
    if (typeof value === 'string') {
        const date = moment(new Date()).format('YYYY-MM-DD');
        returnDate = moment(`${date}T${value}`).format('HH:mm');
    }
    return returnDate;
}

export function setMobxFieldDatetime(value): string {
    let returnDate = null;

    if (value instanceof Date) {
        returnDate = fromUtcToLocal(value).format();
    }

    return returnDate;
}

export function timeStringToDate(value: string | Date): Date {
    let returnDate = null;

    if (value) {
        const date = moment(new Date()).format('YYYY-MM-DD');
        returnDate = moment(`${date}T${value}`).toDate();
    }

    return returnDate;
}

export function stringToDate(value: string | Date): Date {
    let returnDate = null;

    if (value) {
        returnDate = moment(value).toDate();
    }

    return returnDate;
}

export function stringToDateTime(value: string | Date): Date {
    let returnDate = null;
    value = value ? moment.utc(value).toDate() : value;

    if (value) {
        returnDate = fromUtcToLocal(value).toDate();
    }

    return returnDate;
}

/**
 * Uses Moment Formatter (see https://momentjs.com/docs/#/parsing/string-format/)
 * @param value: string date
 * @param dateFormat: string dateFormat
 */
export function anyDateFormatToString(value: string | Date, dateFormat?: string): string {
    if (!value) {
        return null;
    }
    return moment(value).format(dateFormat || 'MM-DD-YYYY HH:mm:ss A');
}

export function anyUtcDateFormatToString(value: string | Date, dateFormat?: string): string {
    if (!value) {
        return null;
    }
    return fromUtcToLocal(value).format(dateFormat || 'MM-DD-YYYY HH:mm:ss A');
}

/**
 * @param value: string date
 * @param dateFormats: string dateFormat
 */
export function anyDateFormatToCalendarString(
    value: string | Date,
    dateFormats = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MM/DD/YYYY'
    }
) {
    if (!value) {
        return null;
    }
    return moment(value).calendar(null, dateFormats);
}
export function anyUtcDateFormatToCalendarString(
    value: string | Date,
    dateFormats = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MM/DD/YYYY'
    }
) {
    if (!value) {
        return null;
    }
    return fromUtcToLocal(value).calendar(null, dateFormats);
}

function isValidDate(d: Date): boolean {
    return d instanceof Date && !isNaN(d.getTime());
}

export function longUtcDateStringToLocalDateString(utcDate: string | Date): string {
    const dateString = utcDateToLocalDateString(utcDate, 'longDate');
    return dateString;
}

export function longUtcDateStringToLocalDateTimeString(utcDate: string | Date): string {
    const dateString = utcDateToLocalDateString(utcDate, 'medium');
    return dateString;
}

/**
    @dateFormat:
    (see https://angular.io/api/common/DatePipe)
    @'short': equivalent to 'M/d/yy, h:mm a' (6/15/15, 9:03 AM).
    @'medium': equivalent to 'MMM d, y, h:mm:ss a' (Jun 15, 2015, 9:03:01 AM).
    @'long': equivalent to 'MMMM d, y, h:mm:ss a z' (June 15, 2015 at 9:03:01 AM GMT+1).
    @'full': equivalent to 'EEEE, MMMM d, y, h:mm:ss a zzzz' (Monday, June 15, 2015 at 9:03:01 AM GMT+01:00).
    @'shortDate': equivalent to 'M/d/yy' (6/15/15).
    @'mediumDate': equivalent to 'MMM d, y' (Jun 15, 2015).
    @'longDate': equivalent to 'MMMM d, y' (June 15, 2015).
    @'fullDate': equivalent to 'EEEE, MMMM d, y' (Monday, June 15, 2015).
    @'shortTime': equivalent to 'h:mm a' (9:03 AM).
    @'mediumTime': equivalent to 'h:mm:ss a' (9:03:01 AM).
    @'longTime': equivalent to 'h:mm:ss a z' (9:03:01 AM GMT+1).
    @'fullTime': equivalent to 'h:mm:ss a zzzz' (9:03:01 AM GMT+01:00).
*/
export function utcDateToLocalDateString(utcDate: string | Date, dateFormat: string): string {
    let dateString = '';
    if (utcDate) {
        const localDate = fromUtcToLocal(utcDate).toDate();
        dateString = formatDate(localDate, dateFormat, 'en');
    }
    return dateString;
}

export function localDateToLocalDateString(localDate: string | Date, dateFormat: string): string {
    let dateString = '';
    if (localDate) {
        const date = moment(localDate).toDate();
        dateString = formatDate(date, dateFormat, 'en');
    }
    return dateString;
}

export function nearestMinutes(interval, someMoment): moment.Moment {
    const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestPastMinutes(interval, someMoment): moment.Moment {
    const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(interval, someMoment): moment.Moment {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

//   const now = moment();
export const nearest5min = () => nearestMinutes(5, moment());
export const nearest15min = () => nearestMinutes(15, moment());
export const nearest30min = () => nearestMinutes(30, moment());
export const nearestFuture5min = () => nearestFutureMinutes(5, moment());
export const nearestPast5min = () => nearestPastMinutes(5, moment());

export function getFutureDateInXDays(numberOfDays: number): Date {
    const oneDayInMilliseconds = 3600 * 1000 * 24;
    return new Date(Date.now() + oneDayInMilliseconds * numberOfDays);
}
