import { Component } from '@angular/core';

@Component({
    template: `
        <ul *ngIf="contents.length > 0">
            <li *ngFor="let item of contents.split(',')">
                {{ item }}
            </li>
        </ul>
    `
})
export class UnOrderedListGridColumnComponent {
    contents: any;
    props?: any;
}
