import { Component } from '@angular/core';

@Component({
    template: `
        <div class="p-grid">
            <div *ngFor="let member of contents" class="p-md-6 p-col-12">
                {{ member.assignedUsername }}
                <i class="fa fa-times" (click)="removeUser(member)"></i>
            </div>
        </div>
    `
})
export class AssignedUsersListColumnComponent {
    contents: any;
    props?: any;

    removeUser(value: any) {
        this.props.removeAssignedUser(value);
        this.props.update();
    }
}
