import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { IActionItemBoardGridModel } from '@app/modules/residential/models/action-item/action-item-board-grid.model';
import { IActionItemModel } from '@app/modules/residential/models/action-item/action-item.model';
import { PropertyBoardItemStatus } from '@app/modules/residential/enums/property-board-item-status';
import {
    IActionItemFormStore,
    ActionItemFormStore
} from '@app/modules/residential/stores/action-items/action-item-form.store';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-action-item-board',
    templateUrl: './action-item-board.component.html',
    styleUrls: ['./action-item-board.component.scss']
})
export class ActionItemBoardComponent implements OnInit {
    @Input() grid: IActionItemBoardGridModel;
    @Input() propertyId = -1;
    @Input() propertyName?: string;
    @Input() properties?: ISelectItem[];
    @Input() showAdd = true;
    @Input() propertyTeamId?: number;
    selectedItem: IActionItemModel;
    selectedActionItemForm: IActionItemFormStore;
    savingItem = false;
    @Input() showFormDialog = false;
    @Output() showFormDialogChange = new EventEmitter<boolean>();

    constructor(private actionItemStore: ActionItemFormStore) {}

    ngOnInit() {}

    dragStart(actionItem: IActionItemModel) {
        this.selectedItem = actionItem;
    }

    dragEnd() {
        this.selectedItem = null;
    }

    async dropped(status: string) {
        if (this.selectedItem && !this.savingItem) {
            this.savingItem = true;
            this.selectedItem.setStatus(PropertyBoardItemStatus[status]);
            await this.selectedItem.update().then(() => {});
            this.savingItem = false;
            this.selectedItem = null;
        }
    }

    increaseDateOffset(increase: number) {
        this.grid.search.terms.increaseCreatedDateOffset(increase);
    }

    onAddNewBtnClick(typeId?: number) {
        this.grid.update();
        const storeInstance = this.actionItemStore.create(
            0,
            this.propertyId >= 0 ? this.propertyId : null,
            this.propertyName,
            null,
            this.propertyTeamId,
            typeId
        );
        this.showFormDialog = true;
        this.showFormDialogChange.emit(this.showFormDialog);
        this.selectedActionItemForm = storeInstance;
    }

    onEditNewBtnClick(actionItem: IActionItemModel) {
        this.grid.update();
        const storeInstance = this.actionItemStore.create(
            actionItem.id,
            actionItem.propertyId,
            null,
            null,
            this.propertyTeamId
        );
        applyPartialSnapshot(storeInstance, actionItem);
        this.showFormDialog = true;
        this.showFormDialogChange.emit(this.showFormDialog);
        this.selectedActionItemForm = storeInstance;
    }

    onEdit(id: number, propertyId?: number) {
        this.grid.update();
        const storeInstance = this.actionItemStore.create(
            id,
            propertyId,
            null,
            null,
            this.propertyTeamId
        );
        this.showFormDialog = true;
        this.showFormDialogChange.emit(this.showFormDialog);
        this.selectedActionItemForm = storeInstance;
    }

    onCloseFormDialog() {
        this.showFormDialog = false;
        this.showFormDialogChange.emit(this.showFormDialog);
        this.selectedActionItemForm = undefined;
        this.grid.loadItems();
    }

    get itemStatusType() {
        return PropertyBoardItemStatus;
    }
}
