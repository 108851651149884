<div class="{{ btnClass }} control-padding" *ngIf="!fieldAccess?.isHidden">
    <button
        pButton
        label="{{ label }}"
        icon="{{ icon }}"
        [iconPos]="iconPos"
        [disabled]="fieldAccess?.isLocked || disabled"
        (click)="onClick()"
    ></button>
</div>
