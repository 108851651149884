/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component, OnInit } from '@angular/core';
import GoldeyeCalendar from '../goldeye-calendar';

@Component({
    selector: 'app-goldeye-time-only-calendar',
    inputs: [
        'value',
        'name',
        'range',
        'labelClass',
        'inputClass',
        'label',
        'controls',
        'disabled',
        'fieldAccess',
        'dateFormat',
        'hourFormat',
        'defaultDate',
        'appendTo'
    ],
    outputs: ['selection'],
    templateUrl: './time-only-calendar.component.html',
    styleUrls: ['./time-only-calendar.component.scss']
})
export class TimeOnlyCalendarComponent extends GoldeyeCalendar implements OnInit {
    ngOnInit() {
        this.setClassNames();
    }
}
