import { types, Instance } from 'mobx-state-tree';
import { anyUtcDateFormatToString, stringToDateTime } from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';

export const ActionItemUserModel = resource(
    'action-item-users',
    types
        .model({
            id: types.number,
            userId: types.number,
            userName: types.string,
            email: types.string,
            actionItemId: types.number,

            createdDate: types.maybeNull(types.union(types.string, types.Date)),
            createdBy: types.maybeNull(types.string),
            modifiedDate: types.maybeNull(types.union(types.string, types.Date)),
            modifiedBy: types.maybeNull(types.string)
        })
        .views((self) => ({
            get createdOnDate() {
                if (self.createdDate) {
                    return stringToDateTime(self.createdDate);
                }
            },

            get modifiedOnDate() {
                if (self.modifiedDate) {
                    return stringToDateTime(self.modifiedDate);
                }
            }
        }))
        .views((self) => ({
            get createdDateAsDate() {
                if (self.createdDate) {
                    return anyUtcDateFormatToString(self.createdOnDate);
                }
            },
            get createdDateAsJustDate() {
                if (self.createdDate) {
                    return anyUtcDateFormatToString(self.createdOnDate, 'MM/DD/YYYY');
                }
            }
        }))
        .actions((self) => ({
            setUserId: (value: number) => (self.userId = value),
            setActionItemId: (value: number) => (self.actionItemId = value)
        }))
);

type ActionItemUserModelType = Instance<typeof ActionItemUserModel>;
export interface IActionItemUserModel extends ActionItemUserModelType {}
