<div class="inspection-search-dialog" *mobxAutorun>
    <p-dialog
        header="Advanced Search"
        [(visible)]="show"
        class="large-modal"
        [modal]="true"
        (onHide)="onDialogHide()"
    >
        <form novalidate *ngIf="terms">
            <p><em>All fields are optional</em></p>
            <div class="p-grid p-grid-responsive p-grid-pad p-fluid">
                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('priorityId')">
                    <div class="p-col-12 p-md-5">
                        <label for="priorityId"> Priority: </label>
                    </div>

                    <div class="p-col-12 p-md-7">
                        <p-dropdown
                            name="priorityId"
                            [resetFilterOnHide]="true"
                            [filter]="true"
                            [options]="dropdowns.workOrderPriorityDropdown"
                            [ngModel]="terms.priorityId.value"
                            [disabled]="terms.priorityId.disabled"
                            (onChange)="terms.setPriorityId($event.value)"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('statusId')">
                    <div class="p-col-12 p-md-5">
                        <label for="statusId"> Status: </label>
                    </div>

                    <div class="p-col-12 p-md-7">
                        <p-dropdown
                            name="statusId"
                            [options]="dropdowns.workOrderStatusDropdown"
                            [ngModel]="terms.statusId.value"
                            [disabled]="terms.statusId.disabled"
                            (onChange)="terms.setStatusId($event.value)"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 spacer-vertical">
                    <hr />
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('propertyId')">
                    <div class="p-col-12 p-md-5">
                        <label for="unitPropertyId"> Property: </label>
                    </div>

                    <div class="p-col-12 p-md-7">
                        <p-dropdown
                            name="propertyId"
                            [resetFilterOnHide]="true"
                            [filter]="true"
                            [options]="dropdowns.filteredProperties"
                            [ngModel]="terms.propertyId.value"
                            [disabled]="terms.propertyId.disabled"
                            (onChange)="terms.setPropertyId($event.value)"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('unitId')">
                    <div class="p-col-12 p-md-5">
                        <label for="unitId"> Unit: </label>
                    </div>

                    <div class="p-col-12 p-md-7">
                        <p-dropdown
                            [filter]="true"
                            [resetFilterOnHide]="true"
                            name="unitId"
                            [options]="
                                dropdowns.filteredUnits(terms.propertyId.value)
                            "
                            [ngModel]="terms.unitId.value"
                            [disabled]="terms.unitId.disabled"
                            (onChange)="terms.setUnitId($event.value)"
                        >
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 spacer-vertical">
                    <hr />
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('category')">
                    <div class="p-col-12 p-md-5">
                        <label for="category"> Category Id: </label>
                    </div>

                    <div class="p-col-12 p-md-7">
                        <p-dropdown
                            name="category"
                            [options]="dropdowns.workOrderCategoryDropdown"
                            [ngModel]="terms.categoryId.value"
                            [disabled]="terms.categoryId.disabled"
                            (onChange)="terms.setCategoryId($event.value)"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('subcategory')">
                    <div class="p-col-12 p-md-5">
                        <label for="subcategory"> Subcategory Id: </label>
                    </div>

                    <div class="p-col-12 p-md-7">
                        <p-dropdown
                            name="subcategory"
                            [options]="dropdowns.workOrderSubCategoryDropdown"
                            [ngModel]="terms.subcategoryId.value"
                            [disabled]="terms.subcategoryId.disabled"
                            (onChange)="terms.setSubcategoryId($event.value)"
                        >
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 spacer-vertical">
                    <hr />
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('createdForRange')">
                    <div class="p-col-12 p-md-5">
                        <label for="createdForRange"> Created Between: </label>
                    </div>

                    <div class="p-col-12 p-md-5">
                        <p-calendar
                            #createdForCalendar
                            name="createdForRange"
                            selectionMode="range"
                            [showIcon]="true"
                            [hideOnDateTimeSelect]="true"
                            [hourFormat]="'12'"
                            [disabledDays]="[6, 0]"
                            [ngModel]="terms.createdBetween"
                            [disabled]="
                                terms.createdFrom.disabled &&
                                terms.createdTo.disabled
                            "
                            (onSelect)="terms.setCreatedBetween($event)"
                        >
                            <p-footer>
                                <div class="p-grid">
                                    <div class="p-col-3">
                                        <p-button
                                            label="Close"
                                            styleClass="p-button-secondary"
                                            (click)="
                                                createdForCalendar.hideOverlay()
                                            "
                                        >
                                        </p-button>
                                    </div>
                                </div>
                            </p-footer>
                        </p-calendar>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 spacer-vertical">
                    <hr />
                </div>

                <div class="p-col-12 p-grid" *ngIf="!isHiddenFilter('isUnassigned')">
                    <div class="p-col-12 p-md-5">
                        <label for="isUnassigned"> Is Unassigned: </label>
                    </div>

                    <div class="p-col-12 p-md-5">
                        <p-triStateCheckbox
                            name="isUnassigned"
                            [ngModel]="terms.isUnassigned.value"
                            [label]="
                                terms.isUnassigned.value === undefined ||
                                terms.isUnassigned.value === null
                                    ? ''
                                    : terms.isUnassigned.value
                                    ? 'Unassigned'
                                    : 'Assigned'
                            "
                            [disabled]="terms.isUnassigned.disabled"
                            (onChange)="terms.setIsUnassigned($event.value)"
                        ></p-triStateCheckbox>
                    </div>
                </div>
            </div>

            <div
                class="p-grid p-grid-responsive p-grid-pad p-fluid controls"
                style="margin-top: 1.8em"
            >
                <div class="p-col-12 p-md-3">
                    <button
                        pButton
                        id="inspection-search-search-btn"
                        label="Search"
                        icon="fa fa-edit"
                        (click)="onSearchBtnClick()"
                    ></button>
                </div>

                <div class="p-col-12 p-md-3 p-md-offset-1">
                    <button
                        pButton
                        id="inspection-search-cancel-btn"
                        label="Cancel"
                        icon="fa fa-times"
                        (click)="onCancelBtnClick()"
                    ></button>
                </div>
            </div>
        </form>
    </p-dialog>
</div>
