import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LoggingService } from '../services/logging/logging.service';
import { environment } from '@env/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    isUIError(err): boolean {
        if (err.status) {
            return String(err.status).slice(0, 2) === '40';
        }
        const regExp = new RegExp('"status":40');
        return regExp.test(err.message);
    }

    handleError(err: any): void {
        if (!this.isUIError(err)) {
            console.log(err);
        }
        if (!this.isUIError(err) && environment.environmentName !== 'Local') {
            const logging = this.injector.get(LoggingService);
            logging.errorStream$.next({ message: err.message, details: err });
        }
    }
}
