<div *mobxAutorun>
    <p-table
        [columns]="columns"
        [value]="scores"
        [responsive]="true"
        [loading]="loading"
    >
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    No records found
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="caption">
            {{ caption }}
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <span class="p-column-title">{{ col.header }}</span>
                    {{ rowData[col.field] }}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
