import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { lookupResourceBuilder } from '@app/shared/models/lookup.model';
import { LookupFormComponent } from '@app/shared/components/lookup-form/lookup-form.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-lookup-list',
    templateUrl: './lookup-list.component.html',
    styleUrls: ['./lookup-list.component.scss']
})
export class LookupListComponent {
    @Input() lookupList;

    constructor(
        public dialogService: DialogService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private http: HttpClient) {
    }

    onLookupNewBtnClick(endPoint: string) {
        const lookup = lookupResourceBuilder(endPoint).create({ id: 0 },
            { http: this.http });
        const ref = this.dialogService.open(LookupFormComponent, {
            data: { lookup },
            header: 'New Dropdown',
            width: '30%'
        });
        ref.onClose.subscribe(() => {
            this.lookupList.loadItems();
        });
    }

    onLookupEditBtnClick(lookup) {
        const ref = this.dialogService.open(LookupFormComponent, {
            data: { lookup },
            header: 'Edit Dropdown',
            width: '30%'
        });

        ref.onClose.subscribe(() => {
            this.lookupList.loadItems();
        });
    }

    onLookupDeleteBtnClick(lookup) {
        this.confirmationService.confirm({
            key: 'delete-attachment-confirmation-' + this.lookupList.title,
            message: 'Remove selected dropdown?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await lookup.delete();
                this.messageService.add({
                    severity: 'info',
                    detail: `Dropdown '${lookup.displayValue}' deleted`
                });
                this.lookupList.removeItem(lookup);
            }
        });
    }
}
