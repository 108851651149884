import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ISignalRMessage, SignalRMessageStatus, SignalRMessageTypes } from '@app/core/models';
import { SignalRService } from './signalr.service';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { NotificationService } from './notification.service';
import { AppLocalStorageService } from '../storage/app-local-storage.service';

@Injectable()
export class BroadcastHubService implements OnDestroy {
    private hubConnection: HubConnection;
    private readonly hubName = 'broadcast';
    private sub$: Subscription;

    private baseUrl = environment.appConfig.baseUrl;

    private hubInitialized = new BehaviorSubject<boolean>(false);

    // message observables
    private broadcastMessages = new BehaviorSubject<ISignalRMessage[]>([]);
    broadcastMessagesList$: Observable<ISignalRMessage[]>;

    // define hub methods to listen on below
    private readonly broadcastMethod = 'broadcastMessage';
    private readonly messageGroupMethod = 'messageGroup';
    private readonly joinGroupMethod = 'joinGroup';
    private readonly leaveGroupMethod = 'LeaveGroup';

    // Define Group names
    private readonly adminGroupName = 'Admin';

    // private systemMessages: Subject<string> = new Subject();
    // systemMessages$ = this.systemMessages.asObservable();

    // private watchMessages: Subject<string> = new Subject();
    // watchMessages$ = this.watchMessages.asObservable();

    // private reminderMessages: Subject<string> = new Subject();
    // reminderMessages$ = this.reminderMessages.asObservable();

    // private emergencyMessages: Subject<string> = new Subject();
    // emergencyMessages$ = this.emergencyMessages.asObservable();

    // private customMessages: Subject<string> = new Subject();
    // customMessages$ = this.customMessages.asObservable();

    constructor(
        private signalRService: SignalRService,
        private notificationService: NotificationService,
        private appLocalStorage: AppLocalStorageService
    ) {
        // this.init();
    }

    private init() {
        if (!this.broadcastMessagesList$) {
            this.broadcastMessagesList$ = this.broadcastMessages.asObservable();

            this.getUserNotifications();
        }

        if (!this.hubConnection || this.hubConnection.state === HubConnectionState.Disconnected) {
            this.signalRService.init(this.hubName);

            this.sub$ = this.signalRService.hubConnections
                .pipe(
                    map((hubs) => {
                        if (hubs && hubs[this.hubName]) {
                            return hubs[this.hubName];
                        }
                    })
                )
                .subscribe((broadcastHubConnection) => {
                    if (broadcastHubConnection) {
                        this.hubConnection = broadcastHubConnection;

                        this.hubConnection.on(this.broadcastMethod, (data: ISignalRMessage) => {
                            const lastBroadcastValue = this.getLastBroadcastMessageList();

                            this.broadcastANewMessageList([data, ...lastBroadcastValue]);
                        });

                        this.hubInitialized.next(true);
                    }
                });
        }
    }

    getUserEmailAsObservable() {
        return this.appLocalStorage.select('profile_nickname').pipe(take(1));
    }

    getUserNotifications() {
        this.getUserEmailAsObservable().subscribe((userEmail) => {
            // Get notifications for user
            if (userEmail) {
                this.notificationService
                    .getUserNotificationList(userEmail)
                    .pipe(
                        map((msgs) =>
                            // get latest notification first
                            // TODO: Get the sort by date to work
                            msgs.reverse()
                        ),
                        take(1)
                    )
                    .subscribe((notifications) => {
                        this.broadcastANewMessageList(notifications);
                    });
            }
        });
    }

    broadcastANewMessageList(list: ISignalRMessage[]) {
        this.broadcastMessages.next(list);
    }

    // close all observable outlets
    // also close the subscription
    ngOnDestroy(): void {
        if (this.hubConnection) {
            this.hubConnection.off(this.broadcastMethod);
        }

        if (this.sub$) {
            this.sub$.unsubscribe();
        }

        this.hubInitialized.complete();

        this.broadcastMessages.complete();

        // this.systemMessages.complete();
        // this.watchMessages.complete();
        // this.reminderMessages.complete();
        // this.emergencyMessages.complete();
        // this.customMessages.complete();
    }

    // A Test method
    sendStringMessage(message: string): void {
        const msgToSend: ISignalRMessage = {
            id: 1,
            date: new Date(),
            body: message,
            status: SignalRMessageStatus.UNREAD,
            subject: 'Front end',
            type: SignalRMessageTypes.EMERGENCY
        };

        this.send(msgToSend);
    }

    send(message: ISignalRMessage): void {
        this.hubConnection.invoke(this.broadcastMethod, message).catch(function (err) {
            return console.error(err.toString());
        });
    }

    getLastBroadcastMessageList() {
        return this.broadcastMessages.value;
    }

    dismissAllUserNotifications() {
        const lastBroadcastMessageList = this.getLastBroadcastMessageList();

        if (lastBroadcastMessageList) {
            this.getUserEmailAsObservable().subscribe((userEmail) => {
                // set all the statuses to dismissed (unread for now)
                const newList = lastBroadcastMessageList.map((msg) => ({
                    ...msg,
                    status: SignalRMessageStatus.READ,
                    userEmail: userEmail
                }));

                this.notificationService.dismissAllUserNotifications(newList).subscribe((dismissResult) => {
                    if (dismissResult) {
                        this.getUserNotifications();
                    }
                });
            });
        }
    }

    markNotificationAsRead(singleNotification: ISignalRMessage) {
        if (singleNotification) {
            this.getUserEmailAsObservable().subscribe((userEmail) => {
                singleNotification.userEmail = userEmail;

                this.notificationService.markNotificationAsRead(singleNotification).subscribe((dismissResult) => {
                    if (dismissResult) {
                        this.getUserNotifications();
                    }
                });
            });
        }
    }

    messageAnyGroup(message: ISignalRMessage, groupName: string) {
        message.groupName = groupName;

        this.hubConnection.invoke(this.messageGroupMethod, message).catch(function (err) {
            return console.error(err.toString());
        });
    }

    joinAnyGroup(groupName: string) {
        if (this.hubConnection) {
            this.joinGroup(groupName);
        } else {
            this.hubInitialized.pipe(take(2)).subscribe((initVal) => {
                if (initVal) {
                    // hub finally initialized
                    this.joinGroup(groupName);
                }
            });
        }
    }

    private joinGroup(groupName: string) {
        this.hubConnection.invoke(this.joinGroupMethod, groupName).catch(function (err) {
            return console.error(err.toString());
        });
    }

    leaveAnyGroup(groupName: string) {
        if (this.hubConnection) {
            this.leaveGroup(groupName);
        } else {
            this.hubInitialized.pipe(take(2)).subscribe((initVal) => {
                if (initVal) {
                    // hub finally initialized
                    this.leaveGroup(groupName);
                }
            });
        }
    }

    private leaveGroup(groupName: string) {
        this.hubConnection.invoke(this.leaveGroupMethod, groupName).catch(function (err) {
            return console.error(err.toString());
        });
    }

    messageAdminGroup(message: ISignalRMessage) {
        this.messageAnyGroup(message, this.adminGroupName);
    }

    joinAdminGroup() {
        this.joinAnyGroup(this.adminGroupName);
    }

    leaveAdminGroup() {
        this.leaveAnyGroup(this.adminGroupName);
    }
}
