import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { BlobStorageService } from '@app/core/services';
import { IAttachmentModel } from '@app/core/models/storage/attachment.model';
import { Column } from '@app/core/models';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-attachments-mini-grid',
    templateUrl: './attachments-mini-grid.component.html',
    styleUrls: ['./attachments-mini-grid.component.scss']
})
export class AttachmentsMiniGridComponent implements OnInit, OnChanges {
    @Input() loading: boolean;
    @Input() title: string;
    @Input() attachments: IAttachmentModel[];
    @Input() showPhotoThumbnail = false;
    @Input() columns: Column[] = [
        {
            field: 'title',
            header: 'Name',
            type: 'text',
            setter: 'setTitle'
        },
        { field: 'createdDateAsDate', header: 'Date', type: 'dateOnly' },
        { field: 'createdBy', header: 'By', type: 'text' }
    ];

    editAttachmentNameDialog = new BehaviorSubject<boolean>(false);
    attachmentBeingEdited: IAttachmentModel = null;

    constructor(
        private blobStorage: BlobStorageService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit() {
        if (
            this.showPhotoThumbnail &&
            this.attachments &&
            this.attachments.find((a) => a.isImage)
        ) {
            this.tokenizeUrls(this.attachments);

            this.columns = [
                {
                    field: 'thumbnailPhotoAsBase64String',
                    header: '',
                    type: 'thumbnail'
                },
                ...this.columns
            ];
        }
    }

    onEditBtnClick(attachment: IAttachmentModel) {
        this.attachmentBeingEdited = attachment;
        this.editAttachmentNameDialog.next(true);
    }

    onEditCancel() {
        this.editAttachmentNameDialog.next(false);
    }

    onEditSave() {
        this.attachmentBeingEdited.update().then((value) => {
            if (this.attachmentBeingEdited.noErrors) {
                this.onEditCancel();
            }
        });
    }

    onDeleteBtnClick(attachment: IAttachmentModel) {
        this.confirmationService.confirm({
            key: 'delete-attachment-confirmation-' + attachment.id,
            message: 'Remove selected attachment?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await attachment.delete();
                attachment.remove();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.attachments && changes.attachments.currentValue) {
            this.tokenizeUrls(changes.attachments.currentValue);
        }
    }

    private tokenizeUrls(attachments: IAttachmentModel[]) {
        attachments.forEach((a) => this.blobStorage.tokenize(a));
    }

    getFileIcon(file: IAttachmentModel) {
        let icon = 'fa fa-download';
        const imageExtensions = ['jpg', 'png', 'gif', 'jpeg'];
        const wordExtensions = ['doc', 'docx'];
        if (file) {
            const ext: string = file.fileExtension;
            const lowerExt = ext ? ext.toLocaleLowerCase() : '';

            if (imageExtensions.includes(lowerExt)) {
                icon = 'fa fa-file-image';
            } else if (lowerExt === 'pdf') {
                icon = 'fa fa-file-pdf';
            } else if (wordExtensions.includes(lowerExt)) {
                icon = 'fa fa-file-word';
            } else if (lowerExt === 'txt') {
                icon = 'fa fa-file-alt';
            }
        }

        return icon;
    }

    getFileTooltip(file: IAttachmentModel) {
        const fileIcon = this.getFileIcon(file);
        let fileTooltip = 'Download';

        if (fileIcon !== 'fa fa-download') {
            fileTooltip = `View ${file.fileExtension} in a new tab`;
        }

        return fileTooltip;
    }
}
