<div class="pages-body error-page p-d-flex p-flex-column">
    <div class="exception-type">
        <img src="assets/layout/images/error.svg" />
    </div>

    <div class="p-as-center p-mt-auto p-mb-auto">
        <div class="pages-panel card p-d-flex p-flex-column">
            <i class="fa fa-exclamation-circle"></i>
            <h1>An error occurred!</h1>
            <div class="pages-detail p-pb-6">
                An unexpected error has occurred.If the problem persists, please
                contact support for help.
            </div>
            <p-button
                type="button"
                (click)="go_back()"
                #backButton
                class="blue-btn"
                label="Go Back"
            >
            </p-button>
        </div>
    </div>
</div>
