<div class="day-of-week-container control-padding">
    <span
        class="day-of-week-item"
        *ngFor="let item of options"
        [ngClass]="{ selected: models?.includes(+item.value) }"
        (click)="toggleFromModels(item.value)"
    >
        {{ item.label }}
    </span>
</div>
