import { UIError, ErrorMessage } from './ui-error';

export class FkConstraintViolationError extends Error implements UIError {
    constructor(err: any) {
        super('');

        Object.setPrototypeOf(this, FkConstraintViolationError.prototype);
    }

    errors(): ErrorMessage[] {
        return [
            {
                severity: 'error',
                summary: 'Could not be deleted',
                detail: 'Item could not be deleted because is currently in use.'
            }
        ];
    }
}
