import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { countries, provinces, cities } from './geography';

@Injectable({
    providedIn: 'root'
})
export class GeographicalService {

    defaultOption: SelectItem[] = [{label: 'All', value: null}];

    constructor() { }

    getListOfCountries(): Observable<SelectItem[]> {
        return of(countries);
    }

    getListOfProvincesFor(country?: string): Observable<SelectItem[]> {
        return country in provinces ? of(provinces[country]) : of(this.defaultOption);
    }

    getListOfCitiesFor(province?: string): Observable<SelectItem[]> {
        return province in cities ? of(cities[province]) : of(this.defaultOption);
    }
}
