import { types } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';
import * as moment from 'moment';

export const SASTokenModel = resource(
    'sas-token',
    types
        .model({
            id: '',
            url: types.maybe(types.string),
            token: types.maybe(types.string),
            starts: types.maybe(types.string),
            expires: types.maybe(types.string)
        })
        .views(self => ({
            get expired() {
                if (self.expires) {
                    const now = moment().utc();
                    const expirationDate = moment(self.expires).utc();
                    return now > expirationDate;
                }
                return true;
            }
        }))
).actions(self => ({
    renew() {
        if (self.expired) {
            self.load();
        }
    }
}));
type modelType = typeof SASTokenModel.Type;
export interface ISASTokenModel extends modelType {}
