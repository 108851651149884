import { types, Instance } from 'mobx-state-tree';
import { stringToDateTime } from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';
import { withAttachments } from '@app/core/models/storage/with-attachment.model';
import { PropertyBoardItemStatus } from '../../enums/property-board-item-status';
import { ISelectItem, SelectItemModel } from '@app/shared/models/goldeye-select-item';
import { ActionItemUserModel, IActionItemUserModel } from './action-item-user.model';
import { UserModel } from '@app/modules/notifications/models/users/operations-user.model';

export const ActionItemModel = types.compose(
    withAttachments((insp) => `${insp.createdBy ? insp.createdBy : insp.modifiedBy}_action_item_${insp.id}`, 'action-item'),
    resource(
        'action-items',
        types
            .model({
                id: types.identifierNumber,
                action: types.maybeNull(types.string),
                status: types.optional(types.number, PropertyBoardItemStatus.New),
                statusDescription: types.maybeNull(types.string),
                typeId: types.maybeNull(types.number),
                typeDisplayValue: types.maybeNull(types.string),
                lastComment: types.maybeNull(types.string),
                typeColor: types.maybeNull(types.string),
                typeIcon: types.maybeNull(types.string),

                assignedToUsers: types.array(ActionItemUserModel),
                projects: types.array(SelectItemModel),
                vendors: types.array(SelectItemModel),
                workOrders: types.array(SelectItemModel),

                propertyId: types.maybeNull(types.number),
                propertyName: types.maybeNull(types.string),
                propertyCode: types.maybeNull(types.string),
                propertyAddress: types.maybeNull(types.string),
                unitId: types.maybeNull(types.number),
                unitCode: types.maybeNull(types.string),
                commonAreaId: types.maybeNull(types.number),
                isArchived: types.maybeNull(types.boolean),
                completedOn: types.maybeNull(types.union(types.string, types.Date)),
                createdDate: types.maybeNull(types.union(types.string, types.Date)),
                createdBy: types.maybeNull(types.string),
                modifiedDate: types.maybeNull(types.union(types.string, types.Date)),
                modifiedBy: types.maybeNull(types.string),
                createdByUser: types.maybeNull(UserModel)
            })
            .views((self) => ({
                get createdOnDate() {
                    if (self.createdDate) {
                        return stringToDateTime(self.createdDate);
                    }
                },
                get modifiedOnDate() {
                    if (self.modifiedDate) {
                        return stringToDateTime(self.modifiedDate);
                    }
                },
                get completedOnDate() {
                    if (self.completedOn) {
                        return stringToDateTime(self.completedOn);
                    }
                },
                get assignedToUserNames() {
                    if (self.assignedToUsers) {
                        return self.assignedToUsers.map((a: IActionItemUserModel) => a.userName).join(', ');
                    }
                },
                get assignedToUserNamesAndDates() {
                    if (self.assignedToUsers) {
                        return self.assignedToUsers
                            .map((a: IActionItemUserModel) => a.userName + ' on ' + a.createdDateAsJustDate)
                            .join(', ');
                    }
                },
                get assignedToUserIds(): Array<number> {
                    if (self.assignedToUsers) {
                        return self.assignedToUsers.map((a: IActionItemUserModel) => +a.userId);
                    }
                },
                get projectIds() {
                    if (self.projects) {
                        return self.projects.map((a: ISelectItem) => +a.value);
                    }
                },
                get projectNames() {
                    if (self.projects) {
                        return self.projects.map((a: ISelectItem) => a.label).join(', ');
                    }
                },
                get vendorIds() {
                    if (self.vendors) {
                        return self.vendors.map((a: ISelectItem) => +a.value);
                    }
                },
                get vendorNames() {
                    if (self.vendors) {
                        return self.vendors.map((a: ISelectItem) => a.label).join(', ');
                    }
                },
                get workOrderIds() {
                    if (self.workOrders) {
                        return self.workOrders.map((a: ISelectItem) => +a.value);
                    }
                },
                get workOrderNames() {
                    if (self.workOrders) {
                        return self.workOrders.map((a: ISelectItem) => a.label).join(', ');
                    }
                },
                get workOrderNumbers() {
                    if (self.workOrders) {
                        return self.workOrders.map((a: ISelectItem) => a.label.split(' - ')[0]).join(', ');
                    }
                },
                get createdByUserName(){
                    if(self.createdByUser){
                        return self.createdByUser.fullname;
                    }
                }
            }))
            .views((self) => ({
                get actionItemSummary() {
                    let name: string;
                    if (self.projects && self.projects.length > 0) {
                        name = self.projectNames;
                    } else if (self.workOrders && self.workOrders.length > 0) {
                        name = self.workOrderNames;
                    }
                    return (name ? name + ' - ' : '') + self.action;
                }
            }))
            .actions((self) => ({
                setAction: (value: string) => (self.action = value),
                setStatus: (value: number) => (self.status = value),
                setStatusDescription: (value: string) => (self.statusDescription = value),
                setTypeId: (value: number) => (self.typeId = value),
                setTypeDisplayValue: (value: string) => (self.typeDisplayValue = value),

                addAssignedUser(value: ISelectItem) {
                    self.assignedToUsers.push({
                        id: 0,
                        userId: +value.value,
                        actionItemId: self.id,
                        userName: value.label
                    });
                },
                removeAssignedUser(value: IActionItemUserModel) {
                    self.assignedToUsers.remove(value);
                },
                addProject(value: ISelectItem) {
                    self.projects.push(value);
                },
                removeProject(value: ISelectItem) {
                    self.projects.remove(value);
                },
                addVendor(value: ISelectItem) {
                    self.vendors.push(value);
                },
                removeVendor(value: ISelectItem) {
                    self.vendors.remove(value);
                },
                addWorkOrder(value: ISelectItem) {
                    self.workOrders.push(value);
                },
                removeWorkOrder(value: ISelectItem) {
                    self.workOrders.remove(value);
                },

                setPropertyId: (value: number) => (self.propertyId = value),
                setUnitId: (value: number) => (self.unitId = value),
                setCommonAreaId: (value: number) => (self.commonAreaId = value),
                setIsArchived: (value: boolean) => (self.isArchived = value)
            }))
    )
);

type ActionItemModelType = Instance<typeof ActionItemModel>;
export interface IActionItemModel extends ActionItemModelType {}
