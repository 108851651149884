export function arrayRemove(arr: any[], value: any) {
    return arr.filter(function (ele: any) {
        return ele !== value;
    });
}

/**
 * Adds a value to the array if the value does not exist
 * OR
 * Removes a value from an array if the value exists
 */
export function arrayToggleValue(arr: any[], value: any) {
    if (arr.includes(value)) {
        arr = arrayRemove(arr, value);
    } else {
        arr.push(value);
    }

    return arr;
}

/**
 * Checks if an object is an array or is null. Null is valid
 */
export function isArrayOrNull(arr) {
    return arr == null || Array.isArray(arr);
}

export function getUniqueArrayObjectsForKey(arr: any[], key: string) {
    const arrayUniqueByKey = [
        ...new Map(arr.map((item) => [item[key], item])).values()
    ];

    return arrayUniqueByKey;
}
