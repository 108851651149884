import { Component } from '@angular/core';

@Component({
    template: `
        {{ contents | yesNo }}
    `
})
export class BooleanGridColumnComponent {
    contents: any;
    props?: any;
}
