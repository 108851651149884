import { Component } from '@angular/core';

@Component({
    template: `
        <span>
            {{ contents | fileSize }}
        </span>
    `
})
export class FileSizeColumnComponent {
    contents: any;
    props?: any;
}
