import { dateDescription as dDesc } from '@app/shared/utils/date-helper';
import { types, Instance, getSnapshot } from 'mobx-state-tree';
import { BuildingManagerSearchTermsModel } from './building-manager-search-terms.model';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';
import { searchTermsUtils } from '@app/shared/utils/mobx-utils';
import { BuildingManagerDropdownModel } from '../property/building-manager-dropdown.model';

export const BuildingManagerSearchModel = types
    .model({
        dropdowns: types.optional(
            BuildingManagerDropdownModel,
            {} as Instance<typeof BuildingManagerDropdownModel>
        ),
        terms: types.optional(
            BuildingManagerSearchTermsModel,
            {} as Instance<typeof BuildingManagerSearchTermsModel>
        )
    })
    .views((self) => ({
        get description(): Array<[ISearchTerm, string]> {
            const { terms } = self;
            const descriptions: Array<[ISearchTerm, string]> = [
                [
                    terms.propertyId,
                    dDesc('PropertyId: ', terms.propertyId.value)
                ],
                [terms.isDefault, dDesc('Is Default: ', terms.isDefault.value)]
            ];
            return descriptions.filter((term) =>
                searchTermsUtils.hasValue(term[0])
            );
        },
        get inEffect() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(searchTermsUtils.hasValue).length
            );
        },
        get initialized() {
            return Boolean(self.dropdowns.dropdownsReady);
        },
        get enabledTerms() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(
                    (term) =>
                        searchTermsUtils.snapshotHasValue(term) &&
                        !term.disabled
                ).length
            );
        }
    }))
    .views((self) => ({
        get canBeCleared() {
            return (
                self.initialized &&
                self.inEffect &&
                searchTermsUtils.anyEnabledTerms(self.terms)
            );
        }
    }))
    .actions((self) => ({
        async initialize() {
            await self.dropdowns.fetchDropdowns();
        }
    }));
type modelType = Instance<typeof BuildingManagerSearchModel>;
export interface IBuildingManagerSearchModel extends modelType {}
