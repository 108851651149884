import { getSnapshot, applySnapshot, ModelSnapshotType, IMaybeNull, IType, ISimpleType } from 'mobx-state-tree';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';

/**
 * Updates the provided state with new state
 * @param state Old state
 * @param newState New state
 */
export function applyPartialSnapshot(state, newState) {
    const currentSnapshot = getSnapshot(state);
    const overrideState = Object.assign({}, currentSnapshot, newState);
    applySnapshot(state, overrideState);
}

export const searchTermsUtils = {
    areEmpty(terms) {
        return !Object.values(terms).reduce((prev, { value }) => prev && !!value, true);
    },
    anyEnabledTerms(terms) {
        const termsSnapshot = getSnapshot(terms);
        return Boolean(
            Object.values(termsSnapshot).filter((term) => searchTermsUtils.hasValue(term as ISearchTerm) && !term.disabled).length
        );
    },
    hasValue(term: ISearchTerm) {
        return term.value !== null && (term.value === 0 || Boolean(term.value) || term.ignoreFalseValue);
    },
    snapshotHasValue(
        term: ModelSnapshotType<{
            value: IMaybeNull<any>;
            disabled: IType<boolean, boolean, boolean>;
            ignoreFalseValue: ISimpleType<boolean>;
        }>
    ) {
        return term.value !== null && (term.value === 0 || Boolean(term.value) || term.ignoreFalseValue);
    }
};
