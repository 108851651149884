import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorMessage } from '@app/core/errors';

@Injectable({ providedIn: 'root' })
export class ValidationMessagesService {
    private subject = new Subject<ErrorMessage[]>();

    setValidationMessages(messages: ErrorMessage[]) {
        this.subject.next(messages);
    }

    clearValidationMessages() {
        this.subject.next(null);
    }

    getValidationMessages(): Observable<ErrorMessage[]> {
        return this.subject.asObservable();
    }
}
