import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { AuthGuard } from './guards/auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { BroadcastHubService, PopupService, SignalRService } from './services';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    providers: [
        AuthGuard,
        ConfirmationService,
        SignalRService,
        BroadcastHubService,
        PopupService
    ],
    exports: []
})
export class CoreModule {}
