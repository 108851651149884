/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component, OnInit } from '@angular/core';
import GoldeyeCalendar from '../goldeye-calendar';

@Component({
    selector: 'app-one-month-with-time-calendar',
    inputs: [
        'value',
        'name',
        'range',
        'labelClass',
        'inputClass',
        'label',
        'controls',
        'disabled',
        'fieldAccess',
        'dateFormat',
        'appendTo'
    ],
    outputs: ['selection'],
    templateUrl: './one-month-with-time-calendar.component.html',
    styleUrls: ['./one-month-with-time-calendar.component.scss']
})
export class OneMonthWithTimeCalendarComponent extends GoldeyeCalendar implements OnInit {
    ngOnInit() {
        this.setClassNames();
        this.setDefaultControls();
    }
}
