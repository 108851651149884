<div *mobxAutorun class="lookup-list">
    <p-confirmDialog
        appendTo="body"
        header="Confirmation"
        key="delete-attachment-confirmation-{{ lookupList.title }}"
        icon="pi pi-exclamation-triangle"
        [style]="{ width: '425px' }"
    >
    </p-confirmDialog>

    <p-dataView
        #dv
        [loading]="lookupList.listLoading"
        [value]="lookupList.items"
        filterBy="displayValue"
    >
        <p-header>
            <div class="p-grid p-justify-between p-align-center">
                <div class="p-col-4">{{ lookupList.title }}</div>

                <div class="p-col-6 p-xl-7">
                    <div class="p-inputgroup">
                        <input
                            type="search"
                            style="width: 100%"
                            pInputText
                            placeholder="Search by name"
                            (keyup)="dv.filter($any($event.target).value, 'contains')"
                        />
                        <span class="p-inputgroup-addon">
                            <i
                                class="fa fa-search"
                                style="line-height: 1.25"
                            ></i>
                        </span>
                    </div>
                </div>
            </div>
        </p-header>

        <ng-template let-lookup let-rowIndex pTemplate="listItem">
            <div class="p-col-12 g-list-item">
                <div class="p-col-8 p-xl-10 lookup-list__display-value">
                    {{ lookup?.displayOrder }} - {{ lookup?.displayValue }}
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
