<div class="p-grid p-align-center control-padding">
    <div class="{{ colorClass }}">
        <p-colorPicker
            name="{{ name }}"
            [disabled]="true"
            [ngModel]="color"
            [ngClass]="{ 'round-colors': useRoundColorInputs }"
        >
        </p-colorPicker>
    </div>

    <div class="{{ textClass }}">
        <span class="legend-text">{{ text }}</span>
    </div>
</div>
