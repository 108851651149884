<div class="card no-margin" *mobxAutorun>
    <app-goldeye-page-header
        [title]=""
        titleSize="hidden"
        controlSize="p-md-12"
    >
        <div class="page-help"></div>
        <ng-container class="page-controls" *ngIf="form.initialized">
            <div class="p-col-4 action-button">
                <h2 class="header-icons">
                    {{ header }}
                </h2>
            </div>

            <div class="p-col-8 action-button">
                <h2 class="main-header">
                    <i
                        class="fa fa-table"
                        (click)="onBoardIconClick()"
                        style="color: #b0bec5"
                    ></i>
                    <i class="fa fa-chart-area" style="color: #71b2c1"></i>
                    {{ title }}
                </h2>
            </div>
        </ng-container>
    </app-goldeye-page-header>
    <p-tabView orientation="left" (onChange)="onTabChange($event.index)">
        <p-tabPanel tooltip="Home">
            <ng-template pTemplate="header">
                <i class="fa fa-home"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
        <p-tabPanel tooltip="Timeline">
            <ng-template pTemplate="header">
                <i class="fa fa-arrow-down"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
        <p-tabPanel tooltip="Action Items">
            <ng-template pTemplate="header">
                <i class="fa fa-hand-point-right" style="color: #dac659"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
        <p-tabPanel tooltip="Work Orders">
            <ng-template pTemplate="header">
                <i class="fa fa-cogs" style="color: #8b8460"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
        <p-tabPanel tooltip="Inspections">
            <ng-template pTemplate="header">
                <i class="far fa-list-alt" style="color: #558b2f"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
        <p-tabPanel tooltip="Claims">
            <ng-template pTemplate="header">
                <i class="fa fa-dollar-sign" style="color: #607d8b"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
        <p-tabPanel tooltip="Jobs">
            <ng-template pTemplate="header">
                <i class="fa fa-globe" style="color: #8d6d78"></i>
            </ng-template>
            <app-under-construction></app-under-construction>
        </p-tabPanel>
    </p-tabView>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
