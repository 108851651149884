import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IAttachmentModel } from '@app/core/models/storage/attachment.model';
import { SelectItem } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-attachment-selection',
    templateUrl: './attachment-selection.component.html',
    styleUrls: ['./attachment-selection.component.scss']
})
export class AttachmentSelectionComponent {
    @Input() attachment: IAttachmentModel;
    @Input() types?: SelectItem[] = null;

    fileAttachmentIcon(fileExtension) {
        switch (fileExtension) {
            case 'xls':
            case 'xlsx':
            case 'xlsm':
            case 'xlt':
            case 'xltm':
            case 'xla':
            case 'xlam':
            case 'csv':
                return 'fa fa-file-excel';
            case 'doc':
            case 'docm':
            case 'docx':
            case 'dot':
            case 'dotm':
            case 'dotx':
            case 'odt':
            case 'rtf':
                return 'fa fa-file-word';
            case 'pot':
            case 'potm':
            case 'potx':
            case 'ppa':
            case 'ppam':
            case 'pps':
            case 'ppsm':
            case 'ppsx':
            case 'ppt':
            case 'pptm':
            case 'pptx':
                return 'fa fa-file-powerpoint';
            case 'pdf':
                return 'fa fa-file-pdf';
            default:
                return 'fa fa-file-alt';
        }
    }
}
