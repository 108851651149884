import { commonEnvironment } from './environment.common';

export const environment = {
    production: true,
    environmentName: 'QA',
    environmentCode: 'QA',
    appConfig: {
        baseUrl: 'https://goldeye-api-01-501.azurewebsites.net/api/',
        manualVersion: '0.0.0',
        ...commonEnvironment.appConfig
    },
    authConfig: {
        clientConfigurationBaseUrl: 'https://cdn.auth0.com',
        clientID: 'KaS71Z1faCqYvHtp3l48WaOnFZ9T9yxt',
        domain: 'edisonproperties.auth0.com',
        // eslint-disable-next-line max-len
        redirectUrl: 'https://qa.goldeye.edisonproperties.ca/callback', // was: 'http://goldeye-website-01-501.azurewebsites.net',
        responseType: 'token id_token',
        scope: 'openid profile',
        tenant: 'Edison Properties - Goldeye',
        customDomain: 'login.edisonproperties.ca'
    },
    adminApiUrl: 'https://goldeye-api-01-110.azurewebsites.net/api/log'
};
