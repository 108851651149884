<div class="p-grid p-align-center control-padding">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <textarea
            pInputTextarea
            [appHighlightOnValidationError]="name"
            [ngModel]="model"
            readonly="readonly"
            name="{{ name }}"
            [autoResize]="true"
            [rows]="rows"
        >
        </textarea>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
