import { types, flow, Instance } from 'mobx-state-tree';
import {
    withItems,
    withCount,
    withPaginator
} from '@app/shared/utils/mobx-mixins';
import { PropertyTeamMembersQuery } from '../../queries/property-team-member-query';
import { PropertyTeamMemberModel } from './property-team-member.model';
import { SortOrder } from '@app/core/models';
import { PropertyTeamMemberSearchModel } from './property-team-member-search.model';

export const PropertyTeamMemberGridModel = types
    .compose(
        withItems('property-team-members', PropertyTeamMemberModel),
        withCount('property-team-members/count'),
        withPaginator('auditId', SortOrder.Descending),
        types.model({
            loading: true,
            search: PropertyTeamMemberSearchModel
        })
    )
    .views((self) => ({
        get query(): PropertyTeamMembersQuery {
            return {
                ...self.paginator.query,
                ...self.search.terms.query
            };
        },
        get listLoading() {
            return Boolean(!self.search.initialize || self.loading);
        },
        get itemName() {
            if (self.totalRecords > 1) {
                return 'PropertyTeamMembers';
            }
            return 'PropertyTeamMember';
        }
    }))
    .actions((self) => ({
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([
                    self.fetchItems(self.query),
                    self.fetchCount(self.query)
                ]);
            } finally {
                self.loading = false;
            }
        })
    }));
type PropertyTeamMemberGridModelType = Instance<typeof PropertyTeamMemberGridModel>;
export interface IPropertyTeamMemberGridModel extends PropertyTeamMemberGridModelType {}
