import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import { DropdownModel } from '@app/shared/models/goldeye-select-item';
import { BuildingManagerTypes } from '../../enums/building-manager-types';

export const BuildingManagerDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            propertyDropdown: DropdownModel,
            noEmptyVendorDropdown: DropdownModel,
            propertyManagerDropdown: DropdownModel,
            maintenanceManagerDropdown: DropdownModel
        })
        .views((self) => ({
            filteredUserDropdown(
                selectedUsers: { userId: number }[],
                type: BuildingManagerTypes
            ) {
                if (type === BuildingManagerTypes.PropertyManager) {
                    return self.propertyManagerDropdown.filter(
                        (u) =>
                            !selectedUsers.some(
                                (selectedUser) =>
                                    u.value === selectedUser.userId
                            )
                    );
                } else if (type === BuildingManagerTypes.MaintenanceManager) {
                    return self.maintenanceManagerDropdown.filter(
                        (u) =>
                            !selectedUsers.some(
                                (selectedUser) =>
                                    u.value === selectedUser.userId
                            )
                    );
                }
            }
        }))
);
type BuildingManagerDropdownsModelType = Instance<
    typeof BuildingManagerDropdownModel
>;
export interface IBuildingManagerDropdownsModel
    extends BuildingManagerDropdownsModelType {}
