import { types, Instance } from 'mobx-state-tree';
import { utcDateToLocalDateString } from '@app/shared/utils/date-helper';

export const WorkOrderAssignedUserModel = types
    .model({
        id: types.maybeNull(types.number),

        assignedUserId: types.maybeNull(types.number),
        assignedUsername: types.maybeNull(types.string),
        assignedUserPhone: types.maybeNull(types.string),
        assignedByUserId: types.maybeNull(types.number),
        skillId: types.maybeNull(types.number),
        skillDisplayValue: types.maybeNull(types.string),
        skillIcon: types.maybeNull(types.string),
        assignedByUserEmail: types.maybeNull(types.string),
        dateClockedInClockedOut: types.maybeNull(types.string),
        dateAssigned: types.maybeNull(types.string)
    })
    .views((self) => ({
        get formattedDateAssigned() {
            return utcDateToLocalDateString(
                self.dateAssigned,
                'shortDate'
            );
        },
        get formattedDateClockedInClockedOut() {
            return utcDateToLocalDateString(
                self.dateClockedInClockedOut,
                'medium'
            );
        }
    }))
    .actions((self) => ({
        setAssignedUserId: (val: number) => (self.assignedUserId = val),
        setAssignedByUserId: (val: number) => (self.assignedByUserId = val),
        setSkillId: (val: number) => (self.skillId = val),
        setAssignedByUserEmail: (val: string) =>
            (self.assignedByUserEmail = val)
    }));

type WorkOrderAssignedUserModelType = Instance<
    typeof WorkOrderAssignedUserModel
>;
export interface IWorkOrderAssignedUserModel
    extends WorkOrderAssignedUserModelType {}
