import { Instance, types } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';
import { anyUtcDateFormatToString } from '../utils/date-helper';

export const LookupModel = types
    .model({
        id: types.identifierNumber,
        displayValue: types.maybeNull(types.string),
        displayOrder: types.maybeNull(types.number),
        tooltipDescription: types.maybeNull(types.string),
        fullDescription: types.maybeNull(types.string),
        createdBy: types.maybeNull(types.string),
        createdDate: types.maybeNull(types.union(types.string, types.Date)),
        modifiedBy: types.maybeNull(types.string),
        modifiedDate: types.maybeNull(types.union(types.string, types.Date))
    })
    .views((self) => ({
        get createdOnDate() {
            if (self.createdDate) {
                return anyUtcDateFormatToString(
                    self.createdDate,
                    'MM/dd/yy hh:mm a'
                );
            }
        },
        get modifiedOnDate() {
            if (self.modifiedDate) {
                return anyUtcDateFormatToString(
                    self.modifiedDate,
                    'MM/dd/yy hh:mm a'
                );
            }
        }
    }))
    .actions((self) => ({
        setDisplayValue: (value: string) => (self.displayValue = value),
        setDisplayOrder: (value: string) =>
            (self.displayOrder = parseInt(value, 10)),
        setTooltipDescription: (value: string) =>
            (self.tooltipDescription = value),
        setFullDescription: (value: string) => (self.fullDescription = value)
    }));

type modelType = Instance<typeof LookupModel>;
export interface ILookupModel extends modelType {}

export const lookupResourceBuilder = (endPoint) =>
    resource(endPoint, LookupModel);
