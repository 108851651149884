<div class="p-grid control-padding">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-inputSwitch
            [ngModel]="model"
            [name]="name"
            (onChange)="onChange($event)"
        ></p-inputSwitch>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
