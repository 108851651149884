import { Component } from '@angular/core';

@Component({
    template: `
        <span [title]="contents"> {{ contents | date: 'mediumDate' }} </span>
    `
})
export class DateOnlyGridColumnComponent {
    contents: any;
    props?: any;
}
