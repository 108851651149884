export class RoleLookup implements IRoleLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
    displayOrder: number;
}

export class IRoleLookup {
    label: string;
    value: {
        id: number;
        name: string;
        code: string;
    };
    displayOrder: number;
}
