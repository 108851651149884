import { types, Instance } from 'mobx-state-tree';
import { stringToDateTime } from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';
import { withAttachments } from '@app/core/models/storage/with-attachment.model';
import { VendorRatingModel } from '../vendor-ratings/vendor-rating.model';

export const VendorModel = types.compose(
    withAttachments(
        ({ createdBy, id }) => `${createdBy}_vendor_${id}`,
        'vendor'
    ),
    resource(
        'vendors',
        types
            .model({
                id: types.identifierNumber,

                vendorTypeId: types.maybeNull(types.number),
                vendorStatusId: types.maybeNull(types.number),
                vendorTypeDisplayValue: types.maybeNull(types.string),
                vendorStatusDisplayValue: types.maybeNull(types.string),
                vendorVatNumber: types.maybeNull(types.string),
                description: types.maybeNull(types.string),
                code: types.maybeNull(types.string),
                name: types.maybeNull(types.string),
                contactName: types.maybeNull(types.string),
                vendorEmail: types.maybeNull(types.string),
                mobile: types.maybeNull(types.string),
                office: types.maybeNull(types.string),
                isPurchaseOrderRequired: false,
                isVendorVatRegistered: false,
                score: types.maybeNull(types.number),
                reviewCount: types.maybeNull(types.number),

                ratings: types.array(VendorRatingModel),

                createdDate: types.maybeNull(
                    types.union(types.string, types.Date)
                ),
                createdBy: types.maybeNull(types.string),
                modifiedBy: types.maybeNull(types.string)
            })
            .views((self) => ({
                get createdOnDate() {
                    if (self.createdDate) {
                        return stringToDateTime(self.createdDate);
                    }
                },
                userRating(userId: number) {
                    if (self.ratings && self.ratings.length > 0) {
                        return self.ratings.find((r) => r.userId === userId);
                    }
                }
            }))
            .views(() => ({}))
            .actions((self) => ({
                // setter action example
                setVendorTypeId: (value) => (self.vendorTypeId = value),
                setVendorStatusId: (value) => (self.vendorStatusId = value),
                setVendorVatNumber: (value) => (self.vendorVatNumber = value),
                setDescription: (value) => (self.description = value),
                setCode: (value) => (self.code = value),
                setName: (value) => (self.name = value),
                setContactName: (value) => (self.contactName = value),
                setVendorEmail: (value) => (self.vendorEmail = value),
                setMobile: (value) => (self.mobile = value),
                setOffice: (value) => (self.office = value),
                setIsPurchaseOrderRequired: (value) =>
                    (self.isPurchaseOrderRequired = value),
                setIsVendorVatRegistered: (value) =>
                    (self.isVendorVatRegistered = value)
            }))
    )
);

type VendorModelType = Instance<typeof VendorModel>;
export interface IVendorModel extends VendorModelType {}
