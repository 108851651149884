import { Injectable } from '@angular/core';
import { WindowFeatures } from '@app/core/models/popup/window-features';

@Injectable()
export class PopupService {
    public openPopupWithUrl(
        url: string,
        title = '',
        options: WindowFeatures = new WindowFeatures()
    ): Window {
        const newWindow = window.open(
            url,
            title,
            this.parseWindowFeaturesOptions(options)
        );
        newWindow.focus();
        return newWindow;
    }

    public openPopup(
        commands: any[],
        title = '',
        options: WindowFeatures = new WindowFeatures()
    ): Window {
        return this.openPopupWithUrl(commands.join('/'), title, options);
    }

    private parseWindowFeaturesOptions(options: WindowFeatures) {
        return Object.keys(options).reduce((val, key) => {
            if (options[key] !== null && options[key] !== undefined) {
                return (
                    val +
                    (val.length > 0 ? ',' : '') +
                    `${key}=${Number(options[key])},`
                );
            }
            return val;
        }, '');
    }
}
