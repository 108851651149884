import { Component } from '@angular/core';

@Component({
    template: `
        <span>{{ contents }}</span>
    `,
    selector: 'app-text-grid-column'
})
export class TextGridColumnComponent {
    contents: any;
    props?: any;
}
