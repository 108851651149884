<div *mobxAutorun>
    <p-card
        *ngFor="let pa of propertyAccess.items"
        styleClass="p-card-shadow user-card"
    >
        <h2>{{ pa.userFullName }}</h2>
        <hr />
        <ul class="user-card__roles">
            <li *ngFor="let userRole of pa.userRoles">{{ userRole }}</li>
        </ul>
        <ul class="user-card__properties">
            <li>
                <b>Email:</b
                ><a href="mailto:{{ pa.userEmail }}">{{ pa.userEmail }}</a>
            </li>
            <li *ngIf="pa.userDirectNumber">
                <b>Phone:</b> {{ pa.userDirectNumber }}
            </li>
            <li *ngIf="pa.userMobileNumber">
                <b>Mobile:</b> {{ pa.userMobileNumber }}
            </li>
        </ul>
    </p-card>
</div>
