import { types, getSnapshot, Instance } from 'mobx-state-tree';
import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import { BuildingManagersQuery } from '../../queries/building-manager-query';
import { BuildingManagerTypes } from '../../enums/building-manager-types';

export const BuildingManagerSearchTermsModel = types
    .model({
        propertyId: searchTermOf(types.number),
        isDefault: searchTermOf(types.boolean),
        Type: searchTermOf(types.number)
    })
    .actions((self) => ({
        setPropertyId: (propertyId: number) =>
            (self.propertyId.value = propertyId),
        setIsDefault: (isDefault: boolean) =>
            (self.isDefault.value = isDefault),
        setType: (type: BuildingManagerTypes) => (self.Type.value = type)
    }))
    .views((self) => ({
        get query(): BuildingManagersQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        }
    }));
type BuildingManagerSearchTermsModelType = Instance<
    typeof BuildingManagerSearchTermsModel
>;
export interface IBuildingManagerSearchTermsModel
    extends BuildingManagerSearchTermsModelType {}
