import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISearchModel } from '@app/shared/utils/mobx-mixins';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
    selector: 'app-grid-search-bar',
    templateUrl: './grid-search-bar.component.html',
    styleUrls: ['./grid-search-bar.component.scss']
})
export class GridSearchBarComponent implements OnInit, OnDestroy {
    matchAllTextChanged = new Subject<string>();
    matchAllTextChanged$: Subscription;

    @Input() search: ISearchModel;
    @Input() placeholder?: string;

    ngOnInit() {
        this.placeholder = this.placeholder || 'Type here ...';
        this.matchAllTextChanged$ = this.matchAllTextChanged
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .pipe(tap(this.search.matchAll.setValue))
            .subscribe(_ => {});
    }

    onMatchAllKeyUp($event) {
        this.matchAllTextChanged.next($event.target.value);
    }

    onClearBtnClick() {
        this.search.matchAll.setValue('');
    }

    ngOnDestroy() {
        if (this.matchAllTextChanged$) {
            this.matchAllTextChanged$.unsubscribe();
        }
    }
}
