<div class="no-margin p-grid" *mobxAutorun>
    <app-action-item-dialog
        *ngIf="selectedActionItemForm"
        class="no-margin"
        [store]="selectedActionItemForm"
        [dialogVisible]="showFormDialog"
        [properties]="properties"
        (closeAction)="onCloseFormDialog()"
        [editTabSelected]="openEdit"
    ></app-action-item-dialog>
    <p-confirmDialog
        header="Are you sure you want to proceed?"
        icon="fa fa-info"
        class="small-modal"
    >
    </p-confirmDialog>
    <div
        class="p-col-12 p-md-6 p-grid"
        *ngIf="showButtons.includes('filter') && grid.search.dropdowns.dropdownsReady"
    >
        <p-selectButton
            [options]="grid.search.dropdowns.noEmptyPropertyBoardItemStatusDropdown"
            [multiple]="true"
            [ngModel]="grid.search.terms.statuses.value"
            (onChange)="grid.search.terms.setStatuses($event.value)"
        ></p-selectButton>
        <button
            pButton
            class="no-left-border-radius"
            [label]="
                grid.search.terms.actionItemType.value !== 2
                    ? 'All Action Items'
                    : 'All Incident Reports'
            "
            type="button"
            [ngClass]="{
                'main-action-btn':
                    !grid.search.terms.statuses.value ||
                    grid.search.terms.statuses.value.length === 0
            }"
            (click)="grid.search.terms.setStatuses()"
        ></button>
    </div>
    <div class="p-col-12 p-md-6 p-grid p-justify-end" *ngIf="showButtons.includes('add')">
        <button
            pButton
            label="Add New"
            type="button"
            icon="fa fa-plus"
            pTooltip="New Action Item"
            tooltipPosition="bottom"
            tooltipStyleClass="action-item-tooltip"
            (click)="onAddNewBtnClick()"
        ></button>
    </div>
    <div class="p-col-12 p-md-12 no-padding">
        <p-table
            [sortField]="grid.paginator.sortField"
            [responsive]="true"
            [sortOrder]="grid.paginator.sortOrder"
            [value]="grid.items"
            [columns]="columns"
            [loading]="grid.listLoading"
            [rowHover]="true"
            [paginator]="grid.paginator.showPages"
            [rows]="grid.paginator.rows"
            [lazy]="true"
            [totalRecords]="grid.totalRecords"
            [rowsPerPageOptions]="[5, 10, 20, 50, 100]"
            (onLazyLoad)="onGridLazyLoad($event)"
            selectionMode="single"
        >
            >
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length + gridButtons.length">No records found</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th
                        *ngFor="let col of columns"
                        [ngStyle]="{ width: col.width ? col.width : 'auto' }"
                        [pSortableColumn]="col.sortColumn ? col.sortColumn : col.field"
                        [pSortableColumnDisabled]="col.disableSorting"
                    >
                        {{ col.header }}
                        <p-sortIcon
                            *ngIf="!col.disableSorting"
                            [field]="col.sortColumn ? col.sortColumn : col.field"
                        ></p-sortIcon>
                    </th>
                    <th
                        [colSpan]="gridButtons.length"
                        *ngIf="gridButtons.length > 0"
                        [style.width]="gridButtons.length * buttonColumnWidth + '%'"
                    >
                        Actions
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr *ngIf="!grid.listLoading">
                    <td *ngFor="let col of columns">
                        <span class="p-column-title">{{ col.header }}</span>
                        <app-grid-column [column]="col" [row]="rowData"></app-grid-column>
                    </td>
                    <td *ngIf="showButtons.includes('view')">
                        <button
                            pButton
                            type="button"
                            label="View"
                            icon="fa fa-search"
                            style="width: 100%"
                            (click)="onViewBtnClick(rowData.id, rowData.propertyId)"
                        ></button>
                    </td>
                    <td *ngIf="showButtons.includes('goTo')">
                        <button
                            pButton
                            type="button"
                            label="View"
                            icon="fa fa-search"
                            style="width: 100%"
                            (click)="onGoToBtnClick(rowData.id, rowData.propertyId)"
                        ></button>
                    </td>
                    <td *ngIf="showButtons.includes('edit')">
                        <button
                            pButton
                            type="button"
                            label="Edit"
                            icon="fa fa-edit"
                            style="width: 100%"
                            (click)="onEditBtnClick(rowData.id, rowData.propertyId)"
                        ></button>
                    </td>
                    <td *ngIf="showButtons.includes('print')">
                        <button
                            pButton
                            type="button"
                            label="Print"
                            icon="fa fa-print"
                            style="width: 100%"
                            (click)="onPrintBtnClick(rowData.id)"
                        ></button>
                    </td>
                    <td *ngIf="showButtons.includes('delete')">
                        <button
                            pButton
                            type="button"
                            label="Delete"
                            icon="fas fa-trash-alt"
                            style="width: 100%"
                            (click)="onDeleteBtnClick(rowData)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
