<div class="p-grid page-header">
    <div [ngClass]="titleClass">
        <h1>
            <i *ngIf="icon" [style.color]="iconColor" class="{{ icon }}"></i>
            {{ title }}
        </h1>
        <ng-content select=".page-help"></ng-content>
    </div>
    <div [ngClass]="controlClass">
        <ng-content select=".page-controls"></ng-content>
    </div>
</div>
