import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Column, ITreeNode } from '@app/core/models';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';
import { IPropertyLocationModel } from '@app/shared/models/property-location.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-locations',
    templateUrl: './property-locations.component.html',
    styleUrls: ['./property-locations.component.scss']
})
export class PropertyLocationsComponent implements OnInit {
    @Input() properties: ISelectItem[];
    @Input() units: ISelectItem[];

    @Input() selectedProperty?: number;
    @Input() selectedUnits: number[];

    @Input() unitDictionary: {};
    @Input() propertyDictionary: {};

    @Output() selectedPropertyChanged = new EventEmitter<number>();
    @Output() selectedUnitsChanged = new EventEmitter<IPropertyLocationModel[]>();

    selectedLocationsTreenodes: ITreeNode[] = [];
    @Input() locationTreenodes: ITreeNode[];
    @Input() readonly = false;

    showEditPropertyLocationDialog = false;

    treeNodeCols: Column[];

    @Input() fieldAccess?: IFieldAccessModel;

    ngOnInit() {
        this.treeNodeCols = [{ field: 'label', header: 'locations' }];
    }

    onPropertyLocationSelection() {
        this.showEditPropertyLocationDialog = false;
        const selectedPropertyLocations = this.convertLocationsStringToPropertyLocationsModel(this.selectedUnits);
        this.selectedUnitsChanged.emit(selectedPropertyLocations);
    }

    editPropertyLocations() {
        this.selectedLocationsTreenodes = this.getLocationsAsTreenodes(this.selectedUnits);
        this.showEditPropertyLocationDialog = true;
    }

    regenerateTreenodes() {
        this.selectedLocationsTreenodes = this.getLocationsAsTreenodes(this.selectedUnits);
    }

    onDialogHide() {
        this.showEditPropertyLocationDialog = false;
    }

    getLocationsAsTreenodes(selectedUnits): ITreeNode[] {
        const locations = this.convertLocationsStringToPropertyLocationsModel(selectedUnits);

        const result = {};
        const resultTreeNodeArray: ITreeNode[] = [];

        if (locations && locations.length > 0) {
            locations.forEach((l) => {
                if (l.propertyId) {
                    if (result[l.propertyCode] == null) {
                        result[l.propertyCode] = [];
                    }
                }

                if (l.unitId) {
                    result[l.unitPropertyCode] =
                        result[l.unitPropertyCode] == null ? [l.unitCode] : [...result[l.unitPropertyCode], l.unitCode];
                }
            });

            Object.keys(result).forEach((propertyCode) => {
                const unitCodes = result[propertyCode];

                const unitToTreeNode = (unitCode): ITreeNode => ({
                    label: unitCode,
                    data: { label: unitCode },
                    expandedIcon: 'far fa-folder-open',
                    collapsedIcon: 'fa fa-folder',
                    children: null
                });

                const treeNode: ITreeNode = {
                    label: propertyCode,
                    data: { label: propertyCode },
                    expandedIcon: 'far fa-folder-open',
                    collapsedIcon: 'fa fa-folder',
                    children: unitCodes ? unitCodes.map(unitToTreeNode) : null,
                    expanded: true
                };

                resultTreeNodeArray.push(treeNode);
            });
        }

        return resultTreeNodeArray;
    }

    convertLocationsStringToPropertyLocationsModel(selectedUnits) {
        const result = selectedUnits.map((selectedUnitId) => {
            // convert a unit to a propertyLocation object
            const unit = this.unitDictionary[selectedUnitId];
            const property = this.propertyDictionary[unit.parentId];

            return {
                propertyId: +property.value,
                propertyCode: property.label,
                unitId: +unit.value,
                unitCode: unit.label,
                unitPropertyCode: property.label
            };
        });

        return result;
    }
}
