import { Component } from '@angular/core';

@Component({
    template: `
        <p>
            <i [ngClass]="contents" [style.color]="getColor(props)" ></i>
        </p>
    `,
    selector: 'app-icon-grid-column',
    styles: ['p { text-align: center; margin: 0; }', 'i { font-size: 24px; }']
})
export class IconGridColumnComponent {
    contents: any;
    props?: any;

    getColor(props) {
        if (props && props.color) {
            return props.color;
        }
    }
}
