import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-goldeye-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;

    @Input() labelClass?: string;
    @Input() inputClass?: string;

    @Input() model: boolean;
    @Output() change = new EventEmitter<boolean>();

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
    }

    onChange(event) {
        this.change.emit(event.checked);
    }
}
