import { withDropdowns } from '@app/shared/utils/mobx-mixins';
import { types, Instance } from 'mobx-state-tree';
import { DropdownModel } from '@app/shared/models/goldeye-select-item';

export const VendorDropdownModel = types.compose(
    withDropdowns(),
    types
        .model({
            vendorTypeDropdown: DropdownModel,
            vendorStatusDropdown: DropdownModel,
            vendorAttachmentTypeDropdown: DropdownModel
        })
        .views((self) => ({}))
);
type VendorDropdownsModelType = Instance<typeof VendorDropdownModel>;
export interface IVendorDropdownsModel extends VendorDropdownsModelType {}
