import { environment } from '@env/environment';
import * as qs from 'qs';

export function toUrl(endPoint: string, params: any = {}): string {
    const config = environment.appConfig;
    const url = new URL(`${config.baseUrl}${endPoint}`);
    url.search = qs.stringify(params);
    return url.toString();
}

export function addQueryParams(urlStr: string, params: any = {}): string {
    const url = new URL(urlStr);
    url.search = qs.stringify(params);
    return url.toString();
}
