import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { IUserAllowedFeature } from '@app/core/models';
import { Router } from '@angular/router';
import { AppLocalStorageService } from '../storage/app-local-storage.service';
import { environment } from '@env/environment.staging';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    private userAllowedFeaturesString = 'users_allowed_features';
    private permissionDeniedRouteString = 'permission-denied';
    private bypassSecurityFeatureCodeString = 'bypass';

    constructor(
        private userService: UserService,
        private appLocalStorage: AppLocalStorageService,
        public router: Router
    ) {}

    handleAuthorization(componentFeatureAccessCode: string) {
        if (componentFeatureAccessCode) {
            // make sure the user has access to the feature
            const userIsAuthorized = this.isUserAuthorizedForFeature(
                componentFeatureAccessCode
            );

            // good, return without redirecting
            if (userIsAuthorized) {
                return;
            }
        }

        // if we get here, then the user is not authorized
        this.router.navigate([this.permissionDeniedRouteString]);
    }

    public getLocalStorageKeyForUserFeatures() {
        return this.userAllowedFeaturesString;
    }

    public saveUsersAllowedFeatures(userEmail: string): void {
        // call the user service to retrieve allowed features
        const userAllowedFeatures = this.userService.getUserAllowedFeatures(
            userEmail
        );

        userAllowedFeatures.subscribe(featuresList => {
            // save the features in the local storage
            this.appLocalStorage.set(
                this.userAllowedFeaturesString,
                featuresList
            );
        });
    }

    public resetAuthorization() {
        // remove the list of features in the local storage
        this.appLocalStorage.remove(this.userAllowedFeaturesString);
    }

    public isUserAuthorizedForFeature(featureCode: string): boolean {
        let userIsAuthorized = false;

        if (featureCode) {
            // specially check if a bypass code was supplied
            userIsAuthorized = this.bypassSecurityInDevIfBypassCodePassed(
                featureCode
            );

            if (userIsAuthorized) {
                return userIsAuthorized;
            }

            // if no bypass feature code was passed, continue
            let featureList: Array<IUserAllowedFeature> = null;

            // grabs the list of allowed features for the currently
            // logged in user
            featureList = this.getAllowedFeaturesForCurrentUser();

            // check if the featureName mentioned
            // exists in the array above
            if (featureList) {
                userIsAuthorized = featureList.find(
                    f => f.featureCode === featureCode
                )
                    ? true
                    : false;
            }
        }

        return userIsAuthorized;
    }

    private bypassSecurityInDevIfBypassCodePassed(featureCode: string) {
        let bypassSecurity = false;
        if (
            !environment.production &&
            featureCode &&
            featureCode.toLowerCase() === this.bypassSecurityFeatureCodeString
        ) {
            bypassSecurity = true;
        }

        return bypassSecurity;
    }

    private getAllowedFeaturesForCurrentUser(): IUserAllowedFeature[] {
        let featureList: IUserAllowedFeature[] = null;
        // get the list of features
        // the user is authorized for
        const featureListObservable = this.appLocalStorage.select(
            this.userAllowedFeaturesString
        );

        featureListObservable.subscribe(val => {
            if (val) {
                // the value may be retrieved from
                // the localstorage or the in-app storage
                if (typeof val === 'string') {
                    featureList = JSON.parse(val);
                } else {
                    featureList = val;
                }
            }
        });

        return featureList;
    }
}
