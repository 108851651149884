import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Column } from '@app/core/models';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { ICommentModel } from '@app/shared/models/comment/comment.model';
import { ICommentGridModel } from '@app/shared/models/comment/comment-grid.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-comment-list-grid',
    templateUrl: './comment-list-grid.component.html',
    styleUrls: ['./comment-list-grid.component.scss']
})
export class CommentListGridComponent {
    selectedItem: ICommentModel;

    @Input() grid: ICommentGridModel;
    @Input() columns: Column[];

    viewFullCommentDialog = false;
    selectedComment = '';

    constructor() {}

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    onViewComment(row: ICommentModel) {
        const lastColumn = this.columns.slice(-1)[0];
        if (row && lastColumn) {
            this.selectedComment = row[lastColumn.field];
        }

        this.viewFullCommentDialog = true;
    }

    onDialogHide() {
        this.viewFullCommentDialog = false;
        this.selectedComment = '';
    }
}
