export const RoutesNames = {
    BASE: '/teams',

    TEAMS: 'list',
    EDIT_TEAM: 'list/edit/:id',
    TEAM_BOARD: 'list/board/:id',
    TEAM_DASHBOARD: 'list/dashboard/:id',
    NEW_TEAM_QUICKSTART: 'add',

    TEAM_SETTING: 'settings',
    PROPERTY_ACCESS: 'settings/rm-access',
    NEW_PROPERTY_ACCESS: 'settings/rm-access/new',
    EDIT_PROPERTY_ACCESS_BY_USER: 'settings/rm-access/edit-by-user/:id',
    EDIT_PROPERTY_ACCESS_BY_PROPERTY: 'settings/rm-access/edit-by-property/:id',

    MAINTENANCE_PROPERTY_ACCESS: 'settings/maintenance-access',
    MAINTENANCE_NEW_PROPERTY_ACCESS: 'settings/maintenance-access/new',
    MAINTENANCE_EDIT_PROPERTY_ACCESS_BY_USER: 'settings/maintenance-access/edit-by-user/:id',
    MAINTENANCE_EDIT_PROPERTY_ACCESS_BY_PROPERTY:
        'settings/maintenance-access/edit-by-property/:id',
    ACTION_ITEM_TYPES: 'settings/action-item-types',

    MEETINGS: ':propertyteamid/meetings',
    SHOW_MEETINGS: ':propertyteamid/meetings/:id'
};
