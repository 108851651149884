import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import { IUserRole, UserRole, IUserRoleLookup, UserRoleLookup, IUser, IRoleLookup } from '@app/core/models';

@Injectable({
    providedIn: 'root'
})
export class RoleLookupService {
    private baseUrl = environment.appConfig.baseUrl;

    constructor(private http: HttpClient) {}

    getRoleLookupsExcludingAdmin() {
        const url = `${this.baseUrl}rolelookups/excludeAdmin`;
        return this.http.get<IRoleLookup[]>(url);
    }

    mapUserRolesToUserRoleLookups(userRoles: IUserRole[]): IUserRoleLookup[] {
        const viewModels = new Array<IUserRoleLookup>();

        userRoles.forEach(element => {

            const viewModel: UserRoleLookup = {
                label: element.roleName,
                value: {
                    id: element.roleId,
                    name: element.roleName,
                    code: element.roleName
                },
                displayOrder: element.displayOrder,
                statusId: element.statusId,
                displayStatus: element.displayStatus
            };

            viewModels.push(viewModel);
        });

        return viewModels;
    }

    mapUserRoleLookupsToUserRoles(lookupUserRoles: UserRoleLookup[], userItem: IUser): IUserRole[] {
        const viewModels = new Array<IUserRole>();

        const now = new Date();
        const currentUserName = localStorage.getItem('profile_nickname');

        // this handles granted roles which were in the source
        lookupUserRoles.forEach(element => {

            const originalRole = userItem.userRoles.find(x => x.roleId === element.value.id);

            const viewModel = new UserRole();

            viewModel.roleId = element.value.id;
            viewModel.roleName = element.label;
            viewModel.createdBy = currentUserName;
            viewModel.createdDate = now;
            viewModel.dateGranted = now;
            viewModel.dateRevoked = null;
            viewModel.description = '';
            viewModel.displayOrder = element.displayOrder;
            viewModel.isArchived = false;
            viewModel.modifiedBy = currentUserName;
            viewModel.modifiedDate = now;
            viewModel.displayStatus = element.displayStatus;
            viewModel.statusId = element.statusId;
            // note: element.statusId will be -1 if it was moved over
            // from the target available roles list (ie: new role granted)
            viewModel.userId = userItem.attributes.userId;

            viewModels.push(viewModel);
        });

        return viewModels;
    }
}
