import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-error-occurred',
    templateUrl: './error-occurred.component.html',
    styleUrls: ['./error-occurred.component.scss']
})
export class ErrorOccurredComponent implements OnInit {
    constructor() {}
    ngOnInit() {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    go_back() {
        // The last route was the invalid route
        // so we have to go back two routes to get
        // to a route the user had access to
        const numberOfPagesToGoBackTo = -2;
        window.history.go(numberOfPagesToGoBackTo);
    }
}
