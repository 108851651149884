<div
    class="layout-wrapper"
    [ngClass]="{
        'p-input-filled': app.inputStyle === 'filled',
        'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive
    }"
    [class]="
        'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme
    "
>
    <app-topbar></app-topbar>

    <app-rightpanel></app-rightpanel>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-menu></app-menu>
        </div>
    </div>

    <div class="layout-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <p-toast position="top-left"></p-toast>
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>
