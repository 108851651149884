import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ICommentGridModel } from '@app/shared/models/comment/comment-grid.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent {
    @Input() list: ICommentGridModel;
    @Input() bubbleColor = '#71b2c1';
}
