import { types, getSnapshot, Instance } from 'mobx-state-tree';
import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import {
    createDateRange,
    toDateRange
} from '@app/shared/utils/date-range-helper';
import { PropertyTeamMembersQuery } from '../../queries/property-team-member-query';

export const PropertyTeamMemberSearchTermsModel = types
    .model({
        propertyId: searchTermOf(types.number),
        propertyTeamId: searchTermOf(types.number),
        roleId: searchTermOf(types.number)
    })
    .actions((self) => ({
        setPropertyId: (propertyId: number) =>
            (self.propertyId.value = propertyId)
    }))
    .views((self) => ({
        get query(): PropertyTeamMembersQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        }
    }));
type PropertyTeamMemberSearchTermsModelType = Instance<
    typeof PropertyTeamMemberSearchTermsModel
>;
export interface IPropertyTeamMemberSearchTermsModel
    extends PropertyTeamMemberSearchTermsModelType {}
