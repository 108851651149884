import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy
} from '@angular/core';
import { Column } from '@app/core/models';
import { IScoreModel } from '@app/shared/models/score.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-score-board',
    templateUrl: './score-board.component.html',
    styleUrls: ['./score-board.component.scss']
})
export class ScoreBoardComponent implements OnInit {
    @Input() loading: boolean;
    @Input() scores: IScoreModel[];
    @Input() caption: string;

    columns: Column[];

    ngOnInit() {
        this.columns = [
            { field: 'label', header: 'Items', type: 'text' },
            { field: 'value', header: 'Count', type: 'number' }
        ];
    }
}
