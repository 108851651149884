<div *mobxAutorun>
    <p-panel header="Important Events" styleClass="timeline">
        <ul>
            <li *ngFor="let item of timeline.items | mstArray; let i = index">
                <div
                    class="activity-detail"
                    *ngIf="i % 2 == 0"
                    style="text-align: right"
                >
                    <header class="event-header">
                        {{ item.cleanDescription }}
                    </header>
                    <div *ngIf="item.isForAttachment">
                        {{ item.logEntryTypeDescription }}
                    </div>
                    <div>{{ item.header }}</div>
                </div>
                <div class="activity-detail" *ngIf="i % 2 != 0"></div>

                <div class="activity-icon-wrapper">
                    <div class="activity-link"></div>

                    <a class="activity-logo">
                        <i [ngClass]="item.icon"></i>
                    </a>
                </div>

                <div
                    class="activity-detail"
                    *ngIf="i % 2 != 0"
                    style="text-align: left"
                >
                    <header class="event-header">
                        {{ item.cleanDescription }}
                    </header>
                    <div *ngIf="item.isForAttachment">
                        {{ item.logEntryTypeDescription }}
                    </div>
                    {{ item.header }}
                </div>
                <div class="activity-detail" *ngIf="i % 2 == 0"></div>
            </li>
        </ul>
    </p-panel>
</div>
