import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-goldeye-readonly-field',
    templateUrl: './readonly-field.component.html',
    styleUrls: ['./readonly-field.component.scss']
})
export class ReadonlyFieldComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;

    @Input() labelClass?: string;
    @Input() inputClass?: string;

    @Input() hideEmpty = false;
    @Input() defaultValue?: any;
    @Input() model: any;

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
        this.defaultValue = this.defaultValue || '';
        this.model = this.model || this.defaultValue;
    }
}
