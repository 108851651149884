import { Component, OnInit } from '@angular/core';
import { ValidationMessagesService } from '@app/core/services/validation/validation-messages.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-lookup-form',
    templateUrl: './lookup-form.component.html',
    styleUrls: ['./lookup-form.component.scss']
})
export class LookupFormComponent implements OnInit {
    lookup;
    constructor(
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private validationMessageService: ValidationMessagesService
    ) {
    }

    ngOnInit() {
        const config: any = this.config;
        this.lookup = config.data.lookup;
    }

    async onSaveBtnClicked() {
        const action = this.lookup.isNew ? this.lookup.save : this.lookup.update;
        await action();
        if (this.lookup.noErrors) {
            this.ref.close();
            this.validationMessageService.clearValidationMessages();
        }
    }

    onCancelBtnClicked() {
        this.ref.close();
    }
}
