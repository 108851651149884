export class IWorkRegion {
    id: number;
    name: string;
    toolTipDescription: string;
    fullDescriptiion: string;
    displayOrder: number;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    modifiedBy: string;
    isArchived: boolean;
}

export class WorkRegion implements IWorkRegion {
    id: number;    name: string;
    toolTipDescription: string;
    fullDescriptiion: string;
    displayOrder: number;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    modifiedBy: string;
    isArchived: boolean;
}
