<div class="no-margin" *mobxAutorun>
    <div class="no-margin p-grid sidebar">
        <div class="p-col-12 p-md-12 p-grid p-justify-end">
            <app-text-editor
                name="comment"
                class="p-col-12 p-md-12"
                labelClass="bigger-font p-col-12 p-grid"
                [model]="commentForm.comment.contents"
                (change)="commentForm.comment.setContents($event)"
                [useCustomControls]="true"
                height="100px"
                label="New Comment"
                [debounceTime]="200"
            >
            </app-text-editor>
            <button
                pButton
                class="main-action-btn"
                label="Add Comment"
                icon="fa fa-save"
                (click)="onSaveBtnClicked()"
                [disabled]="commentForm.comment.isBusy"
            ></button>
        </div>
        <div class="p-col-12 p-md-12 p-grid">
            <div class="bigger-font p-col-12 no-padding">History</div>
            <div class="p-col-12">
                <app-comment-list-grid
                    class="goldeye-mini-grid"
                    [grid]="comments"
                    [columns]="commentsColumns"
                ></app-comment-list-grid>
            </div>
        </div>
    </div>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
