import { UserRole } from '../user/user-role';

export interface IRole {
    id: number;
    name: string;
    displayOrder: number;
    description: string;
    isVisible: boolean;
    isArchived: boolean;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    modifiedBy: string;

    userRoles: UserRole[];
}

export class Role implements IRole {
    id: number;
    name: string;
    displayOrder: number;
    description: string;
    isVisible: boolean;
    isArchived: boolean;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    modifiedBy: string;

    userRoles: UserRole[];
}
