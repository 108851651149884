import {
    Component,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    ContentChildren,
    QueryList,
    TemplateRef,
    AfterContentInit
} from '@angular/core';
import { IGridModel } from '@app/shared/models/grid.model';
import { IKanbanCardModel } from '@app/shared/models/kanban-card.model';

import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { PrimeTemplate } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-kanban-board',
    templateUrl: './kanban-board.component.html',
    styleUrls: ['./kanban-board.component.scss']
})
export class KanbanBoardComponent<
    TColumn extends IGridModel<TCard>,
    TCard extends IKanbanCardModel
> implements AfterViewInit, AfterContentInit {
    @Input() columns: Array<TColumn>;
    @Output() cardClicked = new EventEmitter<TCard>();

    @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
    lineItemTemplate: TemplateRef<any>;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        // Bug: on mobile, when moving from one component
        // to the queue, the tabView data does not show
        // up until the next angular render cycle occurs.
        // Solution: This call fixes that bug
        this.cdr.detectChanges();
    }

    ngAfterContentInit() {
        const match = this.templates.find(
            (t) => t && t.getType() === 'goldeye-kanban-line-item'
        );
        if (match) {
            this.lineItemTemplate = match.template;
        }
    }

    onCardClick(card) {
        this.cardClicked.emit(card);
    }

    onLaneLazyLoad(evt, column) {
        applyPartialSnapshot(column.paginator, evt);
    }
}
