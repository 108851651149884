import { Component, Input, OnInit } from '@angular/core';
import { GridLegend } from '@app/shared/models/grid-legend.model';

@Component({
    selector: 'app-grid-legend',
    templateUrl: './grid-legend.component.html',
    styleUrls: ['./grid-legend.component.scss']
})
export class GridLegendComponent implements OnInit {
    @Input() legends: GridLegend[];
    @Input() legendClass = 'p-col-5 p-md-1-5';

    @Input() useRoundColorInputs = false;

    constructor() {}

    ngOnInit(): void {}
}
