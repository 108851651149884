import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IFieldAccessModel } from '@app/shared/models/field-access/field-access';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-goldeye-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() placeholder?: string;
    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() model: string;
    @Input() rows: number;
    @Input() focus?: boolean;
    @Input() disabled?: boolean;

    @Output() change = new EventEmitter<string>();

    @Input() fieldAccess?: IFieldAccessModel;

    ngOnInit() {
        this.placeholder = this.placeholder || '';
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
        this.rows = this.rows || 4;
    }

    onChange(event) {
        event.stopPropagation();
        this.change.emit(event.target.value);
    }
}
