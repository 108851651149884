<div *mobxAutorun>
    <h2>Recent comments</h2>
    <div *ngIf="!list.loading; else loading">
        <div *ngIf="list.items.length; else empty">
            <div *ngFor="let comment of list.items | mstArray">
                <p-card styleClass="p-card-shadow">
                    <h3>{{ comment.header }}</h3>
                    <p [innerHTML]="comment.contents"></p>
                </p-card>
            </div>
        </div>

        <ng-template #empty>
            <p>No comments</p>
        </ng-template>
    </div>
    <ng-template #loading>
        <app-progress-spinner></app-progress-spinner>
    </ng-template>
</div>
