import { types, flow, Instance } from 'mobx-state-tree';
import { withItems, withCount, withPaginator } from '@app/shared/utils/mobx-mixins';
import { IWorkOrderModel, WorkOrderModel } from './work-order.model';
import { SortOrder } from '@app/core/models';
import { WorkOrdersQuery } from '../../queries/work-orders/work-order.query';
import { SessionFilterModel } from '@app/shared/models/session-filters/session-filter.model';
import { WorkOrderSearchModel } from './work-order-search.model';
import { GridLegend } from '@app/shared/models/grid-legend.model';
import { getUniqueArrayObjectsForKey } from '@app/shared/utils/array-helper';

export const WorkOrderGridModel = types
    .compose(
        withItems('workorders', WorkOrderModel),
        withCount('workorders/count'),
        withPaginator('createdDate', SortOrder.Descending),
        types.model({
            caption: types.maybeNull(types.string),
            loading: true,
            search: WorkOrderSearchModel,
            sessionFilter: types.reference(SessionFilterModel)
        })
    )
    .views((self) => ({
        get query(): WorkOrdersQuery {
            return {
                ...self.paginator.query,
                ...self.search.terms.query,
                ...self.sessionFilter.query
            };
        },
        get listLoading() {
            return Boolean(!self.search.initialize || !self.sessionFilter.initialize || self.sessionFilter.loading || self.loading);
        },
        get itemName() {
            if (self.totalRecords > 1) {
                return 'Work Orders';
            }
            return 'Work Order';
        },
        withPriority(priorityId: number): Array<IWorkOrderModel> {
            return self.items.filter((wo) => wo.workOrderPriorityId === priorityId);
        },
        get gridLegendItems(): GridLegend[] {
            const workOrdersWithUniqueWorkOrderStatuses = getUniqueArrayObjectsForKey(self.items, 'workOrderStatusId') as IWorkOrderModel[];

            const gridLegends = workOrdersWithUniqueWorkOrderStatuses
                ? workOrdersWithUniqueWorkOrderStatuses
                      .sort((x) => x.workOrderStatusId)
                      .reverse()
                      .map((val) => ({
                          color: val.color,
                          text: val.workOrderStatusDescription
                      }))
                : [];

            return gridLegends;
        }
    }))
    .views((self) => ({
        withPrioritySortedByUserAssigned(priorityId: number, userId: number) {
            return self
                .withPriority(priorityId)
                .sort((a, b) => (a.assignedUserByUserId(userId)?.dateAssigned > b.assignedUserByUserId(userId)?.dateAssigned ? -1 : 1));
        }
    }))
    .actions((self) => ({
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([self.fetchItems(self.query), self.fetchCount(self.query)]);
            } finally {
                self.loading = false;
            }
        })
    }));
type WorkOrderGridModelType = Instance<typeof WorkOrderGridModel>;
export interface IWorkOrderGridModel extends WorkOrderGridModelType {}
