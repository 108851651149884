import { ISearchTerm } from '@app/shared/utils/mobx-mixins';
import { searchTermsUtils } from '@app/shared/utils/mobx-utils';
import { Instance, types, getSnapshot } from 'mobx-state-tree';
import { PropertyAccessDropdownModel } from './property-access-dropdown.model';
import { PropertyAccessSearchTermsModel } from './property-access-search-terms.model';
import { dropdownDescriptor as ddDesc } from '@app/shared/utils/dropdown-helper';

export const PropertyAccessSearchModel = types
    .model({
        dropdowns: types.optional(PropertyAccessDropdownModel, {} as Instance<typeof PropertyAccessDropdownModel>),
        terms: types.optional(PropertyAccessSearchTermsModel, {} as Instance<typeof PropertyAccessSearchTermsModel>)
    })
    .views((self) => ({
        get description(): Array<[ISearchTerm, string]> {
            const { terms, dropdowns } = self;
            const descriptions: Array<[ISearchTerm, string]> = [
                [terms.userId, `User Identifier: ${terms.userId.value}`],
                [terms.userRoleId, `Role Identifier: ${terms.userRoleId.value}`],
                [{ ...terms.propertyId, disabled: true }, ddDesc(dropdowns.propertyDropdown, terms.propertyId.value, 'Property: ')],
                [
                    { ...terms.accessStatus, disabled: true },
                    ddDesc(dropdowns.propertyAccessStatusDropdown, terms.accessStatus.value, 'Property: ')
                ],
                [terms.property, `Property Code: ${terms.property.value}`],
                [terms.user, `User Name: ${terms.user.value}`]
            ];
            return descriptions.filter((term) => searchTermsUtils.hasValue(term[0]));
        },
        get inEffect() {
            const terms = getSnapshot(self.terms);
            return Boolean(Object.values(terms).filter(searchTermsUtils.hasValue).length);
        },
        get initialized() {
            return Boolean(self.dropdowns.dropdownsReady);
        },
        get enabledTerms() {
            const terms = getSnapshot(self.terms);
            return Boolean(Object.values(terms).filter((term) => searchTermsUtils.snapshotHasValue(term) && !term.disabled).length);
        }
    }))
    .views((self) => ({
        get canBeCleared() {
            return self.initialized && self.inEffect && searchTermsUtils.anyEnabledTerms(self.terms);
        }
    }))
    .actions((self) => ({
        async initialize() {
            await self.dropdowns.fetchDropdowns();
        }
    }));
type modelType = Instance<typeof PropertyAccessSearchModel>;
export interface IPropertyAccessSearchModel extends modelType {}
