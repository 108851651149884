import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-goldeye-form-controls',
    templateUrl: './form-controls.component.html',
    styleUrls: ['./form-controls.component.scss']
})
export class FormControlsComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() readonly = false;
    @Input() writeonly = false;
    @Input() btnClass?: string;
    @Input() saveLabel?: string;
    @Input() cancelLabel?: string;
    @Input() saveIcon?: string;
    @Input() cancelIcon?: string;
    @Input() saveIconPos: 'left' | 'right' | 'top' | 'bottom' = 'left';
    @Input() reverseButtons = false;
    @Input() containerClass = '';
    @Input() useActionBtnColor = true;
    @Input() useSecondaryActionBtnColor = false;

    @Output() save = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    constructor(private location: Location) {}

    ngOnInit(): void {
        this.btnClass = this.btnClass || 'p-col-12 p-md-2';

        this.saveLabel = this.saveLabel || 'Save';
        this.cancelLabel = this.cancelLabel || 'Cancel';

        this.saveIcon = this.saveIcon || 'fa fa-save';
        this.cancelIcon = this.cancelIcon || 'fa fa-times';
    }

    onSave() {
        this.save.emit();
    }

    onCancel() {
        if (this.cancel.observers.length > 0) {
            this.cancel.emit();
        } else {
            this.location.back();
        }
    }
}
