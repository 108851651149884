import { Component } from '@angular/core';

@Component({
    template: `
        <span pTooltip="{{ contents }}" tooltipPosition="top">
            {{ contents | truncate: 48 }}
        </span>
    `
})
export class TruncatedGridColumnComponent {
    contents: any;
    props?: any;
}
