import {
    UserRole,
    UserChannel,
    UserFeature,
    UserAttributes,
    IUser
} from '@app/core/models';

export class MyAccount implements IUser {
    id: number;
    attributes: UserAttributes;
    userRoles: UserRole[];
    userFeatures: UserFeature[];
    userChannels: UserChannel[];
    userSignalRGroups: string[];
}

// other lists will include
// recentLogins: a list of recent logins;
// userStats: a set of aggregated, gamified user stats
