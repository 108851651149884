import { Instance, types } from 'mobx-state-tree';

export const PointOfContact = types
    .model({
        id: types.number,
        firstName: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
        mobile: types.maybeNull(types.string),
        office: types.maybeNull(types.string),
        email: types.maybeNull(types.string)
    })
    .actions(self => ({
        setFirstName: (value: string) => (self.firstName = value),
        setLastName: (value: string) => (self.lastName = value),
        setMobile: (value: string) => (self.mobile = value),
        setOffice: (value: string) => (self.office = value),
        setEmail: (value: string) => (self.email = value)
    }))
    .views(self => ({
        get fullName() {
            return `${self.firstName} ${self.lastName}`;
        }
    }));

type modelType = Instance<typeof PointOfContact>;
export interface IPointOfContact extends modelType {}
