import { commonEnvironment } from './environment.common';

export const environment = {
    production: false,
    environmentName: 'Staging',
    environmentCode: 'STAGING',
    appConfig: {
        baseUrl: 'https://goldeye-api-01-711.azurewebsites.net/api/',
        manualVersion: '0.0.0',
        ...commonEnvironment.appConfig
    },
    authConfig: {
        clientConfigurationBaseUrl: 'https://cdn.auth0.com',
        clientID: 'gAysJObTp5w7Lny0I235eRHBwzKl1iSX',
        domain: 'edisonproperties.auth0.com',
        redirectUrl: 'https://staging.goldeye.software/callback',
        responseType: 'token id_token',
        scope: 'openid profile',
        tenant: 'Edison Properties - Goldeye',
        customDomain: 'login.edisonproperties.ca'
    },
    adminApiUrl: 'https://goldeye-api-01-110.azurewebsites.net/api/log'
};
