import { types, flow, Instance } from 'mobx-state-tree';
import {
    withItems,
    withCount,
    withPaginator
} from '@app/shared/utils/mobx-mixins';
import { BuildingManagersQuery } from '../../queries/building-manager-query';
import { BuildingManagerModel } from './building-manager.model';
import { SortOrder } from '@app/core/models';
import { BuildingManagerSearchModel } from './building-manager-search.model';
import { BuildingManagerTypes } from '../../enums/building-manager-types';

export const BuildingManagerGridModel = types
    .compose(
        withItems('building-managers', BuildingManagerModel),
        withCount('building-managers/count'),
        withPaginator('auditId', SortOrder.Descending),
        types.model({
            loading: true,
            search: BuildingManagerSearchModel
        })
    )
    .views((self) => ({
        get query(): BuildingManagersQuery {
            return {
                ...self.paginator.query,
                ...self.search.terms.query
            };
        },
        get listLoading() {
            return Boolean(!self.search.initialize || self.loading);
        },
        get itemName() {
            if (self.totalRecords > 1) {
                return 'BuildingManagers';
            }
            return 'BuildingManager';
        },
        filterByType(type: BuildingManagerTypes) {
            return self.items.filter(
                (bm) => bm.type === type
            );
        }
    }))
    .views((self) => ({
        get propertyManagers() {
            return self.filterByType(BuildingManagerTypes.PropertyManager);
        },
        get maintenanceManager() {
            return self.filterByType(BuildingManagerTypes.MaintenanceManager);
        }
    }))
    .actions((self) => ({
        loadItems: flow(function* () {
            try {
                self.loading = true;
                yield self.fetchItems(self.query);
            } finally {
                self.loading = false;
            }
        }),
        loadItemsAndCount: flow(function* () {
            try {
                self.loading = true;
                yield Promise.all([
                    self.fetchItems(self.query),
                    self.fetchCount(self.query)
                ]);
            } finally {
                self.loading = false;
            }
        })
    }));
type BuildingManagerGridModelType = Instance<typeof BuildingManagerGridModel>;
export interface IBuildingManagerGridModel
    extends BuildingManagerGridModelType {}
