import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-legend-item',
    templateUrl: './legend-item.component.html',
    styleUrls: ['./legend-item.component.scss']
})
export class LegendItemComponent implements OnInit {
    @Input() name: string;

    @Input() colorClass?: string;
    @Input() textClass?: string;

    @Input() color: string;
    @Input() text: string;
    @Input() useRoundColorInputs = false;

    ngOnInit() {
        this.colorClass = this.colorClass || 'p-col-5 p-md-3';
        this.textClass = this.textClass || 'p-col-6 p-md-9';
    }
}
