<div *mobxAutorun>
    <p-dialog
        [(visible)]="showDescriptionDialog"
        class="medium-modal claim-step-dialog"
        (onHide)="onDialogHide()"
        [modal]="true"
    >
        <p-header>
            <header class="claim-step-dialog-header">
                <i class="fal fa-comments header-icon"></i>
                <h3>Full Description View</h3>
            </header>
        </p-header>
        <div [innerHTML]="selectedDescription"></div>
    </p-dialog>

    <p-table
        [sortField]="grid.paginator.sortField"
        [responsive]="true"
        [sortOrder]="grid.paginator.sortOrder"
        [value]="grid.items"
        [columns]="columns"
        [loading]="grid.loading"
        [rowHover]="true"
        [paginator]="grid.paginator.showPages"
        [rows]="10"
        [lazy]="true"
        [totalRecords]="grid.totalRecords"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        (onLazyLoad)="onGridLazyLoad($event)"
        selectionMode="single"
    >
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length + 2">
                    No records found
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 10%;">
                    Important
                </th>
                <th
                    *ngFor="let col of columns"
                    [ngStyle]="{ width: col.width ? col.width : 'auto' }"
                    [pSortableColumn]="
                        col.sortColumn ? col.sortColumn : col.field
                    "
                    [pSortableColumnDisabled]="col.disableSorting"
                >
                    {{ col.header }}
                    <p-sortIcon
                        *ngIf="!col.disableSorting"
                        [field]="col.sortColumn ? col.sortColumn : col.field"
                    ></p-sortIcon>
                </th>
                <th>
                    Actions
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
                [pSelectableRow]="rowData"
                (dblclick)="openFullDescriptionDialog(rowData)"
            >
                <td style="text-align: center;">
                    <p-rating
                        (onRate)="toggleImportant(rowData)"
                        [ngModel]="rowData.stars"
                        [stars]="1"
                        [cancel]="false"
                        [disabled]="rowData.isBusy"
                        title="This will appear on the Timeline when the item has a star"
                    ></p-rating>
                </td>
                <td
                    *ngFor="let col of columns"
                >
                    <span class="p-column-title">{{ col.header }}</span>
                    <app-grid-column
                        [column]="col"
                        [row]="rowData"
                    ></app-grid-column>
                </td>
                <td>
                    <button
                        pButton
                        type="button"
                        icon="fa fa-search"
                        (click)="openFullDescriptionDialog(rowData)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
