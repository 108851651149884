import { dateDescription as dDesc } from '@app/shared/utils/date-helper';
import { dropdownDescriptor as ddDesc } from '@app/shared/utils/dropdown-helper';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';
import { searchTermsUtils } from '@app/shared/utils/mobx-utils';
import { getSnapshot, Instance, types } from 'mobx-state-tree';
import { JobDropdownModel } from './job-dropdown.model';
import { JobSearchTermsModel } from './job-search-terms.model';

export const JobSearchModel = types
    .model({
        dropdowns: types.optional(
            JobDropdownModel,
            {} as Instance<typeof JobDropdownModel>
        ),
        terms: types.optional(
            JobSearchTermsModel,
            {} as Instance<typeof JobSearchTermsModel>
        )
    })
    .views((self) => ({
        get description(): Array<[ISearchTerm, string]> {
            const { terms, dropdowns } = self;
            const descriptions: Array<[ISearchTerm, string]> = [
                [
                    self.terms.matchAll,
                    `Filtering by: ${self.terms.matchAll.value}`
                ],
                [
                    terms.createdFrom,
                    dDesc('Created From: ', terms.createdFrom.value)
                ],
                [terms.createdTo, dDesc('Created To: ', terms.createdTo.value)],
                [
                    { ...terms.unitId },
                    ddDesc(dropdowns.unitDropdown, terms.unitId.value, 'Unit: ')
                ],
                [
                    { ...terms.propertyId },
                    ddDesc(
                        dropdowns.propertyDropdown,
                        terms.propertyId.value,
                        'Property: '
                    )
                ],
                [
                    { ...terms.jobTypeId },
                    ddDesc(
                        dropdowns.jobTypeDropdown,
                        terms.jobTypeId.value,
                        'Job Type: '
                    )
                ],
                [
                    { ...terms.jobStatusId },
                    ddDesc(
                        dropdowns.jobStatusDropdown,
                        terms.jobStatusId.value,
                        'Job Status: '
                    )
                ],
                [terms.jobCode, `Job Code: ${self.terms.jobCode.value}`],
                [
                    terms.completionDateFrom,
                    dDesc('Completed From: ', terms.completionDateFrom.value)
                ],
                [
                    terms.completionDateTo,
                    dDesc('Completed To: ', terms.completionDateTo.value)
                ],
                [
                    terms.startDateFrom,
                    dDesc('Started From: ', terms.startDateFrom.value)
                ],
                [
                    terms.startDateTo,
                    dDesc('Started To: ', terms.startDateTo.value)
                ]
            ];
            return descriptions.filter((term) =>
                searchTermsUtils.hasValue(term[0])
            );
        },
        get inEffect() {
            const terms = getSnapshot(self.terms);
            const exceptions = [
                'isCloseToCompletion',
                'isRehab',
                'isReno',
                'isOtherType',
                'createdDateOffset'
            ];
            const filtered = Object.keys(terms)
                .filter((key) => !exceptions.includes(key))
                .reduce((obj, key) => ({ ...obj, [key]: terms[key] }), {});
            return Boolean(
                Object.values(filtered).filter(searchTermsUtils.hasValue).length
            );
        },
        get initialized() {
            return Boolean(self.dropdowns.dropdownsReady);
        },
        get enabledTerms() {
            const terms = getSnapshot(self.terms);
            return Boolean(
                Object.values(terms).filter(
                    (term) =>
                        searchTermsUtils.snapshotHasValue(term) &&
                        !term.disabled
                ).length
            );
        }
    }))
    .views((self) => ({
        get canBeCleared() {
            return (
                self.initialized &&
                self.inEffect &&
                searchTermsUtils.anyEnabledTerms(self.terms)
            );
        }
    }))
    .actions((self) => ({
        async initialize() {
            await self.dropdowns.fetchDropdowns();
        },
        setPropertyScope(scope) {
            self.dropdowns.propertyScope.replace(scope);
        }
    }));
type modelType = Instance<typeof JobSearchModel>;
export interface IJobSearchModel extends modelType {}
