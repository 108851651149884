import { Component } from '@angular/core';

@Component({
    template: `
        <a [href]="contents" target="_blank">{{ text() }}</a>
    `
})
export class HyperlinkGridColumnComponent {
    contents: any;
    props?: any;

    text() {
        return this.props && this.props.text ? this.props.text : this.contents;
    }
}
