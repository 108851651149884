import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
// eslint-disable-next-line max-len
import { IMultiplePropertyAccessFormModel } from '@app/modules/property-team/models/property-access/multiple-property-access-form.model';
import { IDropdownModelType } from '@app/shared/models/goldeye-select-item';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-multiple-property-access-form',
    templateUrl: './multiple-property-access-form.component.html',
    styleUrls: ['./multiple-property-access-form.component.scss']
})
export class MultiplePropertyAccessFormComponent {
    @Input() form: IMultiplePropertyAccessFormModel;
    @Input() userDd: IDropdownModelType;
    @Input() disabled?: string[];
    @Output() saveClicked = new EventEmitter<void>();
    @Output() cancelClicked = new EventEmitter<void>();
}
