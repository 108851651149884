import { types, getSnapshot, Instance } from 'mobx-state-tree';
import { searchTermOf } from '@app/shared/utils/mobx-mixins';
import { createDateRange, toDateRange } from '@app/shared/utils/date-range-helper';
import { VendorQuery } from '../../queries/vendors/vendor.query';

export const VendorSearchTermsModel = types
    .model({
        ids: searchTermOf(types.array(types.number)),

        vendorTypeId: searchTermOf(types.number),
        vendorStatusId: searchTermOf(types.number),
        code: searchTermOf(types.string),
        name: searchTermOf(types.string),
        contactName: searchTermOf(types.string),

        createdFrom: searchTermOf(types.Date),
        createdTo: searchTermOf(types.Date),

        createdAtYear: searchTermOf(types.number),

        jobIds: searchTermOf(types.array(types.number)),
        workOrderIds: searchTermOf(types.array(types.number))
    })
    .actions((self) => ({
        setIds: (id: number) => self.ids.value.push(id),
        setVendorTypeId: (val: number) => (self.vendorTypeId.value = val),
        setVendorStatusId: (val: number) => (self.vendorStatusId.value = val),
        setCode: (code: string) => (self.code.value = code),
        setName: (name: string) => (self.name.value = name),
        setContactName: (contactName: string) => (self.contactName.value = contactName),
        setJobIds: (jobIds: Array<number>) => (self.jobIds.value = jobIds),
        setWorkOrderIds: (workOrderIds: Array<number>) => (self.workOrderIds.value = workOrderIds)
    }))
    .views((self) => ({
        get query(): VendorQuery {
            const snapshot = getSnapshot(self);
            return Object.keys(snapshot).reduce((curr, key) => {
                if (self[key] && self[key].value !== null) {
                    curr[key] = self[key].value;
                }
                return curr;
            }, {});
        },
        get createdBetween(): Date[] {
            return toDateRange(self.createdFrom.value, self.createdTo.value);
        }
    }));
type VendorSearchTermsModelType = Instance<typeof VendorSearchTermsModel>;
export interface IVendorSearchTermsModel extends VendorSearchTermsModelType {}
