import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import { IWorkOrderSearchTermsModel } from '@app/modules/operations/models/work-orders/work-order-search-terms.model';
import { IWorkOrderDropdownsModel } from '@app/modules/operations/models/work-orders/work-order-dropdown.model';

@Component({
    selector: 'app-work-order-search-dialog',
    templateUrl: './work-order-search-dialog.component.html',
    styleUrls: ['./work-order-search-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkOrderSearchDialogComponent {
    @Input() show?: boolean;
    @Input() terms?: IWorkOrderSearchTermsModel;
    @Input() dropdowns?: IWorkOrderDropdownsModel;
    @Input() hiddenFilters: Array<string> = [];

    @Output() searchBtnClicked = new EventEmitter<IWorkOrderSearchTermsModel>();
    @Output() cancelBtnClicked = new EventEmitter<void>();
    @Output() dialogHidden = new EventEmitter<void>();

    onSearchBtnClick() {
        this.searchBtnClicked.emit(this.terms);
    }
    onCancelBtnClick() {
        this.cancelBtnClicked.emit();
    }

    onDialogHide() {
        this.dialogHidden.emit();
    }

    isHiddenFilter(filterName: string): boolean {
        return this.hiddenFilters.includes(filterName);
    }
}
