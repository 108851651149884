<div class="p-grid p-align-center control-padding">
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-checkbox
            [ngModel]="model"
            [binary]="true"
            [disabled]="disabled"
            (onChange)="onChange($event)"
        ></p-checkbox>
        <ng-content select="label"></ng-content>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
