<div class="p-grid main-attachment-container" *mobxAutorun>
    <div class="p-col-2" style="text-align: center; font-size: 2.2rem;">
        <img
            *ngIf="attachment.isImage"
            [src]="attachment.objectUrl"
            style="width: 50px;"
        />
        <i *ngIf="!attachment.isImage" [class]="fileAttachmentIcon(attachment.fileExtension)"></i>
    </div>

    <ng-container *ngIf="attachment.notUploaded">
        <div class="p-col-6">
            <app-goldeye-dropdown
                label="Attachment Type"
                name="typeId"
                *ngIf="types !== null"
                [required]="true"
                [filter]="true"
                [options]="types"
                [model]="attachment.typeId"
                (change)="attachment.setTypeId($event)"
            >
            </app-goldeye-dropdown>
            <app-goldeye-text-input
                label="Filename"
                name="title"
                [model]="attachment.title"
                (change)="attachment.setTitle($event)"
            >
            </app-goldeye-text-input>
        </div>

        <div class="p-col-3" style="text-align: center">
            <span>{{ attachment.fileSize | fileSize }}</span>
        </div>
        <div class="p-col-1">
            <button
                type="button"
                icon="pi pi-times"
                pButton
                (click)="attachment.remove()"
                [disabled]="attachment.uploading"
            ></button>
        </div>
    </ng-container>
    <ng-container *ngIf="attachment.uploading && !attachment.cancelled">
        <p-progressBar [value]="attachment.progress"></p-progressBar>
        <div class="p-col-6">{{ attachment.title }}</div>
        <div class="p-col-3">{{ attachment.progress }} %</div>
        <div class="p-col-1">
            <button
                icon="pi pi-times"
                pButton
                class="p-button-danger"
                (click)="attachment.cancel()"
            ></button>
        </div>
    </ng-container>
    <ng-container *ngIf="attachment.cancelled">
        <div class="p-col-6">{{ attachment.title }}</div>
        <div class="p-col-3">Cancelled</div>
        <div class="p-col-1"></div>
    </ng-container>
    <ng-container *ngIf="attachment.withErrors">
        <div class="p-col-6">{{ attachment.title }}</div>
        <div class="p-col-3">Could not upload</div>
        <div class="p-col-1"></div>
    </ng-container>
    <ng-container *ngIf="attachment.uploaded">
        <div class="p-col-6">{{ attachment.title }}</div>
        <div class="p-col-3">Done</div>
        <div class="p-col-1"></div>
    </ng-container>
</div>
