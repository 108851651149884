import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit
} from '@angular/core';
import { Column } from '@app/core/models';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { IEntityLogEntryGridModel } from '@app/shared/models/entity-log-entry/entity-log-entry-grid.model';
import { IEntityLogEntryModel } from '@app/shared/models/entity-log-entry/entity-log-entry.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-entity-log-entry-list-grid',
    templateUrl: './entity-log-entry-list-grid.component.html',
    styleUrls: ['./entity-log-entry-list-grid.component.scss']
})
export class EntityLogEntryListGridComponent implements OnInit {
    @Input() grid: IEntityLogEntryGridModel;
    columns: Column[];

    showDescriptionDialog = false;
    selectedDescription = '';

    ngOnInit() {
        this.columns = [
            {
                field: 'cleanDescription',
                header: 'Description',
                disableSorting: true
            },
            {
                field: 'localCreatedDate',
                header: 'Date',
                sortColumn: 'createdDate'
            },
            {
                field: 'createdBy',
                header: 'Performed By'
            }
        ];
    }

    async toggleImportant(logEntry: IEntityLogEntryModel) {
        logEntry.toggleImportant();
        await logEntry.update();
    }

    onGridLazyLoad(evt) {
        applyPartialSnapshot(this.grid.paginator, evt);
    }

    openFullDescriptionDialog(logEntry: IEntityLogEntryModel) {
        this.selectedDescription = logEntry.description;

        this.showDescriptionDialog = true;
    }

    onDialogHide() {
        this.showDescriptionDialog = false;
        this.selectedDescription = '';
    }
}
