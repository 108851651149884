<div *mobxAutorun>
    <p-table
        [sortField]="grid.paginator.sortField"
        [responsive]="true"
        [sortOrder]="grid.paginator.sortOrder"
        [value]="grid.items"
        [columns]="columns"
        [loading]="grid.listLoading"
        [rowHover]="true"
        [paginator]="grid.paginator.showPages"
        [rows]="grid.paginator.rows"
        [lazy]="true"
        [totalRecords]="grid.totalRecords"
        [rowsPerPageOptions]="[5, 10, 20, 50, 100]"
        (onLazyLoad)="onGridLazyLoad($event)"
        selectionMode="single"
    >
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length + 1">No records found</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th
                    *ngFor="let col of columns"
                    [ngStyle]="{ width: col.width ? col.width : 'auto' }"
                    [pSortableColumn]="
                        col.sortColumn ? col.sortColumn : col.field
                    "
                    [pSortableColumnDisabled]="col.disableSorting"
                    [ngClass]="col.headerClass"
                >
                    {{ col.header }}
                    <p-sortIcon
                        *ngIf="!col.disableSorting"
                        [field]="col.sortColumn ? col.sortColumn : col.field"
                    ></p-sortIcon>
                </th>
                <th
                    *ngIf="actionButtonCount > 0"
                    [colSpan]="actionButtonCount"
                    [style.width]="
                        (isMiniGrid
                            ? actionButtonCount * 30
                            : actionButtonCount * 10) + '%'
                    "
                >
                    {{ actionButtonsColumnTitle }}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
                [pSelectableRow]="rowData"
                (dblclick)="onRowDblClick(rowData.id)"
            >
                <td *ngFor="let col of columns">
                    <span class="p-column-title" [ngClass]="col.headerClass">{{
                        col.header
                    }}</span>
                    <app-grid-column
                        [column]="col"
                        [row]="rowData"
                    ></app-grid-column>
                </td>
                <ng-template
                    *ngTemplateOutlet="buttons; context: { $implicit: rowData }"
                >
                </ng-template>
            </tr>
        </ng-template>
    </p-table>
</div>
