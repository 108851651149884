import { Component } from '@angular/core';

@Component({
    template: `
        <p-rating
            *ngIf="contents"
            [pTooltip]="contents"
            tooltipPosition="top"
            [ngModel]="round(+contents)"
            [readonly]="true"
            [cancel]="false"
        ></p-rating>
        <span *ngIf="!contents"> No Ratings</span>
    `,
    selector: 'app-rating-grid-column'
})
export class RatingGridColumnComponent {
    contents: any;
    props?: any;

    round(value: number): number {
        return Math.round(value);
    }
}
