import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ISearchTerm } from '@app/shared/utils/mobx-mixins';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-data-bar',
    templateUrl: './data-bar.component.html',
    styleUrls: ['./data-bar.component.scss']
})
export class DataBarComponent {
    @Input() filterIsExpanded = false;
    @Input() searchInEffect: boolean;
    @Input() searchTerms: Array<[ISearchTerm, string, string?]>;

    removeItem(event, term) {
        event.stopPropagation();
        term.setValue(undefined);
    }
}
