import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ISessionFilterModel } from '@app/shared/models/session-filters/session-filter.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-session-filter',
    templateUrl: './session-filter.component.html',
    styleUrls: ['./session-filter.component.scss']
})
export class SessionFilterComponent implements OnInit {
    @Input() sessionFilter: ISessionFilterModel;
    @Input() label = 'Property Group Filter';
    @Input() labelClass = 'p-col-12 p-md-4 p-md-offset-3';
    @Input() inputClass = 'p-col-12 p-md-5';
    @Input() isBold = true;
    help: string;

    ngOnInit() {
        this.help =
            'A Property Group Filter remains active until you ' +
            'remove or change it.  You will only see the properties ' +
            'in the group when you navigate to different views';
    }
}
