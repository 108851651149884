<div
    class="p-grid p-align-center control-padding"
    *ngIf="!fieldAccess?.isHidden"
>
    <ng-content select=".before-form-field"></ng-content>

    <div class="{{ labelClass }}">
        <label [appHighlightOnValidationError]="name" for="{{ name }}">
            {{ label }}
        </label>
    </div>

    <ng-content select=".between-form-field"></ng-content>

    <div class="{{ inputClass }}">
        <p-calendar
            #monthCal
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [ngClass]="{ hidden: step !== 1 }"
            (onSelect)="daySelected($event, dayCal)"
            [ngModel]="value"
            view="month"
            dateFormat="mm/yy"
            [yearNavigator]="true"
            yearRange="1950:2030"
            [disabled]="fieldAccess?.isLocked || disabled"
        ></p-calendar>

        <p-calendar
            #dayCal
            [ngClass]="{ hidden: step !== 2 }"
            [style]="{ width: '100%' }"
            [selectionMode]="range ? 'range' : 'single'"
            [inputStyle]="{ width: '100%' }"
            name="{{ this.name }}"
            [appHighlightOnValidationError]="name"
            [showIcon]="true"
            [numberOfMonths]="1"
            [ngModel]="resultDate"
            (onSelect)="daySelected($event, cal)"
            class="show-input-border-right"
            [disabled]="fieldAccess?.isLocked || disabled"
        >
        </p-calendar>

        <p-calendar
            #cal
            [dateFormat]="dateFormat"
            [ngClass]="{ hidden: step !== 3 }"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            name="{{ this.name }}"
            [showTime]="true"
            [timeOnly]="true"
            [stepMinute]="stepMinute"
            [hourFormat]="'12'"
            [appHighlightOnValidationError]="this.name"
            [showIcon]="false"
            [ngModel]="resultDate"
            [disabled]="fieldAccess?.isLocked || disabled"
            (onSelect)="finalDateSelected($event)"
            (onClose)="resetStep()"
        >
        </p-calendar>
    </div>

    <ng-content select=".after-form-field"></ng-content>
</div>
