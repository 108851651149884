import { types, getParent, Instance } from 'mobx-state-tree';
import { resource } from '@app/shared/utils/mobx-mixins';
import { applyPartialSnapshot } from '@app/shared/utils/mobx-utils';
import { fromUtcToLocal, stringToDateTime } from '@app/shared/utils/date-helper';
import { formatDate } from '@angular/common';

export const AttachmentModel = resource(
    (myself) => `${myself.discriminator}/${myself.attachedToId}/attachments`,
    types
        .model({
            id: types.maybe(types.number),
            fileName: types.maybe(types.string),
            title: types.maybeNull(types.string),
            caption: types.maybeNull(types.string),
            attachedToId: types.number,
            discriminator: types.enumeration([
                'inspection',
                'policy',
                'claim',
                'sov',
                'life-insurance',
                'certificate',
                'research-file',
                'renewal-file',
                'inspectedItem',
                'unit',
                'support-file',
                'vendor',
                'action-item',
                'property',
                'work-order',
                'template-work-order',
                'amenity'
            ]),
            mimeType: types.string,
            typeId: types.maybeNull(types.number),
            typeDescription: types.maybeNull(types.string),
            typeDisplayOrder: types.maybeNull(types.number),
            fileSize: types.number,
            url: types.string,
            thumbnailUrl: types.string,
            thumbnailPhotoAsBase64String: types.maybeNull(types.string),
            createdBy: types.string,
            createdDate: types.maybeNull(types.string),
            modifiedBy: types.string,
            important: false,
            storageStatus: types.optional(
                types.enumeration([
                    'notUploaded',
                    'uploading',
                    'uploaded',
                    'cancelled',
                    'error'
                ]),
                'uploaded'
            )
        })
        .volatile((_) => ({
            tokenizedUrl: null,
            tokenizedThumbnailUrl: null,
            file: null,
            thumbnail: null,
            objectUrl: null,
            progress: 0,
            justUploaded: false
        }))
)
    .views((self) => ({
        get header() {
            const createdDate = self.createdDate;
            return `${self.createdBy} on ${formatDate(
                stringToDateTime(createdDate),
                'short',
                'en'
            )}`;
        },
        get previewImageSrc() {
            return self.tokenizedUrl;
        },
        get thumbnailImageSrc() {
            return self.tokenizedThumbnailUrl;
        },
        get alt() {
            return self.caption;
        },
        get notUploaded() {
            return self.storageStatus === 'notUploaded';
        },
        get uploading() {
            return self.storageStatus === 'uploading';
        },
        get uploaded() {
            return self.storageStatus === 'uploaded';
        },
        get cancelled() {
            return self.storageStatus === 'cancelled';
        },
        get withErrors() {
            return self.storageStatus === 'error';
        },
        get isImage() {
            return self.mimeType ? self.mimeType.startsWith('image') : false;
        },
        get createdDateAsDate() {
            return stringToDateTime(self.createdDate);
        },
        get fileExtension() {
            return self.title.split('.').pop();
        }
    }))
    .actions((self) => ({
        setTypeId: (value) => (self.typeId = value),
        setTokenizedUrls: (url: string, token: string) => {
            self.tokenizedThumbnailUrl = `${url}/${self.thumbnailUrl}${token}`;
            self.tokenizedUrl = `${url}/${self.url}${token}`;
        },
        setThumbnail: (thumbnail) => (self.thumbnail = thumbnail),
        setFile: (file) => {
            self.file = file;
            self.fileSize = file.size;
        },
        setObjectUrl: (objUrl) => (self.objectUrl = objUrl),
        setProgress: (progress: number) => (self.progress = progress),

        setTitle: (n: string) => (self.title = n),
        setCaption: (n: string) => (self.caption = n),

        setJustUploaded: (val: boolean) => (self.justUploaded = val),

        uploadingStarted() {
            self.storageStatus = 'uploading';
        },
        uploadingCompleted(): Promise<any> {
            self.storageStatus = 'uploaded';
            return self.save().then((storedAttachment) => applyPartialSnapshot(self, storedAttachment));
        },
        uploadingError() {
            self.storageStatus = 'error';
        },
        cancel() {
            if (self.storageStatus === 'uploading') {
                self.storageStatus = 'cancelled';
            }
        },
        remove() {
            const parent: any = getParent(self, 2);
            parent.removeAttachment(self);
        }
    }));
type modelType = Instance<typeof AttachmentModel>;

export interface IAttachmentModel extends modelType {}
