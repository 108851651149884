import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'smartDate'
})
export class SmartDatePipe implements PipeTransform {
    transform(value: Date): string {
        const today = new Date();
        if (Boolean(value)) {
            return value.getFullYear() < today.getFullYear()
                ? moment(value).format('MMM Do YYYY')
                : moment(value).format('ddd MMM Do');
        }
        return '';
    }
}
