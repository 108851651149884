<p-card stlyeClass="p-card-shadow">
    <section class="card-header">
        <i [class]="headerIcon"></i>
        <span class="card-header-text">{{ headerText }}</span>
    </section>
    <section class="card-body">
        <div class="p-col-12 p-md-6 no-padding sub-header">
            {{ subHeaderText }}
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <ng-content select=".body"></ng-content>
            </div>
            <div class="p-col-12 p-md-6">
                <app-goldeye-action-button
                    [label]="buttonText"
                    [icon]="buttonIcon"
                    [disabled]="false"
                    (btnClick)="buttonClicked()"
                    btnClass="p-col-12 p-md-8"
                >
                </app-goldeye-action-button>
            </div>
        </div>
    </section>
</p-card>
