import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { BlobStorageService } from '@app/core/services';
import { DomSanitizer } from '@angular/platform-browser';
import { ISelectItem } from '@app/shared/models/goldeye-select-item';
import { lastValueFrom } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-attachment-uploader',
    templateUrl: './attachment-uploader.component.html',
    styleUrls: ['./attachment-uploader.component.scss']
})
export class AttachmentUploaderComponent {
    @Input() container: any;
    @Input() accept: string;
    @Input() showCloseButton = false;
    @Input() types?: ISelectItem[] = null;
    @Input() showTypesSelect = false;

    @Output() closed = new EventEmitter<void>();

    constructor(private blobStorage: BlobStorageService, private sanitizer: DomSanitizer) {}

    async onImageSelect(evt) {
        if (!this.showTypesSelect) {
            await this.onClearBtnClick();
        }

        await this.addSelectedFilesToContainer(evt);

        if (!this.showTypesSelect) {
            await this.uploadImages(evt);
        }
    }

    async addSelectedFilesToContainer(evt) {
        Array.from(evt.target.files).forEach((file) => {
            const objectUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            this.container.addAttachment(file, objectUrl);
        });
    }

    async onClearBtnClick() {
        this.container.clearAttachments();
    }

    async uploadImages(evt): Promise<void> {
        if (evt) {
            evt.preventDefault();
        }
        if (this.container.pendingAttachments.length === 0) {
            return Promise.resolve();
        }
        if (this.types && !this.showTypesSelect) {
            const index = this.types[0].value == null ? 1 : 0;
            this.container.pendingAttachments.forEach((att) => {
                att.setTypeId(this.types[index].value);
            });
        }

        this.blobStorage.uploaded.next(false);
        this.blobStorage.initPromise();
        const subscription = this.blobStorage.uploaded.subscribe({
            next: (v) => {
                if (v && this.container.allUploaded) {
                    this.onCloseBtnClick();
                    subscription.unsubscribe();
                    this.blobStorage.uploadedAll.complete();
                }
            },
            error: (err) => {
                subscription.unsubscribe();
            }
        });

        await this.blobStorage.upload(this.container.pendingAttachments);
        return await lastValueFrom(this.blobStorage.uploadedAll, { defaultValue: null });
    }

    onCloseBtnClick() {
        this.container.clearAttachments();
        this.closed.emit();
    }
}
