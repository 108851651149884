import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { ILoginSuccess, ILoginSuccessQuery } from '@app/core/models';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private baseUrl = environment.appConfig.baseUrl;

    constructor(private http: HttpClient) {}

    public saveLoginInfo(info: ILoginSuccess) {
        const url = `${this.baseUrl}loginsuccess/saveloginsuccess`;

        // fire once and unsubscribe automatically
        return this.http.post<ILoginSuccess>(url, info).pipe(take(1));
    }

    public getLoginHistory(
        query: ILoginSuccessQuery = null
    ): Observable<ILoginSuccess[]> {
        const url = `${this.baseUrl}loginsuccess/loginsuccesshistory`;

        const anyQuery: any = query;
        return this.http.get<ILoginSuccess[]>(url, { params: anyQuery });
    }

    public initializeLoginSuccess() {
        const loginSuccess: ILoginSuccess = {
            id: 0,
            createdBy: '',
            createdDate: new Date(),
            isArchived: false,
            modifiedBy: '',
            modifiedDate: new Date(),
            result: '',
            dateCreatedString: '',
            timeLoggedInString: ''
        };

        return loginSuccess;
    }
}
