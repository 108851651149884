import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';

import { LoginComponent, CallbackComponent } from './components';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        LoginComponent,
        CallbackComponent
    ]
})
export class LoginModule { }
