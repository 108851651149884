import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { IActionItemModel } from '@app/modules/residential/models/action-item/action-item.model';
import { convertPascalCaseToASentence } from '@app/shared/utils/app-string-helper';

@Component({
    selector: 'app-action-item-board-lane',
    templateUrl: './action-item-board-lane.component.html',
    styleUrls: ['./action-item-board-lane.component.scss']
})
export class ActionItemBoardLaneComponent implements OnInit {
    @Input() items: Array<IActionItemModel>;
    @Input() type: string;
    @Output() dragStarting = new EventEmitter<IActionItemModel>();
    @Output() dragEnded = new EventEmitter<void>();
    @Output() drop = new EventEmitter<string>();
    @Output() clickItem = new EventEmitter<IActionItemModel>();
    constructor() {}

    ngOnInit() {}

    title() {
        return convertPascalCaseToASentence(this.type);
    }
    dragStart(evt, actionItem: IActionItemModel) {
        this.dragStarting.emit(actionItem);
    }

    dragEnd(evt) {
        this.dragEnded.emit();
    }

    dropped(evt) {
        this.drop.emit(this.type);
    }

    onClick(actionItem: IActionItemModel) {
        this.clickItem.emit(actionItem);
    }
}
