import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';
import { IActionItemFormModel } from '@app/modules/residential/models/action-item/action-item-form.model';
import { ICommentGridModel } from '@app/shared/models/comment/comment-grid.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-action-item-summary',
    templateUrl: './action-item-summary.component.html',
    styleUrls: ['./action-item-summary.component.scss']
})
export class ActionItemSummaryComponent implements OnInit {
    @Input() form: IActionItemFormModel;
    @Input() comments: ICommentGridModel;

    constructor() {}

    ngOnInit() {}
}
