import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy
} from '@angular/core';
import { IPropertyAccessByUserGridModel } from '@app/modules/property-team/models/property-access/property-access-by-user-grid.model';
import { IPropertyAccessByUserModel } from '@app/modules/property-team/models/property-access/property-access-by-user.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-property-access-user-cards',
    templateUrl: './property-access-user-cards.component.html',
    styleUrls: ['./property-access-user-cards.component.scss']
})
export class PropertyAccessUserCardsComponent {
    @Input() propertyAccess: IPropertyAccessByUserGridModel;
}
