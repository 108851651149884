import { WorkRegion } from '@app/core/models';

export interface IUserEntity {
    id: number;
    email: string;
    isActive: boolean;
    dateRegistered: Date;
    isLocked: boolean;
    dateLocked: Date;
    dateDeactivated: Date;
    firstName: string;
    lastName: string;
    jobTitle: string;
    mobileNumber: string;
    directNumber: string;
    hrEmployeeIdentifier: string;
    securityNote: string;
    workRegionId: number;
    workRegion: WorkRegion;
    workRegionNote: string;
    loginStartPage: string;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    modifiedBy: string;
    isArchived: boolean;
}

export class UserEntity implements IUserEntity {
    id: number;
    email: string;
    isActive: boolean;
    dateRegistered: Date;
    isLocked: boolean;
    dateLocked: Date;
    dateDeactivated: Date;
    firstName: string;
    lastName: string;
    jobTitle: string;
    mobileNumber: string;
    directNumber: string;
    hrEmployeeIdentifier: string;
    securityNote: string;
    workRegionId: number;
    workRegion: WorkRegion;
    workRegionNote: string;
    loginStartPage: string;
    createdDate: Date;
    modifiedDate: Date;
    createdBy: string;
    modifiedBy: string;
    isArchived: boolean;
}
