<div class="p-grid attachments-mini-grid" *mobxAutorun>
    <p-confirmDialog
        *ngFor="let attachment of attachments"
        header="Confirmation"
        [key]="'delete-attachment-confirmation-' + attachment.id"
        icon="pi pi-exclamation-triangle"
        [style]="{ width: '425px' }"
    >
    </p-confirmDialog>

    <p-dialog
        appendTo="body"
        styleClass="mini-dialog-no-scroll claim-step-dialog"
        [visible]="editAttachmentNameDialog | async"
        (visibleChange)="editAttachmentNameDialog.next($event)"
        [modal]="true"
        [closable]="true"
    >
        <p-header>
            <header class="claim-step-dialog-header">
                <i class="fa fa-file header-icon"></i>
                <h3>Rename File</h3>
            </header>
        </p-header>

        <p class="edit-instructions">
            Just type the file name and extension you want and click close. The
            display name for your file will be updated. If you want to cancel,
            click x top-right.
        </p>
        <app-goldeye-text-input
            label="Name"
            name="name"
            *ngIf="attachmentBeingEdited"
            [model]="attachmentBeingEdited.title"
            (change)="attachmentBeingEdited.setTitle($event)"
        >
        </app-goldeye-text-input>

        <p-footer>
            <div class="p-grid">
                <div class="p-col-12 p-md-5">
                    <button
                        pButton
                        class="main-action-btn"
                        label="Save"
                        icon="fa fa-save"
                        (click)="onEditSave()"
                    ></button>
                </div>

                <ng-content select=".between-form-field"></ng-content>

                <div class="p-col-12 p-md-5">
                    <button
                        pButton
                        label="Cancel"
                        icon="fa fa-times"
                        (click)="onEditCancel()"
                    ></button>
                </div>
            </div>
        </p-footer>
    </p-dialog>

    <div class="p-col-12" *mobxAutorun>
        <p-table
            class="goldeye-mini-grid"
            [responsive]="true"
            [value]="attachments"
            [columns]="columns"
            [rowHover]="true"
            [loading]="loading || (editAttachmentNameDialog | async)"
            [paginator]="false"
        >
            <ng-template pTemplate="caption">
                {{ title }}
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length + 3">
                        No {{ title }} files have been uploaded
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <span class="p-column-title">{{ col.header }}</span>
                        <app-grid-column
                            *ngIf="!(editAttachmentNameDialog | async)"
                            [column]="col"
                            [row]="rowData"
                            [class]="
                                rowData.justUploaded && col.field === 'title'
                                    ? 'bold'
                                    : ''
                            "
                        ></app-grid-column>
                    </td>

                    <td style="width: 20%; text-align: right">
                        <a
                            href="{{ rowData.tokenizedUrl }}"
                            target="_blank"
                            download
                        >
                            <button
                                pButton
                                type="button"
                                [icon]="getFileIcon(rowData)"
                                style="margin-right: 25px"
                                [pTooltip]="getFileTooltip(rowData)"
                                tooltipPosition="top"
                            ></button>
                        </a>

                        <button
                            pButton
                            type="button"
                            icon="fa fa-money-check-edit"
                            (click)="onEditBtnClick(rowData)"
                            style="margin-right: 25px"
                            pTooltip="Rename File"
                            tooltipPosition="top"
                        ></button>

                        <button
                            pButton
                            type="button"
                            icon="fas fa-trash-alt"
                            (click)="onDeleteBtnClick(rowData)"
                            style="margin-right: 25px"
                            pTooltip="Delete This File"
                            tooltipPosition="top"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
