import { Injectable, ElementRef } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root'
})
export class SaveHtmlElementAsPdfService {
    constructor() {}

    saveCustomElementAsPdf(elementClone: any, fileNameNoExtension: string = 'myPdf') {
        if (elementClone) {
            this.saveCloneAsPdf(elementClone, fileNameNoExtension);
        }
    }

    saveElementAsPdf(element: ElementRef, fileNameNoExtension: string = 'myPdf') {
        if (element) {
            const realElement = element.nativeElement;

            const clone = this.hiddenClone(realElement);

            this.saveCloneAsPdf(clone, fileNameNoExtension);
        }
    }

    hiddenClone(element: any) {
        // Create clone of element
        const clone = element.cloneNode(true);

        // Position element relatively within the
        // body but still out of the viewport
        const style = clone.style;

        style.position = 'fixed';
        style.top = window.innerHeight + 'px';
        style.left = 0;
        style.maxWidth = '794px';
        style.maxHeight = '1123px';
        style.boxShadow = 'unset';

        // Append clone to body and return the clone
        document.body.appendChild(clone);
        return clone;
    }

    private saveCloneAsPdf(clone: any, fileNameNoExtension: string) {
        if (clone) {
            html2canvas(clone, { scale: 1 }).then((canvas) => {
                const imgWidth = clone.offsetWidth;
                const imgHeight = clone.offsetHeight;
                const imgRatio = imgWidth / imgHeight;

                // remove the cloned element after saving as pdf
                document.body.removeChild(clone);

                const img = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const position = 0;

                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                const pageRatio = pageWidth / pageHeight;

                if (imgRatio >= 1) {
                    const wc = imgWidth / pageWidth;
                    if (imgRatio >= pageRatio) {
                        pdf.addImage(img, 'JPEG', 0, 0, pageWidth, imgHeight / wc, null, 'NONE');
                    } else {
                        const pi = pageRatio / imgRatio;
                        pdf.addImage(img, 'JPEG', 0, 0, pageWidth / pi, imgHeight / pi / wc, null, 'NONE');
                    }
                } else {
                    const wc = imgWidth / pageWidth;
                    if (imgRatio >= pageRatio) {
                        pdf.addImage(img, 'JPEG', 0, 0, pageWidth, imgHeight / wc, null, 'NONE');
                    } else {
                        const ip = imgRatio / pageRatio;
                        pdf.addImage(img, 'JPEG', 0, 0, pageWidth / ip, imgHeight / ip / wc, null, 'NONE');
                    }
                }

                pdf.save(fileNameNoExtension + '.pdf'); // Generated PDF
            });
        }
    }
}
