import { types, Instance } from 'mobx-state-tree';
import {
    localDateToLocalDateString,
    stringToDate,
    stringToDateTime,
    utcDateToLocalDateString
} from '@app/shared/utils/date-helper';
import { resource } from '@app/shared/utils/mobx-mixins';

export const JobModel = resource(
    'jobs',
    types
        .model({
            id: types.identifierNumber,

            propertyId: types.maybeNull(types.number),
            propertyCode: types.maybeNull(types.string),
            propertyName: types.maybeNull(types.string),
            propertyDescription: types.maybeNull(types.string),

            unitId: types.maybeNull(types.number),

            unitCode: types.maybeNull(types.string),
            buildingId: types.maybeNull(types.number),
            floorId: types.maybeNull(types.number),
            roomId: types.maybeNull(types.number),
            vendorId: types.maybeNull(types.number),

            jobStatusId: types.maybeNull(types.number),
            jobStatusDescription: types.maybeNull(types.string),

            jobTypeId: types.maybeNull(types.number),
            jobTypeDescription: types.maybeNull(types.string),

            briefDescription: types.maybeNull(types.string),
            fullDescription: types.maybeNull(types.string),
            jobCode: types.maybeNull(types.string),

            jobSiteAddress: types.maybeNull(types.string),
            jobSiteCity: types.maybeNull(types.string),
            jobSiteProv: types.maybeNull(types.string),
            jobSitePostal: types.maybeNull(types.string),
            splitPropertyList: types.maybeNull(types.string),
            payableDistributionList: types.maybeNull(types.string),
            isBuildingSplit: types.maybeNull(types.boolean),
            geography: types.maybeNull(types.string),
            jobAccounting: types.maybeNull(types.string),
            managementFee: types.maybeNull(types.string),
            plannedBudget: types.maybeNull(types.string),
            projectManager: types.maybeNull(types.string),

            dateBudgetStart: types.maybeNull(
                types.union(types.string, types.Date)
            ),
            dateBudgetEnd: types.maybeNull(
                types.union(types.string, types.Date)
            ),
            dateRequisitioned: types.maybeNull(
                types.union(types.string, types.Date)
            ),
            dateScheduled: types.maybeNull(
                types.union(types.string, types.Date)
            ),
            dateCompleted: types.maybeNull(
                types.union(types.string, types.Date)
            ),
            dateStartRevised: types.maybeNull(
                types.union(types.string, types.Date)
            ),
            dateStartActual: types.maybeNull(
                types.union(types.string, types.Date)
            ),

            jobUserDefined1: types.maybeNull(types.string),
            jobUserDefined2: types.maybeNull(types.string),
            jobUserDefined3: types.maybeNull(types.string),
            jobUserDefined4: types.maybeNull(types.string),
            jobUserDefined5: types.maybeNull(types.string),

            createdDate: types.maybeNull(types.union(types.string, types.Date)),
            createdBy: types.maybeNull(types.string),
            modifiedBy: types.maybeNull(types.string)
        })
        .views((self) => ({
            get createdOnDate() {
                if (self.createdDate) {
                    return stringToDateTime(self.createdDate);
                }
            },
            get budgetStartOnDate() {
                if (self.dateBudgetStart) {
                    return stringToDate(self.dateBudgetStart);
                }
            },
            get budgetEndOnDate() {
                if (self.dateBudgetEnd) {
                    return stringToDate(self.dateBudgetEnd);
                }
            },
            get budgetRequisitionedOnDate() {
                if (self.dateRequisitioned) {
                    return stringToDate(self.dateRequisitioned);
                }
            },
            get scheduledOnDate() {
                if (self.dateScheduled) {
                    return stringToDate(self.dateScheduled);
                }
            },
            get completedOnDate() {
                if (self.dateCompleted) {
                    return stringToDate(self.dateCompleted);
                }
            },
            get startRevisedOnDate() {
                if (self.dateStartRevised) {
                    return stringToDate(self.dateStartRevised);
                }
            },
            get startActualOnDate() {
                if (self.dateStartActual) {
                    return stringToDate(self.dateStartActual);
                }
            },
            get icon() {
                if (self.jobTypeId) {
                    if (self.jobTypeId === 18) {
                        return 'fas fa-paint-roller';
                    }
                    if (self.jobTypeId === 19) {
                        return 'fas fa-tools';
                    }
                    return 'fas fa-hard-hat';
                }
            },
            get iconColor(): string {
                if (self.jobStatusId) {
                    if (self.jobStatusId === 1) {
                        return '#a7e1a5';
                    }
                    if (self.jobStatusId === 2) {
                        return '#558b2f';
                    }
                    if (self.jobStatusId === 3) {
                        return '#B0BEC5';
                    }
                    if (self.jobStatusId === 4) {
                        return '#febcaf';
                    }
                    if (self.jobStatusId === 5) {
                        return '#8fa2ac';
                    }
                    if (self.jobStatusId === 6) {
                        return '#dac659';
                    }
                    if (self.jobStatusId === 7) {
                        return '#c5e1a5';
                    }
                    if (self.jobStatusId === 8) {
                        return '#Fe8571';
                    }
                }
            }
        }))
        .views((self) => ({
            get createdOnDateString() {
                if (self.createdDate) {
                    return utcDateToLocalDateString(
                        self.createdDate,
                        'mediumDate'
                    );
                }
            },
            get budgetStartOnDateString() {
                if (self.dateBudgetStart) {
                    return localDateToLocalDateString(
                        self.dateBudgetStart,
                        'mediumDate'
                    );
                }
            },
            get budgetEndOnDateString() {
                if (self.dateRequisitioned) {
                    return localDateToLocalDateString(
                        self.dateBudgetEnd,
                        'mediumDate'
                    );
                }
            },
            get budgetRequisitionedOnDateString() {
                if (self.dateRequisitioned) {
                    return localDateToLocalDateString(
                        self.dateRequisitioned,
                        'mediumDate'
                    );
                }
            },
            get scheduledOnDateString() {
                if (self.dateScheduled) {
                    return localDateToLocalDateString(
                        self.dateScheduled,
                        'mediumDate'
                    );
                }
            },
            get completedOnDateString() {
                if (self.dateCompleted) {
                    return localDateToLocalDateString(
                        self.dateCompleted,
                        'mediumDate'
                    );
                }
            },
            get startRevisedOnDateString() {
                if (self.dateStartRevised) {
                    return localDateToLocalDateString(
                        self.dateStartRevised,
                        'mediumDate'
                    );
                }
            },
            get startActualOnDateString() {
                if (self.dateStartActual) {
                    return localDateToLocalDateString(
                        self.dateStartActual,
                        'mediumDate'
                    );
                }
            }
        }))
        .actions((self) => ({
            // setter action example
            // setJobType: (value: number) => (self.jobType = value),
        }))
);

type JobModelType = Instance<typeof JobModel>;
export interface IJobModel extends JobModelType {}
