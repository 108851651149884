import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    IDropdownModelType,
    ISelectItem
} from '@app/shared/models/goldeye-select-item';
import { SelectItem } from 'primeng/api';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-goldeye-radio-list',
    templateUrl: './radio-list.component.html',
    styleUrls: ['./radio-list.component.scss']
})
export class RadioButtonListComponent implements OnInit {
    @Input() label: string;
    @Input() name: string;
    @Input() options: Array<ISelectItem | SelectItem>;
    @Input() disabled: boolean;
    @Input() required: boolean;

    @Input() labelClass?: string;
    @Input() inputClass?: string;
    @Input() radioButtonClass?: string;

    @Input() model: number;
    @Output() change = new EventEmitter<SelectItem>();

    ngOnInit() {
        this.labelClass = this.labelClass || 'p-col-12 p-md-5';
        this.inputClass = this.inputClass || 'p-col-12 p-md-7';
        this.radioButtonClass = this.radioButtonClass || 'p-col-12 p-md-12';
    }

    onChange(option: SelectItem) {
        this.change.emit(option);
    }
}
